import { Flex } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
import { EmpresasTable } from "./views/Table/EmpresasTable";
import { Helmet } from "react-helmet";
import { TabsInformationEmpresa } from "./views/Information/TabsInformation";

export const Empresas = () => {

    return (
        <Flex
            direction="column"
            w="100%"
        >
            <Helmet>
                <title>Empresas | Proacademy</title>
            </Helmet>
            
            <Flex direction="column">
                <Routes>
                    <Route
                        index
                        element={
                            <EmpresasTable />
                        }
                    />

                    <Route
                        path='/:id'
                        element={
                            <TabsInformationEmpresa />
                        }
                    />
                </Routes>
            </Flex>
        </Flex>
    )
};