import { Badge, Flex, Text, Tooltip } from "@chakra-ui/react"
import { CustomAvatar } from "../../../../../../shared/components/Avatar/CustomAvatar"
import { MatriculasInt } from "../../../../../../interfaces/MatriculasInt"
import { truncate } from "lodash"
import { ColumnProps } from "../../../../../../shared/components/Table/Table"
import { Actions } from "../../../../../../shared/components/TableElements/Actions"
import { isRoleAllowed } from "../../../../../../shared/utils/functions/validateRol"
import { UserRolEnum } from "../../../../../../shared/utils/Types/UserRolEnum"
import { useAuthContex } from "../../../../../../shared/context/auth.context"
import { ColumnAvatarLabelText } from "@imaginagroup/bit-components.ui.columns.column-avatar-label-text"
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text"
import { handleLinkOpen } from "../../../../../../shared/utils/functions/linkOpen"
import { formatTimezone } from "../../../../../../shared/utils/functions/parseDate"
import { useTimeZone } from "../../../../../../shared/hooks/useTimeZone"

interface Props {
    contenido: boolean;
    onEventRemove: (event: any) => void;
}

export const AlumnosColumns = ({
    contenido,
    onEventRemove,
}: Props): ColumnProps[] => {
    const { user } = useAuthContex();
    const timeZone = useTimeZone();

    const columns: ColumnProps[] = [
        {
            header: 'Nombre',
            field: 'nombre',
            key: 'nombre',
            sortable: true,
            body: (rowData: any) => (
                <ColumnAvatarLabelText
                    label={rowData?.nombre + " " + rowData?.apellidos}
                    customIcon={
                        <CustomAvatar name={rowData?.nombre + rowData?.apellidos} src={rowData?.avatar?.url} size="35px" />
                    }
                    link={{
                        to: `/usuarios/alumno/${rowData.id}`,
                    }}
                />
            ),
        },
        {
            header: 'Email',
            field: 'email',
            key: 'email',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.email}
                    link={{
                        to: `/usuarios/alumno/${rowData.id}`,

                    }}
                />
            ),
        },
        {
            header: 'Empresa',
            field: 'empresa',
            key: 'empresa',
            body: (rowData: any) => {
                return (
                    <ColumnSimpleText
                        text={rowData?.empresa?.nombre}
                        link={{
                            to: `/usuarios/alumno/${rowData.id}`,
                        }}
                    />
                )
            },
        },
        {
            header: 'Teléfono',
            field: 'telefono',
            key: 'telefono',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.telefono}
                    link={{
                        to: `/usuarios/alumno/${rowData.id}`,

                    }}
                />
            ),
        },
        {
            header: 'grupos',
            field: 'grupo',
            key: 'grupo',
            body: (rowData: any) => (
                <Flex
                    align="center" fontSize="13px"
                    fontWeight="500"
                    letterSpacing="0.26p"
                    textTransform="uppercase"
                    rounded="5px"
                    p="5px 10px"
                    color="font"
                    overflowX="auto"
                    maxW="300px"
                    css={{
                        '&::-webkit-scrollbar': {
                            height: '8px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: "light_grey",
                        },
                    }}
                >
                    {rowData?.matriculas?.length > 0 ? (
                        <Flex gap="5px">
                            {rowData?.matriculas?.map((matricula: MatriculasInt, index: number) => (
                                <Tooltip
                                    label={matricula?.grupo?.estado === "0" ? "Activo" : matricula?.grupo?.estado === "1" ? "Próximo" : "Inactivo"}
                                    hasArrow
                                    key={index}>
                                    <Text
                                        color={
                                            matricula?.grupo?.estado === "0" ? "success_main" :
                                                matricula?.grupo?.estado === "1" ? "secondary" :
                                                    matricula?.grupo?.estado === "2" ? "dark_grey" :
                                                        "black"
                                        }
                                        bg={
                                            matricula?.grupo?.estado === "0" ? "success_bg" :
                                                matricula?.grupo?.estado === "1" ? "variant" :
                                                    matricula?.grupo?.estado === "2" ? "white" :
                                                        "black"
                                        }
                                        borderColor={
                                            matricula?.grupo?.estado === "0" ? "success_variant" :
                                                matricula?.grupo?.estado === "1" ? "variant" :
                                                    matricula?.grupo?.estado === "2" ? "light_grey" :
                                                        "transparent"
                                        }
                                        borderWidth="1px"
                                        rounded="14px"
                                        p="5px 8px"
                                        whiteSpace="nowrap"
                                    >
                                        {truncate(matricula?.grupo?.nombre, { length: 8 })}
                                    </Text>
                                </Tooltip>
                            ))}
                        </Flex>
                    ) : (
                        <Text>--</Text>
                    )}
                </Flex>
            )
        },
        {
            header: 'Estado',
            field: 'estado',
            key: 'estado',
            body: (rowData: any) => (
                <Tooltip
                    label={
                        <Flex
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <Text>
                                Último acceso
                            </Text>
                            <Text>
                                {
                                    (rowData?.sesiones && rowData?.sesiones?.length !== 0)
                                        && rowData?.sesiones[rowData?.sesiones?.length - 1]?.fechaAcceso
                                        ? formatTimezone({ date: rowData?.sesiones[rowData?.sesiones?.length - 1]?.fechaAcceso, timeZone, customFormat: "dd/MM/yyyy HH:mm" })
                                        : "Sin accesos"
                                }
                            </Text>
                        </Flex>
                    }
                    isDisabled={!rowData?.activo}
                    hasArrow
                >
                    <Badge
                        color={rowData?.activo ? "success_main" : "font"}
                        bg={rowData?.activo ? "success_bg" : "light_grey"}
                        borderWidth="1px"
                        borderColor={rowData?.activo ? "success_variant" : "font"}
                        fontSize="10px"
                        fontWeight="500"
                        letterSpacing="0.2px"
                        textTransform="uppercase"
                        borderRadius="14px"
                        textAlign="center"
                        rounded="14px"
                        width="65px"
                        p="5px 8px"
                    >
                        {
                            rowData?.activo ? "Activo" : "Inactivo"
                        }
                    </Badge>
                </Tooltip>
            )
        }
    ]

    const actionsColumn: ColumnProps[] = [
        {
            header: '',
            field: '',
            key: '',
            body: (rowData: any) => (
                <Flex pos="relative">
                    <Actions
                        remove={{
                            title: "Eliminar alumno",
                            onClick: () => {
                                onEventRemove(rowData)
                            },
                            isDisabled: contenido,
                            disableAlert: "No tienes permisos para esta acción"
                        }}
                    />
                </Flex>
            )
        },
    ]

    return (isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre)) ? [...columns, ...actionsColumn] : columns
}
