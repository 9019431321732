import { Flex, Skeleton, Text } from "@chakra-ui/react";
import { CorreccionesTable } from "./Correcciones/Table/CorreccionesTable";
import { MensajesTable } from "./Mensajes/Table/MensajesTable";
import { useEffect, useState } from "react";
import { BiChat, BiDetail, BiEnvelope } from "react-icons/bi";
import { MdOutlineLabelImportant } from "react-icons/md";
import { AtencionTable } from "./Atencion/Table/AtencionTable";
import { isRoleAllowed } from "../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../shared/utils/Types/UserRolEnum";
import { useAuthContex } from "../../../shared/context/auth.context";
import { Topbar } from "../../../shared/components/Topbar/Topbar";
import { useModuloFundae } from "../../../shared/hooks/ModuloFundae";
import { ForoTable } from "./Foro/Table/ForoTable";
import { TabsComponentInt } from "../../../interfaces/ComponentsInt";
import { CustomTabs } from "@imaginagroup/bit-components.ui.custom-tabs";
import { customTabsStyles } from "../../../ui/bitStyles";

export const TabsSeguimiento = () => {
    const moduloFUNDAE = useModuloFundae();
    const { user } = useAuthContex();
    const [tabIndex, setTabIndex] = useState<number>(0)
    const handleTabsChange = (index: number) => setTabIndex(index)

    const tabs: TabsComponentInt[] = [
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            label: "Correcciones pendientes",
            icon: BiChat,
            content: <CorreccionesTable />
        },
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            label: "Mensajes pendientes",
            icon: BiEnvelope,
            content: <MensajesTable />
        },
        {
            isAllowed: moduloFUNDAE,
            label: "Requieren atención",
            icon: MdOutlineLabelImportant,
            content: <AtencionTable />
        },
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            label: "Dudas foro",
            icon: BiDetail,
            content: <ForoTable />
        }
    ]

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Pendientes
                </Text>
            }
        >
            <Flex
                direction="column"
                w="100%"
            >
                <Flex
                    direction="column"
                    p="30px"
                >
                    <CustomTabs
                        ui={customTabsStyles}
                        tabs={tabs}
                        tabIndex={tabIndex}
                        handleTabsChange={handleTabsChange}
                    />
                </Flex>
            </Flex>
        </Topbar>
    )
};