import { Flex, Skeleton } from "@chakra-ui/react"

export const SkeletonContenido = () => {
    return(
        <Flex
            w="full"
        >
            <Skeleton w="400px" h="796px" rounded="8px" />
        </Flex>
    )
}