import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, ModalFooter, Button, useToast, Box, FormLabel, Text, useDisclosure, ModalCloseButton, Spinner, Checkbox, Image } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { StatusEnumTypes } from "../../../shared/utils/Types/StatusEnumTypes";
import { toastNotify } from "../../../shared/utils/functions/toastNotify";
import { useEffect, useRef, useState } from "react";
import { FormAsyncSelect } from "@imaginagroup/bit-components.ui.form-async-select";
import { handleErrors } from "../../../shared/utils/functions/handleErrors";
import { FormDateInput } from "@imaginagroup/bit-components.ui.form-date-input";
import { useClient } from "../../../shared/context/client.context";
import { NewEmpresaModal } from "../../Empresas/components/NewEmpresaModal";
import { useNavigate } from "react-router-dom";
import { CustomSwitch } from "../../../shared/components/CustomElements/CustomSwitch";
import { CustomShowInput } from "../../../shared/components/CustomElements/CustomShowInput";
import { EmpresasInt } from "../../../interfaces/EmpresasInt";
import { useData } from "../../../shared/hooks/useData";
import { EndpointTypes } from "../../../shared/utils/Types/EndpointTypes";
import { ModalCloseAlert } from "../../../shared/components/Modals/ModalCloseAlert";
import { loadData } from "../../../shared/utils/functions/loadData";
import { QueryTypes } from "../../../shared/utils/Types/QueryTypes";
import { FormSelect } from "@imaginagroup/bit-components.ui.form-select";
import { UserRolEnum } from "../../../shared/utils/Types/UserRolEnum";
import { useAuthContex } from "../../../shared/context/auth.context";
import { useUserPolicy } from "../../../shared/hooks/PermissionPolicy";
import { parseDate } from "../../../shared/utils/functions/parseDate";
import { formSelectStyles } from "../../../ui/formSelectStyles";
import { useTimeZone } from "../../../shared/hooks/useTimeZone";
import { DateTime } from "luxon";
import { RutasInt } from "../../../interfaces/RutasInt";
import { CursosInt } from "../../../interfaces/CursosInt";
import { defaultIcon } from "../../Onboarding/Onboarding";
import { addGrupoRuta } from "../../../shared/middlewares/rutas.middleware";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    setRefreshTable: () => void;
    ruta: RutasInt;
}
export const NewGrupoRutaModal = ({
    isOpen,
    onClose,
    setRefreshTable,
    ruta
}: Props) => {
    const timeZone = useTimeZone();
    const client = useClient();
    const { user, refreshUser } = useAuthContex();
    const { total, gestion, contenido } = useUserPolicy();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();
    const { isOpen: isOpenEmpresa, onOpen: onOpenEmpresa, onClose: onCloseEmpresa } = useDisclosure();
    const toast = useToast();
    const [newEmpresa, setNewEmpresa] = useState<EmpresasInt | null>(null)
    const selectedEmpresa = useRef<string | null | undefined>(null);
    const selectedFechaInicio = useRef<string | null>(null);
    const selectedFechaFin = useRef<string | null>(null);
    const [isUniqueEmpresa, setIsUniqueEmpresa] = useState<boolean>(true)
    const [empresaDefectoId, setEmpresaDefectoId] = useState<string | null>(null)
    const { data: empresas } = useData({
        client: client,
        endpoint: EndpointTypes.EMPRESA,
        query: {
            interna: "true",
        },
        ignoreRequest: (user?.role?.nombre !== UserRolEnum.ADMIN || gestion || contenido),
    })

    useEffect(() => {
        if (empresas && empresas?.data?.length > 0) {
            setIsUniqueEmpresa(empresas?.meta?.total === 1)

            if (empresas?.meta?.total === 1) {
                setEmpresaDefectoId(empresas?.data[0]?.id)
            }
        }
    }, [empresas])

    const initialValues = {
        empresaId: selectedEmpresa.current,
        empresaOrganizadoraId: null,
        fechaInicio: selectedFechaInicio.current,
        fechaFin: selectedFechaFin.current,
        fundae: true,
        gestionaEmpresa: false,
        nombre: null,
        importeMatricula: null,
        isUniqueEmpresa: isUniqueEmpresa,
        sinFechaInicio: false,
        sinFechaFin: false,
        tutores: [],
        rutaId: ruta?.id,
    };

    const validationSchema = Yup.object({
        isUniqueEmpresa: Yup.boolean(),
        fundae: Yup.boolean(),
        gestionaEmpresa: Yup.boolean(),
        empresaId: Yup.string()
            .notRequired(),
        fechaInicio: Yup.string().when(['sinFechaInicio', 'fundae'], {
            is: (sinFechaInicio: boolean, fundae: boolean) => !sinFechaInicio && fundae,
            then: (validationScheme) => validationScheme
                .required('La fecha de inicio es obligatoria.')
                .typeError('La fecha de inicio es obligatoria.'),
            otherwise: (validationScheme) => validationScheme
                .notRequired(),
        }),
        fechaFin: Yup.string().when(['sinFechaFin', 'fundae'], {
            is: (sinFechaFin: boolean, fundae: boolean) => !sinFechaFin && fundae,
            then: (validationScheme) => validationScheme
                .required('La fecha de fin es obligatoria.')
                .typeError('La fecha de fin es obligatoria.'),
            otherwise: (validationScheme) => validationScheme
                .notRequired(),
        }),
        empresaOrganizadoraId: Yup.string().when(['isUniqueEmpresa'], {
            is: (isUniqueEmpresa: boolean) => isUniqueEmpresa,
            then: (validationScheme) => validationScheme
                .notRequired(),
            otherwise: (validationScheme) => validationScheme
                .required('La empresa factura es obligatoria.')
                .typeError('La empresa factura es obligatoria.'),
        }),
        importeMatricula: Yup.number()
            .required('El importe de la matrícula es obligatorio.')
            .typeError('El importe de la matrícula es obligatorio.'),
        nombre: Yup.string().notRequired().nullable(),
        sinFechaInicio: Yup.boolean(),
        sinFechaFin: Yup.boolean(),
        estado: Yup.string().when(['sinFechaFin', "fundae"], {
            is: (sinFechaFin: boolean, fundae: boolean) => sinFechaFin && !fundae,
            then: (validationScheme) => validationScheme
                .required('Debe asignar un estado al grupo.')
                .typeError('Debe asignar un estado al grupo.'),
            otherwise: (validationScheme) => validationScheme
                .notRequired(),
        }),
        tutores: Yup.array().of(Yup.object().shape({
            cursoId: Yup.string().notRequired(),
            tutorId: Yup.string().required('El tutor es obligatorio.')
        }))
            .min(ruta?.cursos?.length || 0, 'Debe asignar un tutor a cada curso.'),
        rutaId: Yup.string().required()
    })

    const handleSubmit = (values: any) => {
        setIsLoading(true)

        let fechaInicio = parseDate(values.fechaInicio) + "T12:00"
        let fechaFin = parseDate(values.fechaFin) + "T12:00"

        fechaInicio = DateTime.fromISO(fechaInicio).setZone(timeZone).startOf('day').toString()
        fechaFin = DateTime.fromISO(fechaFin).setZone(timeZone).endOf('day').toString()

        let tutores = {}
        values.tutores.map((t: { cursoId: string; tutorId: string; }) => tutores = {
            ...tutores,
            [t.cursoId]: t.tutorId
        })

        let newGrupo: any = {
            nombre: values.nombre,
            rutaId: values.rutaId,
            modalidad: "teleformacion",
            perfil: "organizadora",
            fechaInicio: fechaInicio,
            fechaFin: fechaFin,
            fundae: values?.fundae,
            permitirDescargaDiploma: values?.fundae ? false : true,
            empresas: newEmpresa ? [newEmpresa?.id] : [values.empresaId],
            empresaOrganizadoraId: isUniqueEmpresa ? empresaDefectoId : values.empresaOrganizadoraId,
            importeMatricula: values?.importeMatricula,
            tutores: tutores
        }

        if (values?.fundae) {
            newGrupo = {
                ...newGrupo,
                gestionaEmpresa: values?.gestionaEmpresa,
            }
        }

        addGrupoRuta({
            ruta: newGrupo,
            client: client
        })
            .then(() => {
                setRefreshTable();
                handleClose();

                toastNotify(toast, StatusEnumTypes.SUCCESS, `Se ha creado el curso ${newGrupo.nombre}`)
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleClose = () => {
        onClose()
        selectedEmpresa.current = null
        selectedFechaInicio.current = null
        selectedFechaFin.current = null
    }

    const loadCursos = async (value: string) => await loadData({
        value: value,
        endpoint: EndpointTypes.CURSOS,
        client: client,
        query: QueryTypes.NOMBRE,
        specificQuery: QueryTypes.ACTIVO
    })

    const loadEmpresa = async (value: string) => await loadData({
        value: value,
        endpoint: EndpointTypes.EMPRESA,
        client: client,
        query: QueryTypes.NOMBRE,
    })

    const loadTutores = async (value: string) => await loadData({
        value: value,
        endpoint: EndpointTypes.USUARIOS,
        client: client,
        query: QueryTypes.NOMBRE_EMAIL,
        specificQuery: QueryTypes.ROL_TUTOR,
        nombreCompleto: true
    })

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => null}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                size="xl"
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    maxH="95vh"
                    rounded="20px"
                >
                    <Box
                        color="black"
                    >
                        <ModalHeader
                            textAlign="start"
                            fontSize="24px"
                            fontWeight="600"
                            px="30px"
                            pt="30px"
                            pb="0px"
                        >
                            Nuevo grupo
                        </ModalHeader>

                        <ModalCloseButton onClick={() => onOpenAlert()} />
                    </Box>

                    <Box
                        overflowY="auto"
                        overflowX="hidden"
                        __css={{
                            '&::-webkit-scrollbar': {
                                w: '7px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '20px',
                                bg: `light_grey`,
                            },
                        }}
                    >
                        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                            {(formik) => {
                                const { handleSubmit, values } = formik;

                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                    >
                                        <ModalBody
                                            pt="30px"
                                            px="30px"
                                            pb="0px"
                                        >
                                            <Flex
                                                direction="column"
                                                gap="15px"
                                            >
                                                <Flex
                                                    alignItems='center'
                                                    gap="10px"
                                                >
                                                    <CustomSwitch
                                                        label="Bonificado por FUNDAE"
                                                        value={!formik.values?.fundae}
                                                        setValue={() => formik.setFieldValue('fundae', !formik.values.fundae)}
                                                        onClick={() => {
                                                            selectedEmpresa.current = values?.empresaId
                                                            selectedFechaInicio.current = values?.fechaInicio
                                                            selectedFechaFin.current = values?.fechaFin
                                                        }}
                                                        optionLeft="NO"
                                                        optionRight="Sí"
                                                        width="40px"
                                                        height="30px"
                                                        widhtLabel="100%"
                                                        widthSwitch="20%"
                                                        padding="0"
                                                        labelStyles={{
                                                            color: "black",
                                                            fontSize: "18px",
                                                            fontWeight: 600,
                                                            letterSpacing: "-0.24px",
                                                            width: "80%"
                                                        }}
                                                    />
                                                </Flex>

                                                <Flex
                                                    direction="column"
                                                    gap="20px"
                                                >
                                                    {values?.fundae &&
                                                        <Flex
                                                            direction="column"
                                                        >
                                                            <FormLabel
                                                                htmlFor="gestionaEmpresa"
                                                                display="flex"
                                                                gap="3px"
                                                                color="black"
                                                                fontSize="14px"
                                                                fontWeight="400"
                                                                textTransform="capitalize"
                                                            >
                                                                Gestionado por
                                                            </FormLabel>

                                                            <CustomSwitch
                                                                value={!formik.values?.gestionaEmpresa}
                                                                setValue={() => formik.setFieldValue('gestionaEmpresa', !formik.values.gestionaEmpresa)}
                                                                onClick={() => {
                                                                    selectedEmpresa.current = values?.empresaId
                                                                    selectedFechaInicio.current = values?.fechaInicio
                                                                    selectedFechaFin.current = values?.fechaFin
                                                                }}
                                                                optionLeft="Interna"
                                                                optionRight="Externa"
                                                                height="25px"
                                                                width="260px"
                                                                widhtLabel="100%"
                                                                widthSwitch="100%"
                                                                padding="0"
                                                            />
                                                        </Flex>
                                                    }

                                                    <Flex
                                                        direction="column"
                                                    >
                                                        {!isUniqueEmpresa &&
                                                            <FormSelect
                                                                name="empresaOrganizadoraId"
                                                                label={"Empresa Organizadora / Factura"}
                                                                noOptionsMessage={
                                                                    () => (
                                                                        <Flex
                                                                            w="100%"
                                                                            justifyContent="center"
                                                                            alignItems="center"
                                                                        >
                                                                            <Spinner
                                                                                color="secondary"
                                                                                size="xs"
                                                                            />
                                                                        </Flex>
                                                                    )
                                                                }
                                                                options={
                                                                    (empresas === undefined || empresas === null)
                                                                        ? []
                                                                        : [...empresas?.data?.map((empresa: EmpresasInt) => ({ value: empresa?.id, label: empresa?.nombre }))]
                                                                }
                                                                placeholder="Empresa asociada"
                                                                isRequired={!values.empresaOrganizadoraId ? true : false}
                                                                ui={{
                                                                    formSelectStyles: formSelectStyles,
                                                                }}
                                                            />
                                                        }

                                                        <FormInput
                                                            name="importeMatricula"
                                                            type="number"
                                                            label="Importe de la matrícula"
                                                            placeholder="Indica el importe de la matrícula"
                                                            isRequired={!values.importeMatricula ? true : false}
                                                        />
                                                    </Flex>
                                                </Flex>
                                            </Flex>

                                            <Text
                                                color="black"
                                                fontSize="18px"
                                                fontWeight="600"
                                                mb="15px"
                                            >
                                                Datos del grupo
                                            </Text>

                                            <Flex direction="column" gap="20px">
                                                <Flex
                                                    direction="column"
                                                >
                                                    <FormInput
                                                        name="nombre"
                                                        label="Nombre del grupo"
                                                        placeholder="Nombre del grupo"
                                                    />

                                                    {ruta?.cursos?.map((curso: CursosInt, index: number) => (
                                                        <Flex
                                                            alignItems="center"
                                                            gap="10px"
                                                        >
                                                            <Image
                                                                src={curso?.icono?.url || `data:image/svg+xml;utf8,${defaultIcon}`}
                                                                padding="0"
                                                                boxSize="50px"
                                                            />

                                                            <Flex
                                                                direction="column"
                                                                flex="100%"
                                                            >
                                                                <Text
                                                                    color="font"
                                                                    fontSize="14px"
                                                                    fontWeight="400"
                                                                >
                                                                    Tutor del curso de: <Text as="strong">{curso?.nombre}</Text>
                                                                </Text>

                                                                <Flex
                                                                    w="100%"
                                                                >
                                                                    <FormAsyncSelect
                                                                        name={`tutores[${index}].tutorId`}
                                                                        loadOptions={loadTutores}
                                                                        placeholder="Tutor"
                                                                        isRequired={true}
                                                                        onChangeCallback={() => formik.setFieldValue(`tutores[${index}].cursoId`, curso?.id)}
                                                                        ui={{
                                                                            formSelectStyles: formSelectStyles
                                                                        }}
                                                                    />
                                                                </Flex>
                                                            </Flex>
                                                        </Flex>
                                                    ))}
                                                </Flex>


                                                <Text
                                                    color="black"
                                                    fontSize="18px"
                                                    fontWeight="600"
                                                >
                                                    Datos de la formación
                                                </Text>

                                                {newEmpresa ?
                                                    <CustomShowInput
                                                        value={newEmpresa?.nombre}
                                                        label="Empresa"
                                                        placeholder="Empresa asociada"
                                                        isChangeable={true}
                                                        valueChange={setNewEmpresa}
                                                    />
                                                    :
                                                    <FormAsyncSelect
                                                        name="empresaId"
                                                        label="Empresa"
                                                        defaultOptions={true}
                                                        loadOptions={loadEmpresa}
                                                        placeholder="Empresa asociada"
                                                        isRequired={!values.empresaId ? true : false}
                                                        ui={{
                                                            formSelectStyles: formSelectStyles
                                                        }}
                                                    />
                                                }

                                                <Flex alignItems="center" gap="20px">
                                                    <Text
                                                        color="#67748E"
                                                        fontSize="13px"
                                                        fontWeight="500"
                                                        lineHeight="16px"
                                                        letterSpacing="-0.11px"
                                                    >
                                                        ¿No encuentras la empresa?
                                                    </Text>

                                                    <Text
                                                        cursor="pointer"
                                                        color="black"
                                                        fontSize="13px"
                                                        fontWeight="600"
                                                        letterSpacing="-0.11px"
                                                        onClick={onOpenEmpresa}
                                                        _hover={{ textDecoration: "underline" }}
                                                    >
                                                        Crear empresa
                                                    </Text>
                                                </Flex>

                                                <Flex
                                                    gap="15px"
                                                    alignItems="center"
                                                >
                                                    <Flex
                                                        gap="10px"
                                                        direction="column"
                                                        w="100%"
                                                        justifyContent="center"
                                                    >
                                                        {!values?.fundae &&
                                                            <Checkbox
                                                                gap="0"
                                                                alignItems="center"
                                                                isChecked={values.sinFechaInicio}
                                                                onChange={() => formik.setFieldValue("sinFechaInicio", !values.sinFechaInicio)}
                                                            >
                                                                Grupo sin fecha inicio
                                                            </Checkbox>
                                                        }

                                                        <FormDateInput
                                                            locale="es"
                                                            name="fechaInicio"
                                                            label="Fecha Inicio"
                                                            isRequired={!values.fechaInicio ? true : false}
                                                            onChange={formik.setFieldValue}
                                                            placeholder="Día/Mes/Año"
                                                            isDisabled={values.sinFechaInicio}
                                                        />
                                                    </Flex>

                                                    <Flex
                                                        gap="10px"
                                                        direction="column"
                                                        w="100%"
                                                        justifyContent="center"
                                                    >
                                                        {!values?.fundae &&
                                                            <Checkbox
                                                                gap="0"
                                                                alignItems="center"
                                                                isChecked={values.sinFechaFin}
                                                                onChange={() => formik.setFieldValue("sinFechaFin", !values.sinFechaFin)}
                                                            >
                                                                Grupo sin fecha fin
                                                            </Checkbox>
                                                        }

                                                        <FormDateInput
                                                            locale="es"
                                                            name="fechaFin"
                                                            label="Fecha Fin"
                                                            isRequired={!values.fechaFin ? true : false}
                                                            onChange={formik.setFieldValue}
                                                            placeholder="Día/Mes/Año"
                                                            isDisabled={values.sinFechaFin}
                                                        />
                                                    </Flex>
                                                </Flex>

                                                {values?.sinFechaFin &&
                                                    <Flex
                                                        direction="column"
                                                        alignItems="center"
                                                        justifyContent={"center"}
                                                    >
                                                        <Text
                                                            fontSize="15px"
                                                            color="secondary"
                                                            textAlign={"center"}
                                                        >
                                                            Si el grupo no tiene fecha fin debe asignar un estado manual.
                                                        </Text>

                                                        <FormSelect
                                                            name="estado"
                                                            options={[
                                                                { value: "2", label: "Inactivo" },
                                                                { value: "0", label: "Activo" },
                                                            ]}
                                                            placeholder="Estado"
                                                            ui={{
                                                                formSelectStyles: formSelectStyles,
                                                            }}
                                                        />
                                                    </Flex>
                                                }
                                            </Flex>
                                        </ModalBody>

                                        <ModalFooter justifyContent="center">
                                            <Button
                                                isLoading={isLoading}
                                                type="submit"
                                                bg="secondary"
                                                p="10px 25px"
                                                h="fit-content"
                                                rounded="5"
                                                color="purewhite"
                                                fontSize="18px"
                                                fontWeight="500"
                                                _hover={{ color: "purewhite", backgroundColor: "variant" }}
                                            >
                                                Crear grupo
                                            </Button>
                                        </ModalFooter>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Box>
                </ModalContent>
            </Modal>

            <ModalCloseAlert
                isOpen={isOpenAlert}
                onClose={onCloseAlert}
                onCloseForm={handleClose}
            />

            <NewEmpresaModal
                isOpen={isOpenEmpresa}
                onClose={onCloseEmpresa}
                selectEmpresaGrupo={setNewEmpresa}
            />
        </>
    )
}