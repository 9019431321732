import { Box, Flex, Text } from "@chakra-ui/react";
import { UserRolEnum } from "../../utils/Types/UserRolEnum";
import { isRoleAllowed } from "../../utils/functions/validateRol";
import { useAuthContex } from "../../context/auth.context";
import { FormikErrors } from "formik";

interface Props {
    label?: string;
    optionRight: string;
    optionLeft: string;
    width?: string;
    height?: string;
    value: boolean | undefined;
    setValue: any;
    colorSwitch?: string;
    onClick?: (event?: boolean) => void;
    allowToEdit?: UserRolEnum[]
    labelStyles?: any;
    padding?: string;
    widhtLabel?: string;
    widthSwitch?: string;
    isDisabled?: boolean;
    isRow?: boolean;
}

export const CustomSwitch = ({
    label,
    isRow = true,
    optionRight,
    optionLeft,
    value = false,
    width = "50%",
    height = "30px",
    widhtLabel= "30%",
    widthSwitch = "70%",
    padding = "20px",
    setValue,
    colorSwitch = "font",
    onClick = () => { },
    allowToEdit = [],
    labelStyles = {
        flex: "1",
        color: "gray",
        fontSize: "15px",
        fontWeight: "500",
        letterSpacing: "0.24px",
        textTransform: "capitalize"
    },
    isDisabled = false
}: Props) => {
    const { user } = useAuthContex();

    const handleClick = () => {
        if (isRoleAllowed([UserRolEnum.ADMIN, ...allowToEdit], user?.role?.nombre) && !isDisabled) {
            setValue((prev: any) => !prev)
            onClick(!value);
        }
    }

    return (
        <Box
            p={padding}
            w="100%"
        >
            <Flex
                alignItems={isRow ? "center" : "flex-start"}
                w="100%"
                gap={isRow ? "20px" : "10px"}
                direction={isRow ? "row" : "column"}
            >

                {label &&
                    <Flex
                        w={widhtLabel}
                    >
                        <Text
                            {...labelStyles}
                        >
                            {label}
                        </Text>
                    </Flex>
                }

                <Flex
                    pos="relative"
                    w={widthSwitch}
                    borderRadius="6px"
                    border="1px solid"
                    borderColor="light_grey"
                    alignItems="center"
                    bg="bg"
                    mr="-6px"
                    transition="0.5s"
                    opacity={isRoleAllowed([UserRolEnum.ADMIN, ...allowToEdit], user?.role?.nombre) && !isDisabled ? "1" : "0.5"}
                    cursor={isRoleAllowed([UserRolEnum.ADMIN, ...allowToEdit], user?.role?.nombre) && !isDisabled ? "pointer" : "not-allowed"}
                    onClick={handleClick}
                >
                    <Box
                        pos="absolute"
                        h={height}
                        w={width}
                        left={value ? "0" : "50%"}
                        bg={"secondary"}
                        borderRadius="6px"
                        transition="0.5s"
                    />

                    <Text
                        color={value ? "purewhite" : "dark_grey"}
                        transition="0.5s"
                        fontSize="11px"
                        fontWeight="500"
                        letterSpacing="0.22px"
                        textTransform="uppercase"
                        borderRadius="6px"
                        w={width}
                        h={height}
                        p="3px 10px"
                        zIndex="99"
                        textAlign="center"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {optionLeft}
                    </Text>

                    <Text
                        color={!value ? "purewhite" : "dark_grey"}
                        transition="0.5s"
                        fontSize="11px"
                        fontWeight="500"
                        letterSpacing="0.22px"
                        textTransform="uppercase"
                        p="3px 10px"
                        borderRadius="6px"
                        w={width}
                        h={height}
                        zIndex="99"
                        textAlign="center"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {optionRight}
                    </Text>
                </Flex>
            </Flex>
        </Box>
    );
}