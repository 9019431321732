import { Button, Flex, Image, Text } from '@chakra-ui/react';
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { CustomSwitch } from '../../../shared/components/CustomElements/CustomSwitch';
import { InformationFilepond } from '../../../shared/components/Information/InformationFilepond';
import ColorPicker from '../../../shared/components/CustomElements/ColorPicker';
import { useClient } from '../../../shared/context/client.context';
import tinycolor from "tinycolor2";

interface Props {
    formik: any;
    data: any;
    isLoading: boolean;
    Refresh: any;
}

export const OnboardingForm = ({ formik, data, isLoading, Refresh }: Props) => {
    const client = useClient();

    const generateVariants = (baseColor: string) => {
        const colorObj = tinycolor(baseColor);
        const lighter = colorObj.clone().lighten(40).toHexString();
        const darker = colorObj.clone().darken(40).toHexString();

        return { baseColor, darker, lighter };
    };
    const handleColorChange = (formik: any, color: string) => {
        const { baseColor, darker, lighter } = generateVariants(color);

        formik.setFieldValue('colores.main', baseColor);
        formik.setFieldValue('colores.secondary', darker);
        formik.setFieldValue('colores.variant', lighter);
    };

    return (
        <Flex
            direction="column"
            gap="30px"
            w="100%"
            overflowY="auto"
            h={{ lg: '90vh', xl: '75vh' }}
            css={{
                '&::-webkit-scrollbar': {
                    width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                    width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#DFE2E6',
                    borderRadius: '24px',
                },
            }}
        >
            <Text color="pureblack" fontSize="28px" fontWeight="600">
                Personaliza tu campus
            </Text>

            <Flex direction="column" gap="30px" w="100%">
                <Flex direction="column" w="100%">
                    <FormInput
                        name="nombre"
                        label="Nombre del campus"
                        placeholder="Ej: Proacademy"
                        isDisabled={true}
                    />

                    <FormInput
                        name="senderName"
                        label="Nombre remitente de los correos"
                        placeholder="Ej: Proacademy Campus"
                    />

                    <FormInput
                        name="replyTo"
                        label="Correo de respuesta"
                        placeholder="Ej: soporte@proacademy.app"
                    />

                    <CustomSwitch
                        label={'Módulo FUNDAE'}
                        optionLeft="Si"
                        optionRight="No"
                        value={formik.values?.moduloFUNDAE}
                        setValue={() => formik.setFieldValue('moduloFUNDAE', !formik.values.moduloFUNDAE)}
                        isDisabled={false}
                        isRow={false}
                        labelStyles={{
                            display: 'flex',
                            gap: '3px',
                            color: 'font',
                            fontSize: '14px',
                            fontWeight: '400',
                        }}
                        width="50%"
                        height="38px"
                        widhtLabel="100%"
                        widthSwitch="100%"
                        padding="0"
                    />

                    <ColorPicker
                        main={formik.values?.colores?.main}
                        secondary={formik.values?.colores?.secondary}
                        variant={formik.values?.colores?.variant}
                        initialColor={formik.values?.color}
                        onChange={(color: string) => handleColorChange(formik, color)}
                    />

                    <Flex w="100%" mt="30px" gap="20px" alignItems="flex-start">
                        <Flex>
                            {data?.icono ? (
                                <Image m="auto" boxSize="full" rounded="10px" src={data?.icono} />
                            ) : (
                                <Image
                                    m="auto"
                                    boxSize="80px"
                                    rounded="10px"
                                    src={`data:image/svg+xml;utf8, ${defaultIcon}`}
                                />
                            )}
                        </Flex>

                        <Flex w="full">
                            <InformationFilepond
                                method="POST"
                                noHead={true}
                                name="icono"
                                label="Icono"
                                putEP={`${client}/tenant/uploadIcono`}
                                setRefresh={Refresh}
                                labelIdle="Arrastra o selecciona el icono"
                            />
                        </Flex>
                    </Flex>
                </Flex>

                <Flex direction="column">
                    <Button
                        isLoading={isLoading}
                        type="submit"
                        bg="secondary"
                        p="10px 20px"
                        color="purewhite"
                        fontWeight="500"
                        _hover={{ bg: 'main_variant', color: 'secondary' }}
                    >
                        Continuar
                    </Button>
                </Flex>
            </Flex>
        </Flex>

    );
};

export const defaultIcon = `<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="17" y="0.0274948" width="24" height="24" rx="4" transform="rotate(45 17 0.0274948)" fill="%233D3F65" stroke="%233D3F65" stroke-width="2"/>
</svg>`;
