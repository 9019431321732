import { Flex, Link, Skeleton, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { MJMLtoHTML } from "../../../shared/middlewares/plantillas.middleware";
import { useClient } from "../../../shared/context/client.context";
import { Link as LinkRouter } from "react-router-dom";

interface Props {
    plantilla: {
        contenido: string;
        createdAt: string;
        id: string;
        nombre: string;
        tipo: string;
        updatedAt: string;
    }
    loading: boolean;
    error: {
        response: {
            status: number;
            data: {
                errors: any[];
            };
        };
    } | null;
}

export const CardPlantilla = ({
    plantilla,
    loading,
    error
}: Props) => {
    const client = useClient();
    const [preview, setPreview] = useState<string>()

    useEffect(() => {
        if (!loading && !error && plantilla) {
            MJMLtoHTML({
                mjml: plantilla?.contenido,
                client: client
            })
                .then((response) => {
                    const html = response?.data?.html.replace("<!--[if mso]>", scrollBar);

                    const modifiedHtml = `
                        <style>
                            body {
                                margin: 0;
                                padding: 0;
                                overflow: hidden;
                                transform: translate(0px, -315px);
                                transform-origin: top left;
                            }
                        </style>
                        ${html}
                    `;

                    setPreview(modifiedHtml);
                })
                .catch((error) => console.log(error))
        }
    }, [plantilla, loading, error, client]);

    return (
        loading
            ?
            <Skeleton
                minW="420px"
                h="330px"
                rounded="10px"
            />
            :
            <Flex
                direction="column"
                gap="20px"
                pos="relative"
                zIndex={"10"}
            >
                <Flex
                    rounded="20px"
                    minW="20px"
                    h="330px"
                    bg="#E7E7E7"
                    px="20px"
                    pt="20px"
                    overflow={"hidden"}
                >
                    <iframe
                        title="Email Preview"
                        srcDoc={preview}
                        width="100%"
                        height="330px"
                        style={{
                            transform: "scale(1)",
                            transformOrigin: "top left",
                            overflow: "hidden",
                            borderRadius: "20px",
                        }}
                    />

                </Flex>

                <Text
                    fontSize="18px"
                    fontWeight="500"
                    color="pureblack"
                    textTransform="capitalize"
                    ml="10px"
                >
                    {plantilla?.nombre}
                </Text>

                <Link
                    zIndex={"20"}
                    as={LinkRouter}
                    pos={"absolute"}
                    bottom="0"
                    left="0"
                    right="0"
                    top="0"
                    rounded={"10px"}
                    to={`/plantillas/${plantilla?.id}`}
                />
            </Flex>
    )
}

const scrollBar = `<style>
::-webkit-scrollbar {
    width: 0;
}

::-webkit-scrollbar-thumb {
    background-color: transparent;
}
</style>`