import { ExamenInt, CreateExamenInt, PreguntaInt, CreatePreguntaInt, RespuestaInt, CreateRespuestaInt } from '../../interfaces/ExamenInt';
import { ClientInt } from '../services/http.service';
import _axios from "../services/http.service";
import tenantInstance from '../services/tenantInstance.service';


export const updateExamen = async ({ id, data, client }: { id: string, data: ExamenInt, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/examenes/${id}`,
        data,
        {}
    )
};

export const addExamen = async ({ examen, client }: { examen: CreateExamenInt, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/examenes`,
        examen,
        {}
    )
};

export const updatePregunta = async ({ id, data, client }: { id: string, data: PreguntaInt, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/preguntas/${id}`,
        data,
        {}
    )
};

export const addPregunta = async ({ pregunta, client }: { pregunta: CreatePreguntaInt, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/preguntas`,
        pregunta,
        {}
    )
};

export const updateRespuesta = async ({ id, data, client }: { id: string, data: RespuestaInt, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/respuestas/${id}`,
        data,
        {}
    )
};

export const addRespuesta = async ({ respuesta, client }: { respuesta: CreateRespuestaInt, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/respuestas`,
        respuesta,
        {}
    )
};