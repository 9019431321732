import { Flex } from "@chakra-ui/react"
import { Route, Routes } from "react-router-dom"
import { GruposTable } from "../Grupos/views/Table/GruposTable"
import { InformationInformes } from "./views/Information";
import { Helmet } from "react-helmet";

export const Informes = () => {
    return (
        <Flex
            direction="column"
            w="100%"
        >
            <Helmet>
                <title>Informes | Proacademy</title>
            </Helmet>
            <Flex direction="column">
                <Routes>
                    <Route
                        index
                        element={
                            <GruposTable />
                        }
                    />

                    <Route
                        path='/:id'
                        element={
                            <InformationInformes />
                        }
                    />
                </Routes>
            </Flex>
        </Flex>
    )
}