import { Flex, Text, useToast } from "@chakra-ui/react"
import { useParams } from "react-router-dom"
import { Topbar } from "../../../../shared/components/Topbar/Topbar";
import { useState } from "react";
import { BiBookContent, BiUserCircle } from "react-icons/bi";
import { TabPerfilExamen } from "./TabItem/TabPerfil";
import { TabContenidoExamen } from "./TabItem/TabContenido";
import { updateExamen } from "../../../../shared/middlewares/examenes.middleware";
import { useClient } from "../../../../shared/context/client.context";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { useDataId } from "../../../../shared/hooks/useDataId";
import { TabsComponentInt } from "../../../../interfaces/ComponentsInt";
import { CustomTabs } from "@imaginagroup/bit-components.ui.custom-tabs";
import { customTabsStyles } from "../../../../ui/bitStyles";

export const TabsExamenesInformation = () => {
    const { id } = useParams();
    const client = useClient();
    const toast = useToast();
    const { data: examen, loading, Refresh } = useDataId({
        id: id,
        endpoint: EndpointTypes.EXAMENES,
        client: client
    });

    const [tabIndex, setTabIndex] = useState<number>(0)
    const handleTabsChange = (index: number) => setTabIndex(index)

    const updateValue = async (value: any) => {
        if (!id) return;

        return updateExamen({
            id: id,
            data: value,
            client: client
        })
            .then(() => {
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Campo actualizado correctamente")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    };

    const tabs: TabsComponentInt[] = [
        {
            isAllowed: true,
            label: "Perfil",
            icon: BiUserCircle,
            content:
                <TabPerfilExamen
                    examen={examen}
                    updateValue={updateValue}
                    loading={loading}
                    Refresh={Refresh}
                />
        },
        {
            isAllowed: true,
            label: "Contenido",
            icon: BiBookContent,
            content:
                <TabContenidoExamen
                    loading={loading}
                    examen={examen}
                    Refresh={Refresh}
                />
        },

        // TODO: Proxima implementación
        // {
        //     isAllowed: true,
        //     label: "Audits",
        //     icon: RiSwapBoxLine,
        //     content:
        //         <TabAudits
        //             tipo={AuditsTipoEnum.EXAMEN}
        //         />
        // }
    ];

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Exámenes
                </Text>
            }
            buttonBack
        >
            <Flex
                direction="column"
                px="20px"
                pt="20px"
                width="100%"
            >
                <CustomTabs
                    ui={customTabsStyles}
                    tabs={tabs}
                    tabIndex={tabIndex}
                    handleTabsChange={handleTabsChange}
                />
            </Flex>
        </Topbar>
    )
}