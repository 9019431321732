import { Flex, useDisclosure } from "@chakra-ui/react"
import { useClient } from "../../../../../shared/context/client.context";
import { useRef } from "react";
import { NewAnuncioModal } from "../../../components/modals/NewAnuncioModal";
import { AnunciosInt } from "../../../../../interfaces/AnunciosInt";
import { AnunciosDrawer } from "../../../components/Drawer/AnunciosDrawer";
import { useData } from "../../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { useParams } from "react-router";
import { checkQuerys } from "../../../../../shared/utils/functions/checkQuerys";
import { Table } from "../../../../../shared/components/Table/Table";
import { AnunciosColumns } from "../../../components/TabsColumns/AnunciosColumns";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";

interface Props {
    isOpenAnuncio: boolean;
    onCloseAnuncio: () => void;
    cursoId: string;
}

export const TabAnuncios = ({ isOpenAnuncio, onCloseAnuncio, cursoId }: Props) => {
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.ANUNCIOS_CURSO });
    const { id } = useParams();
    const client = useClient();
    const { isOpen: isOpenDrawer, onOpen: onOpenDrawer, onClose: onCloseDrawer } = useDisclosure()
    const { data: anuncios, loading, Refresh } = useData({
        client: client,
        endpoint: EndpointTypes.ANUNCIOS,
        specificQuery: {
            curso: id!
        },
        query: query,
        ignoreRequest: !id
    })

    const anuncioRef = useRef<AnunciosInt | null>(null)

    const onRowClick = ({ data }: any) => {
        anuncioRef.current = data;
        onOpenDrawer()
    }

    return (
        <Flex>
            <Table
                query={query}
                setQuery={setQuery}
                data={anuncios?.data || []}
                columns={AnunciosColumns()}
                isLoading={loading}
                total={anuncios?.meta?.total || 0}
                onRowClick={onRowClick}
            />

            <NewAnuncioModal
                isOpen={isOpenAnuncio}
                onClose={onCloseAnuncio}
                cursoId={cursoId}
                setRefresh={Refresh}
            />

            <AnunciosDrawer
                isOpen={isOpenDrawer}
                onClose={onCloseDrawer}
                anuncios={anuncioRef.current}
            />
        </Flex>
    )
}