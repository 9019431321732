import { Flex } from "@chakra-ui/react"
import { Route, Routes } from "react-router-dom"
import { TabsPlantillas } from "./views/General/TabsPlantillas"
import { PlantillasInformation } from "./views/Information/Information"
import { Helmet } from "react-helmet"

export const Plantillas = () => {
    return (
        <Flex
            direction="column"
            w="100%"
        >
            <Helmet>
                <title>Plantillas | Proacademy</title>
            </Helmet>
            <Flex direction="column">
                <Routes>
                    <Route
                        index
                        element={
                            <TabsPlantillas
                            />
                        }
                    />

                    <Route
                        path="/:id"
                        element={
                            <PlantillasInformation />
                        }
                    />
                </Routes>
            </Flex>
        </Flex>
    )
}