import { useEffect, useState } from "react";
import { Flex, Text, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../../../shared/context/client.context";
import { useAuthContex } from "../../../../shared/context/auth.context";
import { handleUnauthorized } from "../../../../shared/utils/functions/handleErrors";
import { useData } from "../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { FiltrosNames, FiltrosTypes } from "../../../../shared/utils/Types/FiltrosEnum";
import { Topbar } from "../../../../shared/components/Topbar/Topbar";
import { useModuloFundae } from "../../../../shared/hooks/ModuloFundae";
import { Helmet } from "react-helmet";
import { EmptyMessageType } from "../../../../shared/utils/Types/EmptyMessageType";
import { Table } from "../../../../shared/components/Table/Table";
import { Filters } from "../../../../shared/components/Filters/Filters";
import { MatriculasColumns } from "./Columns/MatriculasColumns";
import { EntityTypes, useQuerySession } from "../../../../shared/hooks/useQuerySession";

export const MatriculasTable = () => {
    const moduloFUNDAE = useModuloFundae();
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.MATRICULAS })
    const client = useClient();
    const { logout } = useAuthContex()
    const navigate = useNavigate();
    const toast = useToast()
    const { data: matriculas, loading, error, status, errorsList } = useData({
        client: client,
        endpoint: EndpointTypes.MATRICULAS,
        query: query,
    });
    const [hasFilter, setHasFilters] = useState<boolean>();

    useEffect(() => {
        if (error)
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
    }, [error])

    const filterElements = [
        {
            name: FiltrosNames.ESTADO_GRUPO,
            type: FiltrosTypes.SELECT,
        },
        {
            name: FiltrosNames.FECHA_INICIO_SUP,
            type: FiltrosTypes.DATE,
        },
        {
            name: FiltrosNames.FECHA_FIN_INF,
            type: FiltrosTypes.DATE,
        },
    ]

    const extraFiltrosElements = [
        {
            name: FiltrosNames.FUNDAE,
            type: FiltrosTypes.SELECT,
        },
    ]

    return (
        <>
            <Helmet>
                <title>Matrículas | Proacademy</title>
            </Helmet>
            <Topbar
                title={
                    <Text
                        color="font"
                        fontSize="20px"
                        fontWeight="400"
                    >
                        Matrículas
                    </Text>
                }
            >
                <Flex
                    p="30px"
                    direction="column"
                    gap="20px"
                    w="100%"
                    boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)"
                    borderColor="light_grey"
                >
                    <Filters
                        setHasFilters={setHasFilters}
                        query={query}
                        setQuery={setQuery}
                        allowToggle={true}
                        filterHeaders={[
                            {
                                name: FiltrosNames.NOMBRE_EMAIL,
                                type: FiltrosTypes.INPUT,
                            },
                        ]}
                        filterElements={moduloFUNDAE ? [...filterElements.slice(0, 1), ...extraFiltrosElements, ...filterElements.slice(1)] : filterElements}
                    />

                    <Table
                        query={query}
                        setQuery={setQuery}
                        data={matriculas?.data || []}
                        columns={MatriculasColumns({ moduloFUNDAE })}
                        isLoading={loading}
                        total={matriculas?.meta?.total || 0}
                        emptyMessage={hasFilter ? EmptyMessageType.matriculasFiltros : EmptyMessageType.matriculas}
                    />
                </Flex>
            </Topbar>
        </>

    );
};