// Chakra UI
import { Box, Flex, Checkbox, Button, Text, Image, useDisclosure } from '@chakra-ui/react'
// Img
import logoProcademy from '../../../assets/img/LogoProcademyBlack.svg'
// React
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
// Toast
import { useToast } from '@chakra-ui/react'
import { notify } from '../../../shared/utils/functions/notify'
import { StatusEnum } from '../../../shared/utils/functions/notify'
// Middlewares
import { getToken, loadContext } from '../../../shared/middlewares/token.middleware'
// Axios
import { AxiosResponse } from 'axios'
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from "yup"
import { UserRolEnum } from '../../../shared/utils/Types/UserRolEnum'
import { isRoleAllowed } from '../../../shared/utils/functions/validateRol'
import { useAuthContex } from '../../../shared/context/auth.context'
import { RecoveryModal } from '../components/RecoveryModal'
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import tenantInstance from '../../../shared/services/tenantInstance.service'
import { handleErrors } from '../../../shared/utils/functions/handleErrors'
import { toastNotify } from '../../../shared/utils/functions/toastNotify'
import { StatusEnumTypes } from '../../../shared/utils/Types/StatusEnumTypes'
import { iconoTenant } from '../../../shared/middlewares/tenant.middleware'
import { useTenantInfo } from '../../../shared/hooks/useTenantInfo'

export const FormLogin = () => {
    const { login, logout } = useAuthContex();
    const { handleSetInfo, handleGetInfo } = useTenantInfo();
    const [search, setSearch] = useSearchParams();
    const navigate = useNavigate();
    const toast = useToast();
    const [_isLoading, setIsLoading] = useState<boolean>(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState<boolean>(true)
    const icono = handleGetInfo({ key: "icono" })
    const nombre = handleGetInfo({ key: "nombre" })

    useEffect(() => {
        setLoading(true)

        iconoTenant()
            .then((response) => {
                const { nombre, icono, colores } = response;

                handleSetInfo({ key: "icono", value: icono })
                handleSetInfo({ key: "colores", value: colores })
                handleSetInfo({ key: "nombre", value: nombre })
            })
            .catch((error) => console.log(error))
            .finally(() => setLoading(false))
    }, [])


    useEffect(() => {
        if (
            (search.has("loginByCampus") || search.has("loginByRegister"))
            && search.has("token")
        ) {
            logout("Sesión anterior finalizada", navigate, toast)

            const token = search.get("token")
            const isLoginBy = search.get("loginByCampus") || search.get("loginByRegister")

            if (isLoginBy === "true") handleLoginByCampus(token as string)

            return
        }
    }, [])

    const handleLoginByCampus = (token: string) => {
        loadContext(token).then((response: any) => {
            const user = response?.data?.data;

            if (isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.SUPERVISOR], user?.role?.nombre)) {
                login(token, user, navigate)
            } else {
                toastNotify(toast, StatusEnumTypes.WARNING, "No tiene autorizacion para ingresar!")
            }
        })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })

        search.delete("loginByCampus")
        search.delete("loginByRegister")
        search.delete("token")

        setSearch(search)
    }

    const initialValues = {
        userId: null,
        password: null
    };

    const validationSchema = Yup.object().shape({
        userId: Yup.string()
            .required("El campo email es obligatorio")
            .typeError('El campo email es obligatorio'),
        password: Yup.string()
            // .matches(passwordRules, { message: "La contraseña debe ser valida" })
            .required('El campo password es obligatorio')
            .typeError('El campo password es obligatorio'),
    });

    const submitForm = (values: any) => {
        setIsLoading(true)

        const userLogin = {
            userId: values.userId,
            password: values.password
        }

        getToken(userLogin)
            .then(async (response: AxiosResponse) => {
                const tenant = await tenantInstance()
                const role = response?.data?.data?.role?.nombre;
                const user = response?.data?.data;

                const superAdmin = {
                    id: user?.id,
                    email: user?.email,
                    username: user?.username,
                    avatar: user.avatar,
                    nombre: user?.nombre,
                    apellidos: user?.apellidos,
                    localidad: user?.localidad,
                    telefono: user?.telefono,
                    role: { nombre: UserRolEnum.SUPERADMIN },
                    sepe: user?.sepe
                }

                if (tenant === UserRolEnum.SUPERADMIN) return login(response?.data?.token?.token, superAdmin, navigate)

                isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.SUPERVISOR], role)
                    ? login(response?.data?.token?.token, response?.data?.data, navigate)
                    : notify(toast, StatusEnum.warning, "No tiene autorizacion para ingresar!")
            })
            .catch((error) => {
                if (error?.response?.data?.data?.responseText)
                    notify(toast, StatusEnum.error, "La contraseña no es válida")
                else notify(toast, StatusEnum.error, "Se ha producido un error en el acceso. Contacte con soporte")
            })
            .finally(() => setIsLoading(false))
    };

    return (
        <Flex flex="1" flexDirection="column" pt="5%" pb="2%" backgroundColor="#FFFFFF">

            {loading ?
                <Flex
                    w="200px"
                    h="65px"
                    ml="40px"
                    rounded="6px"
                />
                :
                (
                    (icono && nombre) ?
                        <Flex
                            w="200px"
                            alignItems="center"
                            gap="5px"
                        >
                            <Image
                                src={icono}
                                alt='img/logo'
                                objectFit="contain"
                                w="65px"
                                h="65px"
                                ml="40px"
                            />

                            <Text
                                textTransform="capitalize"
                                fontSize="20px"
                                color="main"
                                fontWeight="700"
                            >
                                {nombre}
                            </Text>
                        </Flex>
                        :
                        <Image
                            src={logoProcademy}
                            alt='img/logo'
                            objectFit="contain"
                            w="200px"
                            p="0 3%"
                            ml="40px"
                        />)
            }

            <Box w="50%" m="auto" ml="45%">
                <Text fontWeight="700" lineHeight="normal" fontSize="26px" color="main">Comienza</Text>

                <Text mt="20px" fontWeight="400" lineHeight="normal" fontSize="14px" color="#67748E">Introduzca su email y contraseña</Text>

                <Formik
                    onSubmit={(values) => submitForm(values)}
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {(formik) => {
                        const { handleSubmit } = formik;

                        return (

                            <FormikForm
                                onSubmit={handleSubmit}
                            >
                                <Box mt="7">
                                    <Box>
                                        <FormInput
                                            name='userId'
                                            placeholder="Usuario / Email"
                                            label='Usuario / Email'
                                        />
                                    </Box>

                                    <Box mt="5px">
                                        <FormInput
                                            type="password"
                                            name='password'
                                            placeholder='Contraseña'
                                            label='Contraseña'
                                        />
                                    </Box>

                                    <Flex justifyContent="space-between" alignItems="center" mt="8">
                                        <Checkbox size='md' fontSize="8px">Recuérdame</Checkbox>

                                        <Text
                                            _hover={{ textDecoration: "underline" }}
                                            cursor="pointer"
                                            color="secondary_font"
                                            fontSize="12px"
                                            fontWeight="600"
                                            onClick={() => onOpen()}
                                        >
                                            ¿Has olvidado la contraseña?
                                        </Text>
                                    </Flex>

                                    <Button type='submit' background='main' color="#FFFFFF" mt="8" w="100%" fontWeight="500">Iniciar sesión</Button>
                                </Box>
                            </FormikForm>
                        );
                    }}
                </Formik>
            </Box>

            <RecoveryModal
                isOpen={isOpen}
                onClose={onClose}
            />
        </Flex>
    );
};