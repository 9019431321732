import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex, Icon, Input, InputGroup, InputLeftElement, InputRightElement, Spinner, Text } from '@chakra-ui/react';
import { BiChevronLeft, BiSearch } from 'react-icons/bi';

interface Props {
    loadOptions: any;
    onClick: (e: any) => void;
    placeholder?: string;
    setRefreshData?: (value: boolean) => void;
    refreshData?: boolean;
}

export const CustomAsyncSelect = ({
    loadOptions,
    onClick,
    placeholder,
    setRefreshData = () => { },
    refreshData = true
}: Props) => {
    const [inputValue, setInputValue] = useState('');
    const [menu, setMenu] = useState<boolean>(false);
    const [searchOptions, setSearchOptions] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (refreshData) {
            debouncedLoadOptions(' ')
            setInputValue('')

            setRefreshData(false)
        }
    }, [refreshData])

    const debouncedLoadOptions = useCallback(
        debounce(async (inputValue: string) => {
            if (inputValue && inputValue !== '') {
                setIsLoading(true);
                const options = await loadOptions(inputValue);

                setSearchOptions(options);
                setIsLoading(false);
            }
            else
                setSearchOptions(null);
        }, 300),
        [loadOptions]
    );

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        setInputValue(value);
        debouncedLoadOptions(value);
    };

    const handleClick = (option: any) => {
        setMenu(false)
        setRefreshData(true)
        onClick(option)
    }

    return (
        <Box
            position="relative"
        >
            <InputGroup>
                <Input
                    bg="purewhite"
                    color='font'
                    fontSize="16px"
                    fontWeight="400"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder={placeholder}
                    onFocus={() => setMenu(true)}
                    onBlur={() =>
                        setTimeout(() => {
                            setMenu(false)
                        }, 200)
                    }
                />

                <InputLeftElement>
                    <Icon
                        as={BiSearch}
                        boxSize="20px"
                    />
                </InputLeftElement>

                <InputRightElement>
                    <Icon
                        as={BiChevronLeft}
                        boxSize="24px"
                        transition={'transform 0.3s ease'}
                        transform={menu ? 'rotate(-90deg)' : 'rotate(0deg)'}
                        onClick={() => {
                            setMenu((prev) => !prev)
                        }}
                    />
                </InputRightElement>
            </InputGroup>

            <Box
                zIndex={999}
                position="absolute"
                maxH={300}
                overflowY="scroll"
                css={{
                    '&::-webkit-scrollbar': {
                        width: '5px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: "#E2E8F0",
                        borderRadius: '20px',
                    },
                }}
                top="115%"
                left="0"
                w="100%"
                bg="purewhite"
                border="1px solid #E2E8F0"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.05)"
                rounded="8px"
                display={menu ? 'block' : 'none'}
                padding="12px 8px"
            >
                {(searchOptions && !isLoading) ?
                    <Flex
                        direction="column"
                        gap="10px"
                    >
                        {searchOptions?.length > 0 ?
                            searchOptions?.map((option: any, i: number) => (
                                <Flex
                                    key={i}
                                    alignItems="center"
                                    gap="10px"
                                    cursor="pointer"
                                    _hover={{ bg: "light_grey" }}
                                    px="8px"
                                    onClick={() => handleClick(option)}
                                >
                                    <Text
                                        fontSize="14px"
                                        fontWeight="400"
                                        color="font"
                                    >
                                        {option.label}
                                    </Text>
                                </Flex>
                            ))
                            :
                            <Text
                                fontSize="14px"
                                fontWeight="400"
                                color="secondary_font"
                            >
                                No hay resultados
                            </Text>
                        }
                    </Flex>
                    :
                    !isLoading ?
                        <Text
                            textAlign="center"
                        >
                            Escribe para mostrar opciones...
                        </Text>
                        :
                        <Flex
                            justifyContent="center"
                            alignItems="center"
                            w="100%"
                        >
                            <Spinner
                                size="sm"
                                color="secondary"
                                thickness="2px"
                                speed="0.65s"
                                emptyColor="light_grey"
                            />
                        </Flex>
                }
            </Box>
        </Box >

    )
}