import { UserTypesEnum } from "../../../../shared/utils/Types/UserTypesEnum";
import { UserInt } from "../../../../interfaces/UserInt";
import { PerfilAlumno } from "../../components/PerfilesItems/PerfilAlumno";
import { PerfilTutor } from "../../components/PerfilesItems/PerfilTutor";
import { PerfilSupervisor } from "../../components/PerfilesItems/PerfilSupervisor";
import { useClient } from "../../../../shared/context/client.context";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { QueryTypes } from "../../../../shared/utils/Types/QueryTypes";
import { loadData } from "../../../../shared/utils/functions/loadData";

interface Props {
    usuario: UserInt | undefined;
    updateValue: (action: any) => void;
    userType: UserTypesEnum;
    setRefresh: () => void;
    loading: boolean;
}

export const TabPerfil = ({ usuario, updateValue, userType, setRefresh, loading }: Props) => {
    const client = useClient();
    
    const loadEmpresas = async (value: string) => await loadData({
        value: value,
        client: client,
        endpoint: EndpointTypes.EMPRESA,
        query: QueryTypes.NOMBRE,
    })

    return(
        userType === UserTypesEnum.ALUMNO ?
        <PerfilAlumno 
            alumno={usuario}
            updateValue={updateValue}
            loadEmpresa={loadEmpresas}
            loading={loading}
        />
        : userType === UserTypesEnum.TUTOR ?
        <PerfilTutor
            tutor={usuario}
            updateValue={updateValue}
            setRefresh={setRefresh}
            loading={loading}
        />
        :
        <PerfilSupervisor
            supervisor={usuario}
            updateValue={updateValue}
            loadEmpresa={loadEmpresas}
            loading={loading}
        />
    );
}