import { Badge, Link, Button, Flex, Icon, Progress, Text, Tooltip } from "@chakra-ui/react"
import { BsCheckCircleFill, BsChevronRight, BsXCircleFill } from "react-icons/bs"
import { ColumnProps } from "../../../../shared/components/Table/Table";
import { Link as RouterLink } from "react-router-dom";
import { ColumnLabelText } from "@imaginagroup/bit-components.ui.columns.column-label-text"
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { ColumnIcon } from "@imaginagroup/bit-components.ui.columns.column-icon"
import { formatTimezone } from "../../../../shared/utils/functions/parseDate";
import { useTimeZone } from "../../../../shared/hooks/useTimeZone";

interface Props {
    navigate: (path: string) => void;
    moduloFUNDAE: boolean;
}

export const ShowMatriculasAlumnos = ({ navigate, moduloFUNDAE }: Props): ColumnProps[] => {
    const timeZone = useTimeZone();

    const columns: ColumnProps[] = [
        {
            header: 'Duracion',
            field: 'estado',
            key: 'estado',
            sortable: true,
            style: { width: "250px" },
            body: (rowData: any) => (
                <Link
                    as={RouterLink}
                    to={`/matriculas/${rowData?.id}`}
                    _hover={{ textDecoration: "none" }}
                >
                    <Flex
                        direction="column"
                        alignItems="flex-start"
                        ml="16px"
                    >
                        <Badge
                            color={
                                rowData?.grupo?.estado == 2 ? "#616675" :
                                    rowData?.grupo?.estado == 1 ? "secondary"
                                        : "success_main"
                            }
                            bg={
                                rowData?.grupo?.estado == 2 ? "#DFE2E6" :
                                    rowData?.grupo?.estado == 1 ? "#EEEDFA"
                                        : "success_bg"
                            }
                            borderWidth="1px"
                            borderColor={
                                rowData?.grupo?.estado == 2 ? "#DFE2E6" :
                                    rowData?.grupo?.estado == 1 ? "#EEEDFA"
                                        : "success_variant"
                            }

                            fontSize="10px"
                            fontWeight="500"
                            letterSpacing="0.2px"
                            rounded="14px"
                            textTransform="uppercase"
                            p="2px 5px"
                        >
                            {
                                rowData?.grupo?.estado == 2 ? "Inactivo" :
                                    rowData?.grupo?.estado == 1 ? "Próximo"
                                        : "Activo"
                            }
                        </Badge>

                        <Flex
                            alignItems="center"
                            gap="5px"
                            color="font"
                            fontSize="14px"
                            fontWeight="400"
                        >
                            <Text>{formatTimezone({ date: rowData?.grupo?.fechaInicio, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                            <Text mb="2px">-</Text>
                            <Text>{formatTimezone({ date: rowData?.grupo?.fechaFin, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                        </Flex>
                    </Flex>
                </Link>
            )
        },
        {
            header: 'Nombre',
            field: 'nombre',
            key: 'nombre',
            style: { width: "350px" },
            sortable: true,
            body: (rowData: any) => (
                <Flex
                    alignItems="center"
                    gap="20px"
                >
                    <ColumnLabelText
                        label={rowData?.grupo?.nombre}
                        text={rowData?.grupo?.curso?.nombre}
                        link={{
                            to: `/matriculas/${rowData?.id}`,
                        }}
                    />

                    <Tooltip
                        hasArrow
                        label="Ir al grupo"
                    >
                        <Button
                            h="29px"
                            w="35px"
                            bg="secondary"
                            rounded="100%"
                            p="7px"
                            minW="0"
                            cursor="pointer"
                            _hover={{ bg: "variant" }}
                            onClick={() => navigate(`/grupos/${rowData?.grupo?.id}`)}
                            ml="auto"
                            mr="30%"
                        >
                            <Icon
                                color="purewhite"
                                as={BsChevronRight}
                                boxSize="15px"
                            />
                        </Button>
                    </Tooltip>
                </Flex>
            )
        },
        {
            header: 'Puntuacion',
            field: 'puntuacionMedia',
            key: 'puntuacionMedia',
            sortable: true,
            style: { width: "150px" },
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.puntuacionMedia || 0 + "/100"}
                    ui={{
                        colorFont: rowData?.puntuacionMedia || 0 < 75 ? "fail" : "success",
                    }}
                    link={{
                        to: `/matriculas/${rowData?.id}`,
                    }}
                />
            )
        },
        {
            header: 'Progreso',
            field: 'progresoLecciones',
            key: 'progresoLecciones',
            sortable: true,
            body: (rowData: any) => (
                <Link
                    as={RouterLink}
                    to={`/matriculas/${rowData?.id}`}

                    _hover={{ textDecoration: "none" }}
                >
                    <Flex
                        gap="5px"
                        alignItems="center"                    >

                        <Progress
                            value={rowData?.progresoLecciones || 0}
                            flex="1"
                            h="7px"
                            rounded="10px"
                            sx={{ '& > div': { background: "secondary" } }}
                            maxW="200px"
                        />

                        <Text
                            color="font"
                            fontSize="14px"
                            fontWeight="500"
                        >
                            {parseInt(rowData?.progresoLecciones) || 0} %
                        </Text>
                    </Flex>
                </Link>
            )
        },
    ]

    const extraColumns = [
        {
            header: 'FUNDAE',
            field: 'fundae',
            key: 'fundae',
            style: { width: "150px" },
            sortable: true,
            body: (rowData: any) => (
                rowData?.grupo?.fundae
                    ?
                    <ColumnIcon
                        icon={BsCheckCircleFill}
                        ui={{
                            color: "#E9B418",
                            boxSize: "18px"
                        }}
                        link={{
                            to: `/matriculas/${rowData?.id}`,
                        }}
                    />
                    :
                    <ColumnIcon
                        icon={BsXCircleFill}
                        ui={{
                            color: "#616675",
                            boxSize: "18px"
                        }}
                        link={{
                            to: `/matriculas/${rowData?.id}`,

                        }}
                    />
            )
        },
    ]
    return moduloFUNDAE ? [...columns.slice(0, 1), ...extraColumns, ...columns.slice(1)] : columns
}
