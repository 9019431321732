import { Flex, useToast } from "@chakra-ui/react"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../../../../shared/context/client.context";
import { useAuthContex } from "../../../../../shared/context/auth.context";
import { handleUnauthorized } from "../../../../../shared/utils/functions/handleErrors";
import { useData } from "../../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { Table } from "../../../../../shared/components/Table/Table";
import { LiquidacionesColumns } from "./Colums/LiquidacionesColumns";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";
import { Filters } from "../../../../../shared/components/Filters/Filters";
import { FiltrosNames, FiltrosTypes } from "../../../../../shared/utils/Types/FiltrosEnum";

export const LiquidacionesTable = () => {
    const client = useClient();
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.LIQUIDACIONES });
    const { logout } = useAuthContex();
    const navigate = useNavigate();
    const toast = useToast();
    const { data, loading, error, status, errorsList } = useData({
        client: client,
        endpoint: EndpointTypes.LIQUIDACIONES,
        query: query
    });

    useEffect(() => {
        if (error)
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
    }, [error])

    return (
        <Flex direction="column" gap="20px" w="100%">
            <Filters
                query={query}
                setQuery={setQuery}
                allowToggle={true}
                filterElements={[
                    {
                        name: FiltrosNames.TUTOR,
                        type: FiltrosTypes.ASYNC_SELECT,
                    },
                    {
                        name: FiltrosNames.FECHA_PAGO_INICIO,
                        type: FiltrosTypes.DATE,
                    },
                    {
                        name: FiltrosNames.FECHA_PAGO_FIN,
                        type: FiltrosTypes.DATE,
                    },
                    {
                        name: FiltrosNames.ESTADO_LIQUIDACION,
                        type: FiltrosTypes.SELECT,
                    },
                    {
                        name: FiltrosNames.LIQUIDACION_EMPRESA,
                        type: FiltrosTypes.SELECT,
                    },
                ]}
            />

            <Table
                query={query}
                setQuery={setQuery}
                data={data?.data || []}
                columns={LiquidacionesColumns()}
                isLoading={loading}
                total={data?.meta?.total || 0}
            />
        </Flex>
    )
}