import { useTenantInfo } from "../shared/hooks/useTenantInfo";

export const SetColors = () => {
  const { handleGetInfo } = useTenantInfo();
  const colores = handleGetInfo({ key: "colores" });

  return {
    main: (!colores || !colores?.main) ? '#0F1122' : colores?.main,
    secondary: (!colores || !colores?.secondary) ? '#8694FF' : colores?.secondary,
    variant: (!colores || !colores?.variant) ? '#C7CDFF' : colores?.variant,
    grey: '#F1F3F6',
    light_grey: '#EAEAEA',
    dark_grey: '#8C909C',
    black: '#202020',
    pureblack: "#000000",
    white: '#F8F8F8',
    purewhite: "#FFFFFF",
    main_variant: '#EAECFF',
    bg: "#f2f2fd",
    base: "#F9FAFB",
    disable: '#A0AEC0',
    success: "#76E874",
    success_main: "#02B29F",
    success_variant: "#CDF0EC",
    success_bg: "#F0FDFD",
    fail: "#EF4E40",
    error: "#E53E3E",
    fail_main: "#F44261",
    fail_variant: "#FDD7DD",
    fail_bg: "#FEEAED",
    blue: "#4FD1C5",
    blue_bg: "#C1FFF9",
    warning: "#FD891F",
    warning_variant: "##F8F2E7",
    warning_bg: "#F0E2CD",

    font: "#3D3F65",
    secondary_font: '#959596',
    shadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)",
    main_shadow: '0px 4px 15px 0px rgba(199, 205, 255, 0.60)',

    "chakra-body-bg": {
      _light: "#F9FAFB",
      _dark: "#1a202c",
    },
  }
}