import { Box, Flex, Icon, Input, InputGroup, InputRightElement, Text, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { BiTimer } from "react-icons/bi"
import { toastNotify } from "../../utils/functions/toastNotify";
import { StatusEnumTypes } from "../../utils/Types/StatusEnumTypes";

type Time = {
    horas: number,
    minutos: number,
    segundos: number
}

enum TimeEnum {
    HORAS = "horas",
    MINUTOS = "minutos",
    SEGUNDOS = "segundos"
}

interface Props {
    onBlur?: (e?: any) => any;
    isDisabled?: boolean;
    onChange?: (e?: any) => any;
    onFocus?: (e?: any) => any;
    defaultValue?: number;
}

export const TimePicker = ({
    onBlur = () => { },
    isDisabled,
    onChange = () => { },
    onFocus = () => { },
    defaultValue,
}: Props) => {
    const toast = useToast();
    const [time, setTime] = useState<Time>({
        horas: 0,
        minutos: 0,
        segundos: 0
    })
    const [menu, setMenu] = useState<boolean>(false);
    const [focus, setFocus] = useState<boolean>(false);

    useEffect(() => {
        if (defaultValue) {
            const time = secondsToTime(defaultValue)
            setTime(time)
        }
    }, [])

    const handleSelectTime = (time: TimeEnum, value: number) => {
        if (isNaN(value)) return toastNotify(toast, StatusEnumTypes.WARNING, "El valor debe ser un número")
        if (time === TimeEnum.HORAS && value > 12) return toastNotify(toast, StatusEnumTypes.INFO, "Las horas no pueden ser mayores a 12")
        if (time === TimeEnum.MINUTOS && value > 60) return toastNotify(toast, StatusEnumTypes.INFO, "Las minutos no pueden ser mayores a 60")
        if (time === TimeEnum.SEGUNDOS && value > 60) return toastNotify(toast, StatusEnumTypes.INFO, "Las segundos no pueden ser mayores a 60")

        setTime((prev: Time) => ({
            ...prev,
            [time]: value
        }))
    }

    useEffect(() => {
        onChange(time)
    }, [time])

    const handleClick = () => {
        !isDisabled && setMenu((prev: boolean) => !prev)

        if (menu) {
            setFocus(false)
            onBlur()
        } else {
            setFocus(true)
            onFocus()
        }
    }

    const getTimes = (time: TimeEnum) => {
        const options: number[] = []

        for (let i = 0; i <= (time === "horas" ? 12 : 60); i++) {
            options.push(i)
        }

        return options
    }

    const secondsToTime = (totalSeconds: number): Time => {
        const horas = Math.floor(totalSeconds / 3600);
        const minutos = Math.floor((totalSeconds % 3600) / 60);
        const segundos = totalSeconds % 60;

        return { horas, minutos, segundos };
    };

    return (
        <Box
            position="relative"
            h="fit-content"
            minW="200px"
            w="100%"
        >
            <Flex
                border={`1px solid`}
                borderColor={focus ? "secondary" : "light_grey"}
                bg="purewhite"
                rounded="5px"
                boxShadow={focus ? "0 0 0 1px secondary" : ""}
                alignItems="center"
                justifyContent="space-between"
                cursor={isDisabled ? "not-allowed" : "pointer"}
            >
                <Flex
                    alignItems="center"
                >
                    <Input
                        isDisabled={isDisabled}
                        color='font'
                        w="25px"
                        pl="3px"
                        pr="2px"
                        fontSize="16px"
                        fontWeight="400"
                        border="none"
                        bg="purewhite"
                        value={time?.horas > 10 ? time?.horas : `0${time?.horas}`}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectTime(TimeEnum.HORAS, Number(e.target.value))}
                        placeholder={""}
                        onFocus={() => {
                            setFocus(true)
                            onFocus()
                        }}
                        onBlur={() => {
                            setFocus(false)
                            onBlur()
                        }}
                        _focus={{ border: "none", boxShadow: "none", borderColor: "inherit" }}
                    />

                    <Text
                        color='font'
                        fontSize="16px"
                        fontWeight="400"
                        pb="2px"
                    >
                        :
                    </Text>

                    <Input
                        isDisabled={isDisabled}
                        color='font'
                        w="22px"
                        p="1px"
                        fontSize="16px"
                        fontWeight="400"
                        border="none"
                        bg="purewhite"
                        value={time?.minutos > 10 ? time?.minutos : `0${time?.minutos}`}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectTime(TimeEnum.MINUTOS, Number(e.target.value))}
                        placeholder={""}
                        onFocus={() => {
                            setFocus(true)
                            onFocus()
                        }}
                        onBlur={() => {
                            setFocus(false)
                            onBlur()
                        }}
                        _focus={{ border: "none", boxShadow: "none", borderColor: "inherit" }}
                    />

                    <Text
                        color='font'
                        fontSize="16px"
                        fontWeight="400"
                        pb="2px"
                    >
                        :
                    </Text>

                    <Input
                        isDisabled={isDisabled}
                        color='font'
                        w="25px"
                        p="1px"
                        fontSize="16px"
                        fontWeight="400"
                        border="none"
                        bg="purewhite"
                        value={time?.segundos > 10 ? time?.segundos : `0${time?.segundos}`}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectTime(TimeEnum.SEGUNDOS, Number(e.target.value))}
                        placeholder={""}
                        onFocus={() => {
                            setFocus(true)
                            onFocus()
                        }}
                        onBlur={() => {
                            setFocus(false)
                            onBlur()
                        }}
                        _focus={{ border: "none", boxShadow: "none", borderColor: "inherit" }}
                    />
                </Flex>

                <Icon
                    as={BiTimer}
                    boxSize="25px"
                    transition={'transform 0.3s ease'}
                    cursor={'pointer'}
                    onClick={handleClick}
                    mr="10px"
                    mt="2px"
                />
            </Flex>

            <Box
                position="absolute"
                zIndex={2000}
                h="200px"
                w="175px"
                top="105%"
                left="0"
                bg="purewhite"
                border="1px solid #E2E8F0"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.05)"
                rounded="8px"
                display={menu ? 'block' : 'none'}
            >
                <Flex
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Flex
                        flex="1"
                        h="200px"
                        borderRight="1px solid"
                        borderColor="font"
                        direction="column"
                        w="fit-content"
                        alignItems="center"
                        py="10px"
                        overflowY="scroll"
                        css={{
                            '&::-webkit-scrollbar': {
                                width: '0px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                background: "#E2E8F0",
                                borderRadius: '20px',
                            },
                        }}
                    >
                        {getTimes(TimeEnum.HORAS).map((hora: number) => (
                            <Box
                                key={hora}
                                bg={time.horas === hora ? "font" : "purewhite"}
                                color={time.horas === hora ? "purewhite" : "font"}
                                cursor="pointer"
                                _hover={{
                                    bg: "secondary_font",
                                    color: "purewhite"
                                }}
                                p="2px 15px"
                                rounded="5px"
                                onClick={() => handleSelectTime(TimeEnum.HORAS, hora)}
                            >
                                {hora < 10 ? `0${hora}` : hora}
                            </Box>
                        ))}
                    </Flex>

                    <Flex
                        flex="1"
                        borderRight="1px solid"
                        borderColor="font"
                        direction="column"
                        w="fit-content"
                        alignItems="center"
                        h="200px"
                        py="10px"
                        overflowY="scroll"
                        css={{
                            '&::-webkit-scrollbar': {
                                width: '0px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                background: "#E2E8F0",
                                borderRadius: '20px',
                            },
                        }}
                    >
                        {getTimes(TimeEnum.MINUTOS).map((minuto: number) => (
                            <Box
                                key={minuto}
                                bg={time.minutos === minuto ? "font" : "purewhite"}
                                color={time.minutos === minuto ? "purewhite" : "font"}
                                cursor="pointer"
                                _hover={{
                                    bg: "secondary_font",
                                    color: "purewhite"
                                }}
                                p="2px 15px"
                                rounded="5px"
                                onClick={() => handleSelectTime(TimeEnum.MINUTOS, minuto)}
                            >
                                {minuto < 10 ? `0${minuto}` : minuto}
                            </Box>
                        ))}
                    </Flex>

                    <Flex
                        flex="1"
                        direction="column"
                        w="fit-content"
                        alignItems="center"
                        h="200px"
                        py="10px"
                        overflowY="scroll"
                        css={{
                            '&::-webkit-scrollbar': {
                                width: '0px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                background: "#E2E8F0",
                                borderRadius: '20px',
                            },
                        }}
                    >
                        {getTimes(TimeEnum.SEGUNDOS).map((segundo: number) => (
                            <Box
                                key={segundo}
                                bg={time.segundos === segundo ? "font" : "purewhite"}
                                color={time.segundos === segundo ? "purewhite" : "font"}
                                cursor="pointer"
                                _hover={{
                                    bg: "secondary_font",
                                    color: "purewhite"
                                }}
                                p="2px 15px"
                                rounded="5px"
                                onClick={() => handleSelectTime(TimeEnum.SEGUNDOS, segundo)}
                            >
                                {segundo < 10 ? `0${segundo}` : segundo}
                            </Box>
                        ))}
                    </Flex>
                </Flex>
            </Box>
        </Box>
    )
}