import { Flex, ListItem, Text, UnorderedList } from "@chakra-ui/react"
import { InformationBox } from "../../../../../shared/components/CustomElements/InformationBox/InformationBox"
import { LeccionInt } from "../../../../../interfaces/CursosInt"
import { Dispatch, SetStateAction } from "react"
import DropzoneComponent from "../../../../../shared/components/Dropzone/Dropzone"
import { InformationComponent } from "../../../../../shared/components/CustomElements/InformationComponent"
import { BiInfoCircle } from "react-icons/bi"
import { LeccionTipoEnum } from "../../../../../shared/utils/Types/LeccionTipoEnum"
import { FormInput } from "@imaginagroup/bit-components.ui.form-input"
import { FormTextarea } from "@imaginagroup/bit-components.ui.form-textarea"
import { FormDateInput } from "@imaginagroup/bit-components.ui.form-date-input"
import { FormTextEditor } from "../../../../../shared/components/FormElements/FormTextEditor"

interface Props {
    formik: any;
    leccion: LeccionInt | undefined;
    file: File | null;
    setFile: Dispatch<SetStateAction<File | null>>;
    loading?: boolean;
}

export const InformationLeccion = ({
    leccion,
    formik,
    file,
    setFile,
    loading
}: Props) => {

    return (
        <>
            <InformationBox
                noHeader
            >
                <Flex
                    direction="column"
                    py="20px"
                    px="20px"
                >
                    {(leccion?.tipo === LeccionTipoEnum.URL || leccion?.tipo === LeccionTipoEnum.ZOOM) &&

                        <Flex
                            direction="column"
                            gap="15px"
                        >
                            <Flex
                                alignItems="center"
                                gap="5px"
                            >
                                <Text
                                    color="font"
                                    fontSize="14px"
                                    fontWeight="400"
                                >
                                    Contenido lección
                                </Text>

                                <InformationComponent
                                    tooltip={{
                                        content:
                                            <Flex
                                                direction="column"
                                            >
                                                <Text
                                                    textAlign="center"
                                                    fontSize="16px"
                                                    fontWeight={"600"}
                                                    mb="10px"
                                                >
                                                    Lección de tipo {leccion?.tipo === LeccionTipoEnum.ZOOM ? "Zoom" : "URL"}
                                                </Text>

                                                {leccion?.tipo === LeccionTipoEnum.ZOOM ?
                                                    <UnorderedList
                                                        pl="10px"
                                                        display="flex"
                                                        flexDirection={"column"}
                                                        gap="10px"
                                                        fontSize="14px"
                                                        fontWeight={"400"}
                                                    >
                                                        <ListItem>
                                                            Inserte el <Text as={"strong"}>LINK</Text> completo de la reunión que utiliza para compartirla.
                                                        </ListItem>
                                                    </UnorderedList>
                                                    :
                                                    <UnorderedList
                                                        pl="10px"
                                                        display="flex"
                                                        flexDirection={"column"}
                                                        gap="10px"
                                                        fontSize="14px"
                                                        fontWeight={"400"}
                                                    >
                                                        <ListItem>
                                                            Inserte la <Text as={"strong"}>URL</Text> a donde se redireccionara o descargara el recurso de la leccion.
                                                        </ListItem>

                                                        <ListItem>
                                                            Si estás obteniendo el <Text as={"strong"}>URL</Text> para una descarga de una nube como OneDrive, Google Drive, entre otras, <Text as={"strong"}>asegúrate de compartir</Text> la URL como <Text as={"strong"}>público</Text>. Esto garantizará que la lección se cargue correctamente en la aplicación.
                                                        </ListItem>
                                                    </UnorderedList>
                                                }
                                            </Flex>,
                                        placement: "right",
                                        hasArrow: true,
                                        isDisabled: false,
                                        styles: { rounded: "8px", p: "10px" }
                                    }}
                                    icon={BiInfoCircle}
                                />
                            </Flex>

                            <FormTextarea
                                name="contenido"
                                placeholder={leccion?.tipo === LeccionTipoEnum.ZOOM ? "Introduce el link de la reunión" : "Introduce el enlace al recurso"}
                                noHead={true}
                                ui={{
                                    minHeigh: "20px",
                                    styles: {
                                        border: "1px solid",
                                        borderColor: "#E2E8F0",
                                        borderRadius: "5px",
                                        color: "font",
                                        padding: "5px",
                                        maxHeight: "100px",
                                    }
                                }}
                            />
                        </Flex>
                    }

                    {(leccion?.tipo === LeccionTipoEnum.FICHERO) &&
                        <Flex
                            w="100%"
                            direction="column"
                            mb="20px"
                        >
                            <DropzoneComponent
                                file={file}
                                setFile={setFile}
                                loading={loading}
                                hasContent={leccion?.contenido ? true : false}
                            />
                        </Flex>
                    }


                    {
                        (leccion?.tipo === LeccionTipoEnum.EMBEBIDO || leccion?.tipo === LeccionTipoEnum.ENTREGABLE) &&

                        <Flex
                            direction="column"
                            gap="15px"
                            pb="10px"
                        >
                            <Flex
                                alignItems="center"
                                gap="5px"
                            >
                                <Text
                                    color="font"
                                    fontSize="14px"
                                    fontWeight="400"
                                >
                                    Contenido lección
                                </Text>

                                <InformationComponent
                                    tooltip={{
                                        content:
                                            <Flex
                                                direction="column"
                                            >
                                                <Text
                                                    textAlign="center"
                                                    fontSize="16px"
                                                    fontWeight={"600"}
                                                    mb="10px"
                                                >
                                                    Lección de tipo {leccion?.tipo === LeccionTipoEnum.ENTREGABLE ? "Entregable" : "Embebido"}
                                                </Text>


                                                <UnorderedList
                                                    pl="10px"
                                                    display="flex"
                                                    flexDirection={"column"}
                                                    gap="10px"
                                                    fontSize="14px"
                                                    fontWeight={"400"}
                                                >
                                                    {leccion?.tipo === LeccionTipoEnum.ENTREGABLE ?
                                                        <>
                                                            <ListItem>
                                                                Insete la <Text as={"strong"}>URL</Text> del ejercicio a embeber.
                                                            </ListItem>

                                                            <ListItem>
                                                                Las lecciones de tipo <Text as={"strong"}>Entregable</Text> requieren una entrega en el campus, el alumno debera subir un archivo con el ejercicio resuelto donde el tutor podra calificarlo.
                                                            </ListItem>
                                                        </>
                                                        :
                                                        <ListItem>
                                                            Insete la <Text as={"strong"}>URL</Text> del contenido a embeber.
                                                        </ListItem>
                                                    }

                                                    <ListItem>
                                                        Recuerde que si está obteniendo la <Text as={"strong"}>URL</Text> de una plataforma externa como: "Vimeo", "Slices", "OneDrive", "Google Drive", entre otras. <Text as={"strong"}>Asegúrese de compartir</Text> la URL como <Text as={"strong"}>pública</Text>. Esto garantizará que el ejercicio se cargue correctamente en la aplicación.
                                                    </ListItem>
                                                </UnorderedList>
                                            </Flex>,
                                        placement: "right",
                                        hasArrow: true,
                                        isDisabled: false,
                                        styles: { rounded: "8px", p: "10px" }
                                    }}
                                    icon={BiInfoCircle}
                                />
                            </Flex>

                            <FormTextarea
                                name="contenido"
                                noHead={true}
                                ui={{
                                    minHeigh: "20px",
                                    styles: {
                                        border: "1px solid",
                                        borderColor: "#E2E8F0",
                                        borderRadius: "5px",
                                        color: "font",
                                        padding: "5px",
                                        maxHeight: "100px",
                                    }
                                }}
                                placeholder={"Introce la URL del contenido a embeber"}
                            />
                        </Flex>
                    }

                    <FormTextarea
                        label="Descripción lección"
                        name="descripcion"
                        placeholder="Introduce el texto"
                        ui={{
                            minHeigh: "80px",
                            styleLabel: {
                                color: "font",
                                fontSize: "14px",
                                fontWeight: "400",
                            },
                            styles: {
                                border: "1px solid",
                                borderColor: "#E2E8F0",
                                borderRadius: "5px",
                                color: "font",
                                padding: "5px",
                                maxHeight: "100px",
                            }
                        }}
                    />
                </Flex>
            </InformationBox >


            {
                (leccion?.tipo === LeccionTipoEnum.TEXTO) &&
                <InformationBox
                    noHeader
                >
                    <Flex
                        direction="column"
                        py="20px"
                        px="20px"
                        gap="15px"
                    >
                        <Flex
                            alignItems="center"
                            gap="5px"
                        >
                            <Text
                                color="font"
                                fontSize="14px"
                                fontWeight="400"
                            >
                                Contenido lección
                            </Text>

                            <InformationComponent
                                tooltip={{
                                    content:
                                        <Flex
                                            direction="column"
                                        >
                                            <Text
                                                textAlign="center"
                                                fontSize="16px"
                                                fontWeight={"600"}
                                                mb="10px"
                                            >
                                                Lección de tipo Texto
                                            </Text>

                                            <UnorderedList
                                                pl="10px"
                                                display="flex"
                                                flexDirection={"column"}
                                                gap="10px"
                                                fontSize="14px"
                                                fontWeight={"400"}
                                                mb="10px"
                                            >
                                                <ListItem>
                                                    Insertar <Text as="strong">texto plano</Text>.
                                                </ListItem>

                                                <ListItem>
                                                    Inserta Markdown donde puedes utilizar <Text as="strong">negrita</Text>, <Text as="i">cursiva</Text>, y <Text as="u">subrayado</Text> para resaltar texto.
                                                </ListItem>

                                                <ListItem>
                                                    Insertar <Text as="strong">código</Text>.
                                                </ListItem>

                                                <ListItem>
                                                    Es posible crear listas ordenadas o desordenadas.
                                                </ListItem>

                                                <ListItem>
                                                    Puedes agregar distintos tamaños de letras y darle estilos.
                                                </ListItem>

                                                <ListItem>
                                                    Agrega enlaces en tus textos.
                                                </ListItem>
                                            </UnorderedList>

                                            <Text
                                                textAlign="center"
                                                fontSize="14px"
                                                fontWeight={"700"}
                                            >
                                                Todo esto puedes hacerlo con la barra de herramientas que se encuentra en la parte superior del editor.
                                            </Text>
                                        </Flex>,
                                    placement: "right",
                                    hasArrow: true,
                                    isDisabled: false,
                                    styles: { rounded: "8px", p: "10px" }
                                }}
                                icon={BiInfoCircle}
                            />
                        </Flex>

                        <FormTextEditor
                            name="contenido"
                            placeholder="Introduce el texto"
                            onChange={formik.setFieldValue}
                            stylesEditor={{
                                borderRadius: "10px",
                            }}
                        />
                    </Flex>
                </InformationBox>
            }
        </>
    )
}