import { Box, Flex, Text, Switch } from "@chakra-ui/react";

interface Props {
    title?: string;
    children?: JSX.Element;
    isSwitch?: boolean;
    name?: string;
    value?: boolean;
    setValue?: (e: any) => void;
    component?: JSX.Element;
    onClick?: (e?: any) => void;
    isDisabledSwitch?: boolean;
    extraContent?: JSX.Element;
    noHeader?: boolean;
}

export const InformationBox = ({
    children,
    title,
    isSwitch = false,
    name,
    setValue = (e: any) => { },
    value = false,
    component,
    onClick = (e: any) => { },
    isDisabledSwitch = false,
    extraContent,
    noHeader = false
}: Props) => {
    return (
        <Box
            w="100%"
            borderRadius="10px"
            mb="20px"
            bgColor="purewhite"
            border="1px solid"
            borderColor="light_grey"
        >
            {!noHeader &&
                <Flex
                    direction="column"
                    p="24px"
                    roundedTop="10px"
                    alignItems="flex-start"
                    gap="5px"
                    w={"100%"}
                >
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        w="100%"
                    >
                        <Text
                            color="main"
                            fontSize="18px"
                            fontWeight="500"
                        >
                            {title}
                        </Text>

                        {isSwitch &&
                            <Switch
                                size='md'
                                name={name}
                                isChecked={value}
                                onChange={(e: any) => {
                                    setValue(e.target.checked)
                                    onClick()
                                }}
                                isDisabled={isDisabledSwitch}
                            />
                        }

                        {(!isSwitch && component) &&
                            component
                        }
                    </Flex>

                    {extraContent}
                </Flex>
            }

            {isSwitch ?
                (value &&
                    <Box
                        color="font"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        {children}
                    </Box>
                )
                :
                <Box
                    color="font"
                    fontSize="14px"
                    fontWeight="400"
                >
                    {children}
                </Box>
            }
        </Box>
    );
}