import _axios, { ClientInt } from "../services/http.service";
import tenantInstance from '../services/tenantInstance.service';

export const getInformes = async ({ 
    data, 
    client 
}: {
    data: {
        grupoId: string
        matriculas: { id: string, informes: string[], debug: boolean }[]
    },
    client: ClientInt
}) => {
    if(!client) return;
    
    const tenant = await tenantInstance(); 
    
    return await _axios.post(`${tenant}/${client}/informes`, data, {}).then((response: any) => response)
}

export const getAllInformes = async ({ 
    id, 
    client 
}: {
    id: string | undefined;
    client: ClientInt;
}) => {
    if(!client) return;
    
    const tenant = await tenantInstance(); 
    
    return await _axios.get(`${tenant}/${client}/informesInspector?grupo=${id}`).then((response: any) => response)
}

export const getGuiaInicio = async ({
    data,
    client
} : {
    data: {
        grupoId: string;
        empresaId: string;
        debug: boolean;
        email?: string | undefined;
    },
    client: ClientInt;
}) => {
    if(!client) return;

    const tenant = await tenantInstance();

    return await _axios.post(`${tenant}/${client}/guiaInicio`, data, {}).then((response: any) => response)
}