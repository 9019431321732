import { Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { CustomAvatar } from "../../../../../shared/components/Avatar/CustomAvatar";
import { UserTypesEnum } from "../../../../../shared/utils/Types/UserTypesEnum";
import { BiGroup } from "react-icons/bi";
import { TabTable } from "../../TabItems/TabTable";
import { Skeleton } from "primereact/skeleton";
import { useClient } from "../../../../../shared/context/client.context";
import { EndpointTypes, RelationshipTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { Topbar } from "../../../../../shared/components/Topbar/Topbar";
import { TabsComponentInt } from "../../../../../interfaces/ComponentsInt";
import { CustomTabs } from "@imaginagroup/bit-components.ui.custom-tabs";
import { GruposInspectores } from "../../../components/TabsColumns/GruposInspectores";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";
import { useDataId } from "../../../../../shared/hooks/useDataId";
import { useDataRelationship } from "../../../../../shared/hooks/useDataRelationship";
import { customTabsStyles } from "../../../../../ui/bitStyles";

export const InformationInspector = () => {
    const { id } = useParams();
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.GRUPOS_INSPECTOR });
    const client = useClient();
    const { data: usuario, loading } = useDataId({
        id: id,
        endpoint: EndpointTypes.USUARIOS,
        client: client
    });
    const { data: grupos, loading: loadingGrupos } = useDataRelationship({
        id: id,
        endpoint: EndpointTypes.INSPECTOR,
        relationship: RelationshipTypes.GRUPOS,
        client: client,
        query: query
    });

    const [typeTab, setTypeTab] = useState<"grupos" | "informes">("grupos")
    const [tabIndex, setTabIndex] = useState<number>(typeTab === "grupos" ? 0 : 1)

    const handleTabsChange = (index: number) => setTabIndex(index)


    const tabs: TabsComponentInt[] = [
        {
            isAllowed: true,
            label: "Grupos",
            icon: BiGroup,
            content:
                <TabTable
                    query={query}
                    setQuery={setQuery}
                    loading={loadingGrupos}
                    userType={UserTypesEnum.INSPECTOR}
                    data={grupos}
                    columns={GruposInspectores()}
                />
        }
    ]

    return (
        <Topbar
            title={
                loading ?
                    <Flex gap="15px" alignItems="center">
                        <Skeleton shape="circle" size="55px"></Skeleton>

                        <Flex direction="column" gap="8px">
                            <Skeleton height="22px" width="250px"></Skeleton>

                            <Skeleton height="18px" width="250px"></Skeleton>
                        </Flex>
                    </Flex>
                    :
                    <Flex gap="15px" alignItems="center">
                        <CustomAvatar
                            name={usuario?.nombre + usuario?.apellidos || ""}
                            size="55px"
                            src={usuario?.avatar?.url}
                        />

                        <Flex direction="column" justifyContent="space-between">
                            <Text
                                color="font"
                                fontSize="17px"
                                fontWeight="700"
                                lineHeight="120%"
                            >
                                {usuario?.username}
                            </Text>

                            <Text
                                color="font"
                                fontSize="12px"
                                fontWeight="500"
                            >
                                {usuario?.claveInspectorSepe}
                            </Text>
                        </Flex>
                    </Flex>
            }
            buttonBack
        >
            <Flex
                direction="column"
                px="30px"
                py="12px"
                borderTop="1px solid"
                borderColor="light_grey"
            >
                <CustomTabs
                    ui={customTabsStyles}
                    tabs={tabs}
                    tabIndex={tabIndex}
                    handleTabsChange={handleTabsChange}
                />
            </Flex>
        </Topbar>
    );
}