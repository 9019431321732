import { Accordion, Button, Flex, Icon, useToast } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DndContext, KeyboardSensor, MeasuringStrategy, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core"
import { SortableContext, arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { addModulo, updateModulo } from "../../../../shared/middlewares/modulos.middleware";
import { CursosInt, LeccionInt, ModulosInt } from "../../../../interfaces/CursosInt";
import { useClient } from "../../../../shared/context/client.context";
import { useParams } from "react-router-dom";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { DndModulos } from "./Modulos";
import { BiPlus } from "react-icons/bi";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { TipoSelect } from "../../views/Information/TabItem/TabContenido";
import { useData } from "../../../../shared/hooks/useData";

interface Props {
    curso: CursosInt | undefined;
    selected: { type: TipoSelect; data: ModulosInt | LeccionInt | null; } | undefined;
    setSelected: Dispatch<SetStateAction<{ type: TipoSelect; data: ModulosInt | LeccionInt | null; } | undefined>>;
    refreshData: { modulo: boolean, leccion: boolean };
    setRefreshData: Dispatch<SetStateAction<{ leccion: boolean; modulo: boolean; }>>;
    setRefreshCurso: () => void;
}
export const DndAcordeon = ({
    curso,
    selected,
    setSelected,
    refreshData,
    setRefreshData,
    setRefreshCurso
}: Props) => {
    const { id } = useParams();
    const client = useClient();
    const toast = useToast();
    const [modulos, setModulos] = useState<any[]>([]);

    const { data, Refresh } = useData({
        endpoint: EndpointTypes.MODULOS,
        client: client,
        specificQuery: {
            curso: id!,
            sortBy: "orden",
            order: "asc",
            limit: 100,
        },  
        ignoreRequest: !id
    })

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    useEffect(() => {
        if (data?.data && data?.data.length > 0) {
            setModulos(data?.data)

            if (!selected) {
                setSelected({
                    type: TipoSelect.MODULO,
                    data: data?.data[0]
                })
            }
        }

    }, [data])

    useEffect(() => {
        if (refreshData?.modulo) {
            Refresh()
            setRefreshData((prev) => ({ ...prev, modulo: false }))
        }
    }, [refreshData])

    const handleDragEnd = (event: any) => {
        const { active, over } = event;

        const oldIndex = modulos?.findIndex((modulo: any) => modulo?.id === active?.id)
        const newIndex = modulos?.findIndex((modulo: any) => modulo?.id === over?.id)

        const newOrder = arrayMove(modulos, oldIndex, newIndex)

        const newModulosData: ModulosInt[] = []

        newOrder?.map((m: any, index: number) => {
            const module = {
                ...m,
                orden: (index + 1)
            }

            newModulosData.push(module)
            updateOrder(module)
        })

        setModulos(newModulosData)
    };

    const updateOrder = async (modulo: ModulosInt) => {
        if (!modulo?.id) return

        updateModulo({
            id: modulo?.id,
            data: {
                orden: modulo.orden
            },
            client: client
        })
    }

    const handleDragOver = (event: any) => {
        const overId = event?.over?.id;

        if (overId == null) {
            return;
        }

        const overContainerIndex = modulos.findIndex((modulo) => modulo.id === overId);
        const activeContainerIndex = modulos.findIndex((modulo) => modulo.id === event?.active.id);

        if (overContainerIndex !== -1 && activeContainerIndex !== -1 && activeContainerIndex !== overContainerIndex) {
            setModulos((currentModulos) => {
                const newModulos = [...currentModulos];
                const [movingItem] = newModulos.splice(activeContainerIndex, 1);
                newModulos.splice(overContainerIndex, 0, movingItem);

                return newModulos?.map((modulo, index) => ({
                    ...modulo,
                    orden: index + 1,
                }));
            });
        }
    }

    const handleAddModulo = () => {
        if (!curso?.id) return;

        const lastOrden = curso?.modulos && curso?.modulos?.length > 0 ? curso?.modulos[curso?.modulos?.length - 1]?.orden + 1 : 1

        const newModulo = {
            cursoId: curso?.id,
            nombre: `Módulo ${lastOrden}`,
            descripcion: `Este es un módulo de ejemplo para el curso ${curso?.nombre}, puedes borrarlo o modificar el mismo.`,
            publicado: false,
            orden: lastOrden,
        }

        addModulo({
            modulo: newModulo,
            client: client
        })
            .then((response) => {
                const modulo = response?.data?.data

                setRefreshCurso()
                setRefreshData({ modulo: true, leccion: true })

                setSelected({
                    type: TipoSelect.MODULO,
                    data: modulo
                })
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    }

    return (
        <Flex
            pos="relative"
            direction="column"
        >
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                measuring={{
                    droppable: {
                        strategy: MeasuringStrategy.Always,
                    },
                }}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis]}
            >
                <Accordion
                    defaultIndex={[0]}
                    display="flex"
                    flexDirection="column"
                    gap="15px"
                    allowMultiple
                    css={{
                        '&::-webkit-scrollbar': {
                            width: '5px',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#f1f1f1',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#c7c7c7',
                            borderRadius: '5px',
                        },
                        scrollBehavior: 'smooth',
                    }}
                    p="10px"
                    overflow="auto"
                    h="calc(100vh - 215px)"
                >
                    <SortableContext
                        items={modulos}
                        strategy={verticalListSortingStrategy}
                    >
                        {modulos?.map((modulo: ModulosInt) => (
                            <DndModulos
                                key={modulo.id}
                                modulo={modulo}
                                selected={selected}
                                setSelected={setSelected}
                                id={modulo.id}
                                refreshData={refreshData}
                                setRefreshData={setRefreshData}
                            />
                        ))}
                    </SortableContext>
                </Accordion>
            </DndContext>

            <Button
                position="absolute"
                bottom="0px"
                h="fit-content"
                w="100%"
                py="10px"
                px="10px"
                rounded={"6px"}
                border="0.6px solid"
                borderColor="#AAA"
                bg="white"
                fontSize="14px"
                color="pureblack"
                fontWeight="400"
                leftIcon={<Icon as={BiPlus} boxSize="18px" color="pureblack" />}
                onClick={(event: React.MouseEvent) => {
                    event.stopPropagation()

                    handleAddModulo()
                }}
            >
                Añadir módulo
            </Button>
        </Flex>
    );
};