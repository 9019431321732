import { Flex, Text, useDisclosure, useToast } from "@chakra-ui/react"
import { GruposInt } from "../../../../../interfaces/GruposInt";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import { StatusEnumTypes } from "../../../../../shared/utils/Types/StatusEnumTypes";
import { toastNotify } from "../../../../../shared/utils/functions/toastNotify";
import { useClient } from "../../../../../shared/context/client.context";
import { MatriculasInt } from "../../../../../interfaces/MatriculasInt";
import { ModalDelete } from "../../../../../shared/components/Modals/ModalDelete";
import { bajaMatricula, deleteMatricula } from "../../../../../shared/middlewares/matriculas.middlewate";
import { handleErrors } from "../../../../../shared/utils/functions/handleErrors";
import { ModalBajaMatricula } from "../../../../../shared/components/Modals/ModalBajaMatricula";
import { NewMatriculaModal } from "../../../components/Modals/NewMatriculaModal";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { ModaLoading } from "../../../../../shared/components/Modals/ModalLoading";
import filterNonEmptyItems from "../../../../../shared/utils/functions/cleanImport";
import { getFields } from "../../../components/Fields/Fields";
import { SpreadSheet } from "../../../../../ui/SpreadSheetTheme";
import { useDataRelationship } from "../../../../../shared/hooks/useDataRelationship";
import { EndpointTypes, RelationshipTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { FiltrosNames, FiltrosTypes } from "../../../../../shared/utils/Types/FiltrosEnum";
import { useAuthContex } from "../../../../../shared/context/auth.context";
import { uploadMatriculasImport } from "../../../../../shared/middlewares/adjuntos.middleware";
import { useUserPolicy } from "../../../../../shared/hooks/PermissionPolicy";
import { updateUsuario } from "../../../../../shared/middlewares/users.middleware";
import { Table } from "../../../../../shared/components/Table/Table";
import { Filters } from "../../../../../shared/components/Filters/Filters";
import { MatriculasBajaColumns } from "../../../components/Columns/MatriculasBajaColumns";
import { MatriculasColumns } from "../../../components/Columns/MatriculasColumns";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";
import { useDataId } from "../../../../../shared/hooks/useDataId";
import { ConfigEnum } from "../../../../../shared/utils/Types/ConfigTypes";

interface Props {
    hasMatriculasDeBaja: boolean;
    refreshGrupo: () => void;
    isOpenMatricular: boolean;
    onCloseMatricular: () => void;
    grupo: GruposInt;
    isOpen: boolean;
    onClose: () => void;
    isTutorUnico: boolean | undefined;
    isEmpresaUnica: boolean | undefined;
    tutores: { value: string; label: string; }[];
    empresas: { value: string; label: string; }[];
    isTutorFreelance: boolean;
    tabIndex: number;
}

export const TabMatriculas = ({
    hasMatriculasDeBaja,
    refreshGrupo,
    isOpenMatricular,
    onCloseMatricular,
    grupo,
    isOpen,
    onClose,
    isTutorUnico,
    isEmpresaUnica,
    tutores,
    empresas,
}: Props) => {
    const { id } = useParams();
    const { total, contenido } = useUserPolicy();
    const { user, refreshUser } = useAuthContex();
    const client = useClient();
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.MATRICULAS_GRUPO });
    const toast = useToast();
    const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();
    const { isOpen: isOpenBajaMatricula, onOpen: onOpenBajaMatricula, onClose: onCloseBajaMatricula } = useDisclosure();
    const { isOpen: isOpenLoading, onOpen: onOpenLoading, onClose: onCloseLoading } = useDisclosure();
    const matriculaRef = useRef<MatriculasInt | null>(null)

    const { data: matriculas, loading, Refresh } = useDataRelationship({
        id: id,
        client: client,
        endpoint: EndpointTypes.GRUPOS,
        relationship: RelationshipTypes.MATRICULAS,
        specificQuery: {
            deBaja: "false",
        },
        query: query
    })

    const { data: matriculasBaja, loading: loadingBaja } = useDataRelationship({
        id: id,
        client: client,
        endpoint: EndpointTypes.GRUPOS,
        relationship: RelationshipTypes.MATRICULAS,
        specificQuery: {
            deBaja: "true",
        },
        ignoreRequest: !hasMatriculasDeBaja
    })

    const onEventRemove = (event: MatriculasInt) => {
        matriculaRef.current = event

        if (isOpenDelete) onCloseDelete();
        onOpenDelete();
    };

    const onEventBajaMatricula = (event: MatriculasInt) => {
        matriculaRef.current = event

        if (isOpenDelete) onCloseBajaMatricula();
        onOpenBajaMatricula();
    };

    const filterRepeat = (matriculas: any[]) => matriculas.filter((value, index, self) => index === self.findIndex((t) => (t.email === value?.email)));

    const onSubmit = (data: any) => {
        onOpenLoading()

        if (!grupo?.tutores || grupo?.tutores?.length == 0) return toastNotify(toast, StatusEnumTypes.ERROR, `No hay tutor asignado al grupo`)
        if (!grupo?.empresas || grupo?.empresas?.length == 0) return toastNotify(toast, StatusEnumTypes.ERROR, `No hay empresa asignado al grupo`)

        const matriculas: any[] = [];

        for (let i = 0; i < data?.validData?.length; i++) {
            let newData = { ...data?.validData[i], grupoId: grupo?.id };

            if (isTutorUnico) { newData = { ...newData, tutorId: grupo?.tutores[0]?.id } }

            if (isEmpresaUnica) { newData = { ...newData, empresaId: grupo?.empresas[0]?.id } }

            if (!grupo?.gestionaEmpresa) { newData = { ...newData, empresaFacturaId: grupo?.empresaOrganizadoraId } }

            matriculas.push(newData)
        }

        uploadMatriculasImport({
            data: filterRepeat(matriculas),
            client: client
        })
            .then(() => {
                Refresh();
                refreshGrupo();
                toastNotify(toast, StatusEnumTypes.SUCCESS, `Alumnos matriculados correctamente`)
            })
            .catch(() => toastNotify(toast, StatusEnumTypes.ERROR, `Error en la carga masiva, vuelva a intentarlo`))
            .finally(async () => {
                onCloseLoading()

                if (!user?.config?.matriculas && total) {
                    await updateUsuario({
                        id: user?.id as string,
                        client: client,
                        data: {
                            config: {
                                ...user?.config,
                                matriculas: true
                            }
                        }
                    })
                        .then(() => {
                            refreshUser({
                                config: {
                                    ...user?.config,
                                    matriculas: true
                                }
                            })
                        })
                }
            })
    }

    const onDelete = () => {
        deleteMatricula({ matriculaId: matriculaRef?.current?.id, client: client })
            .then(() => {
                Refresh()
                refreshGrupo()
                toastNotify(toast, StatusEnumTypes.SUCCESS, "La matrícula se ha eliminado correctamente")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => onCloseDelete())
    }

    const onConfirm = () => {
        bajaMatricula({ id: matriculaRef?.current?.id, client: client })
            .then(() => {
                Refresh()
                refreshGrupo()
                toastNotify(toast, StatusEnumTypes.SUCCESS, "La matrícula se ha dado de baja correctamente")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => onCloseBajaMatricula())
    }

    const getKeyWord = (defaultWord: string): string => {
        if (!matriculaRef.current?.user?.nombre || !matriculaRef.current?.user?.apellidos) return defaultWord

        const keyword = matriculaRef.current?.user?.nombre + " " + matriculaRef.current?.user?.apellidos

        return keyword
    }

    return (
        <Flex direction="column" gap="20px" mt="20px">
            <>
                <Filters
                    query={query}
                    setQuery={setQuery}
                    allowToggle={true}
                    showBody={false}
                    filterHeaders={[
                        {
                            name: FiltrosNames.NOMBRE,
                            type: FiltrosTypes.INPUT,
                        },
                    ]}
                />

                <Table
                    query={query}
                    setQuery={setQuery}
                    data={matriculas?.data || []}
                    columns={MatriculasColumns({ onEventRemove, onEventBajaMatricula, contenido })}
                    isLoading={loading}
                    total={matriculas?.meta?.total || 0}
                />

                {hasMatriculasDeBaja &&
                    <Flex
                        direction="column"
                        w="100%"
                        gap="10px"
                    >
                        <Text
                            fontSize="18px"
                            fontWeight="600"
                            color="font"
                        >
                            Matriculas de baja
                        </Text>

                        <Table
                            query={query}
                            setQuery={setQuery}
                            data={matriculasBaja?.data || []}
                            columns={MatriculasBajaColumns()}
                            isLoading={loadingBaja}
                            showFooter={false}
                        />
                    </Flex>
                }
            </>

            <ModalDelete
                isOpen={isOpenDelete}
                onClose={onCloseDelete}
                onDelete={onDelete}
                label={getKeyWord("Eliminar matrícula")}
            />

            <ModalBajaMatricula
                isOpen={isOpenBajaMatricula}
                onClose={onCloseBajaMatricula}
                onConfirm={onConfirm}
                matricula={matriculaRef.current}
                label={getKeyWord("Dar de baja matrícula")}
            />

            <NewMatriculaModal
                isOpen={isOpenMatricular}
                onClose={onCloseMatricular}
                setRefresh={Refresh}
                grupo={grupo}
                refreshGrupo={refreshGrupo}
            />

            <ReactSpreadsheetImport
                isOpen={isOpen}
                onClose={onClose}
                onSubmit={onSubmit}
                tableHook={(data) => { return filterNonEmptyItems(data) }}
                fields={
                    getFields({
                        isTutorUnico: isTutorUnico,
                        isEmpresaUnica: isEmpresaUnica,
                        tutores: tutores,
                        empresas: empresas,
                    })
                }
                customTheme={SpreadSheet().theme}
                translations={SpreadSheet().translations}
            />

            <ModaLoading
                isOpen={isOpenLoading}
            />
        </Flex>
    )
}