import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, ModalFooter, Button, useToast, Box, FormLabel, Text, useDisclosure, ModalCloseButton, Spinner, Checkbox } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { useEffect, useRef, useState } from "react";
import { addGrupo } from "../../../../shared/middlewares/grupos.moddleware";
import { FormAsyncSelect } from "@imaginagroup/bit-components.ui.form-async-select";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { FormDateInput } from "@imaginagroup/bit-components.ui.form-date-input";
import { useClient } from "../../../../shared/context/client.context";
import { NewEmpresaModal } from "../../../Empresas/components/NewEmpresaModal";
import { useNavigate } from "react-router-dom";
import { GruposInt } from "../../../../interfaces/GruposInt";
import { CustomSwitch } from "../../../../shared/components/CustomElements/CustomSwitch";
import { CustomShowInput } from "../../../../shared/components/CustomElements/CustomShowInput";
import { EmpresasInt } from "../../../../interfaces/EmpresasInt";
import { useData } from "../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { ModalCloseAlert } from "../../../../shared/components/Modals/ModalCloseAlert";
import { loadData } from "../../../../shared/utils/functions/loadData";
import { QueryTypes } from "../../../../shared/utils/Types/QueryTypes";
import { CursosInt } from "../../../../interfaces/CursosInt";
import { getKey } from "../../../../shared/middlewares/config.middleware";
import { FormSelect } from "@imaginagroup/bit-components.ui.form-select";
import { UserRolEnum } from "../../../../shared/utils/Types/UserRolEnum";
import { useAuthContex } from "../../../../shared/context/auth.context";
import { useUserPolicy } from "../../../../shared/hooks/PermissionPolicy";
import { parseDate } from "../../../../shared/utils/functions/parseDate";
import { updateUsuario } from "../../../../shared/middlewares/users.middleware";
import { formSelectStyles } from "../../../../ui/formSelectStyles";
import { useTimeZone } from "../../../../shared/hooks/useTimeZone";
import { DateTime } from "luxon";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    setRefreshTable: () => void;
    copyData?: GruposInt | undefined | null;
}
export const NewGrupoModal = ({
    isOpen,
    onClose,
    setRefreshTable,
    copyData,
}: Props) => {
    const timeZone = useTimeZone();
    const client = useClient();
    const { user, refreshUser } = useAuthContex();
    const { total, gestion, contenido } = useUserPolicy();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [blockName, setBlockName] = useState<boolean>(true);
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();
    const { isOpen: isOpenEmpresa, onOpen: onOpenEmpresa, onClose: onCloseEmpresa } = useDisclosure();
    const toast = useToast();
    const [curso, setCurso] = useState<CursosInt>()
    const [isFundae, setIsFundae] = useState<boolean>(true)
    const [gestionaEmpresa, setGestionaEmpresa] = useState<boolean>(false)
    const [prevGrupo, setPrevGrupo] = useState<GruposInt>()
    const [query, setQuery] = useState<Record<string, string | number | string[]>>();
    const { data: grupos } = useData({
        client: client,
        endpoint: EndpointTypes.GRUPOS,
        query: query
    })
    const [newEmpresa, setNewEmpresa] = useState<EmpresasInt | null>(null)
    const selectedCurso = useRef<string | null>(null);
    const selectedEmpresa = useRef<string | null | undefined>(null);
    const selectedFechaInicio = useRef<string | null>(null);
    const selectedFechaFin = useRef<string | null>(null);
    const [isUniqueEmpresa, setIsUniqueEmpresa] = useState<boolean>(true)
    const [empresaDefectoId, setEmpresaDefectoId] = useState<string | null>(null)
    const { data: empresas } = useData({
        client: client,
        endpoint: EndpointTypes.EMPRESA,
        query: {
            interna: "true",
        },
        ignoreRequest: (user?.role?.nombre !== UserRolEnum.ADMIN || gestion || contenido),
    })

    useEffect(() => {
        if (empresas && empresas?.data?.length > 0) {
            setIsUniqueEmpresa(empresas?.meta?.total === 1)

            if (empresas?.meta?.total === 1) {
                setEmpresaDefectoId(empresas?.data[0]?.id)
            }
        }
    }, [empresas])

    useEffect(() => {
        if (isFundae === false)
            setQuery({
                fundae: isFundae ? "true" : "false",
                sortBy: "createdAt",
                order: "desc",
                limit: 1,
            })

        if (isFundae === true)
            setQuery({
                fundae: isFundae ? "true" : "false",
                gestionaEmpresa: gestionaEmpresa ? "true" : "false",
                sortBy: "createdAt",
                order: "desc",
                limit: 1
            })

    }, [isFundae, gestionaEmpresa])

    useEffect(() => { setPrevGrupo(grupos?.data[0]) }, [grupos])

    useEffect(() => {
        if (copyData) {
            setIsFundae(copyData?.fundae)
            setCurso(copyData?.curso)

            copyData?.gestionaEmpresa
                ? setGestionaEmpresa(true)
                : setGestionaEmpresa(false)
        }
    }, [copyData])

    const setCodigoGrupo = (
        grupo: GruposInt | undefined,
        fundae: boolean,
        gestionaEmpresa: boolean | null,
    ) => {
        if (!grupo?.codigoGrupo) return "01"

        if (fundae === false) {
            return `01`
        } else {
            if (gestionaEmpresa === true) {
                return `01`
            } else {
                const newCodigo: any = Number(grupo?.codigoGrupo) + 1

                return newCodigo.toFixed(0).padStart(2, '0')
            }
        }
    }

    const setAccionFormativa = (
        grupo: GruposInt | undefined,
        fundae: boolean,
        gestionaEmpresa: boolean | null
    ) => {
        if (!grupo?.accionFormativa) return "01"

        if (fundae === false) {
            const af: any = Number(grupo?.accionFormativa) + 1

            return af.toFixed(0)
        } else {
            if (gestionaEmpresa === true) {
                const af: any = Number(grupo?.accionFormativa) + 1

                if (af < 10) return `0${af.toFixed(0)}`
                else return af.toFixed(0)
            } else {
                const af: any = Number(grupo?.curso?.accionFormativa)

                return af.toFixed(0)
            }
        }
    }

    const initialValues = {
        cursoId: copyData?.cursoId ? copyData?.cursoId : selectedCurso.current,
        empresaId: (copyData?.empresas && copyData?.empresas?.length > 0) ? copyData?.empresas[0]?.id : selectedEmpresa.current,
        empresaOrganizadoraId: copyData?.empresaOrganizadoraId ? copyData?.empresaOrganizadoraId : null,
        fechaInicio: selectedFechaInicio.current,
        fechaFin: selectedFechaFin.current,
        fundae: copyData ? (copyData?.fundae === true ? true : false) : true,
        accionFormativa: copyData ? setAccionFormativa(copyData, isFundae, gestionaEmpresa) : setAccionFormativa(prevGrupo, isFundae, gestionaEmpresa),
        codigoGrupo: copyData ? setCodigoGrupo(copyData, isFundae, gestionaEmpresa) : setCodigoGrupo(prevGrupo, isFundae, gestionaEmpresa),
        gestionaEmpresa: copyData ? (copyData?.gestionaEmpresa === true ? true : false) : false,
        nombre: copyData ? copyData?.nombre : "",
        importeMatricula: copyData?.importeMatricula ? copyData?.importeMatricula : null,
        isUniqueEmpresa: isUniqueEmpresa,
        sinFechaInicio: false,
        sinFechaFin: false,
    };

    const validationSchema = Yup.object({
        isUniqueEmpresa: Yup.boolean(),
        fundae: Yup.boolean(),
        gestionaEmpresa: Yup.boolean(),
        empresaId: Yup.string()
            .notRequired(),
        cursoId: Yup.string()
            .required('Debe asociar un curso al grupo.')
            .typeError('Debe asociar un curso al grupo.'),
        fechaInicio: Yup.string().when(['sinFechaInicio', 'fundae'], {
            is: (sinFechaInicio: boolean, fundae: boolean) => !sinFechaInicio && fundae,
            then: (validationScheme) => validationScheme
                .required('La fecha de inicio es obligatoria.')
                .typeError('La fecha de inicio es obligatoria.'),
            otherwise: (validationScheme) => validationScheme
                .notRequired(),
        }),
        fechaFin: Yup.string().when(['sinFechaFin', 'fundae'], {
            is: (sinFechaFin: boolean, fundae: boolean) => !sinFechaFin && fundae,
            then: (validationScheme) => validationScheme
                .required('La fecha de fin es obligatoria.')
                .typeError('La fecha de fin es obligatoria.'),
            otherwise: (validationScheme) => validationScheme
                .notRequired(),
        }),
        accionFormativa: Yup.string().when(['fundae', 'gestionaEmpresa'], {
            is: (fundae: boolean, gestionaEmpresa: boolean) => fundae && !gestionaEmpresa,
            then: (validationScheme) => validationScheme
                .notRequired()
                .matches(/^[a-zA-Z0-9\sñÑáéíóúÁÉÍÓÚ-]*$/, 'La AF solo puede contener letras, números y subguiones.'),
            otherwise: (validationScheme) => validationScheme
                .required('La acción formativa es obligatoria.')
                .typeError('La acción formativa es obligatoria.')
                .matches(/^\d+$/, 'La AF solo puede contener números.'),
        }),
        codigoGrupo: Yup.string()
            .required('El codgio de grupo es obligatorio.')
            .typeError('El codgio de grupo es obligatorio.')
            .matches(/^\d+$/, 'El codigo solo puede contener números.'),
        empresaOrganizadoraId: Yup.string().when(['isUniqueEmpresa'], {
            is: (isUniqueEmpresa: boolean) => isUniqueEmpresa,
            then: (validationScheme) => validationScheme
                .notRequired(),
            otherwise: (validationScheme) => validationScheme
                .required('La empresa factura es obligatoria.')
                .typeError('La empresa factura es obligatoria.'),
        }),
        importeMatricula: Yup.number()
            .required('El importe de la matrícula es obligatorio.')
            .typeError('El importe de la matrícula es obligatorio.'),
        nombre: Yup.string().notRequired().nullable(),
        sinFechaInicio: Yup.boolean(),
        sinFechaFin: Yup.boolean(),
        estado: Yup.string().when(['sinFechaFin', "fundae"], {
            is: (sinFechaFin: boolean, fundae: boolean) => sinFechaFin && !fundae,
            then: (validationScheme) => validationScheme
                .required('Debe asignar un estado al grupo.')
                .typeError('Debe asignar un estado al grupo.'),
            otherwise: (validationScheme) => validationScheme
                .notRequired(),
        }),
    });

    const handleSubmit = (values: any) => {
        setIsLoading(true)

        const grupoNombre = (values?.fundae && !values?.gestionaEmpresa)
            ? `AF${curso?.accionFormativa || ""}G${values.codigoGrupo}`
            : `AF${values.accionFormativa}G${values.codigoGrupo}`

        const af = (values?.fundae && !values?.gestionaEmpresa)
            ? curso?.accionFormativa || ""
            : values.accionFormativa


        let fechaInicio = parseDate(values.fechaInicio) + "T12:00"
        let fechaFin = parseDate(values.fechaFin) + "T12:00"

        fechaInicio = DateTime.fromISO(fechaInicio).setZone(timeZone).startOf('day').toString()
        fechaFin = DateTime.fromISO(fechaFin).setZone(timeZone).endOf('day').toString()

        let newGrupo: any = {
            nombre: (!values?.fundae && values.nombre) ? values.nombre : (!blockName && values.nombre) ? values.nombre : grupoNombre,
            modalidad: "teleformacion",
            perfil: "organizadora",
            cursoId: values.cursoId,
            fechaInicio: fechaInicio,
            fechaFin: fechaFin,
            accionFormativa: af,
            codigoGrupo: values.codigoGrupo,
            fundae: values?.fundae,
            permitirDescargaDiploma: values?.fundae ? false : true,
            empresas: newEmpresa ? [newEmpresa?.id] : [values.empresaId],
            empresaOrganizadoraId: isUniqueEmpresa ? empresaDefectoId : values.empresaOrganizadoraId,
            importeMatricula: values?.importeMatricula
        }

        if (values?.fundae) {
            newGrupo = {
                ...newGrupo,
                gestionaEmpresa: values?.gestionaEmpresa,
            }
        }

        addGrupo({
            grupo: newGrupo,
            client: client
        })
            .then(async (response: any) => {
                if (!user?.config?.grupos && total) {
                    await updateUsuario({
                        id: user?.id as string,
                        client: client,
                        data: {
                            config: {
                                ...user?.config,
                                grupos: true
                            }
                        }
                    })
                        .then(() => {
                            refreshUser({
                                config: {
                                    ...user?.config,
                                    grupos: true
                                }
                            })
                        })
                }

                const id = response?.data?.data?.id;

                setRefreshTable();
                handleClose();
                navigate(`/grupos/${id}`)

                toastNotify(toast, StatusEnumTypes.SUCCESS, `Se ha creado el curso ${newGrupo.nombre}`)
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleClose = () => {
        onClose()
        setIsFundae(true)
        setGestionaEmpresa(false)
        setBlockName(true)
        selectedCurso.current = null
        selectedEmpresa.current = null
        selectedFechaInicio.current = null
        selectedFechaFin.current = null
    }

    const loadCursos = async (value: string) => await loadData({
        value: value,
        endpoint: EndpointTypes.CURSOS,
        client: client,
        query: QueryTypes.NOMBRE,
        specificQuery: QueryTypes.ACTIVO
    })

    const loadEmpresa = async (value: string) => await loadData({
        value: value,
        endpoint: EndpointTypes.EMPRESA,
        client: client,
        query: QueryTypes.NOMBRE,
    })

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => null}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                size="xl"
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    maxH="95vh"
                    rounded="20px"
                >
                    <Box
                        color="black"
                    >
                        <ModalHeader
                            textAlign="start"
                            fontSize="24px"
                            fontWeight="600"
                            px="30px"
                            pt="30px"
                            pb="0px"
                        >
                            Nuevo grupo
                        </ModalHeader>

                        <ModalCloseButton onClick={() => onOpenAlert()} />
                    </Box>

                    <Box
                        overflowY="auto"
                        overflowX="hidden"
                        __css={{
                            '&::-webkit-scrollbar': {
                                w: '7px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '20px',
                                bg: `light_grey`,
                            },
                        }}
                    >
                        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                            {(formik) => {
                                const { handleSubmit, values } = formik;

                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                    >
                                        <ModalBody
                                            pt="30px"
                                            px="30px"
                                            pb="0px"
                                        >
                                            <Flex
                                                direction="column"
                                                gap="15px"
                                            >
                                                <Flex
                                                    alignItems='center'
                                                    gap="10px"
                                                >
                                                    <CustomSwitch
                                                        label="Bonificado por FUNDAE"
                                                        value={!formik.values?.fundae}
                                                        setValue={() => formik.setFieldValue('fundae', !formik.values.fundae)}
                                                        onClick={() => {
                                                            setIsFundae(!formik.values.fundae)
                                                            selectedCurso.current = values?.cursoId
                                                            selectedEmpresa.current = values?.empresaId
                                                            selectedFechaInicio.current = values?.fechaInicio
                                                            selectedFechaFin.current = values?.fechaFin
                                                        }}
                                                        optionLeft="NO"
                                                        optionRight="Sí"
                                                        width="40px"
                                                        height="30px"
                                                        widhtLabel="100%"
                                                        widthSwitch="20%"
                                                        padding="0"
                                                        labelStyles={{
                                                            color: "black",
                                                            fontSize: "18px",
                                                            fontWeight: 600,
                                                            letterSpacing: "-0.24px",
                                                            width: "80%"
                                                        }}
                                                    />
                                                </Flex>

                                                <Flex
                                                    direction="column"
                                                    gap="20px"
                                                >
                                                    {values?.fundae &&
                                                        <Flex
                                                            direction="column"
                                                        >
                                                            <FormLabel
                                                                htmlFor="gestionaEmpresa"
                                                                display="flex"
                                                                gap="3px"
                                                                color="black"
                                                                fontSize="14px"
                                                                fontWeight="400"
                                                                textTransform="capitalize"
                                                            >
                                                                Gestionado por
                                                            </FormLabel>

                                                            <CustomSwitch
                                                                value={!formik.values?.gestionaEmpresa}
                                                                setValue={() => formik.setFieldValue('gestionaEmpresa', !formik.values.gestionaEmpresa)}
                                                                onClick={() => {
                                                                    setGestionaEmpresa(!formik.values.gestionaEmpresa)
                                                                    selectedCurso.current = values?.cursoId
                                                                    selectedEmpresa.current = values?.empresaId
                                                                    selectedFechaInicio.current = values?.fechaInicio
                                                                    selectedFechaFin.current = values?.fechaFin
                                                                }}
                                                                optionLeft="Interna"
                                                                optionRight="Externa"
                                                                height="25px"
                                                                width="260px"
                                                                widhtLabel="100%"
                                                                widthSwitch="100%"
                                                                padding="0"
                                                            />
                                                        </Flex>
                                                    }

                                                    <Flex
                                                        direction="column"
                                                    >
                                                        {!isUniqueEmpresa &&
                                                            <FormSelect
                                                                name="empresaOrganizadoraId"
                                                                label={"Empresa Organizadora / Factura"}
                                                                noOptionsMessage={
                                                                    () => (
                                                                        <Flex
                                                                            w="100%"
                                                                            justifyContent="center"
                                                                            alignItems="center"
                                                                        >
                                                                            <Spinner
                                                                                color="secondary"
                                                                                size="xs"
                                                                            />
                                                                        </Flex>
                                                                    )
                                                                }
                                                                defaultValue={copyData ?
                                                                    { value: copyData?.empresaOrganizadora?.id, label: copyData?.empresaOrganizadora?.nombre }
                                                                    : null
                                                                }
                                                                options={
                                                                    (empresas === undefined || empresas === null)
                                                                        ? []
                                                                        : [...empresas?.data?.map((empresa: EmpresasInt) => ({ value: empresa?.id, label: empresa?.nombre }))]
                                                                }
                                                                placeholder="Empresa asociada"
                                                                isRequired={
                                                                    copyData
                                                                        ? copyData?.empresaOrganizadora?.id ? false : true
                                                                        : !values.empresaOrganizadoraId ? true : false
                                                                }
                                                                ui={{
                                                                    formSelectStyles: formSelectStyles,
                                                                }}
                                                            />
                                                        }

                                                        <FormInput
                                                            name="importeMatricula"
                                                            type="number"
                                                            label="Importe de la matrícula"
                                                            placeholder="Indica el importe de la matrícula"
                                                            isRequired={!values.importeMatricula ? true : false}
                                                        />
                                                    </Flex>
                                                </Flex>
                                            </Flex>

                                            <Text
                                                color="black"
                                                fontSize="18px"
                                                fontWeight="600"
                                                mb="15px"
                                            >
                                                Datos del grupo
                                            </Text>

                                            <Flex direction="column" gap="20px">
                                                <Flex
                                                    direction="column"
                                                >
                                                    <FormAsyncSelect
                                                        name="cursoId"
                                                        label="Curso asociado"
                                                        defaultValue={copyData ?
                                                            { value: copyData?.curso?.id, label: copyData?.curso?.nombre }
                                                            : null
                                                        }
                                                        loadOptions={loadCursos}
                                                        defaultOptions={true}
                                                        onChangeCallback={(value: any) => setCurso(value?.data)}
                                                        placeholder="Curso asociado"
                                                        isRequired={!values.cursoId ? true : false}
                                                        ui={{
                                                            formSelectStyles: formSelectStyles
                                                        }}
                                                    />

                                                    <Box>
                                                        {values?.fundae &&
                                                            <Flex gap="15px">
                                                                {(values?.fundae && !values?.gestionaEmpresa) ?
                                                                    <Flex flex="1">
                                                                        <CustomShowInput
                                                                            value={curso?.accionFormativa || "" ?
                                                                                curso?.accionFormativa || ""
                                                                                : ""
                                                                            }
                                                                            label="Acción formativa"
                                                                            placeholder="Acción formativa"
                                                                            tooltip="Este campo no es modificable. Si no se asigna automáticamente, verifique que asigno la acción formativa al curso seleccionado."
                                                                        />
                                                                    </Flex>
                                                                    :
                                                                    <Flex flex="1">

                                                                        <FormInput
                                                                            name="accionFormativa"
                                                                            label="Acción formativa"
                                                                            placeholder="Acción formativa"
                                                                            isRequired={!values.accionFormativa ? true : false}
                                                                        />
                                                                    </Flex>

                                                                }

                                                                <Flex flex="1">
                                                                    <FormInput
                                                                        name="codigoGrupo"
                                                                        label="Grupo"
                                                                        placeholder="Código de Grupo"
                                                                        isRequired={!values.codigoGrupo ? true : false}
                                                                    />
                                                                </Flex>
                                                            </Flex>
                                                        }

                                                        {(blockName && values?.fundae) ?
                                                            <CustomShowInput
                                                                value={(values?.fundae && !values?.gestionaEmpresa)
                                                                    ? `AF${curso?.accionFormativa || ""}G${values.codigoGrupo}`
                                                                    : `AF${values.accionFormativa}G${values.codigoGrupo}`
                                                                }
                                                                label="Nombre del grupo"
                                                                placeholder="Nombre del grupo"
                                                                onUnblock={() => setBlockName(false)}
                                                                tooltip="Desbloquee el campo solo en caso de ser necesario cambiar el nombre del grupo. En caso de ser grupo FUNDAE evite esta acción."
                                                            />
                                                            :
                                                            <FormInput
                                                                name="nombre"
                                                                label="Nombre del grupo"
                                                                placeholder="Nombre del grupo"
                                                            />
                                                        }
                                                    </Box>
                                                </Flex>


                                                <Text
                                                    color="black"
                                                    fontSize="18px"
                                                    fontWeight="600"
                                                >
                                                    Datos de la formación
                                                </Text>

                                                {newEmpresa ?
                                                    <CustomShowInput
                                                        value={newEmpresa?.nombre}
                                                        label="Empresa"
                                                        placeholder="Empresa asociada"
                                                        isChangeable={true}
                                                        valueChange={setNewEmpresa}
                                                    />
                                                    :
                                                    <FormAsyncSelect
                                                        name="empresaId"
                                                        label="Empresa"
                                                        defaultValue={
                                                            (copyData?.empresas && copyData?.empresas?.length > 0) ?
                                                                { value: copyData?.empresas[0]?.id, label: copyData?.empresas[0]?.nombre }
                                                                : null
                                                        }
                                                        defaultOptions={true}
                                                        loadOptions={loadEmpresa}
                                                        placeholder="Empresa asociada"
                                                        isRequired={
                                                            (copyData?.empresas && copyData?.empresas?.length > 0)
                                                                ? copyData?.empresas[0]?.id ? false : true
                                                                : !values.empresaId ? true : false
                                                        }
                                                        ui={{
                                                            formSelectStyles: formSelectStyles
                                                        }}
                                                    />
                                                }

                                                <Flex alignItems="center" gap="20px">
                                                    <Text
                                                        color="#67748E"
                                                        fontSize="13px"
                                                        fontWeight="500"
                                                        lineHeight="16px"
                                                        letterSpacing="-0.11px"
                                                    >
                                                        ¿No encuentras la empresa?
                                                    </Text>

                                                    <Text
                                                        cursor="pointer"
                                                        color="black"
                                                        fontSize="13px"
                                                        fontWeight="600"
                                                        letterSpacing="-0.11px"
                                                        onClick={onOpenEmpresa}
                                                        _hover={{ textDecoration: "underline" }}
                                                    >
                                                        Crear empresa
                                                    </Text>
                                                </Flex>

                                                <Flex
                                                    gap="15px"
                                                    alignItems="center"
                                                >
                                                    <Flex
                                                        gap="10px"
                                                        direction="column"
                                                        w="100%"
                                                        justifyContent="center"
                                                    >
                                                        {!values?.fundae &&
                                                            <Checkbox
                                                                gap="0"
                                                                alignItems="center"
                                                                isChecked={values.sinFechaInicio}
                                                                onChange={() => formik.setFieldValue("sinFechaInicio", !values.sinFechaInicio)}
                                                            >
                                                                Grupo sin fecha inicio
                                                            </Checkbox>
                                                        }

                                                        <FormDateInput
                                                            locale="es"
                                                            name="fechaInicio"
                                                            label="Fecha Inicio"
                                                            isRequired={!values.fechaInicio ? true : false}
                                                            onChange={formik.setFieldValue}
                                                            placeholder="Día/Mes/Año"
                                                            isDisabled={values.sinFechaInicio}
                                                        />
                                                    </Flex>

                                                    <Flex
                                                        gap="10px"
                                                        direction="column"
                                                        w="100%"
                                                        justifyContent="center"
                                                    >
                                                        {!values?.fundae &&
                                                            <Checkbox
                                                                gap="0"
                                                                alignItems="center"
                                                                isChecked={values.sinFechaFin}
                                                                onChange={() => formik.setFieldValue("sinFechaFin", !values.sinFechaFin)}
                                                            >
                                                                Grupo sin fecha fin
                                                            </Checkbox>
                                                        }

                                                        <FormDateInput
                                                            locale="es"
                                                            name="fechaFin"
                                                            label="Fecha Fin"
                                                            isRequired={!values.fechaFin ? true : false}
                                                            onChange={formik.setFieldValue}
                                                            placeholder="Día/Mes/Año"
                                                            isDisabled={values.sinFechaFin}
                                                        />
                                                    </Flex>
                                                </Flex>

                                                {values?.sinFechaFin &&
                                                    <Flex
                                                        direction="column"
                                                        alignItems="center"
                                                        justifyContent={"center"}
                                                    >
                                                        <Text
                                                            fontSize="15px"
                                                            color="secondary"
                                                            textAlign={"center"}
                                                        >
                                                            Si el grupo no tiene fecha fin debe asignar un estado manual.
                                                        </Text>

                                                        <FormSelect
                                                            name="estado"
                                                            options={[
                                                                { value: "2", label: "Inactivo" },
                                                                { value: "0", label: "Activo" },
                                                            ]}
                                                            placeholder="Estado"
                                                            ui={{
                                                                formSelectStyles: formSelectStyles,
                                                            }}
                                                        />
                                                    </Flex>
                                                }
                                            </Flex>
                                        </ModalBody>

                                        <ModalFooter justifyContent="center">
                                            <Button
                                                isLoading={isLoading}
                                                type="submit"
                                                bg="secondary"
                                                p="10px 25px"
                                                h="fit-content"
                                                rounded="5"
                                                color="purewhite"
                                                fontSize="18px"
                                                fontWeight="500"
                                                _hover={{ color: "purewhite", backgroundColor: "variant" }}
                                            >
                                                Crear grupo
                                            </Button>
                                        </ModalFooter>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Box>
                </ModalContent>
            </Modal>

            <ModalCloseAlert
                isOpen={isOpenAlert}
                onClose={onCloseAlert}
                onCloseForm={handleClose}
            />

            <NewEmpresaModal
                isOpen={isOpenEmpresa}
                onClose={onCloseEmpresa}
                selectEmpresaGrupo={setNewEmpresa}
            />
        </>
    )
}