import { Flex, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { BiPlusCircle } from "react-icons/bi";
import { isRoleAllowed } from "../../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../../shared/utils/Types/UserRolEnum";
import { useAuthContex } from "../../../../shared/context/auth.context";
import { useClient } from "../../../../shared/context/client.context";
import { handleErrors, handleUnauthorized } from "../../../../shared/utils/functions/handleErrors";
import { FiltrosNames, FiltrosTypes } from "../../../../shared/utils/Types/FiltrosEnum";
import { useData } from "../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { deleteCurso } from "../../../../shared/middlewares/cursos.middleware";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { Topbar } from "../../../../shared/components/Topbar/Topbar";
import { CustomButton } from "../../../../shared/components/CustomElements/CustomButton";
import { useUserPolicy } from "../../../../shared/hooks/PermissionPolicy";
import { ModalDelete } from "../../../../shared/components/Modals/ModalDelete";
import { useModuloFundae } from "../../../../shared/hooks/ModuloFundae";
import { Helmet } from "react-helmet";
import { SinCurso } from "../SinInformacion/SinCursos";
import { NewCursoDrawer } from "../../components/Drawer/NewCursoDrawer";
import { Table } from "../../../../shared/components/Table/Table";
import { CursosColumns } from "./Columns/CursosColumns";
import { EntityTypes, useQuerySession } from "../../../../shared/hooks/useQuerySession";
import { Filters } from "../../../../shared/components/Filters/Filters";
import { EmptyMessageType } from "../../../../shared/utils/Types/EmptyMessageType";

export const CursoTable = () => {
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.CURSOS });
    const { total } = useUserPolicy();
    const moduloFUNDAE = useModuloFundae()
    const { logout, user } = useAuthContex()
    const { gestion } = useUserPolicy();
    const client = useClient();
    const navigate = useNavigate();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cursoRef = useRef<any>(null);
    const { data: cursos, loading, Refresh, error, status, errorsList } = useData({
        client: client,
        endpoint: EndpointTypes.CURSOS,
        query: query
    });
    const [hasFilter, setHasFilters] = useState<boolean>();

    useEffect(() => {
        if (error)
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
    }, [error])

    const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();

    const onEventRemove = (event: any) => {
        cursoRef.current = event;

        if (isOpenDelete) onCloseDelete();
        onOpenDelete();
    };

    const onDelete = () => {
        deleteCurso({ cursoId: cursoRef.current?.id, client: client })
            .then(() => {
                Refresh()
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Curso eliminado correctamente")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => onCloseDelete())
    }

    const getKeyWord = (defaultWord: string): string => {
        if (!cursoRef.current?.nombre) return defaultWord

        const keyword = cursoRef.current?.nombre

        return keyword
    }

    return (
        <>
            <Helmet>
                <title>Cursos | Proacademy</title>
            </Helmet>
            <Topbar
                title={
                    <Text
                        color="font"
                        fontSize="20px"
                        fontWeight="400"
                    >
                        Cursos
                    </Text>
                }
            >
                {(!user?.config?.cursos && total) ?
                    <SinCurso Refresh={Refresh} />
                    :
                    <Flex
                        p="30px"
                        direction="column"
                        gap="20px"
                        w="100%"
                    >
                        <Filters
                            setHasFilters={setHasFilters}
                            query={query}
                            setQuery={setQuery}
                            filterHeaders={[
                                {
                                    name: FiltrosNames.NOMBRE,
                                    type: FiltrosTypes.INPUT,
                                }
                            ]}
                            showBody={false}
                            button={
                                isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) ?
                                    <CustomButton
                                        button={{
                                            label: "Nuevo curso",
                                            onClick: onOpen,
                                            leftIcon: {
                                                as: BiPlusCircle,
                                                boxSize: "20px",
                                            },
                                            isDisabled: gestion
                                        }}
                                        tooltip={{
                                            label: gestion ? "No tienes permisos para esta acción" : "Crear nuevo curso",
                                            isDisabled: false
                                        }}
                                    />
                                    : undefined
                            }
                        />

                        <Table
                            data={cursos?.data || []}
                            columns={CursosColumns({
                                moduloFUNDAE,
                                onEventRemove,
                                gestion,
                                Refresh,
                            })}
                            isLoading={loading}
                            total={cursos?.meta?.total || 0}
                            setQuery={setQuery}
                            query={query}
                            emptyMessage={hasFilter ? EmptyMessageType.cursosFiltros : EmptyMessageType.cursos}
                        />

                        <NewCursoDrawer
                            isOpen={isOpen}
                            onClose={onClose}
                            setRefresh={Refresh}
                        />

                        <ModalDelete
                            isOpen={isOpenDelete}
                            onClose={onCloseDelete}
                            onDelete={onDelete}
                            label={getKeyWord("curso")}
                        />
                    </Flex>
                }
            </Topbar>
        </>

    );
};