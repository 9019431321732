import { Flex, Text, useToast, useDisclosure, Badge, Image } from "@chakra-ui/react"
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { updateGrupo } from "../../../../shared/middlewares/grupos.moddleware";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { IconBox } from "../../../../shared/components/Icons/IconBox";
import { BiBarChartSquare, BiFolder, BiInfoSquare, BiPlusCircle, BiUserCircle } from "react-icons/bi";
import { TabConfiguracion } from "./TabItems/TabConfiguracion";
import { TabFundae } from "./TabItems/TabFundae";
import { TabMatriculas } from "./TabItems/TabMatriculas";
import { TabInformes } from "./TabItems/TabInformes";
import { Skeleton } from "primereact/skeleton";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { useClient } from "../../../../shared/context/client.context";
import { isRoleAllowed } from "../../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../../shared/utils/Types/UserRolEnum";
import { useAuthContex } from "../../../../shared/context/auth.context";
import { UserInt } from "../../../../interfaces/UserInt";
import { useDataId } from "../../../../shared/hooks/useDataId";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { EmpresasInt } from "../../../../interfaces/EmpresasInt";
import { Topbar } from "../../../../shared/components/Topbar/Topbar";
import { afReplaceNombre, codigoReplaceNombre } from "../../../../shared/utils/functions/replaceNombreGrupo";
import { useUserPolicy } from "../../../../shared/hooks/PermissionPolicy";
import { useModuloFundae } from "../../../../shared/hooks/ModuloFundae";
import { MdOutlineMarkEmailRead, MdOutlineMarkEmailUnread } from "react-icons/md";
import { TbSubtask } from "react-icons/tb";
import { TabLogs } from "../../../Emails/views/TabItems/TabLogs";
import { TabPendientes } from "../../../Emails/views/TabItems/TabPendientes";
import { TabTareas } from "../../../Emails/views/TabItems/TabTareas";
import { EmailsSectionTypes } from "../../../../shared/utils/Types/EmailsSectionTypes";
import { Helmet } from "react-helmet";
import { OnboardingStep } from "../../../../shared/components/Cards/OnboardingStep";
import { TabsComponentInt } from "../../../../interfaces/ComponentsInt";
import { CustomTabs } from "@imaginagroup/bit-components.ui.custom-tabs";
import { parseDate } from "../../../../shared/utils/functions/parseDate";
import { DateTime } from "luxon";
import { useTimeZone } from "../../../../shared/hooks/useTimeZone";
import { customTabsStyles } from "../../../../ui/bitStyles";

export const TabsInformationGrupos = () => {
    const timeZone = useTimeZone();
    const moduloFUNDAE = useModuloFundae();
    const { contenido, total } = useUserPolicy();
    const { id } = useParams();
    const { user } = useAuthContex();
    const client = useClient();
    const toast = useToast();
    const { isOpen: isOpenMatricular, onOpen: onOpenMatricular, onClose: onCloseMatricular } = useDisclosure();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { data: grupo, loading, Refresh } = useDataId({
        id: id,
        endpoint: EndpointTypes.GRUPOS,
        client: client
    })
    const [showStepMatricula, setShowStepMatricula] = useState(true)
    const [tutores, setTutores] = useState<{ value: string, label: string }[]>([])
    const [empresas, setEmpresas] = useState<{ value: string, label: string }[]>([]);
    const [isTutorUnico, setIsTutorUnico] = useState<boolean>()
    const [isTutorFreelance, setIsTutorFreelance] = useState<boolean>(true)
    const [isEmpresaUnica, setIsEmpresaUnica] = useState<boolean>()

    const [tabIndex, setTabIndex] = useState<number>(0)
    const handleTabsChange = (index: number) => setTabIndex(index)

    const updateValue = async (values: any) => {
        if (!id) return;

        if ("fechaInicio" in values) {
            let fechaInicio = parseDate(values.fechaInicio) + "T12:00"
            fechaInicio = DateTime.fromISO(fechaInicio).setZone(timeZone).startOf('day').toString()

            values.fechaInicio = fechaInicio
        }

        if ("fechaFin" in values) {
            let fechaFin = parseDate(values.fechaFin) + "T12:00"
            fechaFin = DateTime.fromISO(fechaFin).setZone(timeZone).endOf('day').toString()

            values.fechaFin = fechaFin
        }

        let newValue: any = values;

        for (const [key, value] of Object.entries(values)) {
            if (value === "") {
                newValue = { [key]: null }
            } else {
                newValue = { [key]: value }
            }

            if (grupo?.gestionaEmpresa && key === "accionFormativa") {
                newValue = {
                    [key]: value,
                    nombre: afReplaceNombre(value as string, grupo?.nombre)
                }
            }

            if (grupo?.gestionaEmpresa && key === "codigoGrupo") {
                newValue = {
                    [key]: value,
                    nombre: codigoReplaceNombre(value as string, grupo?.nombre)
                }
            }
        }

        return updateGrupo({
            id: id,
            data: newValue,
            client: client
        })
            .then(() => {
                for (const [key, value] of Object.entries(newValue)) {
                    toastNotify(toast, StatusEnumTypes.SUCCESS, `${[key]} se ha actualizado correctamente`)
                }

                setTimeout(() => Refresh(), 100)
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    };

    const checkTutorFreelance = (tutores: any) => {
        let isFreelance = false;

        if (!tutores || tutores?.length === 0) return isFreelance

        for (let i = 0; i < tutores?.length; i++) {
            if (tutores[i]?.tutorFreelance === true) isFreelance = true
        }

        return isFreelance
    }

    useEffect(() => {
        checkTutorFreelance(grupo?.tutores)
            ? setIsTutorFreelance(true)
            : setIsTutorFreelance(false)
    }, [grupo?.tutores])

    useEffect(() => {
        const optionsTutores: { value: string, label: string }[] = []
        const optionsEmpresas: { value: string, label: string }[] = []

        grupo?.tutores?.map((tutor: UserInt) => {
            if (tutor?.id && tutor?.nombre)
                return optionsTutores.push({ value: tutor?.id, label: `${tutor?.nombre} ${tutor?.apellidos}` })
        })

        grupo?.empresas?.map((empresa: EmpresasInt) => {
            if (empresa?.id && empresa?.nombre)
                return optionsEmpresas.push({ value: empresa?.id, label: empresa?.nombre })
        })

        setTutores(optionsTutores)
        setEmpresas(optionsEmpresas)
    }, [grupo])

    useEffect(() => {
        if (tutores?.length > 1) {
            setIsTutorUnico(false)
        }
        if (tutores?.length === 1) {
            setIsTutorUnico(true)
        }
        if (tutores?.length === 0 || !tutores) {
            setIsTutorUnico(undefined)
        }

    }, [tutores])

    useEffect(() => {
        if (empresas?.length > 1) {
            setIsEmpresaUnica(false)
        }
        if (empresas?.length === 1) {
            setIsEmpresaUnica(true)
        }
        if (empresas?.length === 0 || !empresas) {
            setIsEmpresaUnica(undefined)
        }

    }, [empresas])

    const checkMatriculasDeBaja = (): boolean => {
        const count = grupo?.meta?.matriculasDeBaja;

        if (Number(count) > 0) return true;

        return false;
    }

    const tabs: TabsComponentInt[] = [
        {
            isAllowed: true,
            label: "Configuración",
            icon: BiUserCircle,
            content:
                <TabConfiguracion
                    grupo={grupo}
                    updateValue={updateValue}
                    loading={loading}
                    Refresh={Refresh}
                    isEmpresaUnica={isEmpresaUnica}
                />
        },
        {
            isAllowed: (!isRoleAllowed([UserRolEnum.SUPERVISOR], user?.role?.nombre) || (grupo?.fundae && !contenido && moduloFUNDAE)),
            label: "FUNDAE",
            icon: BiInfoSquare,
            content:
                <TabFundae
                    grupo={grupo}
                    updateValue={updateValue}
                    Refresh={Refresh}
                />
        },
        {
            isAllowed: true,
            label: "Matrículas",
            icon: BiFolder,
            defaultCondition: tabIndex !== 3,
            content:
                <TabMatriculas
                    hasMatriculasDeBaja={checkMatriculasDeBaja()}
                    refreshGrupo={Refresh}
                    isOpenMatricular={isOpenMatricular}
                    onCloseMatricular={onCloseMatricular}
                    grupo={grupo}
                    isOpen={isOpen}
                    onClose={onClose}
                    isTutorUnico={isTutorUnico}
                    tutores={tutores}
                    empresas={empresas}
                    isTutorFreelance={isTutorFreelance}
                    isEmpresaUnica={isEmpresaUnica}
                    tabIndex={tabIndex}
                />
        },
        {
            isAllowed: true,
            label: "Informes",
            icon: BiBarChartSquare,
            content:
                <TabInformes />
        },
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            label: "Enviados",
            icon: MdOutlineMarkEmailRead,
            content:
                <TabLogs
                    id={grupo?.id}
                    tipo={EmailsSectionTypes.GRUPOS}
                />
        },
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            label: "Pendientes",
            icon: MdOutlineMarkEmailUnread,
            content:
                <TabPendientes
                    id={grupo?.id}
                    tipo={EmailsSectionTypes.GRUPOS}
                />
        },
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            label: "Tareas",
            icon: TbSubtask,
            content:
                <TabTareas
                    id={grupo?.id}
                    tipo={EmailsSectionTypes.GRUPOS}
                />
        },


        // TODO: Proxima implementación
        // {
        //     isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
        //     label: "Audits",
        //     icon: RiSwapBoxLine,
        //     content:
        //         <TabAudits
        //             tipo={AuditsTipoEnum.GRUPO}
        //         />
        // }
    ]

    return (
        <>
            <Helmet>
                <title>Grupos | Proacademy</title>
            </Helmet>
            <Topbar
                title={
                    loading ?
                        <Flex gap="15px" alignItems="center" ml="-20px" w="100%">
                            <Skeleton shape="circle" size="45px" />
                            <Skeleton height="25px" width="300px" />
                        </Flex>
                        :
                        <Flex gap="15px" alignItems="center" ml="-20px">
                            {grupo?.curso?.icono?.url ?
                                <Image
                                    src={grupo?.curso?.icono?.url}
                                    padding="0"
                                    boxSize="45px"
                                />
                                :
                                <IconBox
                                    icon={undefined}
                                    size="45px"
                                    border="none"
                                    padding="0"
                                    bg="inherit"
                                />
                            }

                            <Flex
                                gap="15px"
                                alignItems="center"
                            >
                                <Text
                                    color="font"
                                    fontSize="18px"
                                    fontWeight="700"
                                    lineHeight="128%"

                                >
                                    {grupo?.nombre}
                                </Text>

                                {grupo?.fundae &&
                                    <Flex
                                        alignItems="center"
                                        gap="8px"
                                    >

                                        <Text
                                            textTransform="uppercase"
                                            fontSize="12px"
                                            fontWeight="500"
                                            letterSpacing="0.26px"
                                            color="purewhite"
                                            p="3px 7px"
                                            borderRadius="5px"
                                            bg="secondary"
                                        >
                                            FUNDAE
                                        </Text>


                                        <Badge
                                            color={grupo?.gestionaEmpresa ? "main" : "purewhite"}
                                            bg={grupo?.gestionaEmpresa ? "purewhite" : "variant"}
                                            borderColor={grupo?.gestionaEmpresa ? "main" : "variant"}
                                            borderWidth="1px"
                                            fontSize="10px"
                                            fontWeight="500"
                                            letterSpacing="0.2px"
                                            textTransform="uppercase"
                                            borderRadius="14px"
                                            p="2px 5px"

                                        >
                                            {grupo?.gestionaEmpresa ? "Ext" : "Int"}
                                        </Badge>
                                    </Flex>
                                }
                            </Flex>
                        </Flex>
                }
                buttonBack
            >
                <Flex
                    direction="column"
                    p="20px"
                    minH="calc(100vh - 70px)"
                    position="relative"
                >
                    <CustomTabs
                        ui={customTabsStyles}
                        tabs={tabs}
                        tabIndex={tabIndex}
                        handleTabsChange={handleTabsChange}
                        buttons={
                            (isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && ![3, 4, 5, 6, 7].includes(tabIndex)) ?
                                [
                                    {
                                        isAllowed: true,
                                        ui: {
                                            background: "secondary",
                                            colorFont: "purewhite",
                                        },
                                        button: {
                                            label: "Nueva carga masiva",
                                            onClick: () => {
                                                onOpen()
                                                setShowStepMatricula(false)
                                            },
                                            leftIcon: {
                                                as: BiPlusCircle,
                                                boxSize: "20px"
                                            },
                                            isDisabled: tutores?.length === 0 || contenido,
                                        },
                                        tooltip: {
                                            label: contenido ? "No tienes permisos para esta acción" : "No hay tutor asignado",
                                            isDisabled: tutores?.length > 0 && !contenido
                                        }
                                    },
                                    {
                                        isAllowed: true,
                                        ui: {
                                            background: "secondary",
                                            colorFont: "purewhite",
                                        },
                                        button: {
                                            label: "Nueva matrícula",
                                            onClick: () => {
                                                onOpenMatricular()
                                                showStepMatricula && setShowStepMatricula(false)
                                            },
                                            leftIcon: {
                                                as: BiPlusCircle,
                                                boxSize: "20px"
                                            },
                                            isDisabled: tutores?.length === 0 || contenido,
                                        },
                                        tooltip: {
                                            label: contenido ? "No tienes permisos para esta acción" : "No hay tutor asignado",
                                            isDisabled: tutores?.length > 0 && !contenido
                                        }
                                    }
                                ]
                                : undefined
                        }
                    />

                    {(showStepMatricula && (!user?.config?.matriculas && total)) &&
                        <OnboardingStep
                            position={{
                                top: "60px",
                                right: "400px"
                            }}
                            placement="top/right"
                            title="Matricula tus primeros alumnos"
                            description="Asocia matrículas a tus grupos para que los alumnos puedan acceder a los cursos y formaciones que ofreces."
                            isDisabled={tutores?.length === 0 || contenido}
                            disabledTooltip="Primero debes crear un tutor y asignarlo al grupo."
                            onClick={() => {
                                setShowStepMatricula(false)
                                onOpenMatricular()
                            }}
                        />
                    }
                </Flex>
            </Topbar>
        </>
    )
}