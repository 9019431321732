import { Box, Flex, Image, Text, useDisclosure } from "@chakra-ui/react"
import { MutableRefObject, useEffect, useRef } from "react"
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes"
import { UserRolEnum } from "../../../../shared/utils/Types/UserRolEnum"
import { useClient } from "../../../../shared/context/client.context"
import { useAuthContex } from "../../../../shared/context/auth.context"
import { useUserPolicy } from "../../../../shared/hooks/PermissionPolicy"
import { EmpresasInt } from "../../../../interfaces/EmpresasInt"
import { defaultIcon } from "../../../Onboarding/components/OnboardingForm"
import { StatusItem } from "../../components/StatusItem"
import { EditEmpresaDrawer } from "../../components/EditEmpresaDrawer"
import { useData } from "../../../../shared/hooks/useData"
import { updateUsuario } from "../../../../shared/middlewares/users.middleware"
import { NewEmpresaModal } from "../../../Empresas/components/NewEmpresaModal"

interface Props {
    isOpenNew: boolean
    onCloseNew: () => void
}

export const EmpresasOrganizadoras = ({
    isOpenNew,
    onCloseNew,
}: Props) => {
    const client = useClient()
    const { user, refreshUser } = useAuthContex();
    const { gestion, contenido, total } = useUserPolicy()
    const { onOpen: onOpenEdit, isOpen: isOpenEdit, onClose: onCloseEdit } = useDisclosure()
    const empresaRef = useRef<EmpresasInt | null>(null)
    const { data: empresas, Refresh } = useData({
        client,
        endpoint: EndpointTypes.EMPRESA,
        query: {
            interna: "true",
        },
        ignoreRequest: (user?.role?.nombre !== UserRolEnum.ADMIN || gestion || contenido),
    })

    const onEventEdit = (empresa: EmpresasInt | null) => {
        empresaRef.current = empresa;

        if (isOpenEdit) onCloseEdit()
        onOpenEdit()
    }

    const checkEmpresasCompleted = (empresas: EmpresasInt[]) => {
        if (!empresas || empresas?.length === 0) return false

        const totalEmpresas = empresas?.length
        const completed = empresas?.filter(
            (empresa: EmpresasInt) =>
                empresa?.cif &&
                empresa?.email &&
                empresa?.telefono &&
                empresa?.extraInfo?.direccion &&
                empresa?.extraInfo?.horarios &&
                empresa?.icono?.url &&
                empresa?.imagen?.url
        ).length

        return totalEmpresas === completed ? true : false
    }

    useEffect(() => {
        if (!user?.config?.empresasOrganizadoras && total) {
            const completedStep = checkEmpresasCompleted(empresas?.data)

            if (completedStep) {
                updateUsuario({
                    id: user?.id as string,
                    client: client,
                    data: {
                        config: {
                            ...user?.config,
                            empresasOrganizadoras: true
                        }
                    }
                })
                    .then(() => {
                        refreshUser({
                            config: {
                                ...user?.config,
                                empresasOrganizadoras: true
                            }
                        })
                    })
            }
        }
    }, [empresas])

    return (
        <Flex
            w="100%"
            direction="column"
            pt="10px"
            gap="20px"
        >
            {empresas?.data?.map((empresa: EmpresasInt) => (
                <Box
                    cursor="pointer"
                    borderRadius="14px"
                    borderWidth="1px"
                    borderColor="#DFE2E6"
                    bg="purewhite"
                    boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.01)"
                    transition="all 0.4s ease-in-out"
                    _hover={{
                        transform: 'translateY(-0.1px)',
                        boxShadow: 'rgba(64, 85, 128, 0.15) 0px 4px 12px',

                    }}
                    onClick={() => onEventEdit(empresa)}
                >
                    <Flex
                        w="100%"
                        alignItems="center"
                        p="20px"
                    >
                        <Flex
                            gap="10px"
                            alignItems="center"
                            borderRight="1px solid"
                            borderColor="light_grey"
                            w="20%"
                        >
                            {empresa?.icono?.url ?
                                <Image
                                    src={empresa?.icono?.url}
                                    boxSize="45px"
                                    border="none"
                                    padding="0"
                                    objectFit="cover"
                                />
                                :
                                <Image
                                    src={`data:image/svg+xml;utf8,${defaultIcon}`}
                                    boxSize="45px"
                                    border="none"
                                    padding="0"
                                    objectFit="cover"
                                />
                            }

                            <Flex
                                direction="column"
                            >
                                <Text
                                    color="font"
                                    fontSize="16px"
                                    fontWeight="600"
                                >
                                    {empresa.nombre}
                                </Text>

                                <Text
                                    color="font"
                                    fontSize="12px"
                                    fontWeight="500"
                                >
                                    Status de la empresa
                                </Text>
                            </Flex>
                        </Flex>

                        <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            w="80%"
                        >
                            <StatusItem
                                status={empresa?.cif ? true : false}
                                text="CIF"
                            />

                            <StatusItem
                                status={empresa?.email ? true : false}
                                text="Email"
                            />

                            <StatusItem
                                status={empresa?.telefono ? true : false}
                                text="Teléfono"
                            />

                            <StatusItem
                                status={empresa?.extraInfo?.direccion ? true : false}
                                text="Dirección"
                            />

                            <StatusItem
                                status={empresa?.extraInfo?.horarios ? true : false}
                                text="Horario"
                            />

                            <StatusItem
                                status={empresa?.icono?.url ? true : false}
                                text="Icono"
                            />

                            <StatusItem
                                status={empresa?.imagen?.url ? true : false}
                                text="Firma"
                            />
                        </Flex>
                    </Flex>
                </Box>
            ))}

            <EditEmpresaDrawer
                isOpen={isOpenEdit}
                onClose={onCloseEdit}
                empresa={empresaRef?.current}
                Refresh={Refresh}
            />

            <NewEmpresaModal
                isOpen={isOpenNew}
                onClose={onCloseNew}
                setRefreshTable={Refresh}
                interna={true}
            />
        </Flex>
    )
}