import { Button, Flex, Icon, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text, Tooltip, useToast } from "@chakra-ui/react"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { InformationLeccion } from "./TabsLeccion/Informacion"
import { PreviewLeccion } from "./TabsLeccion/Preview";
import { Form as FormikForm, Formik } from 'formik';
import { LeccionInt } from "../../../../interfaces/CursosInt";
import { useTimeZone } from "../../../../shared/hooks/useTimeZone";
import { formatTimezone } from "../../../../shared/utils/functions/parseDate";
import { useClient } from "../../../../shared/context/client.context";
import { loadContenido, updateLeccion } from "../../../../shared/middlewares/lecciones.middleware";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { FormInput } from "@imaginagroup/bit-components.ui.form-input";
import { FiEdit3 } from "react-icons/fi";
import { useTenantInfo } from "../../../../shared/hooks/useTenantInfo";

interface Props {
    leccion: LeccionInt | undefined;
    setRefreshData: Dispatch<SetStateAction<{ leccion: boolean; modulo: boolean; }>>;
}

export const EditLeccion = ({ leccion, setRefreshData }: Props) => {
    const timeZone = useTimeZone();
    const toast = useToast();
    const client = useClient();
    const [file, setFile] = useState<File | null>(null);
    const [editName, setEditName] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { handleGetInfo } = useTenantInfo();
    const colores = handleGetInfo({ key: "colores" });


    useEffect(() => {
        if (editName) {
            const inputElement: HTMLInputElement | null = document.querySelector('input[name="nombre"]');

            if (inputElement) {
                inputElement.focus();
            }
        }
    }, [editName])

    const initialValues = {
        nombre: leccion?.nombre,
        fechaPublicacion: formatTimezone({
            date: leccion?.fechaPublicacion as string,
            timeZone
        }),
        descripcion: leccion?.descripcion,
        contenido: leccion?.contenido,
    }

    const handleSubmit = async (values: any) => {
        setLoading(true);
        let newValue = values

        if (values?.contenido === "") {
            newValue = {
                ...values,
                contenido: null
            }
        }

        if (file) {
            loadContenido({
                id: leccion?.id as string,
                client: client,
                file: file
            })
                .then(() => setRefreshData((prev) => ({ ...prev, leccion: true })))
                .catch((error: any) => {
                    const errors = handleErrors(
                        error?.response?.data?.errors,
                        error?.response?.status
                    )

                    errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
                })
        }

        await updateLeccion({
            id: leccion?.id as string,
            data: newValue,
            client: client
        })
            .then(() => setRefreshData((prev) => ({ ...prev, leccion: true })))
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setLoading(false))
    }

    const isExistsChanges = (values: {
        nombre: string | undefined;
        descripcion: string | undefined;
        contenido: string | undefined | null;
        fechaPublicacion: string | undefined;
    }) => {
        if (
            values?.nombre !== leccion?.nombre ||
            values?.descripcion !== leccion?.descripcion ||
            values?.contenido !== leccion?.contenido ||
            values?.fechaPublicacion !== formatTimezone({
                date: leccion?.fechaPublicacion as string,
                timeZone
            }) || file
        ) return true

        return false
    }

    return (
        <Flex
            w="100%"
            px="10px"
            pt="10px"
            direction="column"
        >
            <Formik
                onSubmit={handleSubmit}
                enableReinitialize
                initialValues={initialValues}
            >
                {(formik) => {
                    const { handleSubmit, values } = formik;

                    return (
                        <FormikForm
                            onSubmit={handleSubmit}
                        >
                            <Flex
                                justifyContent="space-between"
                                mb="20px"
                            >
                                <Flex
                                    h="40px"
                                    alignItems="center"
                                >
                                    {!editName ?
                                        <Flex
                                            alignItems="center"
                                            gap="10px"
                                            mt="2px"
                                            onClick={() => setEditName(true)}
                                        >
                                            <Text
                                                fontSize="24px"
                                                fontWeight="400"
                                                color="pureblack"
                                                pl="2px"
                                            >
                                                {values?.nombre}
                                            </Text>

                                            <Icon
                                                as={FiEdit3}
                                                boxSize="18px"
                                                color="pureblack"
                                                cursor="pointer"
                                            />
                                        </Flex>
                                        :
                                        <FormInput
                                            name="nombre"
                                            isBlockError
                                            onBlur={() => setEditName(false)}
                                            ui={{
                                                styleInput: {
                                                    border: "none",
                                                    bg: "transparent",
                                                    width: "auto",
                                                    fontSize: "24px",
                                                    fontWeight: "400",
                                                    color: "pureblack",
                                                    pl: "2px",
                                                },
                                                focusInput: {
                                                    border: "none",
                                                    boxShadow: `0 0 0 1px ${colores.variant}`,
                                                },
                                            }}
                                        />
                                    }
                                </Flex>

                                <Flex
                                    alignItems="center"
                                    gap="10px"
                                >
                                    <Button
                                        h="fit-content"
                                        w="fit-content"
                                        py="10px"
                                        px="10px"
                                        rounded={"6px"}
                                        bg="secondary"
                                        fontSize="14px"
                                        color="purewhite"
                                        fontWeight="400"
                                        type="submit"
                                        isDisabled={!leccion?.id || !isExistsChanges(values)}
                                        isLoading={loading}
                                    >
                                        Guardar lección
                                    </Button>

                                    <Button
                                        isDisabled={
                                            (!leccion?.id || !isExistsChanges(values) || loading)
                                            && !editName
                                        }
                                        h="fit-content"
                                        w="fit-content"
                                        py="10px"
                                        px="10px"
                                        rounded={"6px"}
                                        border="1px solid"
                                        borderColor="#AAA"
                                        bg="white"
                                        fontSize="14px"
                                        color="pureblack"
                                        fontWeight="400"
                                        onClick={() => {
                                            formik.resetForm()
                                            file && setFile(null)
                                            setEditName(false)
                                        }}
                                    >
                                        Descartar cambios
                                    </Button>
                                </Flex>
                            </Flex>


                            <Flex
                                direction="column"
                            >
                                <InformationLeccion
                                    leccion={leccion}
                                    formik={formik}
                                    file={file}
                                    setFile={setFile}
                                    loading={loading}
                                />

                                {leccion?.contenido &&
                                    <PreviewLeccion
                                        leccion={leccion}
                                    />
                                }
                            </Flex>
                        </FormikForm>
                    );
                }}
            </Formik>
        </Flex>
    );
}