import { Flex, Text } from "@chakra-ui/react";
import { ColumnProps } from "../../../../../../shared/components/Table/Table";
import { EntregableEstadoEnum } from "../../../../../../shared/utils/Types/EntregableEstadoEnum";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { ColumnLabelText } from "@imaginagroup/bit-components.ui.columns.column-label-text";
import { ColumnBadge } from "@imaginagroup/bit-components.ui.columns.column-badge";
import { ColumnDate } from "../../../../../../shared/components/CustomColumns/ColumnDate";

export const CorreccionesColumns = (): ColumnProps[] => {

    return [
        {
            header: 'Pendiente desde',
            field: 'fechaEntrega',
            key: 'fechaEntrega',
            sortable: true,
            body: (rowData: any) => (
                <ColumnDate
                    date={rowData?.createdAt}
                />
            )
        },
        {
            header: 'alumno',
            field: 'nombre',
            key: 'alumno',
            sortable: false,
            body: (rowData: any) => (

                <ColumnLabelText
                    label={rowData?.user?.nombre + " " + rowData?.user?.apellidos}
                    text={rowData?.matricula?.grupo?.nombre}
                    tooltip={{
                        label: (
                            <Flex
                                direction="column"
                                gap="10px"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Text
                                    fontSize="14px"
                                    fontWeight="600"
                                >
                                    Comentario Alumno
                                </Text>

                                <Text
                                    fontSize="12px"
                                    fontWeight="400"
                                >
                                    {rowData?.texto}
                                </Text>
                            </Flex>
                        )
                    }}
                />
            ),
        },
        {
            header: 'lección',
            field: 'leccion',
            key: 'leccion',
            sortable: false,
            body: (rowData: any) => (
                <ColumnLabelText
                    label={rowData?.leccion?.nombre}
                    text={rowData?.curso?.nombre}
                />
            ),
        },
        {
            header: 'tutor',
            field: 'tutorId',
            key: 'tutorId',
            sortable: false,
            body: (rowData: any) => (
                <ColumnLabelText
                    label={rowData?.matricula?.tutor?.nombre + " " + rowData?.matricula?.tutor?.apellidos}
                    text={rowData?.matricula?.tutor?.tutorFreelance ? "Colaborador" : "Contratado"}
                />
            ),
        },
        {
            header: 'estado',
            field: 'estado',
            key: 'estado',
            sortable: false,
            body: (rowData) => (
                <ColumnBadge
                    text={
                        rowData?.estado === EntregableEstadoEnum.ERROR ? 'No superado'
                            : rowData?.estado === EntregableEstadoEnum.CORRECTO ? 'Superado'
                                : "pendiente"
                    }
                    ui={{
                        colorFont: rowData?.estado === EntregableEstadoEnum.ERROR ? 'fail_main' : rowData?.estado === EntregableEstadoEnum.CORRECTO ? 'success_main' : 'secondary',
                        backgroundColor: rowData?.estado === EntregableEstadoEnum.ERROR ? 'fail_bg' : rowData?.estado === EntregableEstadoEnum.CORRECTO ? 'success_bg' : '#EEEDFA',
                        rounded: '14px',
                        fontSize: '10px',
                        fontWeight: 500,
                        styles: {
                            borderColor: rowData?.estado === EntregableEstadoEnum.ERROR ? 'fail_variant' : rowData?.estado === EntregableEstadoEnum.CORRECTO ? 'success_variant' : '#EEEDFA',
                            letterSpacing: '0.26px',
                            textTransform: 'uppercase',
                            borderWidth: '1px',
                        }
                    }}
                />
            ),
        },
    ]
}