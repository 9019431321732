import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import ReactChart from "./charts/ReactChart";
import { useClient } from "../../../shared/context/client.context";
import { CursosInt } from "../../../interfaces/CursosInt";
import SelectorCurso from "./SelectorMatriculas/SelectorMatriculas";
import { useData } from "../../../shared/hooks/useData";
import { EndpointTypes } from "../../../shared/utils/Types/EndpointTypes";
import { checkQuerys } from "../../../shared/utils/functions/checkQuerys";
import { HiOutlineChartBar } from "react-icons/hi";

interface Props {
  onSelectCurso: (curso: any) => void;
  cursos: CursosInt[];
  selectedCurso: CursosInt | undefined;
  colors?: any;
}

export default function Matriculas({ onSelectCurso, cursos, selectedCurso, colors, ...rest }: Props) {
  const client = useClient();
  const { data: matriculas } = useData({
    client: client,
    endpoint: EndpointTypes.STATS_MATRICULAS,
    specificQuery: selectedCurso?.id ? { curso: selectedCurso?.id } : undefined,
    query: selectedCurso?.id ? { meses: 6 } : {},
  })

  return (

    <Flex
      justifyContent="flex-start"
      alignItems="start"
      flexDirection="column"
      w="100%"
      bgColor="purewhite"
      borderWidth={"1px"}
      borderColor={"#DFE2E6"}
      p="24px"
      rounded="14px"
      boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.01)"
      {...rest}
    >
      <Flex
        w="100%"
        display="flex"
        flexDirection="column">

        <Flex
          justifyContent="space-between"
          align={"start"}
        >

          <Flex
            gap="12px"
            align={"center"}
            justify={"end"}
          >
            <Flex
              boxSize={"28px"}
              borderRadius={"4px"}
              bgColor={"#F9FAFB"}
              align={"center"}
              justify={"center"}
            >
              <Icon
                boxSize={"18px"}
                as={HiOutlineChartBar}
                color={"#616675"}
              />
            </Flex>

            <Text
              color="pureblack"
              fontSize="18px"
              textAlign="start"
              fontWeight="400"
              lineHeight="120%"
            >
              Matrículas totales
            </Text>

          </Flex>

          <Flex gap="20px" direction="column">

            <SelectorCurso
              onSelectCurso={onSelectCurso}
              cursos={cursos}
              selectedCurso={selectedCurso}

            />

          </Flex>
        </Flex>
        {matriculas &&
          <Flex
            direction="column"
            justify="space-between"

          >
            <Text
              fontSize="32px"
              fontWeight="500"
              textColor="pureblack"
            >
              {matriculas?.reduce((totalMatriculas: number, mesActual: any) => totalMatriculas + mesActual?.matriculas, 0)}
            </Text>
          </Flex>
        }
        <Box mr="30px">
          <ReactChart data={matriculas} colors={colors} />
        </Box>
      </Flex>
    </Flex>

  );
}
