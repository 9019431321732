import { Badge, Flex, Icon, Image, Skeleton, StyleProps, Text } from "@chakra-ui/react"
import { BiChevronRight } from "react-icons/bi"
import { formatTimezone } from "../../utils/functions/parseDate"
import { defaultIcon } from "../Icons/IconBox"
import { useTimeZone } from "../../hooks/useTimeZone"
import { useEffect, useState } from "react"
import { Pagination } from "./components/Pagination"

export interface CustomColumnProps {
    key: string;
    field: string;
    header: string | JSX.Element;
    sortable?: boolean;
    body?: (data: any) => JSX.Element | undefined;
    style?: StyleProps;
}

interface Props {
    data: any[]
    loading: boolean
    columns: CustomColumnProps[]
    total?: number
    columnsGroup?: CustomColumnProps[]
    groupByKey?: string
    setQuery: (query: Record<string, string | number | string[]>) => void
    query: Record<string, string | number | string[]>
    outContainer?: boolean
}

export const CustomGroupTable = ({
    data,
    loading,
    columns,
    total,
    setQuery,
    query,
    columnsGroup,
    groupByKey,
    outContainer = false
}: Props) => {
    const [sortBy, setSortBy] = useState<string | undefined>();
    const [sortOrder, setSortOrder] = useState<0 | 1 | -1 | null | undefined>();
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<10 | 15 | 20 | 25 | 50 | 75 | 100>(10);

    useEffect(() => {
        let options = Object.fromEntries(Object.entries(query).filter(q => q[0] !== "page" && q[0] !== "limit" && q[0] !== "sortBy" && q[0] !== "order"))
        options = sortOrder === 1 ? { order: "asc", ...options } : sortOrder === -1 ? { order: "desc", ...options } : { ...options };
        options = sortBy !== undefined ? { sortBy: sortBy, ...options } : { ...options };

        setQuery({
            page: page,
            limit: limit,
            ...options
        });

    }, [page, sortBy, sortOrder, limit]);

    const [rowOpen, setRowOpen] = useState<{
        id: string,
        isOpen: boolean
    }[]>([])

    return (
        <Flex
            direction="column"
            w="100%"
            p={!outContainer ? "25px" : "0"}
            bg="purewhite"
            borderRadius="14px"
            border={!outContainer ? "1px solid #DFE2E6" : "none"}
            gap="20px"
        >
            <Flex
                direction="column"
            >
                <Flex
                    width="100%"
                    justifyContent={"space-between"}
                    alignItems="center"
                    p="15px"
                    borderRadius="8px"
                    bg="#F9FAFB"
                >
                    <Flex
                        w="20px"
                        h="100%"
                    />

                    {columns.map((column) => (
                        <Text
                            key={column?.key}
                            fontSize="14px"
                            fontWeight="500"
                            h="100%"
                            w={column?.style?.w || column?.style?.width || `${100 / columns.length}%`}
                            {...column?.style}
                        >
                            {column?.header}
                        </Text>
                    ))}
                </Flex>

                {!loading ?
                    data?.map((item: any, index: number) => (
                        <Flex
                            direction="column"
                        >
                            <Flex
                                width="100%"
                                justifyContent={"space-between"}
                                alignItems="center"
                                px="5px"
                                key={index}
                                borderBottom={rowOpen.find((i: any) => i.id === item.id)?.isOpen || index !== (data?.length - 1) ? "none" : "1px solid #DFE2E6"}
                                borderTop={index === 0 ? "none" : "1px solid #DFE2E6"}
                            >
                                <Flex
                                    w="20px"
                                >
                                    {groupByKey &&
                                        (item[groupByKey] && item[groupByKey]?.length > 0) &&
                                        <Flex
                                            rounded="100%"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Icon
                                                color="#616675"
                                                _active={{
                                                    scale: 0.9
                                                }}
                                                cursor={"pointer"}
                                                as={BiChevronRight}
                                                boxSize="24px"
                                                transition={'transform 0.3s ease'}
                                                transform={rowOpen.find((i: any) => i.id === item.id)?.isOpen ? 'rotate(90deg)' : 'rotate(0deg)'}
                                                onClick={() => {
                                                    setRowOpen((prev: any) => {
                                                        const findItem = prev.find((i: any) => i.id === item.id)

                                                        if (findItem) {
                                                            return prev.filter((i: any) => i.id !== item.id)
                                                        } else {
                                                            return [...prev, { id: item.id, isOpen: true }]
                                                        }
                                                    })
                                                }}
                                            />
                                        </Flex>
                                    }
                                </Flex>

                                {columns?.map((column, index) => (
                                    <Flex
                                        key={index}
                                        w={column?.style?.w || column?.style?.width || `${100 / columns.length}%`}
                                        {...column?.style}
                                    >
                                        {column?.body && column?.body(item)}
                                    </Flex>
                                ))}
                            </Flex>

                            <Flex
                                display={rowOpen.find((i: any) => i.id === item.id)?.isOpen ? "block" : "none"}
                                transition={"all 0.3s ease"}
                                direction="column"
                                ml="3%"
                            >
                                <Flex
                                    width="100%"
                                    justifyContent={"space-between"}
                                    alignItems="center"
                                    py="7px"
                                    px="45px"
                                    borderRadius="8px"
                                    bg="#F9FAFB"
                                >
                                    {columnsGroup?.map((column) => (
                                        <Text
                                            key={column?.key}
                                            fontSize="14px"
                                            fontWeight="500"
                                            h="100%"
                                            w={column?.style?.w || column?.style?.width || `${100 / columnsGroup.length}%`}
                                            {...column?.style}
                                        >
                                            {column?.header}
                                        </Text>
                                    ))}
                                </Flex>

                                {groupByKey &&
                                    item[groupByKey]?.map((group: any, iGrupos: number) => (
                                        <Flex
                                            width="100%"
                                            justifyContent={"space-between"}
                                            alignItems="center"
                                            px="45px"
                                            key={iGrupos}
                                            gap="8px"
                                        >
                                            {columnsGroup?.map((column, index) => (
                                                <Flex
                                                    key={index}
                                                    w={column?.style?.w || column?.style?.width || `${100 / columnsGroup.length}%`}
                                                    {...column?.style}
                                                >
                                                    {column?.body && column?.body(group)}
                                                </Flex>
                                            ))}
                                        </Flex>
                                    ))}
                            </Flex>
                        </Flex>
                    ))
                    :
                    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                        <Flex
                            w="100%"
                            justifyContent="space-between"
                            alignItems="center"
                            key={item}
                            borderBottom={item === 9 ? "none" : "1px solid #DFE2E6"}
                        >
                            {columns.map((column, index: number) => (
                                <Flex
                                    px="5px"
                                    py="16px"
                                    w={column?.style?.w || column?.style?.width || `${100 / columns.length}%`}
                                >
                                    <Skeleton
                                        key={index}
                                        w="100%"
                                        h="42px"
                                        borderRadius="8px"
                                    />
                                </Flex>
                            ))}
                        </Flex>
                    ))
                }
            </Flex>

            <Pagination
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                total={total || 0}
                loading={loading}
                lastPage={Math.ceil((total || 0) / limit)}
                firstPage={1}
            />
        </Flex>
    )
}