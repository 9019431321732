import { Badge, Flex, Text } from "@chakra-ui/react";
import { ColumnProps } from "../../../../../shared/components/Table/Table";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { ColumnBadge } from "@imaginagroup/bit-components.ui.columns.column-badge";
import { useTimeZone } from "../../../../../shared/hooks/useTimeZone";
import { formatTimezone } from "../../../../../shared/utils/functions/parseDate";

interface Props {
    availableSelect: boolean;
}

export const AsignacionesPendientesColumns = ({ availableSelect }: Props): ColumnProps[] => {
    const timeZone = useTimeZone();

    const columns: ColumnProps[] = [
        {
            header: '',
            field: '',
            key: '',
            selectionMode: availableSelect ? "multiple" : undefined,
        },
        {
            header: 'Tutor',
            field: 'tutor',
            key: 'tutor',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.user?.nombre + " " + rowData?.user?.apellidos}
                />
            )
        },
        {
            header: 'Alumno',
            field: 'alumno',
            key: 'alumno',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.matricula?.user?.nombre + " " + rowData?.matricula?.user?.apellidos}
                />
            )
        },
        {
            header: 'Curso',
            field: 'curso',
            key: 'curso',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.matricula?.grupo?.curso?.nombre}
                />
            )
        },
        {
            header: 'Duracion',
            field: 'estado',
            key: 'estado',
            sortable: true,
            body: (rowData: any) => (
                <Flex direction="column" alignItems="flex-start" ml="16px" py="16px">
                    <Badge
                        color={
                            rowData?.matricula?.estado == 2 ? "font" :
                                rowData?.matricula?.estado == 1 ? "secondary"
                                    : "success_main"
                        }
                        bg={
                            rowData?.matricula?.estado == 2 ? "light_grey" :
                                rowData?.matricula?.estado == 1 ? "secondary_bg"
                                    : "success_bg"
                        }
                        borderColor={
                            rowData?.matricula?.estado == 2 ? "light_grey" :
                                rowData?.matricula?.estado == 1 ? "variant"
                                    : "success_variant"}

                        borderWidth="1px"
                        fontSize="10px"
                        fontWeight="500"
                        letterSpacing="0.2px"
                        textTransform="uppercase"
                        borderRadius="14px"
                        p="2px 5px"
                    >
                        {
                            rowData?.matricula?.estado == 2 ? "Inactivo" :
                                rowData?.matricula?.estado == 1 ? "Próximo"
                                    : "Activo"
                        }
                    </Badge>
                    {(rowData?.matricula?.fechaInicio && rowData?.matricula?.fechaFin) &&
                        <Flex
                            alignItems="center"
                            gap="5px"
                            color="font"
                            fontSize="14px"
                            fontWeight="400"
                        >
                            <Text>{formatTimezone({ date: rowData?.matricula?.fechaInicio, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                            <Text mb="2px">-</Text>
                            <Text>{formatTimezone({ date: rowData?.matricula?.fechaFin, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                        </Flex>
                    }
                </Flex>
            )
        },
        {
            header: 'Importe',
            field: 'importe',
            key: 'importe',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.importe?.toFixed(2) + "€" + `(${rowData?.matricula?.importe?.toFixed(2)}€)`}
                />
            )
        },
        {
            header: 'Pagado',
            field: 'liquidada',
            key: 'liquidada',
            sortable: true,
            body: (rowData: any) => (
                <ColumnBadge
                    text={rowData?.liquidada ? "Si" : "No"}
                    ui={{
                        backgroundColor: rowData?.liquidada ? "success_main" : "fail_main",
                        colorFont: rowData?.liquidada ? "success_bg" : "fail_bg",
                        fontSize: "12px",
                        fontWeight: 500,
                        rounded: "14px",
                        styles: {
                            borderColor: rowData?.liquidada ? "success_variant" : "fail_variant",
                            textTransform: "uppercase",
                            w: "fit-content",
                            textAlign: "center",
                        }
                    }}
                />
            )
        },
        {
            header: 'Empresa Factura',
            field: 'empresaFactura',
            key: 'empresaFactura',
            body: (rowData: any) => (
                rowData?.empresaFactura?.nombre ?
                    <ColumnBadge
                        text={rowData?.empresaFactura?.nombre}
                        ui={{
                            backgroundColor: rowData?.empresaFactura?.nombre === "Imagina" ? "#081338" : "main",
                            colorFont: "purewhite",
                            fontSize: "12px",
                            fontWeight: 500,
                            rounded: "14px",
                            styles: {
                                textTransform: "uppercase",
                            }
                        }}
                    />
                    :
                    <ColumnSimpleText
                        text={"---"}
                    />
            )
        },
    ];

    return columns
}