import { Badge, Box, Flex, Icon, Image, Text, useToast } from "@chakra-ui/react";
import { UserInt } from "../../../../interfaces/UserInt";
import { InformationInput } from "@imaginagroup/bit-components.ui.elements.information-input"
import { InformationBox } from "../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { BiPencil } from "react-icons/bi";
import { useState } from "react";
import { IconBox } from "../../../../shared/components/Icons/IconBox";
import { InformationAsyncSelect } from "@imaginagroup/bit-components.ui.elements.information-async-select";
import { HorariosItem } from "../HorariosItem";
import { DiaTypeEnum } from "../../../../shared/utils/Types/HorarioLaboral";
import PerfilAlumnoSkeleton from "../utils/PerfilAlumnoSkeleton";
import { useModuloFundae } from "../../../../shared/hooks/ModuloFundae";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { informationSelectStyles } from "../../../../ui/informationSelectStyles";
import { UserRolEnum } from "../../../../shared/utils/Types/UserRolEnum";
import { useAuthContex } from "../../../../shared/context/auth.context";

interface Props {
    alumno: UserInt | undefined;
    updateValue: (action: any) => void;
    loading: boolean;
    loadEmpresa: (action: string) => void;
}

export const PerfilAlumno = ({ alumno, updateValue, loading, loadEmpresa }: Props) => {
    const toast = useToast();
    const { user } = useAuthContex();
    const moduloFUNDAE = useModuloFundae();
    const [editNombre, setEditNombre] = useState<boolean>(false)
    const [editApellidos, setEditApellidos] = useState<boolean>(false)
    const [editTelefono, setEditTelefono] = useState<boolean>(false)
    const [editDni, setEditDni] = useState<boolean>(false)
    const [editEmail, setEditEmail] = useState<boolean>(false)

    return (
        loading ?
            <PerfilAlumnoSkeleton moduloFUNDAE={moduloFUNDAE} />

            :

            <Flex gap="20px">
                <Flex direction="column" flex="1">
                    <InformationBox
                        title="Datos del alumno"
                        component={
                            <Flex
                                alignItems="center"
                                gap="10px"
                            >
                                <Text
                                    color="font"
                                    fontSize="14px"
                                    fontWeight="600"
                                >
                                    Estado:
                                </Text>

                                <Badge
                                    color={alumno?.activo ? "success_main" : "font"}
                                    bg={alumno?.activo ? "success_bg" : "light_grey"}
                                    borderColor={alumno?.activo ? "success_variant" : "light_grey"}
                                    borderWidth="1px"
                                    fontSize="10px"
                                    fontWeight="500"
                                    letterSpacing="0.2px"
                                    textTransform="uppercase"
                                    borderRadius="14px"
                                    p="5px 8px"
                                >
                                    {
                                        alumno?.activo ? "Activo" : "Inactivo"
                                    }
                                </Badge>
                            </Flex>
                        }
                    >
                        <Box>
                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditNombre(true)}
                                onMouseLeave={() => setEditNombre(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="nombre"
                                    label="Nombre"
                                    defaultValue={alumno?.nombre ? alumno?.nombre : ""}
                                    updateValue={updateValue}
                                    isDisabled={UserRolEnum.ADMIN === user?.role?.nombre ? false : true}
                                />

                                <Flex boxSize="20px">
                                    {editNombre && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditApellidos(true)}
                                onMouseLeave={() => setEditApellidos(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="apellidos"
                                    label="Apellidos"
                                    defaultValue={alumno?.apellidos ? alumno?.apellidos : ""}
                                    updateValue={updateValue}
                                    isDisabled={UserRolEnum.ADMIN === user?.role?.nombre ? false : true}
                                />

                                <Flex boxSize="20px">
                                    {editApellidos && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditDni(true)}
                                onMouseLeave={() => setEditDni(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="dni"
                                    label="documento identidad"
                                    validatorDNI={true}
                                    isInvalidMessage="El DNI ingresado no es correcto"
                                    defaultValue={alumno?.dni ? alumno?.dni : ""}
                                    updateValue={updateValue}
                                    isDisabled={UserRolEnum.ADMIN === user?.role?.nombre ? false : true}
                                />

                                <Flex boxSize="20px">
                                    {editDni && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                p="10px 20px"
                                direction="column"
                                borderBottom="1px solid"
                                borderColor="light_grey"
                            >
                                <Box
                                    pr="30px"
                                >
                                    {UserRolEnum.ADMIN === user?.role?.nombre &&
                                        <InformationAsyncSelect
                                            name="empresaId"
                                            label="Empresa"
                                            placeholder="Buscar Empresa"
                                            updateValue={updateValue}
                                            loadOptions={loadEmpresa}
                                            defaultOptions={true}
                                            ui={{
                                                informationSelectStyles: informationSelectStyles
                                            }}
                                        />
                                    }

                                    {alumno?.empresaId &&
                                        <Flex gap="50px" mt="10px">
                                            <Box flex="1" />

                                            <Flex
                                                borderRadius="9px"
                                                p="10px 15px"
                                                bg="purewhite"
                                                gap="10px"
                                                alignItems="center"
                                                flex="1"
                                                boxShadow="0px 4px 15px 0px rgba(199, 205, 255, 0.60)"
                                            >
                                                {alumno?.empresa?.icono?.url ?
                                                    <Image
                                                        src={alumno?.empresa?.icono?.url}
                                                        padding="0"
                                                        boxSize="30px"
                                                    />
                                                    :
                                                    <IconBox
                                                        icon={undefined}
                                                        size="30px"
                                                        border="none"
                                                        padding="0"
                                                        bg="inherit"
                                                    />
                                                }

                                                <Box>
                                                    <Text
                                                        color="font"
                                                        fontSize="14px"
                                                        fontWeight="500"
                                                    >
                                                        {alumno?.empresa?.nombre}
                                                    </Text>
                                                </Box>
                                            </Flex>
                                        </Flex>
                                    }
                                </Box>
                            </Flex>

                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditTelefono(true)}
                                onMouseLeave={() => setEditTelefono(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="telefono"
                                    label="teléfono"
                                    defaultValue={alumno?.telefono ? alumno?.telefono : ""}
                                    updateValue={updateValue}
                                    isDisabled={UserRolEnum.ADMIN === user?.role?.nombre ? false : true}
                                />

                                <Flex boxSize="20px">
                                    {editTelefono && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>
                        </Box>
                    </InformationBox>

                    <InformationBox title="Credenciales de acceso">
                        <Box>
                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditEmail(true)}
                                onMouseLeave={() => setEditEmail(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="email"
                                    label="Email"
                                    type="email"
                                    defaultValue={alumno?.email ? alumno?.email : ""}
                                    updateValue={updateValue}
                                    isDisabled={UserRolEnum.ADMIN === user?.role?.nombre ? false : true}
                                />

                                <Flex boxSize="20px">
                                    {editEmail && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="username"
                                    label="Username"
                                    defaultValue={alumno?.username ? alumno?.username : ""}
                                    updateValue={updateValue}
                                    isDisabled={true}
                                />

                                <Flex boxSize="20px" />
                            </Flex>
                        </Box>
                    </InformationBox>
                </Flex>

                <Flex direction="column" flex="1" gap="20px">
                    {moduloFUNDAE &&
                        <InformationBox title="Jornada laboral">
                            <Box>
                                <HorariosItem
                                    label="Lunes"
                                    horarios={alumno?.horarioLaboral
                                        ? alumno?.horarioLaboral[DiaTypeEnum.LUNES]
                                        : null
                                    }
                                />

                                <HorariosItem
                                    label="Martes"
                                    horarios={alumno?.horarioLaboral
                                        ? alumno?.horarioLaboral[DiaTypeEnum.MARTES]
                                        : null
                                    }
                                />

                                <HorariosItem
                                    label="Miércoles"
                                    horarios={alumno?.horarioLaboral
                                        ? alumno?.horarioLaboral[DiaTypeEnum.MIERCOLES]
                                        : null
                                    }
                                />

                                <HorariosItem
                                    label="Jueves"
                                    horarios={alumno?.horarioLaboral
                                        ? alumno?.horarioLaboral[DiaTypeEnum.JUEVES]
                                        : null
                                    }
                                />

                                <HorariosItem
                                    label="Viernes"
                                    horarios={alumno?.horarioLaboral
                                        ? alumno?.horarioLaboral[DiaTypeEnum.VIERNES]
                                        : null
                                    }
                                />

                                <HorariosItem
                                    label="Sábado"
                                    horarios={alumno?.horarioLaboral
                                        ? alumno?.horarioLaboral[DiaTypeEnum.SABADO]
                                        : null
                                    }
                                />

                                <HorariosItem
                                    label="Domingo"
                                    horarios={alumno?.horarioLaboral
                                        ? alumno?.horarioLaboral[DiaTypeEnum.DOMINGO]
                                        : null
                                    }
                                    borderBottom="none"
                                />
                            </Box>
                        </InformationBox>
                    }
                </Flex>
            </Flex >
    );
}