import { Flex, Text, useDisclosure } from "@chakra-ui/react";
import { GroupTable } from "../../../../../shared/components/GroupTable/GroupTable";
import { useClient } from "../../../../../shared/context/client.context";
import { useRef } from "react";
import { ModalPrview } from "../../../../../shared/components/Modals/ModalPreview";
import { useData } from "../../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { MensajesColumns } from "./Columns/MensajesColumns";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";

export const MensajesTable = () => {
    const client = useClient();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.MENSAJERIA })
    const { data: mensajes, loading } = useData({
        client: client,
        endpoint: EndpointTypes.MENSAJERIA,
        specificQuery: {
            estado: "no_leido",
            limit: 50,
            sortBy: "createdAt",
            order: "desc"
        }
    })
    const MensajeRef = useRef<any>(null)

    const headerTemplate = (data: any) => {
        return (
            <Flex w="100%">
                <Text>
                    {data?.conversacion?.asunto}
                </Text>
            </Flex>
        );
    };

    const handleRowClick = ({ data }: any) => {
        MensajeRef.current = data;
        onOpen()
    }

    return (
        <Flex>
            <GroupTable
                query={query}
                setQuery={setQuery}
                data={mensajes?.data || []}
                columns={MensajesColumns()}
                groupRowsBy="conversacionId"
                rowGroupHeaderTemplate={headerTemplate}
                loading={loading}
                handleRowClick={handleRowClick}
            />

            <ModalPrview
                isOpen={isOpen}
                onClose={onClose}
                data={MensajeRef.current}
            />
        </Flex>
    )
}