import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    Flex,
    Box,
    useToast,
    ModalCloseButton,
} from '@chakra-ui/react'
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from 'yup';
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { FormSelect } from "@imaginagroup/bit-components.ui.form-select";
import { useState } from 'react';
import { StatusEnumTypes } from '../../../../shared/utils/Types/StatusEnumTypes';
import { toastNotify } from '../../../../shared/utils/functions/toastNotify';
import { handleErrors } from '../../../../shared/utils/functions/handleErrors';
import { useClient } from '../../../../shared/context/client.context';
import { FormTextarea } from '@imaginagroup/bit-components.ui.form-textarea';
import { addAnuncio, uploadAnuncioImage } from '../../../../shared/middlewares/anuncios.middleware';
import { FormAsyncSelect } from "@imaginagroup/bit-components.ui.form-async-select";
// Import FilePond
import 'filepond/dist/filepond.min.css'
import { FilePond } from 'react-filepond';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import { EndpointTypes, RelationshipTypes } from '../../../../shared/utils/Types/EndpointTypes';
import { loadData } from '../../../../shared/utils/functions/loadData';
import { QueryTypes } from '../../../../shared/utils/Types/QueryTypes';
import { FormDateInput } from "@imaginagroup/bit-components.ui.form-date-input";
import { formSelectStyles } from '../../../../ui/formSelectStyles';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    setRefresh: () => void;
    cursoId: string | undefined;
}

export const NewAnuncioModal = ({ isOpen, onClose, setRefresh, cursoId }: Props) => {
    const client = useClient();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [files, setFiles] = useState<any>([]);
    const toast = useToast();

    const initialValues: any = {
        titulo: null,
        contenido: null,
        publicado: null,
        general: null,
        grupoId: null,
        fechaExpiracion: null,
    };

    const validationSchema = Yup.object().shape({
        titulo: Yup.string()
            .required('El titulo del anuncio es obligatorio.')
            .typeError('El titulo del anuncio es obligatorio.'),
        contenido: Yup.string()
            .required('El contenido del anuncio es obligatorio.')
            .typeError('El contenido del anuncio es obligatorio.'),
        publicado: Yup.boolean()
            .notRequired(),
        general: Yup.boolean()
            .notRequired(),
        grupoId: Yup.string().when('general', {
            is: true,
            then: (validationScheme) => validationScheme
                .notRequired()
                .nullable(),
            otherwise: (validationScheme) => validationScheme
                .required('Debe seleccionar un grupo si no es un anuncio general.')
                .typeError('Debe seleccionar un grupo si no es un anuncio general.'),
        }),
        fechaExpiracion: Yup.date()
            .notRequired()
            .nullable(),
    });

    const handleSubmit = (values: any) => {
        if (!cursoId || files?.length === 0) return;
        // setIsLoading(true)

        const newAnuncio = {
            cursoId: cursoId,
            titulo: values?.titulo,
            contenido: values?.contenido,
            publicado: values?.publicado,
            general: values?.general,
            grupoId: values?.grupoId,
            fechaExpiracion: values?.fechaExpiracion,
        }

        addAnuncio({
            anuncio: newAnuncio,
            client: client
        })
            .then((response: any) => {
                const id = response?.data?.data?.id;

                uploadAnuncioImage({
                    id: id,
                    file: files[0]?.file,
                    client: client
                })
                    .catch((error: any) => {
                        const errors = handleErrors(
                            error?.response?.data?.errors,
                            error?.response?.status
                        )

                        errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
                    })
                    .finally(() => {
                        setRefresh();
                        handleClose();

                        toastNotify(toast, StatusEnumTypes.SUCCESS, `Anuncio creado correctamente`)
                    })
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setIsLoading(false))
    }

    const loadGruposByCursoId = async (value: string) => await loadData({
        value: value,
        endpoint: EndpointTypes.CURSOS,
        relationship: RelationshipTypes.GRUPOS,
        id: cursoId,
        client: client,
        query: QueryTypes.NOMBRE
    })

    const handleClose = () => {
        onClose()
        setFiles([])
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => null}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size="3xl"
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                maxH="95vh"
                rounded="20px"
            >
                <Box
                    color="black"
                >
                    <ModalHeader
                        textAlign="start"
                        fontSize="24px"
                        fontWeight="600"
                        px="30px"
                        pt="30px"
                        pb="0px"
                    >
                        Nuevo anuncio
                    </ModalHeader>

                    <ModalCloseButton onClick={() => handleClose()} />
                </Box>

                <Box
                    overflowY="auto"
                    __css={{
                        '&::-webkit-scrollbar': {
                            w: '7px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: '20px',
                            bg: `light_grey`,
                        },
                    }}
                >
                    <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                        {(formik) => {
                            const { handleSubmit, values } = formik;

                            return (
                                <FormikForm
                                    onSubmit={handleSubmit}
                                >
                                    <ModalBody
                                        p="30px"
                                    >
                                        <Flex
                                            direction="column"
                                            boxSize="100%"
                                            gap="20px"
                                        >
                                            <Flex
                                                gap="15px"
                                            >
                                                <FormInput
                                                    name="titulo"
                                                    label="Titulo"
                                                    placeholder='Titulo del anuncio'
                                                    isRequired={!values?.titulo ? true : false}
                                                />

                                                <FormSelect
                                                    isRequired={values?.publicado === null || values?.publicado === undefined}
                                                    name="publicado"
                                                    label="Estado"
                                                    placeholder="Selecciona una opción"
                                                    options={[
                                                        { label: 'Publicado', value: true },
                                                        { label: 'Oculto', value: false },
                                                    ]}
                                                    ui={{
                                                        formSelectStyles: formSelectStyles,
                                                    }}
                                                />
                                            </Flex>

                                            <Flex gap="15px">
                                                <FormSelect
                                                    isRequired={values?.publicado === null || values?.publicado === undefined}
                                                    name="general"
                                                    label="Tipo de anuncio"
                                                    placeholder="Selecciona una opción"
                                                    options={[
                                                        { label: 'General', value: true },
                                                        { label: 'Dirigido', value: false },
                                                    ]}
                                                    ui={{
                                                        formSelectStyles: formSelectStyles,
                                                    }}
                                                />

                                                <FormDateInput
                                                    locale="es"
                                                    name="fechaExpiracion"
                                                    label="Fecha Expiración"
                                                    onChange={formik.setFieldValue}
                                                    placeholder="Día/Mes/Año"
                                                />
                                            </Flex>

                                            {values?.general === false &&
                                                <FormAsyncSelect
                                                    name="grupoId"
                                                    label="Grupo"
                                                    defaultOptions={true}
                                                    loadOptions={loadGruposByCursoId}
                                                    placeholder="Grupo"
                                                    isRequired={!values.grupoId ? true : false}
                                                    ui={{
                                                        formSelectStyles: formSelectStyles
                                                    }}
                                                />
                                            }

                                            <FormTextarea
                                                name="contenido"
                                                label="Contenido"
                                                ui={{
                                                    minHeigh: '200px'
                                                }}
                                            />

                                            <FilePond
                                                files={files}
                                                allowReorder={true}
                                                onupdatefiles={setFiles}
                                                labelIdle='Deja caer tu imagen o <span class="filepond--label-action">impórtalo</span>'
                                                credits={false}
                                            />
                                        </Flex>
                                    </ModalBody>

                                    <ModalFooter
                                        justifyContent="center"
                                        py="30px"
                                    >
                                        <Button
                                            isLoading={isLoading}
                                            type="submit"
                                            bg="secondary"
                                            p="10px 25px"
                                            h="fit-content"
                                            rounded="5"
                                            color="purewhite"
                                            fontSize="18px"
                                            fontWeight="500"
                                            _hover={{ color: "purewhite", backgroundColor: "variant" }}
                                        >
                                            Crear
                                        </Button>
                                    </ModalFooter>
                                </FormikForm>
                            );
                        }}
                    </Formik>
                </Box>
            </ModalContent>
        </Modal>
    )
}