import _axios, { ClientInt } from "../services/http.service";
import tenantInstance from '../services/tenantInstance.service';

export const addLiquidacion = async ({
    data,
    client
} : {
    data: {
        userId: string;
        asignaciones: string[];
    },
    client: ClientInt
}) => {
    if(!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/liquidaciones`,
        data,
        {}
    )
};

export const updateLiquidacion = async ({ 
    id, 
    data,
    client 
}: { 
    id: string, 
    data: any,
    client: ClientInt
}) => {
    if(!client) return;
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/liquidaciones/${id}`,
        data,
        {}
    )
};

export const updateAsignacion = async ({ 
    id, 
    data,
    client,
    ignoreRequest = false
}: { 
    id: string, 
    data: any,
    client: ClientInt
    ignoreRequest?: boolean
}) => {
    if(!client || ignoreRequest) return;
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/asignaciones/${id}`,
        data,
        {}
    )
};