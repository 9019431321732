import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text"
import { ColumnProps } from "../../../../../shared/components/Table/Table"
import { Box, Flex, Image, Text, Link } from "@chakra-ui/react"
import { CursosInt } from "../../../../../interfaces/CursosInt"
import { defaultIcon } from "../../../../Onboarding/Onboarding"
import { Link as RouterLink } from "react-router-dom"

export const RutasColumns = () => {
    const columns: ColumnProps[] = [
        {
            header: 'nombre',
            field: 'nombre',
            key: 'nombre',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.nombre}
                    link={{
                        to: `/rutas/${rowData?.id}`
                    }}
                />
            )
        },
        {
            header: 'Cursos',
            field: 'cursos',
            key: 'cursos',
            body: (rowData: any) => (
                <Link
                    to={`/rutas/${rowData?.id}`}
                    _hover={{
                        textDecoration: "none",
                    }}
                    textDecoration="none"
                    as={RouterLink}
                    display="flex"
                    alignItems="center"
                    rounded="5px"
                    py="16px"
                    px="10px"
                    color="font"
                    overflowX="auto"
                    maxW="550px"
                    css={{
                        '&::-webkit-scrollbar': {
                            height: '8px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: "light_grey",
                        },
                    }}
                >
                    {rowData?.cursos?.map((curso: CursosInt, index: number) => (
                        <Flex
                            alignItems="center"
                        >
                            <Flex
                                key={index}
                                align="center"
                                gap="5px"
                                bg="purewhite"
                                shadow={"0px 4px 15px 0px rgba(199, 205, 255, 0.60)"}
                                py="5px"
                                px="10px"
                                rounded="6px"
                            >
                                <Image
                                    src={curso?.icono?.url || `data:image/svg+xml;utf8,${defaultIcon}`}
                                    padding="0"
                                    boxSize="30px"
                                />

                                <Text
                                    color="font"
                                    fontSize="13px"
                                    fontWeight="500"
                                >
                                    {curso?.nombre}
                                </Text>
                            </Flex>

                            {index < rowData?.cursos?.length - 1 &&
                                <Box
                                    w="25px"
                                    h="3px"
                                    bg="secondary"
                                />
                            }
                        </Flex>
                    ))}
                </Link>
            )
        },
        {
            header: 'Grupos',
            field: 'grupos',
            key: 'grupos',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={(rowData?.meta?.rutaGrupos_count || 0) + " grupos"}
                    link={{
                        to: `/rutas/${rowData?.id}`
                    }}
                />
            )
        },
    ]

    return columns
}