import { Flex, Text, useDisclosure } from "@chakra-ui/react"
import { Topbar } from "../../../shared/components/Topbar/Topbar"
import { CustomTabs } from "@imaginagroup/bit-components.ui.custom-tabs"
import { useState } from "react";
import { ConfigInformation } from "./Config/Information";
import { MdOutlineVideoSettings } from "react-icons/md";
import { TabsComponentInt } from "../../../interfaces/ComponentsInt";
import { BiBuilding, BiPlusCircle } from "react-icons/bi";
import { EmpresasOrganizadoras } from "./Empresas/EmpresasOrganizadoras";
import { useAuthContex } from "../../../shared/context/auth.context";
import { OnboardingStep } from "../../../shared/components/Cards/OnboardingStep";
import { useUserPolicy } from "../../../shared/hooks/PermissionPolicy";
import { customTabsStyles } from "../../../ui/bitStyles";

export const TabsConfig = () => {
    const { user } = useAuthContex()
    const { total } = useUserPolicy()
    const { isOpen: isOpenNew, onClose: onCloseNew, onOpen: onOpenNew } = useDisclosure()
    const [showStep, setShowStep] = useState<boolean>(true)
    const [tabIndex, setTabIndex] = useState<number>(0)

    const handleTabsChange = (index: number) => {
        if (index === 1 && !showStep) setShowStep(true)
        setTabIndex(index)
    }

    const tabs: TabsComponentInt[] = [
        {
            isAllowed: true,
            label: "Mis empresas",
            icon: BiBuilding,
            content:
                <EmpresasOrganizadoras
                    onCloseNew={onCloseNew}
                    isOpenNew={isOpenNew}
                />
        },
        {
            isAllowed: true,
            label: "Configuración general",
            icon: MdOutlineVideoSettings,
            content:
                <ConfigInformation />
        },
    ]

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Configuración
                </Text>
            }
        >
            <Flex
                w="100%"
                p="20px 40px"
                minH={"calc(100vh - 70px)"}
                pos={"relative"}
            >
                <CustomTabs
                    handleTabsChange={handleTabsChange}
                    tabIndex={tabIndex}
                    tabs={tabs}
                    ui={customTabsStyles}
                    buttons={tabIndex === 0 ?
                        [
                            {
                                isAllowed: true,
                                ui: {
                                    background: "secondary",
                                    colorFont: "purewhite",
                                },
                                button: {
                                    label: "Agregar empresa",
                                    onClick: onOpenNew,
                                    leftIcon: {
                                        as: BiPlusCircle,
                                        boxSize: "20px"
                                    },
                                    isDisabled: false,
                                },
                                tooltip: {
                                    label: "Crea una nueva empresa interna para gestionar tus grupos.",
                                    isDisabled: false,
                                }
                            }
                        ] : undefined
                    }
                />

                {(showStep && (!user?.config?.empresasOrganizadoras && total)) &&
                    <OnboardingStep
                        position={{
                            top: "60px",
                            left: "450px"
                        }}
                        title="Completa tu empresa"
                        description="Indica la informacion necesaria de tu empresa para completar la configuracion del campus."
                        placement="top/left"
                        onClick={async () => {
                            tabIndex !== 0 && setTabIndex(0)
                            setShowStep(false)
                        }}
                    />
                }
            </Flex>
        </Topbar>
    )
}