import { Navigate, Route, Routes } from "react-router-dom";
import { RequireAuth } from "../RequireAuth/RequireAuth";
import { Login } from "../../../pages/Login/Login";
import { useAuthContex } from "../../context/auth.context";
import { UserRolEnum } from "../../utils/Types/UserRolEnum";
import { isInspector, isRoleAllowed } from "../../utils/functions/validateRol";
import { Cursos } from "../../../pages/Contenido/Cursos";
import { Grupos } from "../../../pages/Grupos/Grupos";
import { Empresas } from "../../../pages/Empresas/Empresas";
import { Usuarios } from "../../../pages/Usuarios/Usuarios";
import { Seguimiento } from "../../../pages/Seguimiento/Seguimiento";
import { Informes } from "../../../pages/Informes/Informes";
import { Matriculas } from "../../../pages/Matriculas/Matriculas";
import { Dashboard } from "../../../pages/Dashboard/Dashboard";
import { Facturacion } from "../../../pages/Facturacion/Facturacion";
import { Correcciones } from "../../../pages/Correcciones/Correcciones";
import { GruposInspector } from "../../../pages/Inspector/views/Grupos/GruposInspector";
import { AlumnosInspector } from "../../../pages/Inspector/views/Alumnos/Table/AlumnosInspector";
import { Datos } from "../../../pages/Datos/Datos";
import { Tenant } from "../../../pages/Tenant/Tenant";
import { Recovery } from "../../../pages/Recovery/Recovery";
import { Emails } from "../../../pages/Emails/Emails";
import { Plantillas } from "../../../pages/Plantillas/Pantillas";
import { Onboarding } from "../../../pages/Onboarding/Onboarding";
import { CampusConfig } from "../../../pages/Configuracion/CampusConfig";
import { Examenes } from "../../../pages/Examenes/Examenes";
import { useUserPolicy } from "../../hooks/PermissionPolicy";
import { useTenantInfo } from "../../hooks/useTenantInfo";
import { Rutas } from "../../../pages/Rutas/Rutas";

export const RouterController = () => {
    const { contenido, gestion, total } = useUserPolicy();
    const { user } = useAuthContex()
    const { handleGetInfo } = useTenantInfo();
    const produccion = handleGetInfo({ key: "produccion" });

    const redirectTo = (): string => {
        if (!produccion) return "/onboarding"
        else if (produccion && (!user?.config?.cursos && total)) return "/cursos"
        else if (produccion && (!user?.config?.cursos && !total)) return "/dashboard"
        else
            if (!isInspector(user))
                return "/dashboard"
            else
                return "/grupos-inspector"
    }

    return (
        <Routes>
            <Route
                path='recovery/:hash'
                element={
                    !user?.auth
                        ? <Recovery />
                        : <Navigate to={redirectTo()} />
                }
            />

            <Route
                path='login/*'
                element={
                    !user?.auth
                        ? <Login />
                        : <Navigate to={redirectTo()} />
                }
            />

            <Route
                path='*'
                element={
                    !user?.auth
                        ? <Navigate to={"/login"} />
                        : <Navigate to={redirectTo()} />
                }
            />

            <Route
                path='onboarding/*'
                element={
                    !user?.auth
                        ? <Navigate to={"/login"} />
                        : produccion ? <Navigate to={"/dashboard"} />
                            : <Onboarding />
                }
            />

            <Route element={
                <RequireAuth
                    isRoleAllowed={isRoleAllowed([
                        UserRolEnum.ADMIN,
                    ], user?.role?.nombre) && !contenido && !isInspector(user)}
                />
            }
            >
                <Route path='dashboard/*' element={<Dashboard />} />
            </Route>

            <Route element={
                <RequireAuth
                    isRoleAllowed={isRoleAllowed([
                        UserRolEnum.ADMIN,
                    ], user?.role?.nombre) && !isInspector(user)}
                />
            }
            >
                <Route path='cursos/*' element={<Cursos />} />
            </Route>

            <Route element={
                <RequireAuth
                    isRoleAllowed={isRoleAllowed([
                        UserRolEnum.ADMIN,
                        UserRolEnum.SUPERVISOR
                    ], user?.role?.nombre) && !isInspector(user)}
                />
            }
            >
                <Route path='usuarios/*' element={<Usuarios />} />
            </Route>

            <Route element={
                <RequireAuth
                    isRoleAllowed={isRoleAllowed([
                        UserRolEnum.ADMIN,
                    ], user?.role?.nombre) && !contenido && !isInspector(user)}
                />
            }
            >
                <Route path='empresas/*' element={<Empresas />} />
            </Route>

            <Route element={
                <RequireAuth
                    isRoleAllowed={isRoleAllowed([
                        UserRolEnum.ADMIN,
                        UserRolEnum.SUPERVISOR
                    ], user?.role?.nombre) && !isInspector(user)}
                />
            }
            >
                <Route path='grupos/*' element={<Grupos />} />
            </Route>

            <Route element={
                <RequireAuth
                    isRoleAllowed={isRoleAllowed([
                        UserRolEnum.ADMIN
                    ], user?.role?.nombre) && !gestion && !isInspector(user)}
                />
            }
            >
                <Route path='seguimiento/*' element={<Seguimiento />} />
            </Route>

            <Route element={
                <RequireAuth
                    isRoleAllowed={isRoleAllowed([
                        UserRolEnum.ADMIN,
                    ], user?.role?.nombre) && !isInspector(user)}
                />
            }
            >
                <Route path='correcciones/*' element={<Correcciones />} />
            </Route>

            <Route element={
                <RequireAuth
                    isRoleAllowed={isRoleAllowed([
                        UserRolEnum.ADMIN,
                    ], user?.role?.nombre) && !isInspector(user)}
                />
            }
            >
                <Route path='rutas/*' element={<Rutas />} />
            </Route>

            <Route element={
                <RequireAuth
                    isRoleAllowed={isRoleAllowed([
                        UserRolEnum.ADMIN,
                        UserRolEnum.SUPERVISOR
                    ], user?.role?.nombre) && !contenido && !isInspector(user)}
                />
            }
            >
                <Route path='informes/*' element={<Informes />} />
            </Route>

            <Route element={
                <RequireAuth
                    isRoleAllowed={isRoleAllowed([
                        UserRolEnum.ADMIN,
                        UserRolEnum.SUPERVISOR
                    ], user?.role?.nombre) && !isInspector(user)}
                />
            }
            >
                <Route path='matriculas/*' element={<Matriculas />} />
            </Route>

            <Route element={
                <RequireAuth
                    isRoleAllowed={isRoleAllowed([
                        UserRolEnum.ADMIN,
                    ], user?.role?.nombre) && !contenido && !isInspector(user)}
                />
            }
            >
                <Route path='facturacion/*' element={<Facturacion />} />
            </Route>

            <Route element={
                <RequireAuth
                    isRoleAllowed={isRoleAllowed([
                        UserRolEnum.ADMIN,
                    ], user?.role?.nombre) && !contenido && !isInspector(user)}
                />
            }
            >
                <Route path='datos/*' element={<Datos />} />
            </Route>

            <Route element={
                <RequireAuth
                    isRoleAllowed={isRoleAllowed([
                        UserRolEnum.ADMIN,
                    ], user?.role?.nombre) && !contenido && !isInspector(user)}
                />
            }
            >
                <Route path='emails/*' element={<Emails />} />
            </Route>

            <Route element={
                <RequireAuth
                    isRoleAllowed={isRoleAllowed([
                        UserRolEnum.ADMIN,
                    ], user?.role?.nombre) && (!contenido && !gestion) && !isInspector(user)}
                />
            }
            >
                <Route path='plantillas/*' element={<Plantillas />} />
            </Route>

            <Route element={
                <RequireAuth
                    isRoleAllowed={isRoleAllowed([
                        UserRolEnum.ADMIN,
                    ], user?.role?.nombre) && !gestion && !isInspector(user)}
                />
            }
            >
                <Route path='examenes/*' element={<Examenes />} />
            </Route>

            <Route element={
                <RequireAuth
                    isRoleAllowed={isRoleAllowed([
                        UserRolEnum.ADMIN,
                    ], user?.role?.nombre) && (!contenido && !gestion) && !isInspector(user)}
                />
            }
            >
                <Route path='configuracion/*' element={<CampusConfig />} />
            </Route>

            <Route element={
                <RequireAuth
                    isRoleAllowed={isRoleAllowed([
                        UserRolEnum.ADMIN,
                    ], user?.role?.nombre) || isInspector(user)}
                />
            }
            >
                <Route path='grupos-inspector/*' element={<GruposInspector />} />
            </Route>

            <Route element={
                <RequireAuth
                    isRoleAllowed={isRoleAllowed([
                        UserRolEnum.ADMIN,
                    ], user?.role?.nombre) || isInspector(user)}
                />
            }
            >
                <Route path='alumnos-inspector/*' element={<AlumnosInspector />} />
            </Route>

            <Route element={
                <RequireAuth
                    isRoleAllowed={user?.role?.nombre === UserRolEnum.SUPERADMIN}
                />
            }
            >
                <Route path='tenant/*' element={<Tenant />} />
            </Route>

            {/* <Route element={
                <RequireAuth
                    isRoleAllowed={isRoleAllowed([
                        UserRolEnum.ADMIN,
                    ], user?.role?.nombre) && !isInspector(user)}
                />
            }
            >
                <Route path='feedback/*' element={<Feedback />} />
            </Route>

            <Route element={
                <RequireAuth
                    isRoleAllowed={isRoleAllowed([
                        UserRolEnum.ADMIN,
                    ], user?.role?.nombre) && !isInspector(user)}
                />
            }
            >
                <Route path='audits/*' element={<Audits />} />
            </Route> */}
        </Routes>
    );
};
