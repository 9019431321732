import { Box, Button, Flex, useToast } from "@chakra-ui/react";
import { CursosInt } from "../../../../../interfaces/CursosInt";
import { InformationBox } from "../../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { useEffect, useState } from "react";
import { updateCurso } from "../../../../../shared/middlewares/cursos.middleware";
import { useClient } from "../../../../../shared/context/client.context";
import { toastNotify } from "../../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../../shared/utils/Types/StatusEnumTypes";
import ReactQuill from "react-quill";
import { SkeletonGuiaDidactica } from "../../../components/utils/CursosGuiaDidacticaSkeleton";

interface Props {
    curso: CursosInt | undefined;
    loadingCurso: boolean
    Refresh: () => void;
}

export const TabGuiaDidactica = ({ curso, Refresh, loadingCurso }: Props) => {
    const client = useClient();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {}, [curso])

    const getInfoGuia = (type: "datos" | "dirigido" | "requisitos" | "objetivos") => {
        if(!curso?.infoGuia) return undefined;

        if(Reflect.has(curso?.infoGuia, type)) return curso?.infoGuia[type]
        else return undefined;
    }

    const initialValues = {
        datos: getInfoGuia("datos"),
        dirigido: getInfoGuia("dirigido"),
        requisitos: getInfoGuia("requisitos"),
        objetivos: getInfoGuia("objetivos"),
    };

    const validationSchema = Yup.object({
        datos: Yup.string()
            .required("Este campo es requerido")
            .typeError("Este campo es requerido"),
        dirigido: Yup.string()
            .required("Este campo es requerido")
            .typeError("Este campo es requerido"),
        requisitos: Yup.string()
            .required("Este campo es requerido")
            .typeError("Este campo es requerido"),
        objetivos: Yup.string()
            .required("Este campo es requerido")
            .typeError("Este campo es requerido"),
    });

    const handleSubmit = (values: any) => {
        if (!curso?.id) return;
        setIsLoading(true)

        const guiaDidactica = {
            datos: values?.datos,
            dirigido: values?.dirigido,
            requisitos: values?.requisitos,
            objetivos: values?.objetivos,
        }

        updateCurso({
            id: curso?.id,
            data: {
                infoGuia: guiaDidactica
            },
            client: client
        })
            .then(() => {
                Refresh();
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Guía didáctica actualizada correctamente")
            })
            .catch(() => { })
            .finally(() => setIsLoading(false))
    }

    return (

        loadingCurso ? 
            <SkeletonGuiaDidactica/>
        :
        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
            {(formik) => {
                const { handleSubmit, values } = formik;

                return (
                    <FormikForm
                        onSubmit={handleSubmit}
                    >
                        <Flex direction="column" gap="20px" className='information-texteditor'>

                            <Flex gap="20px">
                                <Flex
                                    flex="1"
                                    direction="column"
                                >
                                    <InformationBox title="Datos del curso">
                                        <Box
                                            borderTop="1px solid"
                                            borderColor="light_grey"
                                            p="20px"
                                        >
                                            <ReactQuill
                                                theme="snow"
                                                value={values.datos}
                                                onChange={(e: any) => {
                                                    formik.setValues({ ...values, datos: e })
                                                }}
                                                placeholder={""}
                                                style={{
                                                    borderRadius: "5px",
                                                    width: "100%",
                                                    color: "font",
                                                    background: "#FFF",
                                                    padding: "5px",
                                                    minHeight: "200px"
                                                }}
                                                modules={modules}
                                                formats={formats}
                                            />
                                        </Box>
                                    </InformationBox>

                                    <InformationBox title="Requisitos">
                                        <Box
                                            borderTop="1px solid"
                                            borderColor="light_grey"
                                            p="20px"
                                        >
                                            <ReactQuill
                                                theme="snow"
                                                value={values.requisitos}
                                                onChange={(e: any) => {
                                                    formik.setValues({ ...values, requisitos: e })
                                                }}
                                                placeholder={""}
                                                style={{
                                                    borderRadius: "5px",
                                                    width: "100%",
                                                    color: "font",
                                                    background: "#FFF",
                                                    padding: "5px",
                                                    minHeight: "200px"
                                                }}
                                                modules={modules}
                                                formats={formats}
                                            />
                                        </Box>
                                    </InformationBox>
                                </Flex>

                                <Flex
                                    flex="1"
                                    direction="column"
                                >
                                    <InformationBox title="¿A quién va dirigido?">
                                        <Box
                                            borderTop="1px solid"
                                            borderColor="light_grey"
                                            p="20px"
                                        >
                                            <ReactQuill
                                                theme="snow"
                                                value={values.dirigido}
                                                onChange={(e: any) => {
                                                    formik.setValues({ ...values, dirigido: e })
                                                }}
                                                placeholder={""}
                                                style={{
                                                    borderRadius: "5px",
                                                    width: "100%",
                                                    color: "font",
                                                    background: "#FFF",
                                                    padding: "5px",
                                                    minHeight: "200px"
                                                }}
                                                modules={modules}
                                                formats={formats}
                                            />
                                        </Box>
                                    </InformationBox>

                                    <InformationBox title="Objetivos">
                                        <Box
                                            borderTop="1px solid"
                                            borderColor="light_grey"
                                            p="20px"
                                        >
                                            <ReactQuill
                                                value={values.objetivos}
                                                theme="snow"
                                                onChange={(e: any) => {
                                                    formik.setValues({ ...values, objetivos: e })
                                                }}
                                                placeholder={""}
                                                style={{
                                                    borderRadius: "5px",
                                                    width: "100%",
                                                    color: "font",
                                                    background: "#FFF",
                                                    padding: "5px",
                                                    minHeight: "200px"
                                                }}
                                                modules={modules}
                                                formats={formats}
                                            />
                                        </Box>
                                    </InformationBox>
                                </Flex>
                            </Flex>

                            <Flex
                                w="100%"
                                justifyContent="center"
                            >
                                <Button
                                    isLoading={isLoading}
                                    type="submit"
                                    w="fit-content"
                                    h="fit-content"
                                    bg="black"
                                    color="#FFF"
                                    p="10px 20px"
                                    borderRadius="5px"
                                    fontSize="14px"
                                    fontWeight="500"
                                    _hover={{ bg: "light_grey", color: "black" }}
                                >
                                    Guardar
                                </Button>
                            </Flex>
                        </Flex>
                    </FormikForm>
                );
            }}
        </Formik>
    )
}


const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],

    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    [{ indent: "-1" }, { indent: "+1" }],

    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ align: [] }],

    ["code-block"],
    ["image"],
];

const modules = {
    toolbar: toolbarOptions,
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
};

const formats = [
    "header",
    "image",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "code-block",
    "list",
    "bullet",
    "indent",
    "link",
    "align",
];