import { Box, Button, Flex, Icon, Text, useToast } from "@chakra-ui/react"
import { useNavigate, useParams } from "react-router-dom"
import { MJMLtoHTML, updatePlantilla } from "../../../../shared/middlewares/plantillas.middleware"
import { useClient } from "../../../../shared/context/client.context"
import { useEffect, useState } from "react"
import { FaMobileAlt } from "react-icons/fa";
import { RiComputerLine } from "react-icons/ri";
import Editor from 'react-simple-code-editor';
import { highlight, languages } from "prismjs"
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-markup';
import 'prismjs/themes/prism.css';
import "../../../../ui/scss/scrollBar.scss"
import { useDataId } from "../../../../shared/hooks/useDataId"
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes"
import { toastNotify } from "../../../../shared/utils/functions/toastNotify"
import { handleErrors } from "../../../../shared/utils/functions/handleErrors"
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes"
import { BiPencil } from "react-icons/bi"
import { Topbar } from "../../../../shared/components/Topbar/Topbar"
import { plantillasData } from "../../../../shared/utils/data/plantillasData"
import { TipoPlantillasEnum } from "../../../../shared/utils/Types/PlantillasTypesEnum"

export const PlantillasInformation = () => {
    const client = useClient()
    const { id } = useParams()
    const toast = useToast()
    const { data: plantilla, loading, error, Refresh } = useDataId({
        id: id,
        endpoint: EndpointTypes.PLANTILLAS,
        client: client
    })
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [preview, setPreview] = useState<string>()
    const [code, setCode] = useState<string>()
    const [displayWidth, setDisplayWidth] = useState<2.5 | 5.4>(2.5);

    useEffect(() => {
        if (!loading && !error && plantilla)
            MJMLtoHTML({
                mjml: plantilla?.contenido,
                client: client
            })
                .then((response) => setPreview(response?.data?.html.replace("<!--[if mso]>", scrollBar)))
                .catch((error) => console.log(error))

        setCode(plantilla?.contenido)
    }, [plantilla])

    const totalHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const totalWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    const uploadValue = () => {
        setIsLoading(true)

        updatePlantilla({
            id: plantilla?.id,
            data: {
                contenido: code
            },
            client: client
        })
            .then(() => {
                toastNotify(toast, StatusEnumTypes.SUCCESS, "La plantilla se ha actualizado correctamente")

                Refresh()
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Plantillas
                </Text>
            }
            buttonBack
        >
            <Flex
                direction="column"
                gap="25px"
                p="30px"
            >
                {(plantillasData[plantilla?.nombre as TipoPlantillasEnum]?.nombre &&
                    plantillasData[plantilla?.nombre as TipoPlantillasEnum]?.descripcion) &&
                    <Flex
                        direction="column"
                        gap="25px"
                        bg="purewhite"
                        p="20px"
                        rounded="10px"
                        shadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)"
                    >
                        <Text
                            fontSize="17px"
                            fontWeight="500"
                            color="font"
                        >
                            {plantillasData[plantilla?.nombre as TipoPlantillasEnum].nombre}
                        </Text>

                        <Text
                            fontSize="16px"
                            fontWeight="400"
                            color="font"
                        >
                            {plantillasData[plantilla?.nombre as TipoPlantillasEnum].descripcion}
                        </Text>
                    </Flex>
                }

                <Flex
                    gap="20px"
                    alignItems="flex-start"
                >
                    <Flex
                        flex="1"
                        pos="relative"
                    >
                        <Flex
                            position="absolute"
                            top="0"
                            left="0"
                            right="0"
                            bottom="0"
                            zIndex="10"
                            cursor="not-allowed"
                        >
                            <Text
                                bg="main"
                                fontSize="20px"
                                fontWeight="400"
                                h="fit-content"
                                mt="50%"
                                ml="10%"
                                p="40px"
                                rounded="8px"
                                color="purewhite"
                            >
                                Actualiza tu plan para modificar la plantilla
                            </Text>
                        </Flex>

                        <Flex
                            opacity={0.5}
                            direction="column"
                            gap="10px"
                        >
                            <Button
                                onClick={uploadValue}
                                isDisabled={plantilla?.contenido === code}
                                isLoading={isLoading}
                                bg="black"
                                p="10px 15px"
                                w="fit-content"
                                h="fit-content"
                                ml="auto"
                                rounded="5"
                                color="purewhite"
                                fontSize="14px"
                                fontWeight="500"
                                _hover={{ bgColor: "light_grey", color: "black" }}
                                leftIcon={<Icon as={BiPencil} boxSize="20px" />}
                            >
                                Guardar
                            </Button>

                            <Editor
                                value={code || ""}
                                onValueChange={code => setCode(code)}
                                highlight={code => highlight(code, languages.markup, "markup")}
                                padding={10}
                                className="scrollBar"
                                style={{
                                    fontFamily: '"Fira code", "Fira Mono", monospace',
                                    fontSize: 12,
                                    background: "purewhite",
                                    width: `${totalWidth / 2.5}px`,
                                    // height: `${totalHeight - 170}px`,
                                    // overflowY: "scroll",
                                }}
                            />
                        </Flex>
                    </Flex>

                    <Flex
                        flex="1"
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Flex
                            justifyContent="flex-end"
                            gap="20px"
                            bg="black"
                            p="10px 20px"
                            width={`${totalWidth / 2.5}px`}
                        >
                            <Icon
                                as={FaMobileAlt}
                                color={displayWidth === 5.4 ? "secondary" : "purewhite"}
                                onClick={() => setDisplayWidth(5.4)}
                                boxSize="25px"
                                cursor="pointer"
                            />
                            <Icon
                                as={RiComputerLine}
                                color={displayWidth === 2.5 ? "secondary" : "purewhite"}
                                onClick={() => setDisplayWidth(2.5)}
                                boxSize="25px"
                                cursor="pointer"
                            />
                        </Flex>

                        <iframe
                            title="Email Preview"
                            srcDoc={preview}
                            width={`${totalWidth / displayWidth}px`}
                            height={`${totalHeight - 170}px`}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </Topbar>
    )
}

const scrollBar = `<style>
::-webkit-scrollbar {
    width: 0;
}

::-webkit-scrollbar-thumb {
    background-color: transparent;
}
</style>`