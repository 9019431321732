import { Flex, Icon, Text, useToast } from "@chakra-ui/react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { HiOutlineClipboard, HiOutlineDocumentDownload, HiOutlineDocumentText, HiOutlineEye, HiOutlineEyeOff, HiOutlineLink, HiOutlinePaperClip, HiOutlinePlay } from "react-icons/hi";
import { LeccionInt, ModulosInt } from "../../../../interfaces/CursosInt";
import { AiOutlineHolder } from "react-icons/ai";
import { TipoSelect } from "../../views/Information/TabItem/TabContenido";
import { Dispatch, SetStateAction, useState } from "react";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { updateLeccion } from "../../../../shared/middlewares/lecciones.middleware";
import { useClient } from "../../../../shared/context/client.context";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { LeccionTipoEnum } from "../../../../shared/utils/Types/LeccionTipoEnum";

interface Props {
    leccion: any;
    selected: { type: TipoSelect; data: ModulosInt | LeccionInt | null; } | undefined;
    setSelected: Dispatch<SetStateAction<{ type: TipoSelect; data: ModulosInt | LeccionInt | null; } | undefined>>;
    setRefreshData: Dispatch<SetStateAction<{ leccion: boolean; modulo: boolean; }>>;
}

export const DndLecciones = ({
    leccion,
    selected,
    setSelected,
    setRefreshData
}: Props) => {
    const client = useClient();
    const toast = useToast();
    const {
        setNodeRef,
        listeners,
        transform,
        transition,
    } = useSortable({
        id: leccion?.id
    });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }
    const [estado, setEstado] = useState<boolean>(leccion?.publicado)

    const changeEstadoLeccion = async (event: React.MouseEvent) => {
        event.stopPropagation()
        const initState = estado
        setEstado(!initState)

        updateLeccion({
            id: leccion?.id,
            data: {
                publicado: !initState
            },
            client: client
        })
            .then(() => setRefreshData((prev) => ({ ...prev, leccion: true })))
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
                setEstado(initState)
            })
    }

    return (
        <Flex
            ref={setNodeRef}
            style={style}
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            py="20px"
            px="10px"
            rounded="5px"
            border="1px solid"
            borderColor={selected?.data?.id === leccion?.id ? "secondary" : "#DFE2E6"}
            onClick={(event: React.MouseEvent) => {
                event.stopPropagation();

                setSelected({
                    type: TipoSelect.LECCION,
                    data: leccion
                })
            }}
        >
            <Flex
                alignItems="center"
                gap="8px"
            >
                <Icon as={AiOutlineHolder} boxSize="18px" color="pureblack" cursor="grab" {...listeners} />

                <Text
                    color="pureblack"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="100%"
                    letterSpacing="-0.16px"
                >
                    {leccion?.nombre}
                </Text>
            </Flex>

            <Flex
                alignItems="center"
                gap="8px"
            >
                <Icon
                    as={
                        leccion?.tipo === LeccionTipoEnum.FICHERO
                            ? HiOutlinePaperClip
                            : leccion?.tipo === LeccionTipoEnum.ENTREGABLE
                                ? HiOutlineClipboard
                                : leccion?.tipo === LeccionTipoEnum.URL
                                    ? HiOutlineLink
                                    : leccion?.tipo === LeccionTipoEnum.TEXTO
                                        ? HiOutlineDocumentText
                                        : leccion?.tipo === LeccionTipoEnum.ZOOM
                                            ? HiOutlinePlay
                                            : HiOutlineDocumentDownload
                    }
                    boxSize="15px"
                />

                <Icon
                    onClick={changeEstadoLeccion}
                    _active={{ transform: "scale(0.95)" }}
                    as={estado ? HiOutlineEye : HiOutlineEyeOff}
                    color={"pureblack"}
                />
            </Flex>
        </Flex>
    );
}