import { As, Button, Flex, Grid, Icon, ListItem, Text, UnorderedList, useDisclosure, useToast } from "@chakra-ui/react"
import { LeccionInt, ModulosInt } from "../../../../interfaces/CursosInt"
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { HiOutlineClipboard, HiOutlineDocumentDownload, HiOutlineDocumentText, HiOutlineLink, HiOutlinePaperClip, HiOutlinePlay } from "react-icons/hi";
import { InformationBox } from "../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from 'yup';
import { useClient } from "../../../../shared/context/client.context";
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { FormTextarea } from '@imaginagroup/bit-components.ui.form-textarea';
import { TipoSelect } from "../../views/Information/TabItem/TabContenido";
import { useDataId } from "../../../../shared/hooks/useDataId";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { ModalCloseAlert } from "../../../../shared/components/Modals/ModalCloseAlert";
import { addLeccion, loadContenido } from "../../../../shared/middlewares/lecciones.middleware";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { LeccionTipoEnum } from "../../../../shared/utils/Types/LeccionTipoEnum";
import { InformationComponent } from "../../../../shared/components/CustomElements/InformationComponent";
import { file } from "jszip";
import { BiInfoCircle } from "react-icons/bi";
import DropzoneComponent from "../../../../shared/components/Dropzone/Dropzone";
import { FormTextEditor } from "../../../../shared/components/FormElements/FormTextEditor";
import { FiEdit3 } from "react-icons/fi";
import { useTenantInfo } from "../../../../shared/hooks/useTenantInfo";

interface TipoContenido {
    tipo: LeccionTipoEnum,
    icon: As,
    titulo: string,
    descripcion: string
}

interface Props {
    data: Partial<LeccionInt> | undefined;
    setRefreshData: Dispatch<SetStateAction<{ leccion: boolean; modulo: boolean; }>>;
    setSelected: Dispatch<SetStateAction<{ type: TipoSelect; data: ModulosInt | LeccionInt | null; } | undefined>>;
}

export const AddLeccion = ({ data, setRefreshData, setSelected }: Props) => {
    const [step, setStep] = useState<number>(1);
    const [file, setFile] = useState<File | null>(null);
    const client = useClient();
    const { data: modulo } = useDataId({
        endpoint: EndpointTypes.MODULOS,
        client: client,
        id: data?.moduloId,
        ignoreRequest: !data?.moduloId
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [editName, setEditName] = useState<boolean>(false);
    const { handleGetInfo } = useTenantInfo();
    const colores = handleGetInfo({ key: "colores" });
    const toast = useToast();
    const { onOpen: onOpenAlert, onClose: onCloseAlert, isOpen: isOpenAlert } = useDisclosure();

    useEffect(() => {
        if (editName) {
            const inputElement: HTMLInputElement | null = document.querySelector('input[name="nombre"]');

            if (inputElement) {
                inputElement.focus();
            }
        }
    }, [editName])

    const initialValues: any = {
        moduloId: data?.moduloId,
        nombre: data?.nombre,
        publicado: data?.publicado,
        orden: data?.orden,
        tipo: null,
        contenido: data?.contenido,
        descripcion: data?.descripcion,
    };

    const validationSchema = Yup.object().shape({
        nombre: Yup.string()
            .required('¡El nombre de la leccion es obligatorio!')
            .typeError('¡El nombre de la leccion es obligatorio.'),
    });

    const handleSubmit = async (values: any) => {
        setIsLoading(true)

        const newLeccion = {
            moduloId: values?.moduloId,
            nombre: values?.nombre,
            publicado: values?.publicado,
            orden: values?.orden,
            tipo: values?.tipo,
            descripcion: values?.descripcion,
            contenido: values?.contenido,
        }

        addLeccion({
            leccion: newLeccion,
            client: client
        })
            .then((response) => {
                const leccion = response?.data?.data;

                if (file) {
                    loadContenido({
                        id: leccion?.id as string,
                        client: client,
                        file: file
                    })
                        .catch((error: any) => toastNotify(toast, StatusEnumTypes.WARNING, "Lección creada, pero no se pudo cargar el contenido, vuelva a intentarlo."))
                }

                setRefreshData({
                    leccion: true,
                    modulo: true
                });

                setSelected({
                    type: TipoSelect.LECCION,
                    data: leccion
                })
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setIsLoading(false))
    }

    const tipoContenido: TipoContenido[] = [
        {
            tipo: LeccionTipoEnum.FICHERO,
            icon: HiOutlinePaperClip,
            titulo: "Fichero",
            descripcion: "Sube documentos, imágenes o videos directamente, como archivos PDF, Word, Excel, PowerPoint, o cualquier tipo de fichero.",
        },
        {
            tipo: LeccionTipoEnum.EMBEBIDO,
            icon: HiOutlineDocumentDownload,
            titulo: "Contenido Embebido",
            descripcion: "Incorpora contenido desde plataformas externas, como videos de YouTube, presentaciones de Slideshare, o documentos de Google Drive, que se pueden visualizar directamente en la plataforma.",
        },
        {
            tipo: LeccionTipoEnum.TEXTO,
            icon: HiOutlineDocumentText,
            titulo: "Texto",
            descripcion: "Agrega texto plano o contenido en formato Markdown para proporcionar información detallada o instrucciones dentro de la plataforma.",
        },
        {
            tipo: LeccionTipoEnum.ENTREGABLE,
            icon: HiOutlineClipboard,
            titulo: "Entregable",
            descripcion: "Tarea o proyecto que los estudiantes deben completar y entregar para su evaluación.",
        },
        {
            tipo: LeccionTipoEnum.URL,
            icon: HiOutlineLink,
            titulo: "URL",
            descripcion: "Añade enlaces a recursos externos como artículos, libros, o cualquier página web útil para complementar el aprendizaje.",
        },
        {
            tipo: LeccionTipoEnum.ZOOM,
            icon: HiOutlinePlay,
            titulo: "Zoom",
            descripcion: "Sesión en vivo a través de Zoom para interacción directa, discusión y aprendizaje en tiempo real.",
        }
    ]

    return (
        <Flex
            w="100%"
            direction="column"
            px="10px"
            pt="10px"
            css={{
                '&::-webkit-scrollbar': {
                    width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#f1f1f1',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#c7c7c7',
                    borderRadius: '5px',
                },
                scrollBehavior: 'smooth',
            }}
            overflowY="auto"
            h="calc(100vh - 175px)"
        >
            <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                {(formik) => {
                    const { handleSubmit, values } = formik;

                    return (
                        <FormikForm
                            onSubmit={handleSubmit}
                        >
                            {
                                step === 1 &&
                                <Flex
                                    w="100%"
                                    direction="column"
                                    gap="20px"
                                >
                                    <Text
                                        fontSize="24px"
                                        fontWeight="400"
                                        color="pureblack"
                                    >
                                        Lecciones
                                    </Text>

                                    <Text
                                        fontSize="16px"
                                        fontWeight="600"
                                        color="pureblack"
                                    >
                                        Tipo de contenido
                                    </Text>

                                    <Grid
                                        templateColumns="repeat(2, 1fr)"
                                        columnGap="20px"
                                        rowGap="30px"
                                    >
                                        {tipoContenido?.map((item: TipoContenido, index: number) => (
                                            <Flex
                                                key={index}
                                                gap="20px"
                                                alignItems="start"
                                                p="10px"
                                                rounded="5px"
                                                cursor="pointer"
                                                _hover={{ bg: "variant", transition: "all 0.2s" }}
                                                onClick={() => {
                                                    formik.setFieldValue('tipo', item?.tipo)
                                                    setStep(2)
                                                }}
                                            >
                                                <Flex
                                                    w="fit-content"
                                                    h="fit-content"
                                                    rounded="5px"
                                                    bg="purewhite"
                                                    shadow="0px 7.178px 7.068px 0px rgba(211, 213, 216, 0.30)"
                                                    p="10px"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Icon as={item?.icon} boxSize="24px" />
                                                </Flex>

                                                <Flex
                                                    direction="column"
                                                    gap="8px"
                                                >
                                                    <Text
                                                        fontSize="17px"
                                                        fontWeight="400"
                                                        color="pureblack"
                                                    >
                                                        {item?.titulo}
                                                    </Text>

                                                    <Text
                                                        fontSize="17px"
                                                        fontWeight="300"
                                                        color="#666E7D"
                                                    >
                                                        {item?.descripcion}
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        ))}
                                    </Grid>
                                </Flex>
                            }

                            {
                                step === 2 &&
                                <Flex
                                    w="100%"
                                    direction="column"
                                    gap="20px"
                                >
                                    <Flex
                                        h="40px"
                                        alignItems="center"
                                    >
                                        {!editName ?
                                            <Flex
                                                alignItems="center"
                                                gap="10px"
                                                mt="2px"
                                                onClick={() => setEditName(true)}
                                            >
                                                <Text
                                                    fontSize="24px"
                                                    fontWeight="400"
                                                    color="pureblack"
                                                    pl="2px"
                                                >
                                                    {values?.nombre}
                                                </Text>

                                                <Icon
                                                    as={FiEdit3}
                                                    boxSize="18px"
                                                    color="pureblack"
                                                    cursor="pointer"
                                                />
                                            </Flex>
                                            :
                                            <FormInput
                                                name="nombre"
                                                isBlockError
                                                onBlur={() => setEditName(false)}
                                                ui={{
                                                    styleInput: {
                                                        border: "none",
                                                        bg: "transparent",
                                                        width: "auto",
                                                        fontSize: "24px",
                                                        fontWeight: "400",
                                                        color: "pureblack",
                                                        pl: "2px",
                                                    },
                                                    focusInput: {
                                                        border: "none",
                                                        boxShadow: `0 0 0 1px ${colores.variant}`,
                                                    },
                                                }}
                                            />
                                        }
                                    </Flex>

                                    <InformationBox
                                        noHeader
                                    >
                                        <Flex
                                            direction="column"
                                            py="20px"
                                            px="20px"
                                            gap="30px"
                                        >
                                            {(values?.tipo === LeccionTipoEnum.URL || values?.tipo === LeccionTipoEnum.ZOOM) &&
                                                <Flex
                                                    direction="column"
                                                    gap="15px"
                                                >
                                                    <Flex
                                                        alignItems="center"
                                                        gap="5px"
                                                    >
                                                        <Text
                                                            color="pureblack"
                                                            fontSize="16px"
                                                            fontWeight="400"
                                                        >
                                                            Contenido lección
                                                        </Text>

                                                        <InformationComponent
                                                            tooltip={{
                                                                content:
                                                                    <Flex
                                                                        direction="column"
                                                                    >
                                                                        <Text
                                                                            textAlign="center"
                                                                            fontSize="16px"
                                                                            fontWeight={"600"}
                                                                            mb="10px"
                                                                        >
                                                                            Lección de tipo {values?.tipo === LeccionTipoEnum.ZOOM ? "Zoom" : "URL"}
                                                                        </Text>

                                                                        {values?.tipo === LeccionTipoEnum.ZOOM ?
                                                                            <UnorderedList
                                                                                pl="10px"
                                                                                display="flex"
                                                                                flexDirection={"column"}
                                                                                gap="10px"
                                                                                fontSize="14px"
                                                                                fontWeight={"400"}
                                                                            >
                                                                                <ListItem>
                                                                                    Inserte el <Text as={"strong"}>LINK</Text> completo de la reunión que utiliza para compartirla.
                                                                                </ListItem>
                                                                            </UnorderedList>
                                                                            :
                                                                            <UnorderedList
                                                                                pl="10px"
                                                                                display="flex"
                                                                                flexDirection={"column"}
                                                                                gap="10px"
                                                                                fontSize="14px"
                                                                                fontWeight={"400"}
                                                                            >
                                                                                <ListItem>
                                                                                    Inserte la <Text as={"strong"}>URL</Text> a donde se redireccionara o descargara el recurso de la leccion.
                                                                                </ListItem>

                                                                                <ListItem>
                                                                                    Si estás obteniendo el <Text as={"strong"}>URL</Text> para una descarga de una nube como OneDrive, Google Drive, entre otras, <Text as={"strong"}>asegúrate de compartir</Text> la URL como <Text as={"strong"}>público</Text>. Esto garantizará que la lección se cargue correctamente en la aplicación.
                                                                                </ListItem>
                                                                            </UnorderedList>
                                                                        }
                                                                    </Flex>,
                                                                placement: "right",
                                                                hasArrow: true,
                                                                isDisabled: false,
                                                                styles: { rounded: "8px", p: "10px" }
                                                            }}
                                                            icon={BiInfoCircle}
                                                        />
                                                    </Flex>

                                                    <FormTextarea
                                                        name="contenido"
                                                        placeholder={values?.tipo === LeccionTipoEnum.ZOOM ? "Introduce el link de la reunión" : "Introduce el enlace al recurso"}
                                                        noHead={true}
                                                        ui={{
                                                            minHeigh: "20px",
                                                            styles: {
                                                                border: "1px solid",
                                                                borderColor: "#E2E8F0",
                                                                borderRadius: "5px",
                                                                color: "font",
                                                                padding: "5px",
                                                                maxHeight: "100px",
                                                            }
                                                        }}
                                                    />
                                                </Flex>
                                            }

                                            {(values?.tipo === LeccionTipoEnum.FICHERO) &&
                                                <Flex
                                                    w="100%"
                                                    direction="column"
                                                >
                                                    <DropzoneComponent
                                                        file={file}
                                                        setFile={setFile}
                                                        loading={isLoading}
                                                    />
                                                </Flex>
                                            }


                                            {
                                                (values?.tipo === LeccionTipoEnum.EMBEBIDO || values?.tipo === LeccionTipoEnum.ENTREGABLE) &&
                                                <Flex
                                                    direction="column"
                                                    gap="15px"
                                                >
                                                    <Flex
                                                        alignItems="center"
                                                        gap="5px"
                                                    >
                                                        <Text
                                                            color="pureblack"
                                                            fontSize="16px"
                                                            fontWeight="400"
                                                        >
                                                            Contenido lección
                                                        </Text>

                                                        <InformationComponent
                                                            tooltip={{
                                                                content:
                                                                    <Flex
                                                                        direction="column"
                                                                    >
                                                                        <Text
                                                                            textAlign="center"
                                                                            fontSize="16px"
                                                                            fontWeight={"600"}
                                                                            mb="10px"
                                                                        >
                                                                            Lección de tipo {values?.tipo === LeccionTipoEnum.ENTREGABLE ? "Entregable" : "Embebido"}
                                                                        </Text>


                                                                        <UnorderedList
                                                                            pl="10px"
                                                                            display="flex"
                                                                            flexDirection={"column"}
                                                                            gap="10px"
                                                                            fontSize="14px"
                                                                            fontWeight={"400"}
                                                                        >
                                                                            {values?.tipo === LeccionTipoEnum.ENTREGABLE ?
                                                                                <>
                                                                                    <ListItem>
                                                                                        Insete la <Text as={"strong"}>URL</Text> del ejercicio a embeber.
                                                                                    </ListItem>

                                                                                    <ListItem>
                                                                                        Las lecciones de tipo <Text as={"strong"}>Entregable</Text> requieren una entrega en el campus, el alumno debera subir un archivo con el ejercicio resuelto donde el tutor podra calificarlo.
                                                                                    </ListItem>
                                                                                </>
                                                                                :
                                                                                <ListItem>
                                                                                    Insete la <Text as={"strong"}>URL</Text> del contenido a embeber.
                                                                                </ListItem>
                                                                            }

                                                                            <ListItem>
                                                                                Recuerde que si está obteniendo la <Text as={"strong"}>URL</Text> de una plataforma externa como: "Vimeo", "Slices", "OneDrive", "Google Drive", entre otras. <Text as={"strong"}>Asegúrese de compartir</Text> la URL como <Text as={"strong"}>pública</Text>. Esto garantizará que el ejercicio se cargue correctamente en la aplicación.
                                                                            </ListItem>
                                                                        </UnorderedList>
                                                                    </Flex>,
                                                                placement: "right",
                                                                hasArrow: true,
                                                                isDisabled: false,
                                                                styles: { rounded: "8px", p: "10px" }
                                                            }}
                                                            icon={BiInfoCircle}
                                                        />
                                                    </Flex>

                                                    <FormTextarea
                                                        name="contenido"
                                                        noHead={true}
                                                        ui={{
                                                            minHeigh: "20px",
                                                            styles: {
                                                                border: "1px solid",
                                                                borderColor: "#E2E8F0",
                                                                borderRadius: "5px",
                                                                color: "font",
                                                                padding: "5px",
                                                                maxHeight: "100px",
                                                            }
                                                        }}
                                                        placeholder={"Introce la URL del contenido a embeber"}
                                                    />
                                                </Flex>
                                            }

                                            {
                                                (values?.tipo === LeccionTipoEnum.TEXTO) &&
                                                <Flex
                                                    direction="column"
                                                    gap="15px"
                                                >
                                                    <Flex
                                                        alignItems="center"
                                                        gap="5px"
                                                    >
                                                        <Text
                                                            color="pureblack"
                                                            fontSize="16px"
                                                            fontWeight="400"
                                                        >
                                                            Contenido lección
                                                        </Text>

                                                        <InformationComponent
                                                            tooltip={{
                                                                content:
                                                                    <Flex
                                                                        direction="column"
                                                                    >
                                                                        <Text
                                                                            textAlign="center"
                                                                            fontSize="16px"
                                                                            fontWeight={"600"}
                                                                            mb="10px"
                                                                        >
                                                                            Lección de tipo Texto
                                                                        </Text>

                                                                        <UnorderedList
                                                                            pl="10px"
                                                                            display="flex"
                                                                            flexDirection={"column"}
                                                                            gap="10px"
                                                                            fontSize="14px"
                                                                            fontWeight={"400"}
                                                                            mb="10px"
                                                                        >
                                                                            <ListItem>
                                                                                Insertar <Text as="strong">texto plano</Text>.
                                                                            </ListItem>

                                                                            <ListItem>
                                                                                Inserta Markdown donde puedes utilizar <Text as="strong">negrita</Text>, <Text as="i">cursiva</Text>, y <Text as="u">subrayado</Text> para resaltar texto.
                                                                            </ListItem>

                                                                            <ListItem>
                                                                                Insertar <Text as="strong">código</Text>.
                                                                            </ListItem>

                                                                            <ListItem>
                                                                                Es posible crear listas ordenadas o desordenadas.
                                                                            </ListItem>

                                                                            <ListItem>
                                                                                Puedes agregar distintos tamaños de letras y darle estilos.
                                                                            </ListItem>

                                                                            <ListItem>
                                                                                Agrega enlaces en tus textos.
                                                                            </ListItem>
                                                                        </UnorderedList>

                                                                        <Text
                                                                            textAlign="center"
                                                                            fontSize="14px"
                                                                            fontWeight={"700"}
                                                                        >
                                                                            Todo esto puedes hacerlo con la barra de herramientas que se encuentra en la parte superior del editor.
                                                                        </Text>
                                                                    </Flex>,
                                                                placement: "right",
                                                                hasArrow: true,
                                                                isDisabled: false,
                                                                styles: { rounded: "8px", p: "10px" }
                                                            }}
                                                            icon={BiInfoCircle}
                                                        />
                                                    </Flex>

                                                    <FormTextEditor
                                                        name="contenido"
                                                        placeholder="Introduce el texto"
                                                        onChange={formik.setFieldValue}
                                                        stylesEditor={{
                                                            borderRadius: "10px",
                                                        }}
                                                    />
                                                </Flex>
                                            }

                                            <Flex
                                                direction="column"
                                                gap="15px"
                                            >
                                                <Text
                                                    color="pureblack"
                                                    fontSize="16px"
                                                    fontWeight="400"
                                                >
                                                    Descripción lección
                                                </Text>

                                                <FormTextarea
                                                    name="descripcion"
                                                    noHead
                                                    ui={{
                                                        minHeigh: '200px',
                                                        styles: {
                                                            borderRadius: "5px",
                                                            border: "1px solid #EAEAEA",
                                                            padding: "10px",
                                                        }
                                                    }}
                                                />
                                            </Flex>
                                        </Flex>
                                    </InformationBox>
                                </Flex>
                            }

                            <Flex
                                mt="10px"
                                gap="15px"
                                alignItems={"center"}
                                justifyContent="end"
                            >
                                {step === 2 &&
                                    <Button
                                        px="10px"
                                        py="10px"
                                        h="fit-content"
                                        w="fit-content"
                                        gap="10px"
                                        rounded="5px"
                                        border="1px solid"
                                        borderColor={"secondary"}
                                        fontSize="14px"
                                        fontWeight={"500"}
                                        color="purewhite"
                                        bg="secondary"
                                        _hover={{ bg: "variant" }}
                                        type="submit"
                                    >
                                        Guardar
                                    </Button>
                                }

                                <Button
                                    px="10px"
                                    py="10px"
                                    h="fit-content"
                                    w="fit-content"
                                    gap="10px"
                                    rounded="5px"
                                    border="1px solid"
                                    borderColor={"secondary"}
                                    fontSize="14px"
                                    fontWeight={"500"}
                                    color="secondary"
                                    bg="purewhite"
                                    onClick={onOpenAlert}
                                >
                                    Cancelar
                                </Button>
                            </Flex>
                        </FormikForm>
                    );
                }}
            </Formik>

            <ModalCloseAlert
                isOpen={isOpenAlert}
                onClose={onCloseAlert}
                onCloseForm={() => {
                    setStep(1);
                    setSelected({
                        type: TipoSelect.MODULO,
                        data: modulo
                    })

                    setRefreshData((prev) => ({ ...prev, leccion: true }))
                    onCloseAlert();
                }}
            />
        </Flex>
    )
}