import { Box, Flex, useToast } from "@chakra-ui/react"
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes"
import { useClient } from "../../../../shared/context/client.context"
import { InformationBox } from "../../../../shared/components/CustomElements/InformationBox/InformationBox"
import { ColorPickerRowProps, InformationRow, InformationRowItems, InformationRowTypes, InputRowProps, SwitchSelectRowProps } from "../../../../shared/components/CustomElements/InformationBox/InformationRow"
import { updateCampusConfig } from "../../../../shared/middlewares/config.middleware"
import { handleErrors } from "../../../../shared/utils/functions/handleErrors"
import { toastNotify } from "../../../../shared/utils/functions/toastNotify"
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes"
import { useData } from "../../../../shared/hooks/useData"
import { configLabels, configOrder } from "../../../../shared/utils/functions/configCampus"
import { ConfigEnum } from "../../../../shared/utils/Types/ConfigTypes"
import { useTenantInfo } from "../../../../shared/hooks/useTenantInfo"

export const ConfigInformation = () => {
    const { handleSetInfo } = useTenantInfo();
    const client = useClient()
    const toast = useToast()

    const { data, loading, Refresh } = useData({
        client,
        endpoint: EndpointTypes.CAMPUS_CONFIG_READ
    })

    const updateValue = async (values: Record<string, string | object | boolean | number>) => {
        let newValues: any = {}

        Object.entries(values).map(([key, value]) =>
            newValues = {
                [key]: value
            }
        )

        updateCampusConfig({
            data: newValues,
            client: client
        })
            .then(() => {

                if (newValues[ConfigEnum.COLORES]) {
                    handleSetInfo({ key: "colores", value: newValues[ConfigEnum.COLORES] })
                }

                Refresh()
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => location.reload())
    }

    const validKeys = Object.values(ConfigEnum).map((item) => item)

    for (const key in data) {
        if (!validKeys.find((item) => item === key)) {
            delete data[key];
        }

        if (key === ConfigEnum.MODULO_FUNDAE) {
            if (!data[key]) {
                delete data[ConfigEnum.MAIL_BIENVENIDA];
                delete data[ConfigEnum.MAIL_TUTOR];
                delete data[ConfigEnum.MAIL_CONTROL];
                delete data[ConfigEnum.MAIL_SUPERVISOR];
            }
        }
    }

    const configInfo: InformationRowItems[] = loading ? [] : [
        ...Object.entries(configOrder(data)).map(([key, value], index) => (
                (typeof value === "boolean") ?
                    {
                        label: configLabels(key),
                        optionLeft: "Si",
                        optionRight: "No",
                        defaultValue: value,
                        onClick: (event: any) => updateValue({ [key as any]: event }),
                        isDisabled: false,
                        hideBorder: {
                            top: 0 !== index,
                            bottom: Object.entries(data)?.length - 1 === index
                        },
                    } as SwitchSelectRowProps
                    : key === ConfigEnum.COLORES ?
                        {
                            label: configLabels(key),
                            defaultValue: value,
                            updateValue: updateValue,
                            hideBorder: {
                                top: 0 !== index,
                                bottom: Object.entries(data)?.length - 1 === index
                            },
                        } as ColorPickerRowProps
                        :
                        {
                            label: configLabels(key),
                            name: key,
                            defaultValue: value,
                            isRequired: false,
                            isDisabled: key === ConfigEnum.USER_URL || key === ConfigEnum.TIME_ZONE,
                            updateValue: updateValue,
                            hideBorder: {
                                top: 0 !== index,
                                bottom: Object.entries(data)?.length - 1 === index
                            },
                        } as InputRowProps
        )),
    ]

    return (
        <Flex
            w="100%"
        >
            <InformationBox
                title="Detalles de la configuración"
            >
                <Box>
                    {configInfo?.map((item: InformationRowItems, index: number) => (
                        <InformationRow
                            key={index}
                            type={
                                item?.label === "Colores" ?
                                    InformationRowTypes?.COLOR_PICKER
                                    : typeof item?.defaultValue === "boolean"
                                        ? InformationRowTypes?.SWITCH
                                        : InformationRowTypes.INPUT
                            }
                            item={item}
                        />
                    ))}
                </Box>
            </InformationBox>
        </Flex>
    )
}
