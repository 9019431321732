import { Badge, Box, Button, Flex, Icon, Image, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { UserInt } from "../../../../interfaces/UserInt";
import { InformationInput } from "@imaginagroup/bit-components.ui.elements.information-input"
import { InformationBox } from "../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { BiPencil } from "react-icons/bi";
import { useState } from "react";
import { IconBox } from "../../../../shared/components/Icons/IconBox";
import { InformationAsyncSelect } from "@imaginagroup/bit-components.ui.elements.information-async-select";
import PerfilSupervisorSkeleton from "../utils/PerfilSupervisorSkeleton";
import { resendEmail } from "../../../../shared/middlewares/token.middleware";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { useClient } from "../../../../shared/context/client.context";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { ModalCredenciales } from "../Modals/ModalCredenciales";
import { informationSelectStyles } from "../../../../ui/informationSelectStyles";

interface Props {
    supervisor: UserInt | undefined;
    updateValue: (action: any) => void;
    loading: boolean;
    loadEmpresa: (action: string) => void;
}

export const PerfilSupervisor = ({ supervisor, updateValue, loadEmpresa, loading }: Props) => {
    const toast = useToast();
    const client = useClient();
    const [loadingCredenciales, setLoadingCredenciales] = useState<boolean>(false)
    const [editNombre, setEditNombre] = useState<boolean>(false)
    const [editApellidos, setEditApellidos] = useState<boolean>(false)
    const [editTelefono, setEditTelefono] = useState<boolean>(false)
    const [editDni, setEditDni] = useState<boolean>(false)
    const [editEmail, setEditEmail] = useState<boolean>(false)
    const { onOpen: onOpenCredenciales, isOpen: isOpenCredenciales, onClose: onCloseCredenciales } = useDisclosure()

    const handleResendCredentials = async (grupoId: string) => {
        if (!supervisor?.id) return;
        if (!grupoId) return toastNotify(toast, StatusEnumTypes.ERROR, "Debe seleccionar un grupo para reenviar las credenciale")
        setLoadingCredenciales(true)

        resendEmail({
            client: client,
            user: supervisor?.id,
            grupo: grupoId
        })
            .then(() => toastNotify(toast, StatusEnumTypes.SUCCESS, "Credenciales reenviadas correctamente"))
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => {
                setLoadingCredenciales(false)
                onCloseCredenciales()
            })
    }

    return (
        loading ?
            <PerfilSupervisorSkeleton />

            :

            <Flex gap="20px">
                <Flex direction="column" flex="1" gap="20px">
                    <InformationBox
                        title="Datos del alumno"
                        component={
                            <Flex
                                alignItems="center"
                                gap="10px"
                            >
                                <Text
                                    color="font"
                                    fontSize="14px"
                                    fontWeight="600"
                                >
                                    Estado:
                                </Text>

                                <Badge
                                    color={supervisor?.activo ? "success_main" : "font"}
                                    bg={supervisor?.activo ? "success_bg" : "light_grey"}
                                    borderWidth="1px"
                                    borderColor={supervisor?.activo ? "success_variant" : "light_grey"}
                                    fontSize="10px"
                                    fontWeight="500"
                                    letterSpacing="0.2px"
                                    textTransform="uppercase"
                                    borderRadius="14px"
                                    p="5px 8px"
                                >
                                    {
                                        supervisor?.activo ? "Activo" : "Inactivo"
                                    }
                                </Badge>
                            </Flex>
                        }
                    >
                        <Box>
                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditNombre(true)}
                                onMouseLeave={() => setEditNombre(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="nombre"
                                    label="Nombre"
                                    defaultValue={supervisor?.nombre || ""}
                                    updateValue={updateValue}
                                />

                                <Flex boxSize="20px">
                                    {editNombre && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditApellidos(true)}
                                onMouseLeave={() => setEditApellidos(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="apellidos"
                                    label="Apellidos"
                                    defaultValue={supervisor?.apellidos || ""}
                                    updateValue={updateValue}
                                />

                                <Flex boxSize="20px">
                                    {editApellidos && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditDni(true)}
                                onMouseLeave={() => setEditDni(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="dni"
                                    label="documento identidad"
                                    validatorDNI={true}
                                    isInvalidMessage="El DNI ingresado no es correcto"
                                    defaultValue={supervisor?.dni || ""}
                                    updateValue={updateValue}
                                />

                                <Flex boxSize="20px">
                                    {editDni && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                p="10px 20px"
                                direction="column"
                                borderBottom="1px solid"
                                borderColor="light_grey"
                            >
                                <Box
                                    pr="25px"
                                >
                                    <InformationAsyncSelect
                                        name="empresaId"
                                        label="Empresa"
                                        placeholder="Buscar Empresa"
                                        updateValue={updateValue}
                                        loadOptions={loadEmpresa}
                                        defaultOptions={true}
                                        ui={{
                                            informationSelectStyles: informationSelectStyles
                                        }}
                                    />

                                    <Flex gap="50px" mt="10px">
                                        <Box flex="1" />

                                        <Flex
                                            borderRadius="9px"
                                            p="10px 15px"
                                            bg="purewhite"
                                            gap="10px"
                                            alignItems="center"
                                            flex="1"
                                            boxShadow="0px 4px 15px 0px rgba(199, 205, 255, 0.60)"
                                        >
                                            {supervisor?.empresa?.icono?.url ?
                                                <Image
                                                    src={supervisor?.empresa?.icono?.url}
                                                    padding="0"
                                                    boxSize="30px"
                                                />
                                                :
                                                <IconBox
                                                    icon={undefined}
                                                    size="30px"
                                                    border="none"
                                                    padding="0"
                                                    bg="inherit"
                                                />
                                            }

                                            <Box>
                                                <Text
                                                    color="font"
                                                    fontSize="14px"
                                                    fontWeight="500"
                                                >
                                                    {supervisor?.empresa?.nombre}
                                                </Text>
                                            </Box>
                                        </Flex>
                                    </Flex>
                                </Box>
                            </Flex>

                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditTelefono(true)}
                                onMouseLeave={() => setEditTelefono(false)}
                                gap="5px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="telefono"
                                    label="teléfono"
                                    defaultValue={supervisor?.telefono || ""}
                                    updateValue={updateValue}
                                />

                                <Flex boxSize="20px">
                                    {editTelefono && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>
                        </Box>
                    </InformationBox>
                </Flex>

                <Flex direction="column" flex="1" gap="20px">
                    <InformationBox
                        title="Credenciales de acceso"
                        component={
                            <Button
                                p="7px 12px"
                                h="fit-content"
                                w="fit-content"
                                borderRadius="5px"
                                bg="secondary"
                                color="#FFF"
                                fontSize="14px"
                                fontWeight="500"
                                onClick={onOpenCredenciales}
                            >
                                Reenviar credenciales
                            </Button>
                        }
                    >
                        <Box>
                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditEmail(true)}
                                onMouseLeave={() => setEditEmail(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="email"
                                    label="Email"
                                    type="email"
                                    defaultValue={supervisor?.email || ""}
                                    updateValue={updateValue}
                                />

                                <Flex boxSize="20px">
                                    {editEmail && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>
                        </Box>
                    </InformationBox>
                </Flex>

                <ModalCredenciales
                    supervisorId={supervisor?.id}
                    isOpen={isOpenCredenciales}
                    onClose={onCloseCredenciales}
                    handleResendCredentials={handleResendCredentials}
                    loadingCredenciales={loadingCredenciales}
                />
            </Flex >
    );
}