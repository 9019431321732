import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, ModalFooter, Button, useToast, Box, Text, useDisclosure, ModalCloseButton, Tooltip, Icon } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { FormSelect } from "@imaginagroup/bit-components.ui.form-select";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { useEffect, useState } from "react";
import { GruposInt } from "../../../../interfaces/GruposInt";
import { UserInt } from "../../../../interfaces/UserInt";
import { EmpresasInt } from "../../../../interfaces/EmpresasInt";
import { addMatriculaToGrupo } from "../../../../shared/middlewares/matriculas.middlewate";
import { NewAlumnoModal } from "../../../Usuarios/components/Modals/NewAlumnoModal";
import { CustomAvatar } from "../../../../shared/components/Avatar/CustomAvatar";
import { ModalCloseAlert } from "../../../../shared/components/Modals/ModalCloseAlert";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { useClient } from "../../../../shared/context/client.context";
import tenantInstance from "../../../../shared/services/tenantInstance.service";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { useAuthContex } from "../../../../shared/context/auth.context";
import { useUserPolicy } from "../../../../shared/hooks/PermissionPolicy";
import { updateUsuario } from "../../../../shared/middlewares/users.middleware";
import { formSelectStyles } from "../../../../ui/formSelectStyles";
import { getData } from "../../../../shared/middlewares/middlewares";
import { formatTimezone } from "../../../../shared/utils/functions/parseDate";
import { useTimeZone } from "../../../../shared/hooks/useTimeZone";
import { MatriculasInt } from "../../../../interfaces/MatriculasInt";
import { CustomAsyncSelect } from "../../../../shared/components/CustomElements/CustomAsyncSelect";
import { BiTrashAlt } from "react-icons/bi";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    setRefresh: () => void;
    grupo: GruposInt | undefined;
    refreshGrupo?: () => void;
}
export const NewMatriculaModal = ({ isOpen, onClose, setRefresh, grupo, refreshGrupo = () => { } }: Props) => {
    const timeZone = useTimeZone();
    const client = useClient();
    const { user, refreshUser } = useAuthContex();
    const { total } = useUserPolicy();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const toast = useToast();
    const { isOpen: isOpenAlumno, onOpen: onOpenAlumno, onClose: onCloseAlumno } = useDisclosure();
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();

    const [tutores, setTutores] = useState<{ value: string, label: string }[]>([]);
    const [empresas, setEmpresas] = useState<string>()
    const [alumnosMatriculas, setAlumnosMatriculas] = useState<UserInt[]>([])
    const [refreshData, setRefreshData] = useState<boolean>(true)

    useEffect(() => {
        const optionsTutores: { value: string, label: string }[] = []

        grupo?.tutores?.map((tutor: UserInt) => {
            if (tutor?.id && tutor?.nombre)
                return optionsTutores.push({ value: tutor?.id, label: tutor?.nombre })
        })

        setTutores(optionsTutores)

        const empresasId: string[] = []

        grupo?.empresas?.map((empresa: EmpresasInt) => {
            if (empresa?.id)
                return empresasId.push(empresa?.id)
        })

        const empresas = empresasId.join(",")

        setEmpresas(empresas)
    }, [isOpen, grupo])

    const initialValues = {
        tutorUnico: tutores?.length == 1 ? true : tutores?.length > 1 ? false : null,
        tutor: tutores?.length == 1 ? tutores[0]?.value : null,
    };

    const validationSchema = Yup.object({
        tutorUnico: Yup.boolean(),
        tutor: Yup.string().when("tutorUnico", {
            is: true,
            then: (validationScheme) => validationScheme.notRequired(),
            otherwise: (validationScheme) => validationScheme
                .required('Debe seleccionar un tutor.')
                .typeError('Debe seleccionar un tutor.'),

        }),
    });

    const handleSubmit = async (values: any) => {
        if (!alumnosMatriculas || alumnosMatriculas?.length == 0) return
        setIsLoading(true)

        validateTutor(values?.tutor).then((response: any) => {

            if (response) {
                alumnosMatriculas?.map((alumno: any) => {
                    const matricula: any = {
                        userId: alumno?.value?.id,
                        grupoId: grupo?.id,
                        tutorId: values.tutor,
                        importe: values.importe,
                        empresaFacturaId: values.empresaFacturaId
                    };

                    addMatriculaToGrupo({
                        data: matricula,
                        client: client
                    })
                        .then(() => {
                            setRefresh();
                            refreshGrupo();
                            toastNotify(toast, StatusEnumTypes.SUCCESS, `Alumno matriculado correctamente`)
                        })
                        .catch((error: any) => {
                            const errors = handleErrors(
                                error?.response?.data?.errors,
                                error?.response?.status
                            )

                            errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
                        })
                })
            } else {
                toastNotify(toast, StatusEnumTypes.ERROR, `El tutor supera las 80 matrículas, asigne otro tutor`)
            }
        })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(async () => {
                setIsLoading(false)
                handleClose();

                if (!user?.config?.matriculas && total) {
                    await updateUsuario({
                        id: user?.id as string,
                        client: client,
                        data: {
                            config: {
                                ...user?.config,
                                matriculas: true
                            }
                        }
                    })
                        .then(() => {
                            refreshUser({
                                config: {
                                    ...user?.config,
                                    matriculas: true
                                }
                            })
                        })
                }
            })
    }

    const loadAlumnos = async (value: string) => {
        const tenant = await tenantInstance()
        let query = ""

        if (value && value !== "") query = `&nombreYemail=${value}`

        if (!empresas) return

        const { data } = await getData(
            tenant + "/" + client + EndpointTypes.USUARIOS + "?empresa=" + empresas + query
        )

        const alumnos = data?.data?.data.map((alumno: UserInt) => ({
            value: alumno,
            label: `${alumno?.nombre} ${alumno?.apellidos}`,
        }));

        const alumnosFilter = alumnos.filter(({ value }: { value: { id: string } }) =>
            !grupo?.matriculas?.some((m: MatriculasInt) => m?.userId === value?.id) &&
            !alumnosMatriculas?.some((alumno: any) => alumno?.value?.id === value?.id)
        )

        return alumnosFilter;
    }

    const handleSelectAlumno = (e: any) => {
        setAlumnosMatriculas((prev: any) => [
            ...prev,
            e
        ])

        setRefreshData(true)
    }

    const removeAlumno = (alumnoId: string | undefined | null) => {
        if (!alumnoId) return

        const alumnosFilter = alumnosMatriculas.filter((alumno: any) => alumno?.value !== alumnoId)

        setAlumnosMatriculas(alumnosFilter)
        setRefreshData(true)
    }

    const handleClose = () => {
        setAlumnosMatriculas([]);
        setEmpresas(undefined)
        setTutores([])
        setRefreshData(true)
        onClose();
    }

    const validateTutor = async (tutorId: string) => {
        const tenant = await tenantInstance()
        if (!grupo?.fundae) return true

        const fechaInicio = formatTimezone({ date: grupo?.fechaInicio, timeZone })

        const { data } = await getData(`${tenant}/${client}/matriculasFuturas?tutorId=${tutorId}&fecha=${fechaInicio}`)
        const totalMatriculas = data?.data + alumnosMatriculas?.length

        return totalMatriculas < 80 ? true : false
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => null}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                size="xl"
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    maxH="95vh"
                    rounded="20px"
                >
                    <Box
                        color="black"
                    >
                        <ModalHeader
                            textAlign="start"
                            fontSize="24px"
                            fontWeight="600"
                            px="30px"
                            pt="30px"
                            pb="0px"
                        >
                            Nueva matrícula
                        </ModalHeader>

                        <ModalCloseButton onClick={() => onOpenAlert()} />
                    </Box>

                    <Box
                        overflowY="auto"
                        __css={{
                            '&::-webkit-scrollbar': {
                                w: '7px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '20px',
                                bg: `light_grey`,
                            },
                        }}
                    >
                        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                            {(formik) => {
                                const { handleSubmit, values } = formik;

                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                    >
                                        <ModalBody
                                            p="30px"
                                        >
                                            {tutores?.length > 1 &&
                                                <Flex
                                                    direction="column"
                                                    gap="15px"
                                                    mb="5px"
                                                >
                                                    <Text
                                                        color="font"
                                                        fontSize="18px"
                                                        fontWeight="600"
                                                    >
                                                        Tutor
                                                    </Text>

                                                    <FormSelect
                                                        noHead={true}
                                                        name="tutor"
                                                        options={tutores}
                                                        isRequired={values?.tutor ? false : true}
                                                        ui={{
                                                            formSelectStyles: formSelectStyles,
                                                        }}
                                                    />

                                                    {grupo?.tutores?.map((tutor: UserInt, index: number) => (
                                                        tutor?.id === values?.tutor &&
                                                        <Flex
                                                            key={index}
                                                            borderRadius="9px"
                                                            p="10px 15px"
                                                            bg="purewhite"
                                                            boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)"
                                                            gap="10px"
                                                            alignItems="center"
                                                            mb="15px"
                                                        >
                                                            <CustomAvatar
                                                                name={tutor?.nombre || ""}
                                                                size="30px"
                                                                src={tutor?.avatar?.url}
                                                            />

                                                            <Box>
                                                                <Text
                                                                    color="font"
                                                                    fontSize="14px"
                                                                    fontWeight="500"
                                                                >
                                                                    {tutor?.nombre} {tutor?.apellidos}
                                                                </Text>

                                                                <Text
                                                                    color="dark_grey"
                                                                    fontSize="12px"
                                                                    fontWeight="500"
                                                                >
                                                                    {tutor?.email}
                                                                </Text>
                                                            </Box>
                                                        </Flex>
                                                    ))}
                                                </Flex>
                                            }

                                            <Flex
                                                direction="column"
                                                boxSize="100%"
                                                gap="10px"
                                            >
                                                <Text
                                                    color="black"
                                                    fontSize="18px"
                                                    fontWeight="600"
                                                    mb="5px"
                                                >
                                                    Alumnos
                                                </Text>

                                                <CustomAsyncSelect
                                                    placeholder="Selecciona un alumno"
                                                    loadOptions={loadAlumnos}
                                                    onClick={handleSelectAlumno}
                                                    refreshData={refreshData}
                                                    setRefreshData={setRefreshData}
                                                />

                                                <Flex
                                                    direction="column"
                                                    gap="10px"
                                                >
                                                    {alumnosMatriculas?.map((alumno: any, index: number) => (
                                                        <Flex
                                                            key={index}
                                                            borderRadius="9px"
                                                            p="10px 15px"
                                                            bg="purewhite"
                                                            gap="10px"
                                                            alignItems="center"
                                                        >
                                                            <CustomAvatar
                                                                name={alumno?.value?.nombre}
                                                                src={alumno?.value?.avatar?.url}
                                                                size="35px"
                                                            />
                                                            <Flex
                                                                ml="5px"
                                                                direction="column"
                                                            >
                                                                <Text
                                                                    color="font"
                                                                    fontSize="14px"
                                                                    fontWeight="500"
                                                                >
                                                                    {alumno?.value?.nombre} {alumno?.value?.apellidos}
                                                                </Text>

                                                                <Text
                                                                    color="font"
                                                                    fontSize="14px"
                                                                    fontWeight="500"
                                                                >
                                                                    {alumno?.value?.email}
                                                                </Text>
                                                            </Flex>

                                                            <Icon as={BiTrashAlt} boxSize="22px" ml="auto" cursor="pointer" onClick={() => removeAlumno(alumno?.value)} />
                                                        </Flex>
                                                    )
                                                    )}
                                                </Flex>

                                                <Flex
                                                    alignItems="center"
                                                    gap="20px"
                                                    justifyContent="center"
                                                >
                                                    <Text
                                                        color="#67748E"
                                                        fontSize="13px"
                                                        fontWeight="500"
                                                        lineHeight="16px"
                                                        letterSpacing="-0.11px"
                                                    >
                                                        ¿No encuentras el alumno?
                                                    </Text>

                                                    <Text
                                                        cursor="pointer"
                                                        color="black"
                                                        fontSize="13px"
                                                        fontWeight="600"
                                                        letterSpacing="-0.11px"
                                                        onClick={onOpenAlumno}
                                                        _hover={{ textDecoration: "underline" }}
                                                    >
                                                        Crear Alumno
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        </ModalBody>

                                        <ModalFooter
                                            justifyContent="center"
                                        >
                                            <Tooltip
                                                label={"Debe haber un tutor asociado al grupo"}
                                                isDisabled={tutores?.length > 0}
                                            >
                                                <Button
                                                    isLoading={isLoading}
                                                    mt="20px"
                                                    type="submit"
                                                    bg="secondary"
                                                    p="10px 25px"
                                                    h="fit-content"
                                                    rounded="5"
                                                    color="#FFF"
                                                    fontSize="18px"
                                                    fontWeight="500"
                                                    isDisabled={tutores?.length == 0}
                                                    _hover={{ color: "purewhite", backgroundColor: "variant" }}
                                                >
                                                    Matricular alumnos
                                                </Button>
                                            </Tooltip>
                                        </ModalFooter>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Box>
                </ModalContent>
            </Modal>

            <NewAlumnoModal
                isOpen={isOpenAlumno}
                onClose={onCloseAlumno}
                setRefreshTable={setRefresh}
                section="grupo"
                grupoEmpresas={grupo?.empresas}
                handleSelectAlumno={handleSelectAlumno}
            />

            <ModalCloseAlert
                isOpen={isOpenAlert}
                onClose={onCloseAlert}
                onCloseForm={handleClose}
            />
        </>
    )
}