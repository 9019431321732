import { UserInt } from "../../interfaces/UserInt";
import _axios, { ClientInt } from "../services/http.service";
import tenantInstance from '../services/tenantInstance.service';

export const addUsuario = async ({ data, client } : { data: UserInt, client: ClientInt }) => {
    if(!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/users`,
        data,
        {}
    )
};

export const updateUsuario = async ({ 
    id, 
    data,
    client
} : { 
    id: string, 
    data: UserInt,
    client: ClientInt 
}) => {
    if(!client) return;
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/users/${id}`,
        data,
        {}
    )
};

export const validateDatos = async ({ 
    id, 
    validate,
    client
} : { 
    id: string, 
    validate: boolean,
    client: ClientInt 
}) => {
    if(!client) return;
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/cambioDatos/${id}`,
        { 
            aceptado: validate, 
        },
        {}
    )
};

export const deleteUser = async ({ userId, client }: { userId: string | undefined, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.delete(`${tenant}/${client}/users/${userId}`)
};