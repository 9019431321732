import { Query, useQuery } from "@tanstack/react-query"
import queryString from 'query-string';
import _axios, { ClientInt } from "../services/http.service";
import { omit } from "lodash";
import { EndpointTypes } from "../utils/Types/EndpointTypes";
import { getData } from "../middlewares/middlewares";
import tenantInstance from "../services/tenantInstance.service";

const tenant = await tenantInstance();

type AdonisErrorInt = {
  field: string;
  message: string;
  rule: string;
};

declare module '@tanstack/react-query' {
  interface Register {
    defaultError: { response: { status: number, data: { errors: any[] } } };
  }
}

interface UseDataProps {
  client: ClientInt;
  endpoint: EndpointTypes;
  specificQuery?: Record<string, string | number | string[]> | undefined;
  query?: Record<string, string | number | string[]>;
  ignoreRequest?: boolean;
  refetchInterval?: number | false | ((query: Query) => number | false | undefined)
}

export const useData = ({
  client,
  endpoint,
  specificQuery,
  query = {},
  ignoreRequest = false,
  refetchInterval = false,
}: UseDataProps) => {
  const cleanQuery = omit(query, ['tabIndex']);

  let finalEndpoint = tenant + "/" + client + endpoint

  if (specificQuery) finalEndpoint += `?${queryString.stringify({ ...specificQuery, ...cleanQuery })}`;
  else if (Object.keys(cleanQuery).length) {
    finalEndpoint += `?${queryString.stringify(cleanQuery)}`;
  }

  const queryKey = [endpoint, client, { ...cleanQuery, ...specificQuery }];

  const {
    data,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey,
    queryFn: () => getData(finalEndpoint),
    enabled: !ignoreRequest,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchOnMount: true,
    refetchInterval: refetchInterval,
    retry: 1,
    staleTime: 1000 * 60 * 5
  })

  return {
    data: data?.data?.data,
    loading: isLoading,
    error: error,
    Refresh: refetch,
    status: !error ? data?.status : error?.response?.status,
    errorsList: error?.response?.data?.errors as AdonisErrorInt[],
  }
}