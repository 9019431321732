import { Flex } from "@chakra-ui/react"
import { Route, Routes } from "react-router-dom"
import { Helmet } from "react-helmet";
import { TabsSeguimiento } from "./views/TabsSeguimiento";

export const Seguimiento = () => {

    return (
        <Flex
            direction="column"
            w="100%"
        >
            <Helmet>
                <title>Seguimiento | Proacademy</title>
            </Helmet>
            <Flex direction="column">
                <Routes>
                    <Route
                        index
                        element={
                            <TabsSeguimiento />
                        }
                    />
                </Routes>
            </Flex>
        </Flex>
    )
}