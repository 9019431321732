import {
  Flex,
  Box,
  Text,
  Icon,
  Image,
  Progress,
} from "@chakra-ui/react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./UltimasMatriculas.scss"
import { useClient } from "../../../shared/context/client.context";
import { useNavigate } from "react-router-dom";
import { useData } from "../../../shared/hooks/useData";
import { EndpointTypes } from "../../../shared/utils/Types/EndpointTypes";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import { truncate } from "lodash";
import { defaultIcon } from "../../Onboarding/components/OnboardingForm";
import { formatTimezone } from "../../../shared/utils/functions/parseDate";
import { useTimeZone } from "../../../shared/hooks/useTimeZone";


export interface ColumnProps {
  key: string;
  field: string;
  header: string;
  sortable?: boolean;
  options?: { label: string; value: any }[];
  render?: (data: any) => JSX.Element | undefined;
  loadOptions?: (
    search: string
  ) => Promise<{ label: string; value: string }[] | undefined>;
  style?: React.CSSProperties;
  body: any;
}

export default function UltimasMatriculas() {
  const timeZone = useTimeZone();
  const client = useClient();
  const navigate = useNavigate();
  const { data: matriculas } = useData({
    client: client,
    endpoint: EndpointTypes.MATRICULAS,
    specificQuery: {
      sortBy: "matriculas.created_at",
      order: "desc",
      limit: 5
    }
  })

  return (
    <Flex
      className="matriculas"
      direction="column"
      width="100%"
      bgColor="purewhite"
      p="24px"
      rounded="14px"
      border="1px"
      borderColor="#DFE2E6"
      boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.01)"
      gap="28px"
    >
      <Flex
        justifyContent="space-between"
        align="start"

      >
        <Flex
          align="center"
          justify="center"
          gap="12px"
        >
          <Flex
            bgColor="#F9FAFB"
            boxSize="28px"
            borderRadius="4px"
            align="center"
            justify="center"
          >
            <Icon
              boxSize="18px"
              color="#616675"
              as={HiOutlineClipboardCopy}
            />
          </Flex>
          <Text
            color="black"
            fontSize="18px"
            fontWeight="400"
            lineHeight="120%"

          >
            Últimas matrículas
          </Text>
        </Flex>
        <Text
          color="secondary"
          fontSize="16px"
          fontWeight="400"
          cursor="pointer"
          onClick={() => navigate("/matriculas")}
        >
          Ver todo
        </Text>

      </Flex>

      <Box
        className="matriculasTable"
        maxW="100%"
        overflowX="hidden"

      >
        <DataTable
          className="dataTableMatriculas"
          value={matriculas?.data}
          resizableColumns
          columnResizeMode="fit"
          onRowClick={({ data }) => navigate(`/matriculas/${data.id}`)}

        >
          <Column field="grupo.curso.nombre" header="Curso" body={(rowData: any) => (
            <Flex
              gap="12px"
              align="center"
            >
              {rowData?.grupo?.curso?.icono?.url ?
                <Image
                  boxSize="36px"
                  src={rowData?.grupo?.curso?.icono?.url}
                />
                :
                <Image
                  boxSize="36px"
                  src={`data:image/svg+xml;utf8,${defaultIcon}`}
                />
              }
              <Text
                color="#000"
                fontWeight="400"
                fontSize="15px"
              >
                {truncate(rowData?.grupo?.curso?.nombre, { length: 22 })}
              </Text>

            </Flex>
          )}
          />

          <Column field="user.nombre" header="Alumno" />

          <Column field="grupo.nombre" header="Grupo" />

          <Column field="estado" header="Estado" body={(rowData: any) => (
            <Flex
              justify="start"
              align="center"
              gap="8px"

            >
              <Progress
                value={rowData?.progresoLecciones}
                w="70%"
                rounded="50px"
                sx={{ '& > div': { backgroundColor: "variant !important" } }}
              />


              <Text

              >
                {rowData?.progresoLecciones ? `${rowData?.progresoLecciones?.toFixed(0)}%` : "0%"}
              </Text>

            </Flex>

          )} />

          <Column
            field="createdAt"
            header="Fecha Inicio"
            body={(rowData) => formatTimezone({ date: rowData?.fechaInicio, timeZone, customFormat: "dd/MM/yyyy" })}
          />

          <Column
            field="createdAt"
            header="Creación"
            body={(rowData) => formatTimezone({ date: rowData?.createdAt, timeZone, customFormat: "dd/MM/yyyy" })}

          />
        </DataTable>
        {/* </Flex> */}
      </Box>
    </Flex>
  );
}
