import { useState, useCallback, useEffect, Dispatch, SetStateAction } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Flex, Icon, Spinner, Text } from '@chakra-ui/react';
import { MdOutlineUploadFile } from 'react-icons/md';
import { ImCloudUpload } from "react-icons/im";

interface Props {
    file: File | null;
    setFile: Dispatch<SetStateAction<File | null>>;
    loading?: boolean;
    hasContent?: boolean;
}

const DropzoneComponent = ({ file, setFile, loading = false, hasContent = false }: Props) => {
    const [isDragActive, setIsDragActive] = useState(false);
    const [isFileDragging, setIsFileDragging] = useState(false);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setFile(acceptedFiles[0]);

        setIsDragActive(false);
        setIsFileDragging(false);
    }, []);

    useEffect(() => {
        let dragCounter = 0;

        const handleDragEnter = (e: DragEvent) => {
            e.preventDefault();
            dragCounter++;
            if (e.dataTransfer && e.dataTransfer.items.length > 0) {
                setIsFileDragging(true);
                setIsDragActive(true);
            }
        };

        const handleDragOver = (e: DragEvent) => {
            e.preventDefault();
        };

        const handleDrop = (e: DragEvent) => {
            e.preventDefault();
            dragCounter = 0;
            setIsFileDragging(false);
            setIsDragActive(false);
        };

        const handleDragLeave = (e: DragEvent) => {
            dragCounter--;
            if (dragCounter === 0) {
                setIsFileDragging(false);
                setIsDragActive(false);
            }
        };

        window.addEventListener("dragenter", handleDragEnter);
        window.addEventListener("dragover", handleDragOver);
        window.addEventListener("drop", handleDrop);
        window.addEventListener("dragleave", handleDragLeave);

        return () => {
            window.removeEventListener("dragenter", handleDragEnter);
            window.removeEventListener("dragover", handleDragOver);
            window.removeEventListener("drop", handleDrop);
            window.removeEventListener("dragleave", handleDragLeave);
        };
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    return (
        <Box position="relative">
            {isFileDragging && (
                <Box
                    position="fixed"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    backgroundColor="rgba(199, 205, 255, 0.50)"
                    zIndex={9998}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                />
            )}
            <Flex
                direction="column"
                gap="20px"
                rounded="md"
            >
                <Flex
                    {...getRootProps()}
                    w="100%"
                    h="100px"
                    border="1px dashed"
                    borderColor={isDragActive ? "secondary" : "dark_grey"}
                    borderRadius="md"
                    backgroundColor={isDragActive ? "rgba(199, 205, 255, 0.60)" : 'transparent'}
                    transition="background-color 0.2s ease"
                    zIndex={isDragActive ? 9999 : 'auto'}
                    position="relative"
                    alignItems="center"
                    justifyContent="center"
                    direction="column"
                    gap="5px"
                    cursor={"pointer"}
                >
                    <input {...getInputProps()} />

                    {!loading ?
                        <>
                            <Icon
                                as={!file ? MdOutlineUploadFile : ImCloudUpload}
                                color={isDragActive ? "purewhite" : "font"}
                                boxSize="26px"
                            />

                            <Text
                                fontSize="18px"
                                fontWeight="400"
                                color={isDragActive ? "purewhite" : "font"}
                            >
                                {!file ? (
                                    !hasContent ? "Arrastra o presiona aqui para subir contenido" : "Arrastra o presiona aqui para editar el contenido" 
                                    ) : file?.name}
                            </Text>

                            {file &&
                                <Text
                                    textTransform="capitalize"
                                    fontSize="14px"
                                    fontWeight="400"
                                    color={"font"}
                                >
                                    {(file.size / (1024 * 1024)).toFixed(2)} MB | {file.type}
                                </Text>
                            }
                        </>
                        :
                        <Spinner
                            color="font"
                            size="lg"
                        />
                    }

                    {isDragActive &&
                        <Icon
                            as={MdOutlineUploadFile}
                            color={"purewhite"}
                            boxSize="250px"
                            pos="fixed"
                            left="50%"
                            top="50%"
                            transform="translate(-50%, -50%)"
                        />
                    }
                </Flex>
            </Flex>
        </Box>
    );
};

export default DropzoneComponent;