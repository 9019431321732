import { Flex, Skeleton, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { MdOutlineMarkEmailRead, MdOutlineMarkEmailUnread } from "react-icons/md"
import { TabLogs } from "./TabItems/TabLogs"
import { TabPendientes } from "./TabItems/TabPendientes"
import { Topbar } from "../../../shared/components/Topbar/Topbar"
import { TbSubtask } from "react-icons/tb";
import { TabTareas } from "./TabItems/TabTareas"
import { TabsComponentInt } from "../../../interfaces/ComponentsInt"
import { CustomTabs } from "@imaginagroup/bit-components.ui.custom-tabs"
import { EntityTypes, useQuerySession } from "../../../shared/hooks/useQuerySession"
import { customTabsStyles } from "../../../ui/bitStyles"

export const TabsEmails = () => {
    const [tabIndex, setTabIndex] = useState<number>(0)

    const handleTabsChange = (index: number) => setTabIndex(index)

    const tabs: TabsComponentInt[] = [
        {
            isAllowed: true,
            label: "Emails Enviados",
            icon: MdOutlineMarkEmailRead,
            content: <TabLogs />
        },
        {
            isAllowed: true,
            label: "Emails Programados",
            icon: MdOutlineMarkEmailUnread,
            content: <TabPendientes />
        },
        {
            isAllowed: true,
            label: "Tareas",
            icon: TbSubtask,
            content: <TabTareas />
        }
    ]

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Emails
                </Text>
            }
        >
            <Flex
                direction="column"
                p="30px"
            >

                <CustomTabs
                    ui={customTabsStyles}
                    tabs={tabs}
                    tabIndex={tabIndex}
                    handleTabsChange={handleTabsChange}
                />
            </Flex>
        </Topbar>
    )
}