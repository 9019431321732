export const customTabsStyles = {
    colorScheme: "black",
    backgroundTabsList: "#FFFFFF",
    colorFont: "font",
    borderColorTabsList: "#EAEAEA",
    showBorder: true,
    colorHover: 'main',
    backgroundHover: 'variant',
    colorSelected: 'main',
    backgroundSelected: 'variant',
    borderColorSelected: 'main',
    showBorderSelected: true,
    sizeIcon: "22px"
}