import {
    Flex,
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Icon,
    Skeleton,
} from '@chakra-ui/react'
import { CursosInt, LeccionInt, ModulosInt } from '../../../../interfaces/CursosInt';
import { truncate } from 'lodash';
import { BsPlayCircle } from 'react-icons/bs';
import { HiCheck, HiOutlineClipboard, HiOutlineDocumentDownload, HiOutlineDocumentText, HiOutlineLink, HiOutlinePaperClip, HiOutlinePencilAlt, HiOutlinePlay, HiOutlinePresentationChartLine, HiX } from 'react-icons/hi';
import { EntregableEstadoEnum } from '../../../../shared/utils/Types/EntregableEstadoEnum';
import { LeccionTipoEnum } from '../../../../shared/utils/Types/LeccionTipoEnum';

interface Props {
    curso: CursosInt;
    validateLeccionCompleted: (leccion: string | undefined, progresos: any) => boolean;
    progresos: any;
    validateEntregable: (leccion: string | undefined, data: { [key: string]: { estado: EntregableEstadoEnum | null; puntuacion: number | null; } }) => any;
    entregables: { [key: string]: { estado: EntregableEstadoEnum | null; puntuacion: number | null; } };
    loading: boolean;
}

export const AccordeonResumen = ({
    curso,
    validateLeccionCompleted,
    progresos,
    validateEntregable,
    entregables,
    loading
}: Props) => {
    const sortModulosByOrder = (Modulos: any[] | undefined) => {
        if (!Modulos) return []

        const sortedLecciones = Modulos.sort((a, b) => (a.orden > b.orden) ? 1 : (a.orden < b.orden) ? -1 : 0);

        return sortedLecciones
    };

    const sortLeccionesByOrder = (lecciones: any[] | undefined) => {
        if (!lecciones) return []

        const sortedLecciones = lecciones.sort((a, b) => (a.orden > b.orden) ? 1 : (a.orden < b.orden) ? -1 : 0);

        return sortedLecciones
    };

    return (
        (!loading) ?
            <Flex
                direction="column"
                border="1px solid"
                borderColor="light_grey"
                rounded="20px"
                overflow="hidden"
                w="100%"
                height="max-content"
                p="10px"
                bg={"purewhite"}
            >
                <Flex
                    w="100%"
                    transition="box-shadow 0.3s"
                    p="15px 20px"
                    pr="10px"
                    roundedTop="8px"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Text
                        color="font"
                        fontSize="18px"
                        fontWeight="600"
                    >
                        Contenido del curso
                    </Text>
                </Flex>
                <Accordion
                    overflow="auto"
                    className="scroll-bar"
                    allowMultiple
                    gap="20px"
                    display={"flex"}
                    flexDirection="column"
                    bg="purewhite"
                    maxH="calc(100vh - 395px)"
                >
                    {sortModulosByOrder(curso?.modulos)?.map((modulo: ModulosInt, index: any) => (
                        <AccordionItem
                            key={index}
                            border="none"
                        >
                            <>
                                <Flex>
                                    <AccordionButton
                                        display="flex"
                                        p="15px 20px"
                                        justifyContent="space-between"
                                        _hover={{ bg: '#EBF4FF' }}
                                        bg="purewhite"
                                    >
                                        <Flex
                                            flexDirection="column"
                                            alignItems="flex-start"
                                        >
                                            <Text
                                                color="font"
                                                fontSize="16px"
                                                fontWeight="500"
                                                lineHeight="100%"
                                                letterSpacing="-0.16px"
                                            >
                                                {truncate(modulo?.nombre, { length: 33 })}
                                            </Text>
                                        </Flex>


                                        <AccordionIcon />
                                    </AccordionButton>
                                </Flex>

                                <AccordionPanel
                                    display="flex"
                                    flexDir="column"
                                    p="0"
                                    bg="purewhite"
                                >
                                    <Flex
                                        flexDirection="column"
                                        w="100%"
                                    >
                                        {sortLeccionesByOrder(modulo?.lecciones)?.map((leccion: LeccionInt, index: number) => {
                                            return (
                                                <Flex
                                                    key={index}
                                                    justifyContent="space-between"
                                                    p="15px 20px"
                                                    cursor="pointer"
                                                    border={"none"}
                                                    _hover={{ bg: '#EBF4FF' }}
                                                    ml="40px"
                                                >
                                                    <Flex
                                                        alignItems="center"
                                                        gap="20px"
                                                    >
                                                        <Icon
                                                            as={
                                                                leccion?.tipo === LeccionTipoEnum.FICHERO
                                                                    ? HiOutlinePaperClip
                                                                    : leccion?.tipo === LeccionTipoEnum.ENTREGABLE
                                                                        ? HiOutlineClipboard
                                                                        : leccion?.tipo === LeccionTipoEnum.URL
                                                                            ? HiOutlineLink
                                                                            : leccion?.tipo === LeccionTipoEnum.TEXTO
                                                                                ? HiOutlineDocumentText
                                                                                : leccion?.tipo === LeccionTipoEnum.ZOOM
                                                                                    ? HiOutlinePlay
                                                                                    : HiOutlineDocumentDownload
                                                            }
                                                            color={"#6E727E"}
                                                            boxSize="16px"
                                                        />

                                                        <Text
                                                            color="#6E727E"
                                                            fontSize="16px"
                                                            fontWeight="400"
                                                            letterSpacing="-0.14px"
                                                            pl="5px"
                                                            alignItems="center"
                                                            gap="5px"
                                                        >
                                                            {leccion?.nombre}
                                                        </Text>

                                                        {leccion?.tipo === LeccionTipoEnum.ENTREGABLE && (
                                                            validateEntregable(leccion?.id, entregables).estado !== null &&
                                                            <Text
                                                                fontSize="12px"
                                                                fontWeight="500"
                                                                textTransform="uppercase"
                                                                rounded="8px"
                                                                p="5px 8px"
                                                                borderWidth="1px"
                                                                color={
                                                                    validateEntregable(leccion?.id, entregables)?.estado === EntregableEstadoEnum.ERROR ? 'fail_main'
                                                                        : validateEntregable(leccion?.id, entregables)?.estado === EntregableEstadoEnum.CORRECTO ? 'success_main'
                                                                            : 'secondary'
                                                                }
                                                                bg={
                                                                    validateEntregable(leccion?.id, entregables)?.estado === EntregableEstadoEnum.ERROR ? 'fail_bg'
                                                                        : validateEntregable(leccion?.id, entregables)?.estado === EntregableEstadoEnum.CORRECTO ? 'success_bg'
                                                                            : '#EEEDFA'
                                                                }
                                                                borderColor={validateEntregable(leccion?.id, entregables)?.estado === EntregableEstadoEnum.ERROR ? 'fail_variant'
                                                                    : validateEntregable(leccion?.id, entregables)?.estado === EntregableEstadoEnum.CORRECTO ? 'success_variant'
                                                                        : '#EEEDFA'

                                                                }

                                                                w="fit-content"
                                                            >
                                                                {
                                                                    validateEntregable(leccion?.id, entregables)?.estado === EntregableEstadoEnum.ERROR ? 'No superado'
                                                                        : validateEntregable(leccion?.id, entregables)?.estado === EntregableEstadoEnum.CORRECTO ? 'Superado'
                                                                            : "pendiente"
                                                                }
                                                            </Text>
                                                        )}
                                                    </Flex>

                                                    {
                                                        (leccion?.tipo === LeccionTipoEnum.ENTREGABLE && validateEntregable(leccion?.id, entregables).estado !== null) ?
                                                            (validateEntregable(leccion?.id, entregables).puntuacion === null ?
                                                                <Text
                                                                    fontSize="21px"
                                                                    fontWeight="700"
                                                                >
                                                                    -
                                                                </Text>
                                                                :
                                                                <Text
                                                                    color={validateEntregable(leccion?.id, entregables).estado === EntregableEstadoEnum.CORRECTO ? "#02B29F" : "#F44261"}
                                                                    fontSize="21px"
                                                                    fontWeight="700"
                                                                >
                                                                    {validateEntregable(leccion?.id, entregables).puntuacion}
                                                                </Text>
                                                            )
                                                            :
                                                            <Flex
                                                                bg={validateLeccionCompleted(leccion?.id, progresos) ? "#02B29F" : "#F44261"}
                                                                rounded="100%"
                                                                boxSize="24px"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <Icon
                                                                    color="purewhite"
                                                                    as={validateLeccionCompleted(leccion?.id, progresos) ? HiCheck : HiX}
                                                                />
                                                            </Flex>
                                                    }
                                                </Flex>
                                            )
                                        })}
                                    </Flex>
                                </AccordionPanel>
                            </>
                        </AccordionItem>
                    ))}
                </Accordion>
            </Flex >
            :
            <Flex
                w="100%"
            >
                <Skeleton height="924px" w="100%" rounded="20px" />
            </Flex>
    );
}