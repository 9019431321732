import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, Icon, Text, useDisclosure, useToast } from "@chakra-ui/react"
import { CursosInt, ModulosInt } from "../../../../../interfaces/CursosInt";
import { BsDot } from "react-icons/bs";
import { BiPlusCircle, BiSave, BiTrash } from "react-icons/bi";
import { CustomButton } from "../../../../../shared/components/CustomElements/CustomButton";
import { Form as FormikForm, Formik } from 'formik';
import { useRef, useState } from "react";
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { updateCurso } from "../../../../../shared/middlewares/cursos.middleware";
import { useClient } from "../../../../../shared/context/client.context";
import { VscOpenPreview } from "react-icons/vsc";
import { toastNotify } from "../../../../../shared/utils/functions/toastNotify";
import { handleErrors } from "../../../../../shared/utils/functions/handleErrors";
import { StatusEnumTypes } from "../../../../../shared/utils/Types/StatusEnumTypes";
import { TemarioPreviewModal } from "../../../components/modals/TemarioPreviewModal";

interface Props {
    curso: CursosInt | undefined;
    Refresh: () => void;
}

export const TabTemario = ({ curso, Refresh }: Props) => {
    const client = useClient();
    const [inTop, setInTop] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const toast = useToast();
    const { onOpen, onClose, isOpen } = useDisclosure();

    const handleScroll = (e: any) => {
        const element = e.target.scrollTop;

        if (element > 0) setInTop(true);
        else setInTop(false);
    }

    const sortByOrder = (object: any[] | undefined) => {
        if (!object) return []

        const sortedLecciones = object.sort((a, b) => (a.orden > b.orden) ? 1 : (a.orden < b.orden) ? -1 : 0);

        return sortedLecciones
    };

    const replaceName = (nombre: string, modulos: any[] | undefined, number: number) => {
        if (!nombre || !modulos) return ""

        if (modulos[0]?.nombre?.includes("Tema 0 -")) {
            return nombre?.replaceAll(`Tema ${number} - `, "")
        }

        return nombre?.replaceAll(`Tema ${number + 1} - `, "")
    }

    const transformTemario = (modulos: ModulosInt[] | undefined): { [key: string]: { tema: string; subtemas: string[] } } => {
        if (!modulos) return {}
        let temario = {}

        sortByOrder(modulos)?.map((modulo, index) => {
            const nombre = replaceName(modulo.nombre, modulos, index)

            const subtemas = modulo.lecciones?.map((leccion: any) => {
                return leccion.nombre
            })

            const key = String(index + 1)

            temario = {
                ...temario,
                [key]: {
                    tema: nombre,
                    subtemas: subtemas
                }
            }
        })

        return temario
    }

    const initialValues: any = {
        temario: curso?.temario ? curso.temario : transformTemario(curso?.modulos)
    }

    const submitTemario = async (values: any) => {
        setIsLoading(true)

        updateCurso({
            id: curso?.id,
            data: {
                temario: values.temario
            },
            client: client
        }).then(() => {
            toastNotify(toast, StatusEnumTypes.SUCCESS, "Temario actualizado correctamente")
            Refresh()
        }).catch((error: any) => {
            const errors = handleErrors(
                error?.response?.data?.errors,
                error?.response?.status
            )

            errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
        })
            .finally(() => setIsLoading(false))
    }

    const temarioRef = useRef<any>(null);

    const onEventPreview = (event: any) => {
        temarioRef.current = event;

        if (isOpen) onClose();
        onOpen();
    }

    return (
        <Flex pb="20px" gap="20px">
            <Flex
                direction="column"
                w="100%"
                bg="purewhite"
                border="1px solid"
                borderColor="light_grey"
                rounded="8px"
            >
                <Formik onSubmit={submitTemario} enableReinitialize initialValues={initialValues}>
                    {(formik) => {
                        const { handleSubmit, values } = formik;
                        return (
                            <FormikForm
                                onSubmit={handleSubmit}
                            >
                                <Flex
                                    direction="column"
                                >
                                    <Flex
                                        w="100%"
                                        boxShadow={inTop ? '0px 4px 18px rgba(18, 22, 37, 0.1)' : 'unset'}
                                        transition="box-shadow 0.3s"
                                        p="20px"
                                        pr="10px"
                                        roundedTop="8px"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Text
                                            color="main"
                                            fontSize="18px"
                                            fontWeight="600"
                                        >
                                            Temario
                                        </Text>

                                        <Flex
                                            gap="20px"
                                        >
                                            <Button
                                                bg="secondary"
                                                color="purewhite"
                                                rounded="8px"
                                                px="20px"
                                                py="10px"
                                                w={"fit-content"}
                                                h={"fit-content"}
                                                fontSize={"14px"}
                                                fontWeight={"500"}
                                                _hover={{ bg: 'variant' }}
                                                leftIcon={<Icon as={VscOpenPreview} boxSize="20px" />}
                                                onClick={() => onEventPreview(values?.temario)}
                                            >
                                                Previsualizar
                                            </Button>

                                            <Button
                                                type="submit"
                                                bg="secondary"
                                                color="purewhite"
                                                rounded="8px"
                                                px="20px"
                                                py="10px"
                                                w={"fit-content"}
                                                h={"fit-content"}
                                                fontSize={"14px"}
                                                fontWeight={"500"}
                                                _hover={{ bg: 'variant' }}
                                                leftIcon={<Icon as={BiSave} boxSize="20px" />}
                                                isLoading={isLoading}
                                            >
                                                Guardar
                                            </Button>
                                        </Flex>
                                    </Flex>


                                    <Accordion
                                        px={"10px"}
                                        py={"10px"}
                                        display="flex"
                                        flexDirection="column"
                                        gap="15px"
                                        allowToggle
                                        css={{
                                            '&::-webkit-scrollbar': {
                                                width: '0px',
                                            },
                                        }}
                                        overflow="auto"
                                        onScroll={handleScroll}
                                        h="calc(100vh - 380px)"
                                    >
                                        {
                                            Object.entries(values.temario).map(([key, value], index) => (
                                                <AccordionItem
                                                    key={index}
                                                    border="none"
                                                >
                                                    <Flex
                                                        w="100%"
                                                        gap="40px"
                                                        p="15px 20px"
                                                    >
                                                        <Flex
                                                            w="100%"
                                                        >
                                                            <FormInput
                                                                label={`Tema ${key}`}
                                                                name={`temario.${key}.tema`}
                                                                placeholder="Nombre del tema"
                                                                isRow
                                                                isBlockError
                                                                ui={{
                                                                    styleInput: {},
                                                                    styleLabel: {
                                                                        mt: "6px",
                                                                        minW: "70px",
                                                                        color: "font",
                                                                        fontSize: "16px",
                                                                        fontWeight: "700",
                                                                    }
                                                                }}
                                                            />
                                                        </Flex>

                                                        <AccordionButton
                                                            display="flex"
                                                            w="fit-content"
                                                            rounded="12px"
                                                            _hover={{ bg: 'variant' }}
                                                        >
                                                            <AccordionIcon />
                                                        </AccordionButton>
                                                    </Flex>

                                                    <AccordionPanel
                                                        display="flex"
                                                        flexDir="column"
                                                        py="10px"
                                                        pl="100px"
                                                        pr="110px"
                                                        gap="10px"
                                                    >
                                                        {(value as any)?.subtemas?.map((_item: string, i: number) => (
                                                            <Flex
                                                                key={i}
                                                            >
                                                                <Icon as={BsDot} boxSize="35px" />

                                                                <FormInput
                                                                    name={`temario.${key}.subtemas.${i}`}
                                                                    placeholder="Nombre del subtema"
                                                                />

                                                                <Box
                                                                    ml="10px"
                                                                >
                                                                    <CustomButton
                                                                        button={{
                                                                            leftIcon: {
                                                                                as: BiTrash,
                                                                                boxSize: "20px"
                                                                            },
                                                                            onClick: () => {
                                                                                const newSubtemas = (values.temario as any)[key].subtemas.filter((_item: string, index: number) => index !== i)

                                                                                formik.setFieldValue(`temario.${key}.subtemas`, newSubtemas)
                                                                            },
                                                                            isDisabled: false,
                                                                        }}
                                                                        tooltip={{
                                                                            label: "Eliminar subtema",
                                                                            hasArrow: true,
                                                                            isDisabled: false,
                                                                            placement: "bottom",
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </Flex>
                                                        ))}

                                                        <Flex
                                                            w="100%"
                                                            justifyContent="center"
                                                        >
                                                            <CustomButton
                                                                button={{
                                                                    label: "Agregar subtema",
                                                                    leftIcon: {
                                                                        as: BiPlusCircle,
                                                                        boxSize: "20px"
                                                                    },
                                                                    onClick: () => {
                                                                        formik.setFieldValue(`temario.${key}.subtemas`, [...(values.temario as any)[key].subtemas, ""])
                                                                    },
                                                                    isDisabled: false,
                                                                }}
                                                            />
                                                        </Flex>
                                                    </AccordionPanel>

                                                </AccordionItem>
                                            ))
                                        }
                                    </Accordion>
                                </Flex>
                            </FormikForm>
                        );
                    }}
                </Formik>
            </Flex>

            <TemarioPreviewModal
                isOpen={isOpen}
                onClose={onClose}
                temario={temarioRef.current}
            />
        </Flex >
    )
}