import { ColumnDate } from "../../../../../../shared/components/CustomColumns/ColumnDate";
import { ColumnProps } from "../../../../../../shared/components/Table/Table";
import { ColumnLabelText } from "@imaginagroup/bit-components.ui.columns.column-label-text";

export const ForoColumns = (): ColumnProps[] => {
    return [
        {
            header: 'Titulo',
            field: 'titulo',
            key: 'titulo',
            sortable: false,
            body: (rowData: any) => (
                <ColumnLabelText
                    label={rowData?.titulo}
                    text={rowData?.titulo}
                />
            )
        },
        {
            header: 'Alumno',
            field: 'user',
            key: 'user',
            sortable: false,
            body: (rowData: any) => (
                <ColumnLabelText
                    label={rowData?.user?.nombreCompleto}
                    text={rowData?.user?.nombreCompleto}
                />
            ),
        },
        {
            header: 'Curso',
            field: 'curso',
            key: 'curso',
            sortable: false,
            body: (rowData: any) => (
                <ColumnLabelText
                    label={rowData?.tema?.grupo?.curso?.nombre}
                    text={rowData?.tema?.grupo?.curso?.nombre}
                />
            ),
        },
        {
            header: 'Tutor',
            field: 'tutor',
            key: 'tutor',
            sortable: false,
            body: (rowData: any) => (
                <ColumnLabelText
                    label={rowData?.tema?.grupo?.tutores[0]?.nombreCompleto}
                    text={rowData?.tema?.grupo?.tutores[0]?.nombreCompleto}
                />
            ),
        },
        {
            header: 'Fecha',
            field: 'createdAt',
            key: 'createdAt',
            sortable: true,
            body: (rowData: any) => (
                <ColumnDate
                    date={rowData?.createdAt}
                />
            ),
        },
    ]
}