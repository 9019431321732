import {
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    Image,
    Text,
    Flex,
    Icon,
    Button,
    Progress,
    useToast,
    FormLabel,
    Box,
} from '@chakra-ui/react'
import { BiArrowBack, BiSolidInfoCircle } from 'react-icons/bi';
import portada from '../../../../assets/img/portada.png'
import icono from '../../../../assets/img/iconoReact.png'
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from 'yup';
import { useClient } from '../../../../shared/context/client.context';
import { useState } from 'react';
import { addCurso } from '../../../../shared/middlewares/cursos.middleware';
import { toastNotify } from '../../../../shared/utils/functions/toastNotify';
import { StatusEnumTypes } from '../../../../shared/utils/Types/StatusEnumTypes';
import { handleErrors } from '../../../../shared/utils/functions/handleErrors';
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { FormTextarea } from '@imaginagroup/bit-components.ui.form-textarea';
import { FilePond } from 'react-filepond';
import { useAuthContex } from '../../../../shared/context/auth.context';
import { updateUsuario } from '../../../../shared/middlewares/users.middleware';
import { useNavigate } from 'react-router-dom';
import { useUserPolicy } from '../../../../shared/hooks/PermissionPolicy';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    setRefresh: () => void;
}

export const NewCursoDrawer = ({ isOpen, onClose, setRefresh }: Props) => {
    const { user, refreshUser } = useAuthContex();
    const { total } = useUserPolicy();
    const client = useClient();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const toast = useToast();
    const [requireIcon, setRequireIcon] = useState<boolean>(true);
    const [requirePortada, setRequirePortada] = useState<boolean>(true);

    const initialValues: any = {
        nombre: '',
        icono: null,
        portada: null,
        descripcion: null,
    };

    const validationSchema = Yup.object().shape({
        nombre: Yup.string()
            .required('¡El título del curso es obligatorio!')
            .typeError('¡El título del curso es obligatorio.')
            .max(80, '¡El título del curso no puede tener más de 80 caracteres!'),
        icono: Yup.mixed()
            .required('¡El icono del curso es obligatorio!')
            .typeError('¡El icono del curso es obligatorio.'),
        portada: Yup.mixed()
            .required('¡La portada del curso es obligatorio!')
            .typeError('¡La portada del curso es obligatorio.'),
        descripcion: Yup.string()
            .required('¡La descripción del curso es obligatoria!')
            .typeError('¡La descripción del curso es obligatoria.')
            .max(500, '¡La descripción del curso no puede tener más de 500 caracteres!'),
    });

    const handleSubmit = (values: any) => {
        setIsLoading(true)

        const newCurso = {
            nombre: values.nombre,
            descripcion: values.descripcion,
            publicado: false,
            activo: true,
            imagen: values?.portada[0]?.file,
            icono: values?.icono[0]?.file,
        }

        addCurso({
            curso: newCurso,
            client: client
        })
            .then(async (response) => {
                const id = response?.data?.data?.id;

                if (!user?.config?.cursos && total) {
                    await updateUsuario({
                        id: user?.id as string,
                        client: client,
                        data: {
                            config: {
                                ...user?.config,
                                cursos: true
                            }
                        }
                    })
                        .then(() => {
                            refreshUser({
                                config: {
                                    ...user?.config,
                                    cursos: true
                                }
                            })
                        })
                }

                setRefresh();
                navigate(`/cursos/${id}`)
                handleClose();
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleClose = () => {
        onClose();
    }

    const handleShowFile = (file: File) => {
        if (file) {
            const portada = URL.createObjectURL(file)

            return portada
        }
    }

    return (
        <Drawer
            isOpen={isOpen}
            onClose={() => null}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            placement='right'
            size="full"
        >
            <DrawerOverlay />

            <DrawerContent>
                <DrawerBody
                    p="0"
                >
                    <Formik
                        onSubmit={handleSubmit}
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        style={{ width: "100", height: "100vh" }}
                    >
                        {(formik) => {
                            const { handleSubmit, values } = formik;

                            return (
                                <FormikForm
                                    onSubmit={handleSubmit}
                                    style={{ height: "100vh" }}
                                >
                                    <Flex
                                        h="full"
                                        w="full"
                                    >
                                        <Flex
                                            p="50px"
                                            bg="#F9FAFB"
                                            border="1px solid"
                                            borderColor="#F9FAFB"
                                            flex="1"
                                            direction="column"
                                        >
                                            <Icon as={BiArrowBack} boxSize="28px" onClick={handleClose} cursor="pointer" />

                                            <Flex
                                                w="full"
                                                h="full"
                                                flex="1"
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                gap="25px"
                                            >
                                                <Flex
                                                    shadow="0px 4.786px 11.966px 0px rgba(0, 69, 103, 0.25)"
                                                    h="fit-content"
                                                    w="420px"
                                                    direction="column"
                                                    bg="purewhite"
                                                >
                                                    {values?.portada && values?.portada.length > 0 ?
                                                        <Image
                                                            src={handleShowFile(values?.portada[0]?.file)}
                                                            w="full"
                                                            h="210px"
                                                            objectFit="cover"
                                                        />
                                                        :
                                                        <Image
                                                            src={portada}
                                                            w="full"
                                                            h="210px"
                                                            objectFit="cover"
                                                        />
                                                    }

                                                    <Flex
                                                        direction="column"
                                                        p="22px"
                                                    >
                                                        <Text
                                                            mb="7px"
                                                            color="font"
                                                            fontSize="16px"
                                                            fontWeight="600"
                                                        >
                                                            {values?.nombre ? values?.nombre : "React JS - Curso completo"}
                                                        </Text>

                                                        <Text
                                                            mb="16px"
                                                            fontSize="10px"
                                                            fontWeight="400"
                                                            color="#959596"
                                                        >
                                                            AF9G09
                                                        </Text>

                                                        <Flex
                                                            fontSize="10px"
                                                            fontWeight="400"
                                                            color="#959596"
                                                            alignItems="center"
                                                            gap="10px"
                                                        >
                                                            <Text>
                                                                2h 30m
                                                            </Text>

                                                            <Progress
                                                                value={60}
                                                                max={100}
                                                                w="90px"
                                                                rounded="50px"
                                                                h="5px"
                                                                sx={{ '& > div': { background: "secondary" } }}
                                                                bgColor="light_grey"
                                                            />

                                                            <Text>
                                                                60%
                                                            </Text>
                                                        </Flex>

                                                        <Text
                                                            color="font"
                                                            fontSize="11px"
                                                            fontWeight="400"
                                                            my="21px"
                                                        >
                                                            {values?.descripcion
                                                                ? values?.descripcion
                                                                : "React JS se trata de una librería de JavaScript de código abierto desarrollada por los ingenieros de Facebook con el objetivo de ofrecer los mejores estándares en experiencia de usuario. Su objetivo es ayudarte en el desarrollo de aplicaciones donde necesites usar datos que cambien todo el tiempo, siendo su uso sencillo, declarativo y fácil de combinar. Mediante este curso de React JS podrás aprender a realizar interfaces de usuario utilizando una de las herramientas más potentes y populares actualmente."
                                                            }
                                                        </Text>

                                                        <Button
                                                            mt="20px"
                                                            ml="auto"
                                                            fontSize="12px"
                                                            fontWeight="600"
                                                            lineHeight="22px"
                                                            rounded="10px"
                                                            px="11px"
                                                            py="7px"
                                                            h="fit-content"
                                                            w="fit-content"
                                                            color="purewhite"
                                                            bg="secondary"
                                                            _hover={{ bg: "variant" }}
                                                            onClick={() => null}
                                                        >
                                                            Continuar
                                                        </Button>
                                                    </Flex>
                                                </Flex>

                                                <Text>
                                                    Así lo verán los alumnos
                                                </Text>
                                            </Flex>
                                        </Flex>

                                        <Flex
                                            p="50px"
                                            bg="#F5F4FF"
                                            flex="1"
                                            w="full"
                                            h="full"
                                            direction="column"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Flex
                                                h="fit-content"
                                                w="100%"
                                                maxW="560px"
                                                direction="column"
                                                gap="10px"
                                            >
                                                <Text
                                                    fontSize="26px"
                                                    color="pureblack"
                                                    fontWeight="600"
                                                >
                                                    Introduzca los siguientes datos
                                                </Text>

                                                <Flex
                                                    direction="column"
                                                    gap="3px"
                                                >

                                                    <Flex>
                                                        <FormInput
                                                            name="nombre"
                                                            label="Titulo"
                                                            placeholder='Ej: React JS'
                                                            isRequired={!values?.nombre ? true : false}
                                                        />
                                                    </Flex>

                                                    <FormTextarea
                                                        name="descripcion"
                                                        label="Descripción"
                                                        placeholder='Ej: React JS se trata de una librería de JavaScript de código abierto desarrollada por los ingenieros de Facebook con el objetivo de ofrecer los mejores estándares en experiencia de usuario.'
                                                        isRequired={!values?.descripcion ? true : false}
                                                        ui={{
                                                            minHeigh: '200px',
                                                            styles: {
                                                                padding: '5px',
                                                                outlineColor: "secondary",
                                                            }
                                                        }}
                                                    />

                                                    <Flex
                                                        direction="column"
                                                    >
                                                        <FormLabel
                                                            display="flex"
                                                            gap="3px"
                                                            color="font"
                                                            fontSize="14px"
                                                            fontWeight="400"
                                                            textTransform="capitalize"
                                                        >
                                                            Icono
                                                        </FormLabel>

                                                        <Flex
                                                            gap="20px"
                                                            alignItems="start"
                                                        >
                                                            <Flex
                                                                w="120px"
                                                                rounded="10px"
                                                                bg="purewhite"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                                p="10px"
                                                            >
                                                                {values?.icono && values?.icono.length > 0 ?
                                                                    <Image
                                                                        src={handleShowFile(values?.icono[0]?.file)}
                                                                        m="auto"
                                                                        boxSize="55px"
                                                                        rounded="10px"
                                                                    />
                                                                    :
                                                                    <Image
                                                                        boxSize="55px"
                                                                        rounded="10px"
                                                                        src={icono}
                                                                    />
                                                                }
                                                            </Flex>

                                                            <Box
                                                                pos={"relative"}
                                                                w="100%"
                                                            >
                                                                <FilePond
                                                                    name='icono'
                                                                    files={values?.icono}
                                                                    allowReorder={true}
                                                                    onupdatefiles={(e) => {
                                                                        if (e.length > 0) {
                                                                            formik.setFieldValue("icono", e)
                                                                            setRequireIcon(false)
                                                                        } else {
                                                                            formik.setFieldValue("icono", null)
                                                                            setRequireIcon(true)
                                                                        }
                                                                    }}
                                                                    allowImagePreview={false}
                                                                    labelIdle='Adjuntar o deja caer tu archivo aquí'
                                                                    credits={false}
                                                                />

                                                                <Icon
                                                                    zIndex="999"
                                                                    display={requireIcon ? "block" : "none"}
                                                                    as={BiSolidInfoCircle}
                                                                    boxSize="15px"
                                                                    color={(formik?.touched["icono"] && formik.errors["icono"]) ? "red" : "font"}
                                                                    pos="absolute"
                                                                    right="-7px"
                                                                    top="-7px"
                                                                />
                                                            </Box>
                                                        </Flex>

                                                        <Box
                                                            h="21px"
                                                        >
                                                            {(formik?.touched["icono"] && formik.errors["icono"]) && (
                                                                <Text
                                                                    color="error"
                                                                    fontSize="14px"
                                                                >
                                                                    {formik.errors["icono"] ? formik.errors["icono"] as string : ""}
                                                                </Text>
                                                            )}
                                                        </Box>
                                                    </Flex>

                                                    <Flex
                                                        direction="column"
                                                    >
                                                        <FormLabel
                                                            display="flex"
                                                            gap="3px"
                                                            color="font"
                                                            fontSize="14px"
                                                            fontWeight="400"
                                                            textTransform="capitalize"
                                                        >
                                                            Portada
                                                        </FormLabel>

                                                        <Box
                                                            pos={"relative"}
                                                            w="100%"
                                                        >
                                                            <FilePond
                                                                name='portada'
                                                                files={values?.portada}
                                                                allowReorder={true}
                                                                onupdatefiles={(e) => {
                                                                    if (e?.length > 0) {
                                                                        formik.setFieldValue("portada", e)
                                                                        setRequirePortada(false)
                                                                    } else {
                                                                        formik.setFieldValue("portada", null)
                                                                        setRequirePortada(true)
                                                                    }
                                                                }}
                                                                allowImagePreview={false}
                                                                labelIdle='Adjuntar o deja caer tu archivo aquí'
                                                                credits={false}
                                                            />

                                                            <Icon
                                                                zIndex="999"
                                                                display={requirePortada ? "block" : "none"}
                                                                as={BiSolidInfoCircle}
                                                                boxSize="15px"
                                                                color={(formik?.touched["portada"] && formik.errors["portada"]) ? "red" : "font"}
                                                                pos="absolute"
                                                                right="-7px"
                                                                top="-7px"
                                                            />
                                                        </Box>

                                                        <Box
                                                            h="21px"
                                                        >
                                                            {(formik.errors["portada"]) && (
                                                                <Text
                                                                    color="error"
                                                                    fontSize="14px"
                                                                >
                                                                    {formik?.touched["portada"] && formik.errors["portada"] ? formik.errors["portada"] as string : ""}
                                                                </Text>
                                                            )}
                                                        </Box>
                                                    </Flex>
                                                </Flex>

                                                <Flex
                                                    mt="5px"
                                                    w="100%"
                                                    justifyContent="flex-end"
                                                >
                                                    <Button
                                                        isLoading={isLoading}
                                                        type="submit"
                                                        bg="secondary"
                                                        h="fit-content"
                                                        p="10px 25px"
                                                        rounded="5"
                                                        color="purewhite"
                                                        fontSize="18px"
                                                        fontWeight="500"
                                                        _hover={{ color: "purewhite", backgroundColor: "variant" }}
                                                    >
                                                        Continuar
                                                    </Button>
                                                </Flex>

                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </FormikForm>
                            );
                        }}
                    </Formik>
                </DrawerBody>
            </DrawerContent >
        </Drawer >
    );
}