import { Flex, Tooltip } from "@chakra-ui/react";
import { CursosInt, LeccionInt, ModulosInt } from "../../../../../interfaces/CursosInt";
import { EditModule } from "../../../components/utils/EditModule";
import { useState } from "react";
import { SkeletonContenido } from "../../../components/utils/CursosContenidoSkeleton";
import { DndAcordeon } from "../../../components/DndAcordeon/Contenidos";
import { AddLeccion } from "../../../components/utils/AddLeccion";
import { EditLeccion } from "../../../components/utils/EditLeccion";

export enum TipoSelect {
    MODULO = "MODULO",
    LECCION = "LECCION",
    NUEVA_LECCION = "NUEVA_LECCION"
}

interface Props {
    curso: CursosInt | undefined;
    setRefreshCurso: () => void;
    loading?: boolean
}

export const TabContenidoCurso = ({ curso, setRefreshCurso, loading }: Props) => {
    const [refreshData, setRefreshData] = useState<{
        leccion: boolean,
        modulo: boolean
    }>({
        leccion: false,
        modulo: false
    })
    const [selected, setSelected] = useState<{
        type: TipoSelect,
        data: ModulosInt | LeccionInt | null
    }>()

    return (
        loading ?
            <SkeletonContenido />
            :
            <Flex
                gap="30px"
                h={"calc(100vh - 170px)"}
            >
                <Flex
                    position={"relative"}
                    opacity={selected?.type === TipoSelect.NUEVA_LECCION ? 0.5 : 1}
                >
                    <DndAcordeon
                        selected={selected}
                        setSelected={setSelected}
                        curso={curso}
                        refreshData={refreshData}
                        setRefreshData={setRefreshData}
                        setRefreshCurso={setRefreshCurso}
                    />

                    <Tooltip
                        label="Primero debes guardar la lección actual."
                        hasArrow
                        placement="right-start"
                        fontSize="14px"
                        color={"purewhite"}
                        bg="pureblack"
                        rounded="6px"
                        py="10px"
                        px="15px"
                        textAlign="center"
                        isDisabled={selected?.type !== TipoSelect.NUEVA_LECCION}
                    >
                        <Flex
                            display={selected?.type === TipoSelect.NUEVA_LECCION ? "block" : "none"}
                            pos={"absolute"}
                            bottom={"0"}
                            right={"0"}
                            top={"0"}
                            left={"0"}
                            bg={"transparent"}
                            cursor={"not-allowed"}
                        />
                    </Tooltip>
                </Flex>

                <Flex
                    flex="1"
                    css={{
                        '&::-webkit-scrollbar': {
                            width: '5px',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#f1f1f1',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#c7c7c7',
                            borderRadius: '5px',
                        },
                        scrollBehavior: 'smooth',
                    }}
                    p="10px"
                    overflow="auto"
                    h="calc(100vh - 170px)"
                >
                    {selected?.type === TipoSelect.MODULO &&
                        <EditModule
                            modulo={selected?.data as ModulosInt}
                            setRefreshData={setRefreshData}
                        />
                    }

                    {selected?.type === TipoSelect.LECCION &&
                        <EditLeccion
                            leccion={selected?.data as LeccionInt}
                            setRefreshData={setRefreshData}
                        />
                    }

                    {selected?.type === TipoSelect.NUEVA_LECCION &&
                        <AddLeccion
                            data={selected?.data as Partial<LeccionInt>}
                            setRefreshData={setRefreshData}
                            setSelected={setSelected}
                        />
                    }
                </Flex>
            </Flex>
    );
}