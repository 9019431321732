import { Flex, Text, Tooltip } from "@chakra-ui/react"
import { ColumnProps } from "../../../../shared/components/Table/Table"
import { MatriculasInt } from "../../../../interfaces/MatriculasInt"
import { truncate } from "lodash"
import { ColumnAvatarLabelText } from "@imaginagroup/bit-components.ui.columns.column-avatar-label-text"
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text"
import { ColumnBadge } from "@imaginagroup/bit-components.ui.columns.column-badge"
import { CustomAvatar } from "../../../../shared/components/Avatar/CustomAvatar"

export const DatosColumns = (): ColumnProps[] => {
    return [
        {
            header: 'Nombre',
            field: 'nombre',
            key: 'nombre',
            sortable: true,
            body: (rowData: any) => (
                <ColumnAvatarLabelText
                    label={rowData?.nombre + " " + rowData?.apellidos}
                    customIcon={
                        <CustomAvatar name={rowData?.previo?.nombre + rowData?.previo?.apellidos} src={rowData?.previo?.avatar?.url} size="35px" />
                    }
                />
            ),
        },
        {
            header: 'Email',
            field: 'email',
            key: 'email',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.previo?.email}
                />
            ),
        },
        {
            header: 'pendiente',
            field: 'pendiente',
            key: 'pendiente',
            body: (rowData: any) => (
                <ColumnBadge
                    text={rowData?.pendiente ? "Si" : "No"}
                    ui={{
                        colorFont: rowData?.pendiente ? "success_main" : "fail_main",
                        backgroundColor: rowData?.pendiente ? "success_bg" : "fail_bg",
                        fontSize: "10px",
                        fontWeight: 500,
                        rounded: "14px",
                        styles: {
                            borderColor: rowData?.pendiente ? "success_variant" : "fail_variant",
                            borderWidth: "1px",
                            letterSpacing: "0.2px",
                        }
                    }}
                />
            )
        },
        {
            header: 'aceptado',
            field: 'aceptado',
            key: 'aceptado',
            body: (rowData: any) => (
                <ColumnBadge
                    text={rowData?.aceptado ? "Si" : "No"}
                    ui={{
                        colorFont: rowData?.aceptado ? "success_main" : "fail_main",
                        backgroundColor: rowData?.aceptado ? "success_bg" : "fail_bg",
                        fontSize: "10px",
                        fontWeight: 500,
                        rounded: "14px",
                        styles: {
                            borderColor: rowData?.aceptado ? "success_variant" : "fail_variant",
                            borderWidth: "1px",
                            letterSpacing: "0.2px",
                        }
                    }}
                />
            )
        },
        {
            header: "grupos",
            field: "grupos",
            key: "grupos",
            body: (rowData: any) => (
                <Flex
                    align="center"
                    fontSize="13px"
                    fontWeight="500"
                    letterSpacing="0.26p"
                    textTransform="uppercase"
                    rounded="5px"
                    p="5px 10px"
                    color="font"
                    overflowX="auto"
                    maxW="300px"
                    css={{
                        '&::-webkit-scrollbar': {
                            height: '8px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: "light_grey",
                        },
                    }}
                >
                    {rowData?.user?.matriculas?.length > 0 ? (
                        <Flex gap="5px">
                            {rowData?.user?.matriculas?.map((matricula: MatriculasInt, index: number) => (
                                <Tooltip
                                    label={matricula?.estado === "0" ? "Activo" : matricula?.estado === "1" ? "Próximo" : "Inactivo"}
                                    hasArrow
                                    key={index}>
                                    <Text
                                        color={
                                            matricula?.estado === "0" ? "success_main" :
                                                matricula?.estado === "1" ? "secondary" :
                                                    matricula?.estado === "2" ? "dark_grey" :
                                                        "black"
                                        }
                                        bg={
                                            matricula?.estado === "0" ? "success_bg" :
                                                matricula?.estado === "1" ? "variant" :
                                                    matricula?.estado === "2" ? "white" :
                                                        "black"
                                        }
                                        borderColor={
                                            matricula?.estado === "0" ? "success_variant" :
                                                matricula?.estado === "1" ? "variant" :
                                                    matricula?.estado === "2" ? "light_grey" :
                                                        "transparent"
                                        }
                                        borderWidth="1px"
                                        rounded="14px"
                                        p="5px 8px"
                                        whiteSpace="nowrap"
                                    >
                                        {truncate(matricula?.grupo?.nombre, { length: 8 })}
                                    </Text>
                                </Tooltip>
                            ))}
                        </Flex>
                    ) : (
                        <Text>--</Text>
                    )}
                </Flex>
            )
        },
    ]
}