import React, { useState, useRef } from 'react';
import { Chrome } from '@uiw/react-color';
import { Box, Button, Flex, Text, useOutsideClick } from '@chakra-ui/react';


interface ColorPickerProps {
    initialColor?: string;
    main: string;
    secondary: string;
    variant: string;
    onChange?: any;
    isConfig?: boolean;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ main, secondary, variant, initialColor = '#8694FF', onChange, isConfig = false }) => {
    const [color, setColor] = useState<string>(initialColor);
    const [showPicker, setShowPicker] = useState<boolean>(false);
    const pickerRef = useRef<HTMLDivElement>(null);

    const variants = [main, secondary, variant]

    const handleChange = (newColor: string) => {
        setColor(newColor);
        if (onChange) {
            onChange(newColor);
        }
    };

    useOutsideClick({
        ref: pickerRef,
        handler: () => setShowPicker(false),
    });

    return (
        <Box
            position="relative"
            mt={`${isConfig ? '0' : '20px'}`}
        >
            <Flex
                direction={`${isConfig ? 'row' : 'column'}`}
                gap="20px"
                alignItems={`${isConfig ? 'center' : ''}`}
            >
                <Text
                    display="flex"
                    gap="3px"
                    color="font"
                    fontSize="14px"
                    fontWeight="400"
                >
                   {isConfig ? "Color Base" : "Colores"}
                </Text>
                <Flex
                    gap="10px"
                    alignItems="center"
                >
                    <Box
                        p="5px"
                        bg="white"
                        w="112px"
                        rounded="10px"
                        border="1px solid"
                        borderColor="gray.200"
                        position="relative"
                    >
                        <Button
                            onClick={() => setShowPicker(!showPicker)}
                            width="100px"
                            height="30px"
                            backgroundColor={color}
                            border="1px solid #ddd"
                            cursor="pointer"
                            _hover={{ opacity: 0.8 }}
                            p={0}
                        />

                        {showPicker && (
                            <Box
                                ref={pickerRef}
                                position="absolute"
                                top="40px"
                                zIndex={999}
                            >
                                <Chrome
                                    color={color}
                                    onChange={(color) => handleChange(color.hex)}
                                />
                            </Box>
                        )}
                    </Box>
                    <Text
                        display="flex"
                        color="font"
                        fontSize="12px"
                        fontWeight="400"
                    >
                        {isConfig ? "" : "Selecciona el color principal para tu campus"}
                    </Text>
                </Flex>

                <Flex
                    direction="column"
                    gap="10px"
                >
                    <Text
                        display="flex"
                        gap="3px"
                        color="font"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        Colores de tu campus
                    </Text>

                    <Flex gap="5px">
                        {variants.map((variant, index) => (
                            <Box
                                key={index}
                                width="full"
                                height="20px"
                                backgroundColor={variant}
                                border="1px solid #ddd"
                                rounded="10px"
                                minW={`${isConfig ? '120px' : ''}`}
                            />
                        ))}
                    </Flex>
                </Flex>


            </Flex>
        </Box>
    );
};

export default ColorPicker;
