import _axios, { ClientInt } from "../services/http.service";

import tenantInstance from '../services/tenantInstance.service';

export const uploadAlumnosExcel = async ({ adjunto, client } : { adjunto: File, client: ClientInt }) => {    
    if(!client) return;
    const tenant = await tenantInstance();

    const form = new FormData()

    form.append('fichero', adjunto)
    
    return _axios.post(
        `${tenant}/${client}/import/excel/alumnos`,
        form,
        {}
    )
};

export const uploadMatriculasExcel = async ({ adjunto, client,  query = "" }: { adjunto: File, client: ClientInt, query?: string }) => {    
    if(!client) return;
    const tenant = await tenantInstance();

    const form = new FormData()

    form.append('fichero', adjunto)

    return _axios.post(
        `${tenant}/${client}/import/excel/matriculas${query}`,
        form,
        {}
    )
};

export const addAlumnosImport = async ({ data, client } : { data: any, client: ClientInt }) => {    
    if(!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/import/wizard/alumnos`,
        {users: data},
        {}
    )
};

export const uploadMatriculasImport = async ({ data, client } : { data: any, client: ClientInt }) => {
    if(!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/import/wizard/matriculas`,
        {users: data},
        {}
    )    
};

export const addLeccionesImport = async ({ data, client } : { data: any, client: ClientInt }) => {
    if(!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/import/wizard/lecciones`,
        {lecciones: data},
        {}
    )    
};