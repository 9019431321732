import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { BiAlarm, BiDownload, BiQuestionMark, BiTaskX, BiWrench } from "react-icons/bi";
import { useClient } from "../../../shared/context/client.context";
import { useNavigate } from "react-router-dom";
import { useData } from "../../../shared/hooks/useData";
import { EndpointTypes } from "../../../shared/utils/Types/EndpointTypes";
import { HiOutlineClipboardList } from "react-icons/hi";
import { EntityTypes, useQuerySession } from "../../../shared/hooks/useQuerySession";

export const Pendiente = () => {
  const { handleSetQuery: setQueryGrupos } = useQuerySession({ entity: EntityTypes.GRUPOS });
  const { handleSetQuery: setQueryMatriculas } = useQuerySession({ entity: EntityTypes.MATRICULAS });

  const client = useClient();
  const { data: stats } = useData({
    client: client,
    endpoint: EndpointTypes.STATS_PENDIENTES
  })
  const navigate = useNavigate();

  const onClickGruposMediaConfig = () => {
    setQueryGrupos({
      page: 1,
      porConfigurar: "true",
      fundae: "true",
    },
      `/grupos`
    )
  }

  const onClickGruposAlumnosDesviados = () => {
    setQueryMatriculas({
      page: 1,
      conDesviacion: "true",
      fundae: "true",
      estado: "0"
    },
      `/matriculas`
    )
  }

  const onClickAlumnosSinAcceder = () => {
    setQueryMatriculas({
      page: 1,
      sinAcceder: "true",
      fundae: "true",
      estado: "0",
      deBaja: "false"
    },
      `/matriculas`
    )
  }

  const onClickCambioDatos = () => navigate(`/datos`);

  return (

    <Flex
      bgColor="purewhite"
      rounded="14px"
      boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.01)"
      border="1px solid"
      borderColor="#DFE2E6"
      w="100%"
      p="24px"
    >
      <Flex
        direction="column"
        w="100%"
        gap={"28px"}
      >
        <Flex
          direction="row"
          justifyContent="space-between"
        >
          <Flex
            direction="column"
            gap="15px"
            justify={"space-between"}
          >
            <Flex
              pl={"10px"}
              align="center"
              gap="12px"
            >
              <Flex
                justify="center"
                align="center"
                borderRadius="4px"
                bgColor="#F9FAFB"
                boxSize="28px"
              >
                <Icon
                  color="#616675"
                  boxSize="18px"
                  as={HiOutlineClipboardList}
                />
              </Flex>

              <Text
                fontSize="18px"
                color="font"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="120%"
              >
                Pendiente de gestión
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          justify="space-between"
          direction="column"
          flex="1"
        >
          <Flex
            gap="20px"
            direction="column"
            flex="1"
            pb="30px"
          >
            <Text
              pl={"10px"}
              fontSize="14px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="120%"
              mt="10px"
              color="#616675"
            >
              Inicios
            </Text>
            <Flex
              flex="1"
              direction="column"
              justify="space-between"
            >
              <Flex
                rounded="5px"
                transition="all 0.15s ease-in-out"
                _hover={{ bg: "main_variant" }}
                padding={"10px"}
                cursor="pointer"
                gap="20px"
                alignItems="center"
                onClick={onClickCambioDatos}
                justify="space-between"
              >
                <Flex
                  gap="10px"
                  cursor="pointer"
                >

                  <Flex
                    boxSize="25px"
                    align="center"
                    justify="center"
                    border="solid"
                    borderColor="#DFE2E6"
                    borderWidth="1px"
                    rounded="4px"
                  >
                    <Icon
                      color="#616675"
                      as={BiQuestionMark}
                      boxSize="18px"
                    />
                  </Flex>


                  <Text
                    color="font"
                    fontSize="14px"
                    fontStyle="normal"
                    fontWeight="500"
                    lineHeight="150%"
                  >
                    Solicitudes de cambio de datos
                  </Text>
                </Flex>

                <Flex
                >
                  <Text
                    fontSize="15px"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="140%"
                    color="main"
                  >
                    {stats?.solicitudesCambioDatos}
                  </Text>
                </Flex>
              </Flex>

              <Box borderWidth="1px" borderColor="#F9FAFB" />

              <Flex
                gap="20px"
                alignItems="center"
                rounded="5px"
                transition="all 0.15s ease-in-out"
                _hover={{ bg: "main_variant" }}
                padding={"10px"}
                cursor="pointer"
                justify="space-between"
              >
                <Flex
                  gap="10px"
                  onClick={onClickGruposMediaConfig}
                >
                  <Flex
                    boxSize="25px"
                    align="center"
                    justify="center"
                    border="solid"
                    borderColor="#DFE2E6"
                    borderWidth="1px"
                    rounded="4px"
                  >
                    <Icon
                      color="#616675"
                      as={BiWrench}
                      boxSize="15px"
                    />
                  </Flex>


                  <Text
                    color="font"
                    fontSize="14px"
                    fontStyle="normal"
                    fontWeight="500"
                    lineHeight="150%"
                  >
                    Grupos a media configuración
                  </Text>
                </Flex>

                <Flex>
                  <Text
                    fontSize="15px"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="140%"
                    color="main"
                  >
                    {stats?.gruposPorConfigurar}
                  </Text>
                </Flex>
              </Flex>

              <Box borderWidth="1px" borderColor="#F9FAFB" />

              <Flex
                gap="20px"
                alignItems="center"
                rounded="5px"
                transition="all 0.15s ease-in-out"
                _hover={{ bg: "main_variant" }}
                padding={"10px"}
                cursor="pointer"
                justify="space-between"
              >
                <Flex gap="10px" onClick={onClickAlumnosSinAcceder} cursor="pointer">


                  <Flex
                    boxSize="25px"
                    align="center"
                    justify="center"
                    border="solid"
                    borderColor="#DFE2E6"
                    borderWidth="1px"
                    rounded="4px"
                  >
                    <Icon
                      color="#616675"
                      as={BiTaskX}
                      boxSize="18px" />
                  </Flex>



                  <Text
                    color="font"
                    fontSize="14px"
                    fontStyle="normal"
                    fontWeight="500"
                    lineHeight="150%"
                  >
                    Grupos con alumnos que no han iniciado el curso
                  </Text>
                </Flex>

                <Flex>
                  <Text
                    fontSize="15px"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="140%"
                    color="main"
                  >
                    {stats?.alumnosMatriculasSinAcceder}
                  </Text>
                </Flex>
              </Flex>

              <Box borderWidth="1px" borderColor="#F9FAFB" />

              <Flex
                gap="20px"
                alignItems="center"
                rounded="5px"
                transition="all 0.15s ease-in-out"
                _hover={{ bg: "main_variant" }}
                padding={"10px"}
                cursor="pointer"
                justify="space-between"
              >
                <Flex
                  gap="10px"
                  onClick={onClickGruposAlumnosDesviados}
                  cursor="pointer"
                >
                  <Flex
                    boxSize="25px"
                    align="center"
                    justify="center"
                    border="solid"
                    borderWidth="1px"
                    borderColor="#DFE2E6"
                    rounded="4px"
                  >
                    <Icon
                      color="#616675"
                      as={BiAlarm}
                      boxSize="18px"
                    />
                  </Flex>


                  <Text
                    color="font"
                    fontSize="14px"
                    fontStyle="normal"
                    fontWeight="500"
                    lineHeight="150%"
                  >
                    Matriculas con desviación
                  </Text>
                </Flex>

                <Flex>
                  <Text
                    fontSize="15px"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="140%"
                    color="main"
                  >
                    {stats?.alumnosConDesviacion}
                  </Text>

                  <Text
                    fontSize="2px"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="140%"
                  >
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex gap="20px" direction="column">

            <Text
              pl={"10px"}
              fontSize="14px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="120%"
              color="#616675"
            >
              Finalizaciones
            </Text>

            <Flex
              gap="20px"
              alignItems="center"
              rounded="5px"
              transition="all 0.15s ease-in-out"
              _hover={{ bg: "main_variant" }}
              padding={"10px"}
              cursor="pointer"
              justify="space-between"
            >
              <Flex gap="10px" cursor="pointer" >
                <Flex
                  boxSize="25px"
                  align="center"
                  justify="center"
                  border="solid"
                  borderColor="#DFE2E6"
                  borderWidth="1px"
                  rounded="4px"
                >
                  <Icon
                    color="#616675"
                    as={BiDownload}
                    boxSize="18px"
                  />
                </Flex>


                <Text
                  color="font"
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="150%"
                >
                  Descargar pendientes de documentación
                </Text>
              </Flex>

              <Flex>
                <Text
                  fontSize="15px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="140%"
                  color="main"
                >
                  {/* {pendientes} */}
                  0
                </Text>
              </Flex>

            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
