import {
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    Image,
    Text,
    Flex,
    useToast,
} from '@chakra-ui/react'
import { EmpresasInt } from '../../../interfaces/EmpresasInt';
import { InformationInput } from '@imaginagroup/bit-components.ui.elements.information-input';
import { useClient } from '../../../shared/context/client.context';
import { useUserPolicy } from '../../../shared/hooks/PermissionPolicy';
import { toastNotify } from '../../../shared/utils/functions/toastNotify';
import { StatusEnumTypes } from '../../../shared/utils/Types/StatusEnumTypes';
import { InformationFilepond } from '../../../shared/components/Information/InformationFilepond';
import { defaultIcon } from '../../Onboarding/components/OnboardingForm';
import { updateEmpresa } from '../../../shared/middlewares/empresas.middleware';
import { handleErrors } from '../../../shared/utils/functions/handleErrors';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    empresa: EmpresasInt | null;
    Refresh: () => void;
}

export const EditEmpresaDrawer = ({ empresa, isOpen, onClose, Refresh }: Props) => {
    const { gestion } = useUserPolicy();
    const client = useClient();
    const toast = useToast();

    const updateValue = (value: any) => {
        if (!empresa?.id) return;

        return updateEmpresa({
            id: empresa?.id,
            data: value,
            client: client
        })
            .then(() => {
                Refresh()
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    };

    return (
        <Drawer
            isOpen={isOpen}
            onClose={onClose}
            placement='right'
        >
            <DrawerOverlay />

            <DrawerContent
                maxW="50vw"
                minW="50vw"
                w="50vw"
            >
                <DrawerBody
                    className='scroll-bar'
                >
                    <Flex
                        direction="column"
                        gap="50px"
                        px="10px"
                    >
                        <Text
                            color={"font"}
                            fontSize="20px"
                            fontWeight="600"
                            mt="20px"
                        >
                            Datos de la empresa
                        </Text>

                        <Flex
                            direction="column"
                            gap="40px"
                        >
                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="nombre"
                                    label="Nombre"
                                    defaultValue={empresa?.nombre || ""}
                                    updateValue={updateValue}
                                    isRequired={true}
                                />
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="cif"
                                    label="cif"
                                    defaultValue={empresa?.cif || ""}
                                    updateValue={updateValue}
                                    isRequired={true}
                                />
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="email"
                                    label="Email"
                                    defaultValue={empresa?.email}
                                    updateValue={updateValue}
                                />
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="numeroExpediente"
                                    label="Nº de expediente"
                                    defaultValue={empresa?.numeroExpediente || ""}
                                    updateValue={updateValue}
                                    isRequired={true}
                                />
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="telefono"
                                    label="teléfono"
                                    defaultValue={empresa?.telefono || ""}
                                    updateValue={updateValue}
                                />
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="sector"
                                    label="sector"
                                    defaultValue={empresa?.sector || ""}
                                    updateValue={updateValue}
                                />
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="web"
                                    label="web"
                                    defaultValue={empresa?.web || ""}
                                    updateValue={updateValue}
                                />
                            </Flex>

                            <Flex
                                alignItems="center"
                            >
                                <Flex
                                    flex="1"
                                >
                                    {empresa?.icono?.url ?
                                        <Image
                                            m="auto"
                                            boxSize="75px"
                                            rounded="10px"
                                            src={empresa?.icono?.url}
                                        />
                                        :
                                        <Image
                                            src={`data:image/svg+xml;utf8,${defaultIcon}`}
                                            m="auto"
                                            boxSize="75px"
                                            rounded="10px"
                                        />
                                    }
                                </Flex>

                                <Flex
                                    flex="1"
                                >
                                    <InformationFilepond
                                        noHead={true}
                                        name="icono"
                                        label="Icono"
                                        putEP={`${client}/empresas/${empresa?.id}`}
                                        isDisabled={!empresa?.id || gestion}
                                        setRefresh={Refresh}
                                        labelIdle="Arrastra o selecciona el icono"
                                    />
                                </Flex>
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    name="direccion"
                                    label="Dirección"
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    defaultValue={empresa?.extraInfo?.direccion || ""}
                                    updateValue={async (values: any) => updateValue({ extraInfo: { ...empresa?.extraInfo, ...values } })}
                                />
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    name="horarios"
                                    label="Horario"
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    defaultValue={empresa?.extraInfo?.horarios || ""}
                                    updateValue={async (values: any) => updateValue({ extraInfo: { ...empresa?.extraInfo, ...values } })}
                                />
                            </Flex>

                            <Flex
                                alignItems="center"
                            >
                                <Flex
                                    flex="1"
                                >
                                    {empresa?.imagen?.url ?
                                        <Image
                                            m="auto"
                                            h="75px"
                                            rounded="10px"
                                            src={empresa?.imagen?.url}
                                        />
                                        :
                                        <Image
                                            src={`data:image/svg+xml;utf8,${defaultIcon}`}
                                            m="auto"
                                            h="75px"
                                            rounded="10px"
                                        />
                                    }
                                </Flex>

                                <Flex
                                    flex="1"
                                >
                                    <InformationFilepond
                                        noHead={true}
                                        name="imagen"
                                        label="Firma"
                                        putEP={`${client}/empresas/${empresa?.id}`}
                                        isDisabled={!empresa?.id || gestion}
                                        setRefresh={Refresh}
                                        labelIdle="Arrastra o selecciona la firma"
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </DrawerBody>
            </DrawerContent>
        </Drawer >
    );
}