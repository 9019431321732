import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, ModalFooter, Button, useToast, Box, useDisclosure, ModalCloseButton } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { useEffect, useState } from "react";
import { FormAsyncSelect } from "@imaginagroup/bit-components.ui.form-async-select";
import { useClient } from "../../../shared/context/client.context";
import tenantInstance from "../../../shared/services/tenantInstance.service";
import { toastNotify } from "../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../shared/utils/Types/StatusEnumTypes";
import { handleErrors } from "../../../shared/utils/functions/handleErrors";
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { FormSelect } from "@imaginagroup/bit-components.ui.form-select";
import { TipoPlantillasEnum } from "../../../shared/utils/Types/PlantillasTypesEnum";
import { UserInt } from "../../../interfaces/UserInt";
import { MatriculasInt } from "../../../interfaces/MatriculasInt";
import { sendTestPlantilla } from "../../../shared/middlewares/plantillas.middleware";
import { updateUsuario } from "../../../shared/middlewares/users.middleware";
import { useAuthContex } from "../../../shared/context/auth.context";
import { ModalFinalConfig } from "../../../shared/components/Modals/ModalFinalConfig";
import { formSelectStyles } from "../../../ui/formSelectStyles";
import { useUserPolicy } from "../../../shared/hooks/PermissionPolicy";
import { getData } from "../../../shared/middlewares/middlewares";


interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export const TestPlantillasModal = ({ isOpen, onClose }: Props) => {
    const { user, refreshUser } = useAuthContex();
    const client = useClient();
    const toast = useToast();
    const { total } = useUserPolicy();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>();
    const [optionsMatriculas, setOptionsMatriculas] = useState<{ value: string; label: string; }[]>([]);
    const { onOpen: onOpenFinalConfig, onClose: onCloseFinalConfig, isOpen: isOpenFinalConfig } = useDisclosure();

    useEffect(() => {
        if (userId)
            loadMatriculasAlumno(userId)
                .then((response: any) => setOptionsMatriculas(response))

    }, [userId])

    const initialValues = {
        userId: null,
        matriculaId: null,
        plantilla: null,
        email: null,
        porcentaje: null,
        semana: null,
    };

    const validationSchema = Yup.object({
        userId: Yup.string()
            .required("El usuario es requerido")
            .typeError("El usuario es requerido"),
        matriculaId: Yup.string()
            .required("La matricula es requerida")
            .typeError("La matricula es requerida"),
        plantilla: Yup.string()
            .required("La plantilla es requerida")
            .typeError("La plantilla es requerida"),
        email: Yup.string()
            .email("El email no es válido")
            .required("El email es requerido")
            .typeError("El email es requerido"),
        porcentaje: Yup.number().when('plantilla', {
            is: (plantilla: TipoPlantillasEnum) => plantilla === TipoPlantillasEnum.CONTROL,
            then: (validationScheme) => validationScheme
                .required("El porcentaje es requerido")
                .typeError("El porcentaje es requerido")
                .nullable(),
            otherwise: (validationScheme) => validationScheme
                .notRequired()
                .nullable(),
        }),
        semana: Yup.number().when('plantilla', {
            is: (plantilla: TipoPlantillasEnum) => plantilla !== TipoPlantillasEnum.TUTOR,
            then: (validationScheme) => validationScheme
                .notRequired()
                .nullable(),
            otherwise: (validationScheme) => validationScheme
                .required("La semana es requerida")
                .typeError("La semana es requerida")
        }),
    });

    const handleSubmit = async (values: any) => {
        setIsLoading(true)

        const plantilla = {
            userId: values.userId,
            matriculaId: values.matriculaId,
            plantilla: values.plantilla,
            email: values.email,
            datos: {
                porcentaje: Number(values.porcentaje),
                semana: Number(values.semana)
            }
        }

        sendTestPlantilla({
            client: client,
            data: plantilla
        })
            .then(() => toastNotify(toast, StatusEnumTypes.SUCCESS, `Email enviado correctamente a ${values.email}`))
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(async () => {
                setIsLoading(false)
                handleClose()

                if (!user?.config?.plantillas && total) {
                    await updateUsuario({
                        id: user?.id as string,
                        client: client,
                        data: {
                            config: {
                                ...user?.config,
                                plantillas: true
                            }
                        }
                    })
                        .then(() => {
                            refreshUser({
                                config: {
                                    ...user?.config,
                                    plantillas: true
                                }
                            })
                        })
                        .finally(() => onOpenFinalConfig())
                }
            })
    }

    const loadAlumnos = async (value: string) => {
        const tenant = await tenantInstance()
        let query = "/users"

        if (value && value !== "") query = query + `?nombreYemail=${value}`

        const { data } = await getData(tenant + "/" + client + query)

        const alumnos = data?.data?.data.map((alumno: UserInt) => ({
            value: alumno?.id,
            label: `${alumno?.nombre} ${alumno?.apellidos}`,
        }));

        return alumnos;
    }

    const loadMatriculasAlumno = async (userId: string | null) => {
        if (!userId) return []

        const tenant = await tenantInstance()
        const query = "/matriculas" + `?user=${userId}`


        const { data } = await getData(tenant + "/" + client + query)

        const matriculas = data?.data?.data.map((matricula: MatriculasInt) => ({
            value: matricula?.id,
            label: matricula?.grupo?.nombre,
        }));

        return matriculas;
    }

    const handleClose = () => {
        setUserId(undefined)
        onClose()
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => null}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                size="xl"
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    maxH="95vh"
                    rounded="20px"
                >
                    <Box
                        color="black"
                    >
                        <ModalHeader
                            textAlign="start"
                            fontSize="24px"
                            fontWeight="600"
                            px="30px"
                            pt="30px"
                            pb="0px"
                        >
                            Enviar plantilla
                        </ModalHeader>

                        <ModalCloseButton onClick={() => handleClose()} />
                    </Box>

                    <Box
                        overflowY="auto"
                        overflowX="hidden"
                        __css={{
                            '&::-webkit-scrollbar': {
                                w: '7px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '20px',
                                bg: `light_grey`,
                            },
                        }}
                    >
                        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                            {(formik) => {
                                const { handleSubmit, values } = formik;

                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                    >
                                        <ModalBody
                                            p="30px"
                                        >
                                            <Flex
                                                direction="column"
                                                gap="2px"
                                                mb="30px"
                                                pt="20px"
                                            >
                                                <FormAsyncSelect
                                                    name="userId"
                                                    label="Alumno"
                                                    defaultOptions={true}
                                                    defaultValue={values?.userId}
                                                    loadOptions={loadAlumnos}
                                                    placeholder="Alumno"
                                                    onChangeCallback={(e: any) => {
                                                        setUserId(e?.value)
                                                    }}
                                                    ui={{
                                                        formSelectStyles: formSelectStyles
                                                    }}
                                                />

                                                <FormSelect
                                                    isDisabled={!values?.userId}
                                                    name="matriculaId"
                                                    label="Matrícula del alumno"
                                                    options={optionsMatriculas}
                                                    placeholder="Matrícula del alumno"
                                                    ui={{
                                                        formSelectStyles: formSelectStyles,
                                                    }}
                                                />

                                                <FormSelect
                                                    name="plantilla"
                                                    label="Tipo de plantilla"
                                                    options={[
                                                        { value: TipoPlantillasEnum.BIENVENIDA, label: "Bienvenida Alumno" },
                                                        { value: TipoPlantillasEnum.BIENVENIDA_SUPERVISOR, label: "Bienvenida Supervisor" },
                                                        { value: TipoPlantillasEnum.BIENVENIDA_TUTOR, label: "Bienvenida Tutor" },
                                                        { value: TipoPlantillasEnum.CONTROL, label: "Control Alumno" },
                                                        { value: TipoPlantillasEnum.CONTROL_SUPERVISOR, label: "Control Supervisor" },
                                                        { value: TipoPlantillasEnum.CORRECCION, label: "Correccion" },
                                                        { value: TipoPlantillasEnum.DUDA, label: "Duda" },
                                                        { value: TipoPlantillasEnum.ENTREGABLE, label: "Entregable" },
                                                        { value: TipoPlantillasEnum.INICIO_FUNDAE, label: "Inicio Fundae" },
                                                        { value: TipoPlantillasEnum.MENSAJE, label: "Mensaje" },
                                                        { value: TipoPlantillasEnum.PREGUNTA, label: "Pregunta" },
                                                        { value: TipoPlantillasEnum.RECOVERY_EMAIL, label: "Email de Recuperacion" },
                                                        { value: TipoPlantillasEnum.RESUMEN_SEMANAL, label: "Resumen Semanal" },
                                                        { value: TipoPlantillasEnum.SINACCESO, label: "Alumno sin Acceso" },
                                                        { value: TipoPlantillasEnum.TUTOR, label: "Mensaje del Tutor" },
                                                    ]}
                                                    placeholder="Tipo de plantilla"
                                                    ui={{
                                                        formSelectStyles: formSelectStyles,
                                                    }}
                                                />

                                                {values?.plantilla === TipoPlantillasEnum.CONTROL &&
                                                    <FormSelect
                                                        name="porcentaje"
                                                        label="Porcentaje control"
                                                        options={[
                                                            { value: 25, label: "25%" },
                                                            { value: 50, label: "50%" },
                                                            { value: 75, label: "75%" },
                                                        ]}
                                                        placeholder="Porcentaje control"
                                                        ui={{
                                                            formSelectStyles: formSelectStyles,
                                                        }}
                                                    />
                                                }

                                                {values?.plantilla === TipoPlantillasEnum.TUTOR &&
                                                    <FormSelect
                                                        name="semana"
                                                        label="Semana"
                                                        options={[
                                                            { value: 1, label: "1" },
                                                            { value: 2, label: "2" },
                                                            { value: 3, label: "3" },
                                                            { value: 4, label: "4" },
                                                            { value: 5, label: "5" },
                                                            { value: 6, label: "6" },
                                                        ]}
                                                        placeholder="Semana"
                                                        ui={{
                                                            formSelectStyles: formSelectStyles,
                                                        }}
                                                    />
                                                }

                                                <FormInput
                                                    name="email"
                                                    label="Email"
                                                    placeholder="Email"
                                                />
                                            </Flex>
                                        </ModalBody>

                                        <ModalFooter justifyContent="center">
                                            <Button
                                                isLoading={isLoading}
                                                type="submit"
                                                bg="secondary"
                                                p="10px 25px"
                                                h="fit-content"
                                                rounded="5"
                                                color="purewhite"
                                                fontSize="18px"
                                                fontWeight="500"
                                                _hover={{ color: "purewhite", backgroundColor: "variant" }}
                                            >
                                                Enviar
                                            </Button>
                                        </ModalFooter>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Box>
                </ModalContent>
            </Modal>

            <ModalFinalConfig
                isOpen={isOpenFinalConfig}
                onClose={onCloseFinalConfig}
            />
        </>
    );
}