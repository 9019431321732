import { Flex, Progress, Text, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { ColumnProps } from "../../../../../shared/components/Table/Table";
import { BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { ColumnIcon } from "@imaginagroup/bit-components.ui.columns.column-icon";
import { ColumnLabelText } from "@imaginagroup/bit-components.ui.columns.column-label-text";
import { ColumnDate } from "../../../../../shared/components/CustomColumns/ColumnDate";
import { formatTimezone } from "../../../../../shared/utils/functions/parseDate";
import { useTimeZone } from "../../../../../shared/hooks/useTimeZone";

interface Props {
    moduloFUNDAE: boolean;
}

export const MatriculasColumns = ({ moduloFUNDAE }: Props): ColumnProps[] => {
    const timeZone = useTimeZone();

    const columns: ColumnProps[] = [
        {
            header: 'nombre alumno',
            field: 'users',
            key: 'nombre',
            style: { width: "250px" },
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.user.nombre + " " + rowData?.user.apellidos}
                    link={{
                        to: `/matriculas/${rowData.id}`,
                    }}
                />
            )
        },
        {
            header: 'Creación',
            field: 'matriculas.created_at',
            key: 'createdAt',
            sortable: true,
            body: (rowData: any) => (
                <ColumnDate
                    date={rowData?.createdAt}
                    link={{
                        to: `/matriculas/${rowData.id}`,
                    }}
                />
            )
        },
        {
            header: 'Duracion', field: 'estado',
            key: 'estado',
            style: { width: "250px" },
            sortable: true,
            body: (rowData: any) => (
                <Link
                    as={RouterLink}
                    to={`/matriculas/${rowData?.id}`}
                    _hover={{ textDecoration: "none" }}
                >
                    <Flex direction="column" alignItems="flex-start" ml="16px" py="16px">
                        <Flex
                            color={
                                rowData?.estado === "2" ? "font" :
                                    rowData?.estado === "1" ? "secondary"
                                        : "success_main"
                            }
                            bg={
                                rowData?.estado === "2" ? "light_grey" :
                                    rowData?.estado === "1" ? "main_variant"
                                        : "success_bg"
                            }
                            borderColor={
                                rowData?.estado === "2" ? "light_grey" :
                                    rowData?.estado === "1" ? "main_variant"
                                        : "success_variant"
                            }
                            borderWidth="1px"
                            fontSize="10px"
                            fontWeight="500"
                            letterSpacing="0.2px"
                            textTransform="uppercase"
                            borderRadius="14px"
                            p="2px 5px"
                        >
                            {
                                rowData?.estado === "2" ? "Inactivo" :
                                    rowData?.estado === "1" ? "Próximo"
                                        : "Activo"
                            }
                        </Flex>
                        <Flex
                            alignItems="center"
                            gap="5px"
                            color="font"
                            fontSize="14px"
                            fontWeight="400"
                        >
                            <Text>{formatTimezone({ date: rowData?.fechaInicio, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                            <Text mb="2px">-</Text>
                            <Text>{formatTimezone({ date: rowData?.fechaFin, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                        </Flex>
                    </Flex>
                </Link>
            )
        },
        {
            header: 'Grupos',
            field: 'grupo',
            key: 'grupo',
            style: { width: "300px" },
            sortable: true,
            body: (rowData: any) => (
                <ColumnLabelText
                    label={rowData?.grupo?.nombre}
                    text={rowData?.grupo?.curso?.nombre}
                    tooltip={{
                        label: rowData?.grupo?.curso?.nombre,
                        hasArrow: true
                    }}
                />
            )
        },
        {
            header: 'Progreso',
            field: 'progresoLecciones',
            key: 'progresoLecciones',
            sortable: true,
            body: (rowData: any) => (
                <Flex gap="5px" alignItems="center">
                    <Text
                        color="font"
                        fontSize="14px"
                        fontWeight="500"
                    >
                        {parseInt(rowData?.progresoLecciones) || 0} %
                    </Text>

                    <Progress
                        value={rowData?.progresoLecciones || 0}
                        flex="1"
                        h="7px"
                        rounded="10px"
                        sx={{ '& > div': { background: "secondary" } }}
                    />
                </Flex>
            )
        },
        {
            header: 'Puntuacion',
            field: 'puntuacionMedia',
            key: 'puntuacionMedia',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={(rowData?.puntuacionMedia || 0) + "/100"}
                />
            )
        },
    ];

    const extraColumns: ColumnProps[] = [
        {
            header: 'FUNDAE',
            field: 'grupos.fundae',
            key: 'fundae',
            sortable: true,
            style: { width: "100px" },
            body: (rowData: any) => (
                rowData?.grupo?.fundae
                    ?
                    <ColumnIcon
                        icon={BsCheckCircleFill}
                        ui={{
                            color: "#E9B418",
                            boxSize: "18px"
                        }}
                        link={{
                            to: `/matriculas/${rowData?.id}`,

                        }}
                    />
                    :
                    <ColumnIcon
                        icon={BsXCircleFill}
                        ui={{
                            color: "#616675",
                            boxSize: "18px"
                        }}
                        link={{
                            to: `/matriculas/${rowData?.id}`,

                        }} />
            )
        },
    ]

    return moduloFUNDAE ? [...columns.slice(0, 3), ...extraColumns, ...columns.slice(3)] : columns
}