import { Flex, Skeleton, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { LiquidacionesTable } from "./Liquidaciones/Table/LiquidacionesTable";
import { RiBillLine } from "react-icons/ri";
import { MdPendingActions } from "react-icons/md";
import { Topbar } from "../../../shared/components/Topbar/Topbar";
import { GrCompliance } from "react-icons/gr";
import { TabsComponentInt } from "../../../interfaces/ComponentsInt";
import { CustomTabs } from "@imaginagroup/bit-components.ui.custom-tabs";
import { AsignacionesLiquidadasTable } from "./AsignacionesLiquidadas/AsignacionesLiquidadasTable";
import { AsignacionesPendientesTable } from "./AsignacionesPendientes/AsignacionesPendientesTable";
import { EntityTypes, useQuerySession } from "../../../shared/hooks/useQuerySession";
import { customTabsStyles } from "../../../ui/bitStyles";

export const TabsFacturacion = () => {
    const [tabIndex, setTabIndex] = useState<number>(0)
    const handleTabsChange = (index: number) => setTabIndex(index)

    const tabs: TabsComponentInt[] = [
        {
            isAllowed: true,
            label: "Matriculas Pendientes",
            icon: MdPendingActions,
            content: <AsignacionesPendientesTable />
        },
        {
            isAllowed: true,
            label: "Matriculas Facturadas",
            icon: GrCompliance,
            content: <AsignacionesLiquidadasTable />
        },
        {
            isAllowed: true,
            label: "Liquidaciones",
            icon: RiBillLine,
            content: <LiquidacionesTable />
        }
    ]

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Facturación
                </Text>
            }
        >
            <Flex
                direction="column"
                w="100%"
            >
                <Flex
                    direction="column"
                    p="30px"
                >
                    <CustomTabs
                        ui={customTabsStyles}
                        tabs={tabs}
                        tabIndex={tabIndex}
                        handleTabsChange={handleTabsChange}
                    />
                </Flex>
            </Flex>
        </Topbar>
    )
};