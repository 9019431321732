export enum TipoPlantillasEnum {
    BIENVENIDA = 'bienvenida',
    BIENVENIDA_TUTOR = 'bienvenidaTutor',
    BIENVENIDA_SUPERVISOR = 'bienvenidaSupervisor',
    RECOVERY_EMAIL = 'recoveryEmail',
    DUDA = 'duda',
    ENTREGABLE = 'entregable',
    CONTROL = 'control',
    CONTROL_SUPERVISOR = 'controlSupervisor',
    CORRECCION = 'correccion',
    MENSAJE = 'mensaje',
    TUTOR = 'tutor',
    TUTOR_1 = 'tutor1',
    TUTOR_2 = 'tutor2',
    TUTOR_3 = 'tutor3',
    TUTOR_4 = 'tutor4',
    TUTOR_5 = 'tutor5',
    TUTOR_6 = 'tutor6',
    SINACCESO = 'sinacceso',
    RESUMEN_SEMANAL = 'resumenSemanal',
    INICIO_FUNDAE = 'inicioFUNDAE',
    PREGUNTA = 'pregunta',
}