import { useEffect, useRef, useState } from "react";
import { Flex, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { deleteGrupo } from "../../../../shared/middlewares/grupos.moddleware";
import { NewGrupoModal } from "../../components/Modals/NewGrupoModal";
import { GruposInt } from "../../../../interfaces/GruposInt";
import { BiPlusCircle } from "react-icons/bi";
import { isRoleAllowed } from "../../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../../shared/utils/Types/UserRolEnum";
import { useAuthContex } from "../../../../shared/context/auth.context";
import { useClient } from "../../../../shared/context/client.context";
import { handleErrors, handleUnauthorized } from "../../../../shared/utils/functions/handleErrors";
import { ModalDelete } from "../../../../shared/components/Modals/ModalDelete";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { FiltrosNames, FiltrosTypes } from "../../../../shared/utils/Types/FiltrosEnum";
import { CardInfoFUNDAE } from "../../../../shared/components/Cards/CardInfoFUNDAE";
import { CardInfo } from "../../../../shared/components/Cards/CardInfo";
import { HiUserGroup, HiAcademicCap, HiOutlineUserGroup, HiOutlineUser, HiOutlineCalendar } from "react-icons/hi"
import { calcularComparativa } from "../../../../shared/utils/functions/comparativeStats";
import GruposSkeleton from "../../components/utils/GruposSkeleton"
import { Topbar } from "../../../../shared/components/Topbar/Topbar";
import { useUserPolicy } from "../../../../shared/hooks/PermissionPolicy";
import { CustomButton } from "../../../../shared/components/CustomElements/CustomButton";
import { useModuloFundae } from "../../../../shared/hooks/ModuloFundae";
import { NewGrupoNoFundae } from "../../components/Modals/NewGrupoNoFundae";
import { SinGrupos } from "../SinInformacion/SinGrupos";
import { EmptyMessageType } from "../../../../shared/utils/Types/EmptyMessageType";
import { Filters } from "../../../../shared/components/Filters/Filters";
import { Table } from "../../../../shared/components/Table/Table";
import { GruposColumns } from "./Columns/GruposColumns";
import { EntityTypes, useQuerySession } from "../../../../shared/hooks/useQuerySession";
import { useData } from "../../../../shared/hooks/useData";
import { CustomGroupTable } from "../../../../shared/components/Table/CustomGroupTable";
import { GroupGruposColumns } from "./Columns/GroupGruposColumns.tsx";


interface Props {
    actions?: boolean;
}

export const GruposTable = ({ actions = false }: Props) => {
    const { query, setQuery, handleSetQuery } = useQuerySession({ entity: EntityTypes.GRUPOS });
    const { handleSetQuery: setQueryUsuarios } = useQuerySession({ entity: EntityTypes.ALUMNOS });
    const moduloFUNDAE = useModuloFundae();
    const { contenido, total } = useUserPolicy();
    const client = useClient();
    const { logout } = useAuthContex();
    const { user } = useAuthContex();
    const navigate = useNavigate();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();
    const grupoRef = useRef<GruposInt | null>(null)
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const [hasFilter, setHasFilters] = useState<boolean>();

    const { data: grupos, loading, Refresh, error, status, errorsList, } = useData({
        client: client,
        endpoint: EndpointTypes.GRUPOS,
        query: query
    })

    const { data: stats } = useData({
        client: client,
        endpoint: EndpointTypes.STATS_ACTIVOS,
    })


    useEffect(() => {
        if (error)
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
    }, [error])

    const onEventRemove = (event: GruposInt) => {
        grupoRef.current = event;

        if (isOpenDelete) onCloseDelete();
        onOpenDelete();
    };

    const onCardClickGruposFundae = () => {
        handleSetQuery({
            page: 1,
            fundae: "true",
            estado: 0
        });
    };

    const onCardClickGruposActivos = () => {
        handleSetQuery({
            page: 1,
            estado: 0
        })
    };

    const onCardClickUsuariosActivos = () => {
        setQueryUsuarios({
            page: 1,
            activo: "true",
        },
            `/usuarios`
        )
    };

    const onCardClickGruposProximaSemana = () => {
        handleSetQuery({
            page: 1,
            estado: 1,
        })
    };

    const onDelete = () => {
        deleteGrupo({ grupoId: grupoRef.current?.id, client: client })
            .then(() => {
                Refresh()
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Grupo eliminado correctamente")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => onCloseDelete())
    }

    const getKeyWord = (defaultWord: string): string => {
        if (!grupoRef.current?.nombre) return defaultWord

        const keyword = grupoRef.current?.nombre;

        return keyword
    }

    const cambioPorcentualGrupos = calcularComparativa(
        grupos?.data,
        firstDayOfMonth,
        firstDayOfLastMonth
    );


    const CardInfoDataFundae: any = [
        {
            onClick: onCardClickGruposFundae,
            label: "Grupos activos",
            info: stats?.gruposActivosFundae,
            tooltip: "Total de grupos activos bonificados por FUNDAE",

        },
    ];


    const CardInfoData: any = [
        {
            onClick: onCardClickGruposActivos,
            label: "Grupos activos",
            info: stats?.gruposActivosNoFundae + stats?.gruposActivosFundae,
            bg: "purewhite",
            tooltip: "Total de grupos activos actualmente",
            cambioPorcentual: cambioPorcentualGrupos
        }
        ,
        {
            onClick: onCardClickUsuariosActivos,
            label: "Alumnos activos",
            info: stats?.usersActivos,
            bg: "purewhite",
            tooltip: "Total de alumnos activos actualmente"
        },
        {
            onClick: onCardClickGruposProximaSemana,
            label: "Grupos próxima semana",
            info: stats?.gruposProximaSemana,
            bg: "purewhite",
            tooltip: "Total de grupos que inician la próxima semana"
        }
    ]

    const filterElements = [
        {
            name: FiltrosNames.ESTADO_GRUPO,
            type: FiltrosTypes.SELECT,
            multi: true,
        },
        {
            name: FiltrosNames.FECHA_INICIO_SUP,
            type: FiltrosTypes.DATE,
        },
        {
            name: FiltrosNames.FECHA_FIN_INF,
            type: FiltrosTypes.DATE,
        },
        {
            name: FiltrosNames.CURSO,
            type: FiltrosTypes.ASYNC_SELECT,
        },
        {
            name: FiltrosNames.EMPRESA,
            type: FiltrosTypes.ASYNC_SELECT,
        },
    ]

    const extraFilterElements = [
        {
            name: FiltrosNames.FUNDAE,
            type: FiltrosTypes.SELECT,
        }
    ]

    const supervisorFilter = [
        {
            name: FiltrosNames.ESTADO_GRUPO,
            type: FiltrosTypes.SELECT,
            multi: true,
        },
        {
            name: FiltrosNames.FECHA_INICIO_SUP,
            type: FiltrosTypes.DATE,
        },
        {
            name: FiltrosNames.FECHA_FIN_INF,
            type: FiltrosTypes.DATE,
        },
    ]

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Grupos
                </Text>
            }
        >
            {(!user?.config?.grupos && total) ?
                <SinGrupos Refresh={Refresh} />
                :
                <Flex
                    p="30px"
                    direction="column"
                    gap="20px"
                >

                    {loading ? (
                        <GruposSkeleton moduloFundae={moduloFUNDAE} />
                    ) : (
                        <Flex
                            w="100%"
                            gap="32px"
                            wrap="wrap"
                        >

                            {moduloFUNDAE && CardInfoDataFundae?.map((item: any, index: number) => (
                                <CardInfoFUNDAE
                                    icon={index === 0 ? HiUserGroup : HiAcademicCap}
                                    key={index}
                                    onClick={item?.onClick}
                                    label={item?.label}
                                    info={item?.info}
                                    tooltip={item?.tooltip}
                                />
                            ))}

                            {CardInfoData?.map((item: any, index: number) => (
                                <CardInfo
                                    key={index}
                                    icon={
                                        index === 0 ? HiOutlineUserGroup :
                                            index === 1 ? HiOutlineUser :
                                                index === 2 ? HiOutlineCalendar : undefined
                                    }
                                    onClick={item?.onClick}
                                    label={item?.label}
                                    info={item?.info}
                                    bg={item?.bg}
                                    mb={item?.mb}
                                    tooltip={item?.tooltip}
                                    cambioPorcentualGrupos={item?.cambioPorcentual}
                                />
                            ))}
                        </Flex>
                    )}


                    <Filters
                        setHasFilters={setHasFilters}
                        allowToggle={true}
                        setQuery={setQuery}
                        query={query}
                        filterHeaders={[
                            {
                                name: FiltrosNames.NOMBRE,
                                type: FiltrosTypes.INPUT,
                            },
                        ]}
                        filterElements={
                            user?.role?.nombre === UserRolEnum.SUPERVISOR ? supervisorFilter :
                                moduloFUNDAE ? [...filterElements.slice(0, 4), ...extraFilterElements, ...filterElements.slice(4)] : filterElements
                        }
                        button={
                            isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) ?
                                <CustomButton
                                    button={{
                                        label: "Nuevo grupo",
                                        onClick: onOpen,
                                        isDisabled: contenido,
                                        leftIcon: {
                                            as: BiPlusCircle,
                                            boxSize: "20px"
                                        }
                                    }}
                                    tooltip={{
                                        label: contenido ? "No tiene permisos para realizar la acción" : "Crear nuevo grupo",
                                        isDisabled: false,
                                    }}
                                />
                                :
                                undefined
                        }
                    />

                    {/* <Table
                        data={grupos?.data || []}
                        columns={GruposColumns({
                            contenido,
                            grupoRef,
                            moduloFUNDAE,
                            onEventRemove,
                            onOpen,
                            actions
                        })}
                        isLoading={loading}
                        total={grupos?.meta?.total || 0}
                        setQuery={setQuery}
                        query={query}
                        emptyMessage={hasFilter ? EmptyMessageType.gruposFiltros : EmptyMessageType.grupos}
                    /> */}

                    <CustomGroupTable
                        data={grupos?.data || []}
                        loading={loading}
                        columns={GruposColumns({
                            contenido,
                            grupoRef,
                            moduloFUNDAE,
                            onEventRemove,
                            onOpen,
                            actions
                        })}
                        groupByKey="subGrupos"
                        columnsGroup={GroupGruposColumns({
                            contenido,
                            grupoRef,
                            moduloFUNDAE,
                            onEventRemove,
                            onOpen,
                            actions
                        })}
                        total={grupos?.meta?.total || 0}
                        setQuery={setQuery}
                        query={query}
                    />

                    {moduloFUNDAE ?
                        <NewGrupoModal
                            isOpen={isOpen}
                            onClose={onClose}
                            setRefreshTable={Refresh}
                            copyData={grupoRef?.current}
                        />
                        :
                        <NewGrupoNoFundae
                            isOpen={isOpen}
                            onClose={onClose}
                            setRefreshTable={Refresh}
                            copyData={grupoRef?.current}
                        />
                    }

                    <ModalDelete
                        isOpen={isOpenDelete}
                        onClose={onCloseDelete}
                        onDelete={onDelete}
                        label={getKeyWord("grupo")}
                    />
                </Flex>
            }
        </Topbar>
    );
};