import { Flex } from "@chakra-ui/react"
import { Route, Routes } from "react-router-dom"
import { LiquidacionesInformation } from "./views/Liquidaciones/Information/LiquidacionesInformation"
import { Helmet } from "react-helmet"
import { TabsFacturacion } from "./views/TabsFacturacion"

export const Facturacion = () => {

    return (
        <Flex
            direction="column"
            w="100%"
        >
            <Helmet>
                <title>Facturación | Proacademy</title>
            </Helmet>
            <Flex direction="column">
                <Routes>
                    <Route
                        index
                        element={
                            <TabsFacturacion />
                        }
                    />

                    <Route
                        path="/:id"
                        element={
                            <LiquidacionesInformation />
                        }
                    />
                </Routes>
            </Flex>
        </Flex>
    )
}