import { UserInt } from "../../../interfaces/UserInt"
import { Flex, Icon, Link, Progress, Text } from '@chakra-ui/react'
import { BsCheckCircleFill, BsCircle } from 'react-icons/bs';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useTenantInfo } from "../../hooks/useTenantInfo";

interface Props {
    user: UserInt;
}

export const OnboardingCard = ({ user }: Props) => {
    const { handleGetInfo } = useTenantInfo();
    const produccion = handleGetInfo({ key: "produccion" });
    
    const obboardingList = [
        { title: "Personalización", complete: produccion, link: "/" },
        { title: "Cursos", complete: user?.config?.cursos, link: "/cursos" },
        { title: "Grupos", complete: user?.config?.grupos, link: "/grupos" },
        { title: "Alumnos", complete: user?.config?.alumnos, link: "/usuarios", },
        { title: "Tutores", complete: user?.config?.tutores, link: "/usuarios", },
        { title: "Matrículas", complete: user?.config?.matriculas, link: "/grupos" },
        { title: "Mis empresas", complete: user?.config?.empresasOrganizadoras, link: "/configuracion" },
        { title: "Plantillas Emails", complete: user?.config?.plantillas, link: "/plantillas" },
    ]

    const getProgress = (): number => {
        let count = 0;

        obboardingList?.map((item) => {
            if (item.complete) {
                count++;
            }
        })

        return count;
    }

    return (
        <Flex
            zIndex="1400"
            direction="column"
            pos="absolute"
            bottom="3%"
            right="2%"
            bg="secondary"
            rounded="13px"
            w="300px"
            shadow="0px 4px 15px 0px rgba(199, 205, 255, 0.60)"
        >
            <Flex
                direction="column"
                py="16px"
                px="16px"
                gap="5x"
            >
                <Text
                    fontSize="18px"
                    fontWeight="600"
                    lineHeight="29.615px"
                    color="purewhite"
                >
                    Configuración
                </Text>

                <Flex
                    alignItems="center"
                    gap="13px"
                >
                    <Text
                        fontSize="16px"
                        color="purewhite"
                        fontWeight="600"
                        lineHeight="24.231px"
                    >
                        {getProgress()}/{obboardingList?.length}
                    </Text>

                    <Progress
                        value={getProgress()}
                        max={obboardingList?.length}
                        w="100%"
                        rounded="50px"
                        h="14px"
                        sx={{ '& > div': { background: "secondary" } }}
                    />
                </Flex>
            </Flex>

            <Flex
                bg="purewhite"
                w="100%"
                rounded="13px"
                px="16px"
                py="16px"
                direction="column"
                gap="16px"
            >
                {obboardingList?.map((item, index) => (
                    <Flex
                        key={index}
                        alignItems="center"
                        gap="10px"
                    >
                        {item?.complete
                            ? <Icon as={BsCheckCircleFill} boxSize="22px" color="success" />
                            : <Icon as={BsCircle} boxSize="22px" color="#6474EF" />
                        }


                        {item?.complete
                            ?
                            <Text
                                fontSize="16px"
                                color="pureblack"
                                fontWeight="400"
                                lineHeight="24.615px"
                            >
                                {item.title}
                            </Text>
                            :
                            <Link
                                _hover={{}}
                                cursor={"pointer"}
                                as={ReactRouterLink}
                                to={item?.link}
                                fontSize="16px"
                                color="pureblack"
                                fontWeight="400"
                                lineHeight="24.615px"
                            >
                                {item.title}
                            </Link>
                        }
                    </Flex>
                ))}
            </Flex>
        </Flex>
    )
}