import { CircularProgress, CircularProgressLabel, Flex, Icon, Image, Text } from "@chakra-ui/react";
import imgPortadaTesting from "../../../assets/img/portadaCursoTesting.png"
import { HiOutlineBookOpen, HiOutlineChat, HiOutlineDocumentDuplicate, HiOutlineUserGroup, HiTrendingUp } from "react-icons/hi";

interface Props {
    mainColor: string;
    secondaryColor: string;
    variantColor: string;
}

export default function CampusScreen({ mainColor = "#000", secondaryColor, variantColor }: Props) {
    return (
        <Flex
            bg="#F2F2FD"
            gap="20px"
            pr="40px"
            maxH="750px"
            rounded="10px"
        >
            <Flex
                h="100vh"
                bg={mainColor}
                p="10px"
                maxH="750px"
                rounded="10px"
            >
                <Flex
                    direction="column"
                >
                    <Text
                        color="purewhite"
                        ml="20px"
                        fontSize="14px"
                        mt="20px"
                    >
                        Logotipo
                    </Text>

                    <Flex
                        direction="column"
                        p="10px"
                        w="100%"
                        gap="10px"
                    >
                        <Flex
                            p="10px"
                            gap="10px"
                            color="purewhite"
                            direction="row"
                            alignItems="center"
                            rounded="15px"
                            outline={"#C7CDFF"}
                        >
                            <Icon
                                as={HiTrendingUp}
                                boxSize="18px"
                                color="purewhite"
                            />

                            <Text
                                color="purewhite"
                                fontSize="10px"
                                fontStyle="normal"
                                fontWeight="500"
                                alignItems="center"
                            >
                                Resumen
                            </Text>
                        </Flex>

                        <Flex
                            p="10px"
                            gap="5px"
                            color="purewhite"
                            direction="row"
                            alignItems="center"
                            rounded="15px"
                            outline={"#C7CDFF"}
                        >
                            <Icon
                                as={HiOutlineBookOpen}
                                boxSize="18px"
                                color="purewhite"
                            />

                            <Text
                                color="purewhite"
                                fontSize="10px"
                                fontStyle="normal"
                                fontWeight="500"
                                alignItems="center"
                            >
                                Cursos
                            </Text>
                        </Flex>

                        <Flex
                            p="10px"
                            gap="5px"
                            color="purewhite"
                            direction="row"
                            alignItems="center"
                            rounded="15px"
                            outline={"#C7CDFF"}
                        >
                            <Icon
                                as={HiOutlineDocumentDuplicate}
                                boxSize="18px"
                                color="purewhite"
                            />

                            <Text
                                color="purewhite"
                                fontSize="10px"
                                fontStyle="normal"
                                fontWeight="500"
                                alignItems="center"
                            >
                                Entregas
                            </Text>
                        </Flex>

                        <Flex
                            p="10px"
                            gap="5px"
                            color="purewhite"
                            direction="row"
                            alignItems="center"
                            rounded="15px"
                            outline={"#C7CDFF"}
                        >
                            <Icon
                                as={HiOutlineUserGroup}
                                boxSize="18px"
                                color="purewhite"
                            />

                            <Text
                                color="purewhite"
                                fontSize="10px"
                                fontStyle="normal"
                                fontWeight="500"
                                alignItems="center"
                            >
                                Foro
                            </Text>
                        </Flex>

                        <Flex
                            p="10px"
                            gap="5px"
                            color="purewhite"
                            direction="row"
                            alignItems="center"
                            rounded="15px"
                            outline={"#C7CDFF"}
                        >
                            <Icon
                                as={HiOutlineChat}
                                boxSize="18px"
                                color="purewhite"
                            />

                            <Text
                                color="purewhite"
                                fontSize="10px"
                                fontStyle="normal"
                                fontWeight="500"
                                alignItems="center"
                            >
                                Mensajería
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex
                direction="column"
                pt="30px"
            >
                <Text
                    fontWeight="700"
                >
                    Panel
                </Text>

                <Flex
                    direction="column"
                    gap="30px"
                >
                    <Text
                        mt="10px"
                    >
                        Cursos Activos
                    </Text>

                    <Flex
                        gap="20px"
                    >
                        <Flex
                            direction="column"
                            bg="purewhite"
                            transition=" 0.3s ease-in-out, box-shadow 0.3s ease-in-out"
                            rounded="5px"
                            h="fit-content"
                        >
                            <Flex
                                w="100%"
                                maxH="600px"
                                overflow="hidden"
                                roundedTop="5px"
                            >
                                <Image
                                    src={imgPortadaTesting}
                                    alt="portadaCursoTesting"
                                    objectFit="cover"
                                    w="100%"
                                    h="100%"
                                />
                            </Flex>

                            <Flex
                                direction="column"
                                p="20px"
                                h="100%"
                            >
                                <Flex
                                    alignItems="start"
                                    justifyContent="space-between"
                                    direction="column"
                                    gap="10px"
                                >
                                    <Flex
                                        direction="column"
                                        gap="10px"
                                    >

                                        <Text
                                            color="font"
                                            fontSize="14px"
                                            fontWeight="700"
                                        >
                                            React
                                        </Text>

                                    </Flex>

                                    <Flex
                                        alignItems="center"
                                        gap="12px"
                                        w="100%"
                                    >
                                        <Text
                                            color="font"
                                            fontSize="10px"
                                            fontWeight="400"
                                        >
                                            60h
                                        </Text>

                                    </Flex>
                                </Flex>

                                <Text
                                    color={"#202020"}
                                    fontSize={{ xl: "10px", lg: "8px" }}
                                    fontWeight="400"
                                >
                                    React JS se trata de una librería de JavaScript de código abierto desarrollada por los ingenieros de Facebook con el objetivo de ofrecer los mejores estándares en experiencia de usuario. Su objetivo es ayudarte en el desarrollo de aplicaciones donde necesites usar datos que cambien todo el tiempo, siendo su uso sencillo, declarativo y fácil de combinar.
                                </Text>

                                <Text
                                    mt="15px"
                                    ml="auto"
                                    p="7px"
                                    bg={mainColor}
                                    color="purewhite"
                                    fontSize="10px"
                                    fontWeight="500"
                                    rounded="5px"
                                >
                                    Continuar
                                </Text>
                            </Flex>
                        </Flex>


                        <Flex
                            direction="column"
                            bg="purewhite"
                            transition=" 0.3s ease-in-out, box-shadow 0.3s ease-in-out"
                            rounded="5px"
                            h="fit-content"
                        >
                            <Flex
                                w="100%"
                                maxH="600px"
                                overflow="hidden"
                                roundedTop="5px"
                            >
                                <Image
                                    src={imgPortadaTesting}
                                    alt="portadaCursoTesting"
                                    objectFit="cover"
                                    w="100%"
                                    h="100%"
                                />
                            </Flex>

                            <Flex
                                direction="column"
                                p="20px"
                                h="100%"
                            >
                                <Flex
                                    alignItems="start"
                                    justifyContent="space-between"
                                    direction="column"
                                    gap="10px"
                                >
                                    <Flex
                                        direction="column"
                                        gap="10px"
                                    >

                                        <Text
                                            color="font"
                                            fontSize="14px"
                                            fontWeight="700"
                                        >
                                            React
                                        </Text>

                                    </Flex>

                                    <Flex
                                        alignItems="center"
                                        gap="12px"
                                        w="100%"
                                    >
                                        <Text
                                            color="font"
                                            fontSize="10px"
                                            fontWeight="400"
                                        >
                                            60h
                                        </Text>

                                    </Flex>
                                </Flex>

                                <Text
                                    color={"#202020"}
                                    fontSize={{ xl: "10px", lg: "8px" }}
                                    fontWeight="400"
                                >
                                    React JS se trata de una librería de JavaScript de código abierto desarrollada por los ingenieros de Facebook con el objetivo de ofrecer los mejores estándares en experiencia de usuario. Su objetivo es ayudarte en el desarrollo de aplicaciones donde necesites usar datos que cambien todo el tiempo, siendo su uso sencillo, declarativo y fácil de combinar.
                                </Text>

                                <Text
                                    mt="15px"
                                    ml="auto"
                                    p="7px"
                                    bg={mainColor}
                                    color="purewhite"
                                    fontSize="10px"
                                    fontWeight="500"
                                    rounded="5px"
                                >
                                    Continuar
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex
                mt="117px"
                direction="column"
                gap="20px"
            >

                <Flex
                    bg="purewhite"
                    padding="20px"
                    direction="column"
                    borderRadius="8px"
                    gap="20px"
                >
                    <Text
                        color="font"
                        fontSize="10px"
                        fontWeight="600"
                    >
                        Tu grupo React finalizará en:
                    </Text>

                    <Flex
                        direction="column"
                        gap="10px"
                        bg="purewhite"
                        rounded="5px"
                        h="fit-content"
                    >
                        <Flex
                            direction="column"
                            gap="10px"

                        >

                            <Flex
                                bg="#FFFFFF"
                                rounded="5px"
                                color="#3D3F65"
                                gap="20px"
                                justify="center"

                            >

                                <CircularProgress capIsRound value={70} color={mainColor} trackColor="#EAECFF" size="55px">
                                    <CircularProgressLabel
                                        fontSize="12px"
                                        fontWeight="600"
                                        lineHeight="90%"

                                    >
                                        1
                                        <Text
                                            fontSize="10px"
                                            fontWeight="400"
                                        >
                                            MES

                                        </Text>
                                    </CircularProgressLabel>
                                </CircularProgress>

                                <CircularProgress capIsRound value={80} color={mainColor} trackColor="#EAECFF" size="55px">
                                    <CircularProgressLabel
                                        fontSize="12px"
                                        fontWeight="600"
                                        lineHeight="90%"
                                    >
                                        4

                                        <Text
                                            fontSize="10px"
                                            fontWeight="400"
                                        >
                                            DÍAS

                                        </Text>
                                    </CircularProgressLabel>
                                </CircularProgress>


                                <CircularProgress capIsRound value={40} color={mainColor} trackColor="#EAECFF" size="55px">
                                    <CircularProgressLabel
                                        fontSize="12px"
                                        fontWeight="600"
                                        lineHeight="90%"
                                    >
                                        2

                                        <Text
                                            fontSize="10px"
                                            fontWeight="400"
                                        >
                                            horas

                                        </Text>
                                    </CircularProgressLabel>
                                </CircularProgress>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>

                <Flex
                    direction="column"
                    gap="20px"
                    bg="purewhite"
                    p="20px"
                    rounded="5px"
                    h="fit-content"
                >
                    <Text
                        fontSize="10px"
                        color="font"
                        fontWeight="700"
                    >
                        Tu progreso
                    </Text>

                    <Flex
                        gap="14px"
                    >
                        <Flex
                            direction="column"
                            gap="14px"
                            w="full"
                        >
                            <Flex
                                direction="column"
                                gap="5px"
                                boxShadow="0px 4px 15px 0px rgba(199, 205, 255, 0.60)"
                                p="9px"
                                alignItems="center"
                                rounded="5px"
                            >
                                <Text
                                    color="font"
                                    fontSize="12px"
                                    fontWeight="700"
                                >
                                    04
                                </Text>
                                <Text
                                    color="dark_grey"
                                    fontSize="8px"
                                    fontWeight="400"
                                    whiteSpace="nowrap"
                                >
                                    Cursos activos
                                </Text>
                            </Flex>
                            <Flex
                                direction="column"
                                gap="5px"
                                boxShadow="0px 4px 15px 0px rgba(199, 205, 255, 0.60)"
                                p="9px"
                                alignItems="center"
                                rounded="5px"
                            >
                                <Text
                                    color="font"
                                    fontSize="12px"
                                    fontWeight="700"
                                >
                                    01
                                </Text>
                                <Text
                                    color="dark_grey"
                                    fontSize="8px"
                                    fontWeight="400"
                                    whiteSpace="nowrap"
                                >
                                    Cursos terminados
                                </Text>
                            </Flex>
                        </Flex>

                        <Flex
                            direction="column"
                            gap="14px"
                            w="full"
                        >
                            <Flex
                                direction="column"
                                gap="5px"
                                boxShadow="0px 4px 15px 0px rgba(199, 205, 255, 0.60)"
                                p="9px"
                                alignItems="center"
                                rounded="5px"
                            >
                                <Text
                                    color="font"
                                    fontSize="12px"
                                    fontWeight="700"
                                >
                                    48h
                                </Text>
                                <Text
                                    color="dark_grey"
                                    fontSize="8px"
                                    fontWeight="400"
                                    whiteSpace="nowrap"
                                >
                                    Horas dedicadas
                                </Text>
                            </Flex>

                            <Flex
                                direction="column"
                                gap="5px"
                                boxShadow="0px 4px 15px 0px rgba(199, 205, 255, 0.60)"
                                p="9px"
                                alignItems="center"
                                rounded="5px"
                            >
                                <Text
                                    color="font"
                                    fontSize="12px"
                                    fontWeight="700"
                                >
                                    87
                                </Text>
                                <Text
                                    color="dark_grey"
                                    fontSize="8px"
                                    fontWeight="400"
                                    whiteSpace="nowrap"
                                >
                                    Nota media
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>


                    <Text
                        bg={mainColor}
                        color="purewhite"
                        fontWeight="500"
                        rounded="5px"
                        maxW="130px"
                        mx="auto"
                        p="7px"
                        fontSize="10px"
                    >
                        Ir al panel
                    </Text>

                </Flex>

                <Flex
                    bg="purewhite"
                    rounded="5px"
                    p="20px"
                    direction="column"
                    gap="10px"
                >
                    <Text
                        color="font"
                        fontSize="10px"
                        fontWeight="700"
                    >
                        Actividad en foro
                    </Text>

                    <Flex
                        flex="1"
                        justifyContent="center"
                        align="center"
                        gap="10px"
                    >

                        <Flex
                            flex="1"
                            gap="15px"
                            minW="146px">
                            {/* <Img
                                w="36px"
                                h="36px"
                                objectFit="cover"
                                rounded="full"
                                src={avatar}
                                 /> */}

                            <Flex
                                direction="column"
                            >
                                <Text
                                    color="font"
                                    fontSize="10px"
                                    fontWeight="400"
                                    whiteSpace="nowrap"

                                >
                                    Carlos Pérez
                                </Text>

                                <Text
                                    color="dark_grey"
                                    fontWeight="400"
                                    fontSize="12px"
                                    whiteSpace="nowrap"

                                >
                                    ¿Cómo se hacia esto?
                                </Text>
                            </Flex>
                        </Flex>

                        <Flex
                            flex="1"
                            justify="end"
                        >
                            <Text
                                color="dark_grey"
                                fontSize="12px"
                                fontWeight="400"
                                align="end"
                            >
                                20
                            </Text>
                        </Flex>

                    </Flex>

                </Flex>

            </Flex>
        </Flex>
    )
}
