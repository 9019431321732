import { Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { useRef } from "react";
import { useClient } from "../../../../shared/context/client.context";
import { deletePendingEmails } from "../../../../shared/middlewares/emails.middleware";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { ModalDelete } from "../../../../shared/components/Modals/ModalDelete";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { useData } from "../../../../shared/hooks/useData";
import { FiltrosNames, FiltrosTypes } from "../../../../shared/utils/Types/FiltrosEnum";
import { checkQuerys } from "../../../../shared/utils/functions/checkQuerys";
import { EmailsSectionTypes } from "../../../../shared/utils/Types/EmailsSectionTypes";
import { Table } from "../../../../shared/components/Table/Table";
import { Filters } from "../../../../shared/components/Filters/Filters";
import { PendientesColumns } from "../../components/TabsColumns/PendientesColumns";
import { EntityTypes, useQuerySession } from "../../../../shared/hooks/useQuerySession";

interface Props {
    id?: string | undefined;
    tipo?: EmailsSectionTypes;
    hideFilters?: boolean;
}

export const TabPendientes = ({
    id,
    tipo = EmailsSectionTypes.EMAILS,
    hideFilters
}: Props) => {
    const client = useClient();
    const toast = useToast();
    const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();
    const emailRef = useRef<any>(null)
    const { query, setQuery } = useQuerySession({
        entity:
            EmailsSectionTypes.MATRICULAS ? EntityTypes.MATRICULAS_EMAILS_PENDIENTES :
                EmailsSectionTypes.GRUPOS ? EntityTypes.GRUPOS_EMAILS_PENDIENTES :
                    EmailsSectionTypes.USUARIOS ? EntityTypes.USUARIOS_EMAILS_PENDIENTES :
                        EntityTypes.EMAILS_PENDIENTES
    });
    const { data: emails, loading, Refresh } = useData({
        client: client,
        endpoint: EndpointTypes.EMAILS_PENDIENTES,
        specificQuery: id ? { [tipo]: id } : undefined,
        query: query
    })

    const onEventRemove = (event: any) => {
        emailRef.current = event;

        if (isOpenDelete) onCloseDelete();
        onOpenDelete();
    };


    const onDelete = () => {
        deletePendingEmails({ id: emailRef.current?.id, client: client })
            .then(() => {
                Refresh()
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Email programado eliminado correctamente")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => onCloseDelete())
    }

    const getKeyWord = (defaultWord: string): string => {
        if (!emailRef.current?.nombre) return defaultWord

        const keyword = emailRef.current?.nombre;

        return keyword
    }

    return (
        <Flex gap="20px" direction="column">
            {!hideFilters &&
                <Filters
                    query={query}
                    setQuery={setQuery}
                    allowToggle={true}
                    filterHeaders={[
                        {
                            name: FiltrosNames.NOMBRE_EMAIL,
                            type: FiltrosTypes.INPUT,
                        },
                    ]}
                    filterElements={
                        tipo === EmailsSectionTypes.EMAILS ?
                            [
                                { name: FiltrosNames.GRUPO_ID, type: FiltrosTypes.ASYNC_SELECT },
                                { name: FiltrosNames.FECHA_ENVIO_SUP, type: FiltrosTypes.DATE, },
                                { name: FiltrosNames.FECHA_ENVIO_INF, type: FiltrosTypes.DATE, },
                            ]
                            :
                            [
                                { name: FiltrosNames.FECHA_ENVIO_SUP, type: FiltrosTypes.DATE, },
                                { name: FiltrosNames.FECHA_ENVIO_INF, type: FiltrosTypes.DATE, },
                            ]
                    }
                />
            }

            <Table
                query={query}
                setQuery={setQuery}
                data={emails?.data || []}
                columns={PendientesColumns({ onEventRemove, Refresh })}
                isLoading={loading}
                total={emails?.meta?.total || 0}
            />

            <ModalDelete
                isOpen={isOpenDelete}
                onClose={onCloseDelete}
                onDelete={onDelete}
                label={getKeyWord("email")}
            />
        </Flex>
    );
}