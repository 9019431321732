import { Box, Flex, Icon, Spinner } from "@chakra-ui/react"
import { useState } from "react";
import { BsArrowLeftSquareFill, BsArrowRightSquareFill, BsFillDashCircleFill, BsFillPlusCircleFill } from "react-icons/bs";
import { Document, Page } from 'react-pdf';

interface Props {
    url: string
    isResponsive?: boolean
}

export const PDFViewer = ({ url }: Props) => {
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [scale, setScale] = useState<number>(0.7);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    return (
        <Flex
            w={"100%"}
            h={"100%"}
            pos={"relative"}
        >
            <Flex
                w={"calc(100vw - 750px)"}
                css={{
                    '&::-webkit-scrollbar': {
                        width: '0',
                        height: '0',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#f1f1f1',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#c7c7c7',
                        borderRadius: '5px',
                    },
                    scrollBehavior: 'smooth',
                }}
                overflow="auto"
                pos={"relative"}
            >
                <Document
                    file={url}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={
                        <Spinner
                            color="font"
                            size="xl"
                        />
                    }
                >
                    <Page
                        pageNumber={pageNumber}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        loading={<Box h="800px" w="calc(100vw - 750px)" />}
                        scale={scale}
                    />
                </Document>
            </Flex>

            <Flex
                pos={"absolute"}
                top="10px"
                right="10px"
                bg="purewhite"
                p="10px"
                rounded="30px"
                gap="10px"
            >
                <Icon
                    as={BsFillDashCircleFill}
                    boxSize="22px"
                    color="pureblack"
                    cursor={scale >= 0.5 ? "pointer" : "not-allowed"}
                    _active={scale >= 0.5 ? { transform: "scale(0.9)" } : {}}
                    opacity={scale >= 0.5 ? 1 : 0.5}
                    onClick={() => {
                        if (scale >= 0.5)
                            setScale(scale - 0.05)
                    }}
                />

                <Box w="1px" bg="pureblack" />

                <Icon
                    as={BsFillPlusCircleFill}
                    boxSize="22px"
                    color="pureblack"
                    cursor={scale <= 1.1 ? "pointer" : "not-allowed"}
                    _active={scale <= 1.1 ? { transform: "scale(1.1)" } : {}}
                    opacity={scale <= 1.1 ? 1 : 0.5}
                    onClick={() => {
                        if (scale <= 1.1)
                            setScale(scale + 0.05)
                    }}
                />
            </Flex>

            <Flex
                pos={"absolute"}
                bottom="10px"
                right="10px"
                bg="purewhite"
                p="10px"
                rounded="2px"
                gap="10px"
            >
                <Icon
                    as={BsArrowLeftSquareFill}
                    boxSize="22px"
                    color="pureblack"
                    cursor={pageNumber === 1 ? "not-allowed" : "pointer"}
                    _active={pageNumber === 1 ? {} : { transform: "scale(0.9)" }}
                    opacity={pageNumber === 1 ? 0.5 : 1}
                    onClick={() => {
                        if (pageNumber > 1) setPageNumber(pageNumber - 1)
                    }}
                />

                <Box w="1px" bg="pureblack" />

                <Icon
                    as={BsArrowRightSquareFill}
                    boxSize="22px"
                    color="pureblack"
                    cursor={pageNumber === numPages ? "not-allowed" : "pointer"}
                    _active={pageNumber === numPages ? {} : { transform: "scale(1.1)" }}
                    opacity={pageNumber === numPages ? 0.5 : 1}
                    onClick={() => {
                        if (pageNumber < numPages!) setPageNumber(pageNumber + 1)
                    }}
                />
            </Flex>
        </Flex>
    )
}