import { Badge, Flex, Text, Link } from "@chakra-ui/react";
import { ColumnProps } from "../../../../shared/components/Table/Table"
import { UserInt } from "../../../../interfaces/UserInt";
import { BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import { ColumnLabelText } from "@imaginagroup/bit-components.ui.columns.column-label-text";
import { Link as RouterLink } from "react-router-dom";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { ColumnIcon } from "@imaginagroup/bit-components.ui.columns.column-icon";
import { handleLinkOpen } from "../../../../shared/utils/functions/linkOpen";
import { formatTimezone } from "../../../../shared/utils/functions/parseDate";
import { useTimeZone } from "../../../../shared/hooks/useTimeZone";


interface Props {
    moduloFUNDAE: boolean;
}

export const GruposColumns = ({ moduloFUNDAE }: Props): ColumnProps[] => {
    const timeZone = useTimeZone();

    const columns: ColumnProps[] = [
        {
            header: 'Duracion',
            field: 'estado',
            key: 'duracion',
            sortable: true,
            body: (rowData: any) => (
                <Link
                    as={RouterLink}
                    to={`/grupos/${rowData?.id}`}
                    _hover={{ textDecoration: "none" }}
                >
                    <Flex direction="column" alignItems="flex-start">
                        <Badge
                            color={
                                rowData?.estado == 2 ? "font" :
                                    rowData?.estado == 1 ? "secondary"
                                        : "success_main"
                            }
                            bg={
                                rowData?.estado == 2 ? "light_grey" :
                                    rowData?.estado == 1 ? "#EEEDFA"
                                        : "success_bg"
                            }
                            fontSize="10px"
                            fontWeight="500"
                            letterSpacing="0.2px"
                            textTransform="uppercase"
                            borderRadius="14px"
                            p="2px 5px"
                        >
                            {
                                rowData?.estado == 2 ? "Inactivo" :
                                    rowData?.estado == 1 ? "Próximo"
                                        : "Activo"
                            }
                        </Badge>
                        <Flex
                            alignItems="center"
                            gap="5px"
                            color="font"
                            fontSize="14px"
                            fontWeight="400"
                        >
                            <Text>{formatTimezone({ date: rowData?.fechaInicio, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                            <Text mb="2px">-</Text>
                            <Text>{formatTimezone({ date: rowData?.fechaFin, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                        </Flex>
                    </Flex>
                </Link>
            )
        },
        {
            header: 'Nombre',
            field: 'nombre',
            key: 'nombre',
            sortable: true,
            body: (rowData: any) => (
                <ColumnLabelText
                    label={rowData?.nombre}
                    text={rowData?.curso?.nombre}
                    link={{
                        to: `/grupos/${rowData?.id}`,
                    }}
                />
            )
        },
        {
            header: 'Total matrículas',
            field: 'meta.totalMatriculas',
            key: 'meta.totalMatriculas',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.meta?.totalMatriculas + " Matrículas"}
                    link={{
                        to: `/grupos/${rowData?.id}`,

                    }}
                />
            )
        },
        {
            header: 'Tutor asignado',
            field: 'tutor',
            key: 'tutor',
            body: (rowData: any) => (
                <Flex direction="column">
                    {!rowData?.tutores || rowData?.tutores?.length === 0 ?
                        <Text
                            color="font"
                            fontSize="14px"
                            fontWeight="500"
                        >
                            ---
                        </Text>
                        :
                        rowData?.tutores?.map((tutor: UserInt, index: number) => (
                            <Text
                                key={index}
                                color="font"
                                fontSize="14px"
                                fontWeight="500"
                            >
                                {tutor?.nombre} {tutor?.apellidos}
                            </Text>
                        ))}
                </Flex>
            )
        },
        {
            header: 'supervisor asignado',
            field: 'supervisor',
            key: 'supervisor',
            body: (rowData: any) => (
                <Flex direction="column">
                    {!rowData?.supervisores || rowData?.supervisores?.length === 0 ?
                        <Text
                            color="font"
                            fontSize="14px"
                            fontWeight="500"
                        >
                            ---
                        </Text>
                        :
                        rowData?.supervisores?.map((supervisor: any, index: number) => (
                            <Text
                                key={index}
                                color="font"
                                fontSize="14px"
                                fontWeight="500"
                            >
                                {supervisor?.user?.nombre} {supervisor?.user?.apellidos}
                            </Text>
                        ))}
                </Flex>
            )
        },
    ]

    const extraColumns: ColumnProps[] = [
        {
            header: 'FUNDAE',
            field: 'fundae',
            key: 'fundae',
            sortable: true,
            body: (rowData: any) => (
                rowData?.fundae
                    ?
                    <ColumnIcon
                        icon={BsCheckCircleFill}
                        ui={{
                            color: "#E9B418",
                            boxSize: "18px"
                        }}
                        link={{
                            to: `/grupos/${rowData?.id}`,
                        }}
                    />
                    :
                    <ColumnIcon
                        icon={BsXCircleFill}
                        ui={{
                            color: "#616675",
                            boxSize: "18px"
                        }}
                        link={{
                            to: `/grupos/${rowData?.id}`,
                        }}
                    />
            )
        },
    ]

    return moduloFUNDAE ? [...columns?.slice(0, 1), ...extraColumns, ...columns.slice(1)] : columns
}