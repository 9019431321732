import { Box, Flex } from "@chakra-ui/layout"
import { getFiltersData, updateFilterInput, updateFilters, updateMultiFilters } from "../../utils/functions/filterUtils"
import { FilterInt } from "./Filters"
import { FiltrosLabel, FiltrosNames, FiltrosSelect, FiltrosTypes } from "../../utils/Types/FiltrosEnum";
import { FilterInput } from "../FiltersElements/FilterInput";
import { FilterAsyncSelect } from "../FiltersElements/FilterAsyncSelect";
import { loadData } from "../../utils/functions/loadData";
import { useClient } from "../../context/client.context";
import { QueryTypes } from "../../utils/Types/QueryTypes";
import { EndpointTypes } from "../../utils/Types/EndpointTypes";
import { FilterSelect } from "../FiltersElements/FilterSelect";
import { FilterDateInput } from "@imaginagroup/bit-components.ui.filters.filter-date-input"
import { useEffect, useState } from "react";
import { getKey } from "../../middlewares/config.middleware";
import { EmpresasInt } from "../../../interfaces/EmpresasInt";
import { UserRolEnum } from "../../utils/Types/UserRolEnum";
import { useAuthContex } from "../../context/auth.context";
import { useUserPolicy } from "../../hooks/PermissionPolicy";
import { FilterSection } from "../../utils/Types/FilterSectionEnum";
import { useData } from "../../hooks/useData";
import { jsDateFormat } from "../../utils/functions/parseDate";

interface Props {
    filter: FilterInt;
    isSearch?: boolean;
    bgSelects?: string;
    clearAndReset: (setValue?: (value: null) => void) => void;
    filtros: { name: string; value: string; label: string; }[];
    setFiltros: (value: any) => void;
}

export const FilterTypes = ({
    filter,
    filtros,
    setFiltros,
    isSearch = false,
    bgSelects = "inherit",
    clearAndReset,
}: Props) => {
    const { gestion, contenido } = useUserPolicy();
    const { user } = useAuthContex()
    const client = useClient();
    const { data: empresasData } = useData({
        client: client,
        endpoint: EndpointTypes.EMPRESA,
        query: {
            interna: "true",
        },
        ignoreRequest: (user?.role?.nombre !== UserRolEnum.ADMIN || gestion || contenido),
    })

    const loadOptions = async (value: string, name: FiltrosNames, section?: string | undefined) => {
        if (name === FiltrosNames.EMPRESA && (contenido || gestion)) return;

        const endpoint: EndpointTypes | null = name === FiltrosNames.CURSO ? EndpointTypes.CURSOS
            : name === FiltrosNames.EMPRESA
                ? EndpointTypes.EMPRESA
                : name === FiltrosNames.GRUPO || name === FiltrosNames.GRUPO_ID ? EndpointTypes.GRUPOS
                    : name === FiltrosNames.TUTOR ? EndpointTypes.TUTORES
                        : null

        if (endpoint === null) return;

        const extraData: boolean = filter?.extraData ? filter?.extraData : false;

        return await loadData({
            value: value,
            endpoint: endpoint,
            client: client,
            specificQuery: (section && section === FilterSection.TUTOR_ENTREGAS_PENDIENTES) ? QueryTypes.ENTRGAS_PENDIENTES : undefined,
            query: name === FiltrosNames.TUTOR ? QueryTypes.NOMBRE_COMPLETO : QueryTypes.NOMBRE,
            nombreCompleto: name === FiltrosNames.TUTOR ? true : false,
            extraData: extraData,
            limit: 100,
        })
    }

    const selectOptions = (name: FiltrosNames, section?: string): { value: any; label: string }[] => {
        if (name === FiltrosNames.ESTADO_GRUPO) return FiltrosSelect.ESTADO_GRUPO
        if (name === FiltrosNames.FUNDAE) return FiltrosSelect.FUNDAE
        if (name === FiltrosNames.ESTADO_LIQUIDACION) return FiltrosSelect.ESTADO_LIQUIDACION
        if (name === FiltrosNames.CONTRATACION) return FiltrosSelect.CONTRATACION
        if (name === FiltrosNames.ESTADO_ENTREGABLE) return FiltrosSelect.ESTADO_ENTREGABLE
        if (name === FiltrosNames.ESTADO_USUARIO) return FiltrosSelect.ESTADO_USUARIO
        if (name === FiltrosNames.ESTADO_MATRICULA) return FiltrosSelect.ESTADO_MATRICULA
        if (name === FiltrosNames.SEMANAS) return FiltrosSelect.SEMANAS

        if (name === FiltrosNames.TIPO) return FiltrosSelect[section as keyof typeof FiltrosSelect]

        if (empresasData?.data && (name === FiltrosNames.ASIGNACION_EMPRESA || name === FiltrosNames.LIQUIDACION_EMPRESA)) {
            return [...empresasData?.data?.map((empresa: EmpresasInt) => ({ value: empresa?.id, label: empresa?.nombre }))]
        }

        return []
    }

    if (filter?.type === FiltrosTypes.INPUT)
        return (
            <Flex flex="1" alignItems="center" boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)" borderColor="light_grey">
                <FilterInput
                    value={(getFiltersData(filter?.name, filtros) as { value: string; label: string; })?.value}
                    name={filter?.name}
                    placeholder={FiltrosLabel[filter?.name as keyof typeof FiltrosLabel]}
                    onChange={(e: any) => {
                        setFiltros((prev: { name: string; value: string; label: string; }[]) => updateFilterInput(prev, e.target.name, e.target.value))
                    }}
                    isSearch={isSearch}
                />
            </Flex>
        )

    if (filter?.type === FiltrosTypes.ASYNC_SELECT)
        return (
            <Flex flex="1" alignItems="center" boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)" borderColor="light_grey">
                <Box w="100%" bg={bgSelects} rounded="7px">

                    <FilterAsyncSelect
                        value={getFiltersData(filter?.name, filtros, filter?.multi)}
                        onChange={(values: any) => {
                            filter?.multi ?
                                setFiltros((prev: { name: string; value: string; label: string; }[]) =>
                                    updateMultiFilters(prev, {
                                        name: filter?.name,
                                        data: values,
                                    })
                                )
                                :
                                setFiltros((prev: { name: string; value: string; label: string; }[]) => updateFilters(prev, {
                                    name: filter?.name,
                                    value: values.value,
                                    label: values.label
                                }))
                        }}
                        clearAndReset={clearAndReset}
                        loadOptions={(value: any) => loadOptions(value, filter?.name, filter?.section)}
                        defaultOptions={true}
                        placeholder={FiltrosLabel[filter?.name as keyof typeof FiltrosLabel]}
                        isMulti={filter?.multi}
                    />
                </Box>
            </Flex>
        )

    if (filter?.type === FiltrosTypes.SELECT)
        return (
            <Box flex="1" bg={bgSelects}>
                <FilterSelect
                    value={getFiltersData(filter?.name, filtros, filter?.multi)}
                    onChange={(values: any) => {
                        filter?.multi ?
                            setFiltros((prev: { name: string; value: string; label: string; }[]) =>
                                updateMultiFilters(prev, {
                                    name: filter?.name,
                                    data: values,
                                })
                            )
                            :
                            setFiltros((prev: { name: string; value: string; label: string; }[]) => updateFilters(prev, {
                                name: filter?.name,
                                value: values.value,
                                label: values.label
                            }))

                    }}
                    placeholder={FiltrosLabel[filter?.name as keyof typeof FiltrosLabel]}
                    options={selectOptions(filter?.name, filter?.section)}
                    isMulti={filter?.multi}
                />
            </Box>
        )

    if (filter?.type === FiltrosTypes.DATE)
        return (
            <Box flex="1">
                <FilterDateInput
                    locale="es"
                    value={(getFiltersData(filter?.name, filtros) as { value: string; label: string; })?.value}
                    onChange={(value: string) => {
                        setFiltros((prev: { name: string; value: string; label: string; }[]) => updateFilters(prev, {
                            name: filter?.name,
                            value: jsDateFormat({ date: value, format: "yyyy-MM-dd" }) as string,
                            label: jsDateFormat({ date: value, format: "dd/MM/yyyy" }) as string,
                        }))
                    }}
                />
            </Box>
        )
}