import { TipoPlantillasEnum } from "../Types/PlantillasTypesEnum";

export const plantillasData = {
    [TipoPlantillasEnum.BIENVENIDA]: {
        nombre: 'Bienvenida del alumno',
        descripcion: 'Plantilla de bienvenida para los alumnos, donde se les enviara las credenciales de acceso del campus y les informa de los pasos a seguir para comenzar su formación.',
    },
    [TipoPlantillasEnum.BIENVENIDA_SUPERVISOR]: {
        nombre: 'Bienvenida del supervisor',
        descripcion: 'Plantilla de bienvenida para los supervisores, donde se les enviaran las credenciales de acceso al panel de administracion.',
    },
    [TipoPlantillasEnum.BIENVENIDA_TUTOR]: {
        nombre: 'Bienvenida del tutor',
        descripcion: 'Plantilla de bienvenida para los tutores, donde se les enviaran las credenciales de acceso al campus.',
    },
    [TipoPlantillasEnum.CONTROL]: {
        nombre: 'Control del alumno',
        descripcion: 'Plantilla de seguimiento para los alumnos. Se trata de un resumen de su progreso en el 25%, 50% y 75% de la formación.',
    },
    [TipoPlantillasEnum.CONTROL_SUPERVISOR]: {
        nombre: 'Control del supervisor',
        descripcion: 'Plantilla de seguimiento para los supervisores. Se notifica con el progreso del grupo.',
    },
    [TipoPlantillasEnum.CORRECCION]: {
        nombre: 'Corrección de entregables',
        descripcion: 'Plantilla para el envío de las correcciones de los entregables de los alumnos.',
    },
    [TipoPlantillasEnum.DUDA]: {
        nombre: 'Nueva Duda',
        descripcion: 'Plantilla que avisa al tutor que un alumno tiene una nueva dudal',
    },
    [TipoPlantillasEnum.ENTREGABLE]: {
        nombre: 'Nuevo Entregable',
        descripcion: 'Plantilla que avisa al tutor para que corrija un entregable de un alumno.',
    },
    [TipoPlantillasEnum.INICIO_FUNDAE]: {
        nombre: 'Inicio Fundae',
        descripcion: 'Plantilla para las empresas organizadoras, con los datos de la acción formativa.',
    },
    [TipoPlantillasEnum.PREGUNTA]: {
        nombre: 'Nueva Pregunta',
        descripcion: 'Plantilla que avisa al tutor que un alumno ha realizado una nueva pregunta.',
    },
    [TipoPlantillasEnum.MENSAJE]: {
        nombre: 'Nuevo Mensaje',
        descripcion: 'Plantilla que avisa al receptor de cuando se envía un nuevo mensaje.',
    },
    [TipoPlantillasEnum.RECOVERY_EMAIL]: {
        nombre: 'Correo Recuperación',
        descripcion: 'Plantilla de recuperación de contraseña de la cuenta.',
    },
    [TipoPlantillasEnum.RESUMEN_SEMANAL]: {
        nombre: 'Resumen Semanal',
        descripcion: 'Plantilla para el envío del resumen del progreso de un grupo.',
    },
    [TipoPlantillasEnum.SINACCESO]: {
        nombre: 'Usuario no ha accedido',
        descripcion: 'Plantilla para notificar al usuario que no ha accedido al campus.',
    },
    [TipoPlantillasEnum.TUTOR_1]: {
        nombre: 'Dinamización Tutor 1',
        descripcion: 'Plantilla de la primera secuencia de dinamización del tutor.',
    },
    [TipoPlantillasEnum.TUTOR_2]: {
        nombre: 'Dinamización Tutor 2',
        descripcion: 'Plantilla de la segunda secuencia de dinamización del tutor.',
    },
    [TipoPlantillasEnum.TUTOR_3]: {
        nombre: 'Dinamización Tutor 3',
        descripcion: 'Plantilla de la tercera secuencia de dinamización del tutor.',
    },
    [TipoPlantillasEnum.TUTOR_4]: {
        nombre: 'Dinamización Tutor 4',
        descripcion: 'Plantilla de la cuarta secuencia de dinamización del tutor.',
    },
    [TipoPlantillasEnum.TUTOR_5]: {
        nombre: 'Dinamización Tutor 5',
        descripcion: 'Plantilla de la quinta secuencia de dinamización del tutor.',
    },
    [TipoPlantillasEnum.TUTOR_6]: {
        nombre: 'Dinamización Tutor 6',
        descripcion: 'Plantilla de la sexta secuencia de dinamización del tutor.',
    },
    [TipoPlantillasEnum.TUTOR]: {
        nombre: 'Dinamización Tutor',
        descripcion: 'Plantilla secuencia de dinamización del tutor de la semana 1 a la 6.',
    },
}