import { useState } from "react";
import { ColumnProps } from "../../../../shared/components/Table/Table"
import { useAuthContex } from "../../../../shared/context/auth.context";
import { Button, Flex, Icon, Text, useToast } from "@chakra-ui/react";
import { BiDownload, BiMedal } from "react-icons/bi";
import { UserRolEnum } from "../../../../shared/utils/Types/UserRolEnum";
import { isInspector } from "../../../../shared/utils/functions/validateRol";
import TiempoFormato from "../../../../shared/utils/functions/formatTime";
import { getInformes } from "../../../../shared/middlewares/informes.middleware";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { useClient } from "../../../../shared/context/client.context";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";

interface Props {
    moduloFUNDAE: boolean;
    grupoId: string | undefined;
}

export const InformesColumns = ({ moduloFUNDAE, grupoId }: Props): ColumnProps[] => {
    const { user } = useAuthContex();
    const client = useClient();
    const toast = useToast();
    const [diplomaLoading, setDiplomaLoading] = useState<boolean>(false)

    const validateRequisitos = (progreso: number, puntuacion: number) => {
        if (!progreso || !puntuacion) return false

        if (progreso >= 75 && puntuacion >= 75) return true
        else return false
    }

    const getHoraProcentaje = (tiempoAcceso: number, duracionCurso: number) => {
        if (!tiempoAcceso || !duracionCurso) return 0

        const tiempo = tiempoAcceso / 3600;

        const porcentaje = (tiempo / duracionCurso) * 100;

        return parseInt(porcentaje.toFixed(0)) <= 100 ? parseInt(porcentaje.toFixed(0)) : 100
    }


    const columns: ColumnProps[] = [
        {
            header: 'nombre',
            field: 'nombre',
            key: 'nombre',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.user?.nombre + " " + rowData?.user?.apellidos}
                    icon={{
                        name: BiMedal,
                        color: validateRequisitos(rowData?.progresoLecciones, rowData?.puntuacionMedia)
                            ? "main"
                            : "dark_grey",
                        size: "20px"
                    }}
                />
            )
        },
        {
            header: '% actividades completadas',
            field: 'progresoLecciones',
            key: 'progresoLecciones',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={parseInt(rowData?.progresoLecciones?.toFixed(0)) + " %"}
                    ui={{
                        colorFont: rowData?.progresoLecciones < 75 ? "fail" : "success_main"
                    }}
                />
            )
        },
        {
            header: '% ejercicios completados',
            field: 'progresoEntregables',
            key: 'progresoEntregables',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.progresoEntregables?.toFixed(0) + " %"}
                    ui={{
                        colorFont: rowData?.progresoEntregables < 75 ? "fail" : "success_main"
                    }}
                />
            )
        },
        {
            header: 'nota media',
            field: 'puntuacionMedia',
            key: 'puntuacionMedia',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.puntuacionMedia?.toFixed(0)}
                    ui={{
                        colorFont: (rowData?.puntuacionMedia || 0) < 75 ? "fail" : "success_main"
                    }}
                />
            )
        },
        {
            header: 'tiempo de acceso (horas/%)',
            field: 'tiempoAcceso',
            key: 'tiempoAcceso',
            sortable: true,
            body: (rowData: any) => (
                <Flex
                    justifyContent="center"
                    color="font"
                    gap="5px"
                >
                    <Text fontWeight="700">
                        <TiempoFormato tiempoAcceso={rowData?.tiempoAcceso} />{' '}
                    </Text>

                    <Text
                        fontSize="16px"
                        fontWeight="400"
                        color="font"
                    >
                        h / {getHoraProcentaje(rowData?.tiempoAcceso, rowData?.grupo?.curso?.duracion)}<Text fontSize="14px" as="span"> %</Text>
                    </Text>
                </Flex>
            )
        },
        {
            header: 'Formulario completado',
            field: 'formularioCompletado',
            key: 'formularioCompletado',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.formularioCompletado ? "Si" : "No"}
                    ui={{
                        fontSize: "14px",
                        styles: {
                            textTransform: "uppercase"
                        }
                    }}
                />
            )
        },
        {
            header: 'Descarga diploma',
            field: 'diplomaDescargado',
            key: 'diplomaDescargado',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.diplomaDescargado ? "Si" : "No"}
                    ui={{
                        fontSize: "14px",
                        styles: {
                            textTransform: "uppercase"
                        }
                    }}
                />
            )
        },
        {
            header: '',
            field: 'diploma',
            key: 'diploma',
            body: (rowData: any) => (
                <Button
                    mr="16px"
                    isDisabled={false} // || !rowData?.diplomaDescargado}
                    isLoading={diplomaLoading}
                    h="auto"
                    p="7px 12px"
                    bg="black"
                    color="white"
                    fontSize="12px"
                    fontWeight="600px"
                    rightIcon={<Icon as={BiDownload} boxSize="20px" />}
                    _hover={{ bg: "secondary", color: "black" }}
                    onClick={() => {
                        setDiplomaLoading(true)

                        getInformes({
                            data: {
                                grupoId: grupoId as string,
                                matriculas: [
                                    {
                                        id: rowData?.id,
                                        informes: ["diploma"],
                                        debug: true
                                    }
                                ]
                            },
                            client: client
                        })
                            .then(() => toastNotify(toast, StatusEnumTypes.SUCCESS, "El diploma se está generando, recibirá una notificación con el enlace de descarga"))
                            .catch((error) => {
                                const errors = handleErrors(
                                    error?.response?.data?.errors,
                                    error?.response?.status
                                )

                                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
                            })
                            .finally(() => setDiplomaLoading(false))
                    }}
                >
                    Diploma
                </Button>
            )
        },
    ];

    const columnsInformes: ColumnProps[] = [
        {
            header: '',
            field: '',
            key: '',
            selectionMode: "multiple",
        },
    ]

    return moduloFUNDAE ? [...columnsInformes, ...columns] : columns
}