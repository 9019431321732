import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import * as Sentry from "@sentry/react";
import * as Ably from 'ably';
import { AblyProvider } from 'ably/react';
import './css/styles.css'

const client = new Ably.Realtime({ key: import.meta.env.VITE_ABLY_API_KEY });

if (import.meta.env.VITE_SENTRY_APIKEY)
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_APIKEY,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [/^https:\/\/imagina.procademy\.es/]
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })

ReactDOM.createRoot(document.getElementById('root')!).render(
  <AblyProvider client={client}>
    <App />
  </AblyProvider>
)
