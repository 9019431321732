import { PlacementWithLogical, Tooltip, Link, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { formatTimezone } from "../../utils/functions/parseDate";
import { useTimeZone } from "../../hooks/useTimeZone";

interface Props {
    date: string;
    isDateTime?: boolean;
    link?: {
        to: string;
        onClick?: (event?: React.MouseEvent) => void;
    };
    tooltip?: {
        label: string | JSX.Element;
        isDisabled?: boolean;
        hasArrow?: boolean;
        placement?: PlacementWithLogical;
    },
}

export const ColumnDate = ({
    date,
    isDateTime = false,
    link,
    tooltip = {
        label: '',
        isDisabled: true,
        hasArrow: true,
        placement: 'bottom'

    },
}: Props) => {
    const timeZone = useTimeZone();

    return (
        <Tooltip
            label={tooltip?.label}
            isDisabled={tooltip?.isDisabled}
            hasArrow={tooltip?.hasArrow}
            placement={tooltip?.placement}
        >
            {link ?
                <Link
                    as={RouterLink}
                    to={link?.to}
                    onClick={link?.onClick}
                    _hover={{ textDecoration: 'none' }}
                    py="16px"
                    w="100%"
                >
                    <Text
                        color={"font"}
                        fontSize={"14px"}
                        fontWeight={400}
                    >
                        {date
                            ?
                            formatTimezone({
                                date,
                                timeZone,
                                customFormat: isDateTime ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy"
                            })
                            : "---"
                        }
                    </Text>
                </Link>
                :
                <Text
                    color={"font"}
                    fontSize={"14px"}
                    fontWeight={400}
                    py="16px"
                    w="100%"
                >
                    {date
                        ?
                        formatTimezone({
                            date,
                            timeZone,
                            customFormat: isDateTime ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy"
                        })
                        : "---"
                    }
                </Text>
            }
        </Tooltip>
    )
}