export enum EndpointTypes {
    ANUNCIOS = "/anuncios",
    EMAILS_LOGS = "/emailLogs",
    EMAILS_PENDIENTES = "/pendingEmails",
    LECCIONES = "/lecciones",
    MODULOS = "/modulos",
    CURSOS = "/cursos",
    EMPRESA = "/empresas",
    ENTREGABLES = "/entregables",
    ASIGNACIONES = "/asignaciones",
    LIQUIDACIONES = "/liquidaciones",
    GRUPOS = "/grupos",
    INSPECTOR = "/inspectores",
    MATRICULAS = "/matriculas",
    MENSAJERIA = "/mensajeria/mensajes",
    PREGUNTAS = "/foro/preguntas",
    PLANTILLAS = "/plantillas",
    SUPERVISORES = "/supervisores",
    USUARIOS = "/users",
    DATOS = "/cambioDatos",
    TUTORES = "/tutores",
    STATS_PENDIENTES = "/statsPendientesDashboard",
    STATS_ACTIVOS = "/statsDashboard",
    TAREAS = "/pendingTasks",
    STATS_MATRICULAS = "/statsMatriculasDashboard",
    FEEDBACK_CONFIG = "/feedbackConfigs",
    FEEDBACK_USERS = "/feedbackUsers",
    EXAMENES = "/examenes",
    BATCHES = "/batches",
    SESIONES = "/sesiones",
    CAMPUS_CONFIG_READ = "/readCampusConfig",
    CAMPUS_CONFIG_UPDATE = "/updateCampusConfig",
    CAMPUS_CONFIG_KEY = "/campusConfigKey",
    AUDITS = "/audits",
    PROGRESOS = "/progresos",
    INFO_TENANT = "/tenant/info",
    EXAMEN_PREGUNTAS = "/preguntas",
    RUTAS = "/rutas",
    RUTAS_GRUPOS = "/rutaGrupos",
}

export enum RelationshipTypes {
    GRUPOS = "/grupos",
    ALUMNOS = "/alumnos",
    MATRICULAS = "/matriculas",
    CURSOS = "/cursos",
}