import { Badge, Button, Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import { ColumnProps } from "../../../shared/components/Table/Table";
import { BsCheckCircleFill, BsChevronRight, BsXCircleFill } from "react-icons/bs";
import { useTimeZone } from "../../../shared/hooks/useTimeZone";
import { formatTimezone } from "../../../shared/utils/functions/parseDate";

export const ModalColumns = ({
    navigate,
    moduloFUNDAE
}: {
    navigate: (path: string) => void;
    moduloFUNDAE: boolean;
}): ColumnProps[] => {
    const timeZone = useTimeZone();

    const columns = [
        {
            header: 'Duracion',
            field: 'estado',
            key: 'estado',
            style: { width: "300px" },
            body: (rowData: any) => (
                <Flex direction="column" alignItems="flex-start">
                    <Badge
                        color={
                            rowData?.estado == 2 ? "#616675" :
                                rowData?.estado == 1 ? "secondary"
                                    : "success_main"
                        }
                        bg={
                            rowData?.estado == 2 ? "#DFE2E6" :
                                rowData?.estado == 1 ? "#EEEDFA"
                                    : "success_bg"
                        }
                        fontSize="10px"
                        fontWeight="500"
                        letterSpacing="0.2px"
                        textTransform="uppercase"
                        borderRadius="5px"
                        p="2px 5px"
                    >
                        {
                            rowData?.estado == 2 ? "Inactivo" :
                                rowData?.estado == 1 ? "Próximo"
                                    : "Activo"
                        }
                    </Badge>
                    <Flex
                        alignItems="center"
                        gap="5px"
                        color="font"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        <Text>{formatTimezone({ date: rowData?.fechaInicio, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                        <Text mb="2px">-</Text>
                        <Text>{formatTimezone({ date: rowData?.fechaFin, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                    </Flex>
                </Flex>
            )
        },
        {
            header: 'Nombre',
            field: 'nombre',
            key: 'nombre',
            body: (rowData: any) => (
                <Flex
                    alignItems="center"
                    gap="20px"
                >
                    <Flex direction="column">
                        <Text
                            color="font"
                            fontSize="14px"
                            fontWeight="500"
                        >
                            {rowData?.grupo?.nombre}
                        </Text>
                        <Text
                            color="#7A7D81"
                            fontSize="14px"
                            fontWeight="400"
                        >
                            {rowData?.grupo?.curso?.nombre}
                        </Text>
                    </Flex>

                    <Tooltip
                        hasArrow
                        label="Ir al grupo"
                    >
                        <Button
                            h="fit-content"
                            w="fit-content"
                            bg="secondary"
                            rounded="100%"
                            p="7px"
                            minW="0"
                            cursor="pointer"
                            _hover={{ bg: "variant" }}
                            onClick={() => navigate(`/grupos/${rowData?.grupo?.id}`)}
                            ml="auto"
                            mr="40%"
                        >
                            <Icon
                                color="purewhite"
                                as={BsChevronRight}
                                boxSize="15px"
                            />
                        </Button>
                    </Tooltip>
                </Flex>
            )
        },
    ]

    const extraColumns = [
        {
            header: 'FUNDAE',
            field: 'fundae',
            key: 'fundae',
            sortable: true,
            body: (rowData: any) => (
                rowData?.grupo?.fundae
                    ?
                    <Flex
                        alignItems="center"
                        px="20px"
                    >
                        <Icon
                            color="#E9B418"
                            as={BsCheckCircleFill}
                            boxSize="18px"

                        />

                    </Flex>
                    :

                    <Flex
                        alignItems="center"
                        px="20px"

                    >
                        <Icon
                            color="#616675"
                            as={BsXCircleFill}
                            boxSize="18px"

                        />

                    </Flex>
            )
        },
    ]

    return moduloFUNDAE ? [...columns.slice(0, 1), ...extraColumns, ...columns.slice(1)] : columns
}