import { Flex, Icon, Text } from "@chakra-ui/react";
import { Column } from "primereact/column";
import { BsEye } from "react-icons/bs";
import { formatTimezone } from "../../../../../../shared/utils/functions/parseDate";
import { useTimeZone } from "../../../../../../shared/hooks/useTimeZone";

export const MensajesColumns = (): JSX.Element[] => {
    const timeZone = useTimeZone();
    return [
        <Column
            header=''
            field=''
            key=''
            style={{
                width: "400px",
            }}
        />,
        <Column
            header='Remitente'
            field='emisor.nombre'
            key='emisor.nombre'
            body={(rowData: any) => (
                <Flex>
                    <Text fontWeight="semibold" fontSize={15}>{rowData?.emisor.nombre} {rowData?.emisor.apellidos}</Text>
                </Flex>
            )}
            style={{
                width: "400px"
            }}
        />,
        <Column
            header='Receptor'
            field='receptor'
            key='receptor'
            body={(rowData: any) => (
                <Flex>
                    <Text fontWeight="semibold" fontSize={15}>{rowData?.receptor.nombre} {rowData?.receptor.apellidos}</Text>
                </Flex>
            )}
            style={{
                width: "400px"
            }}
        />,
        <Column
            header='Fecha de envio'
            field='createdAt'
            key='createdAt'
            body={(rowData: any) => (
                <Flex>
                    <Text fontWeight="500" fontSize={15}>{formatTimezone({ date: rowData?.createdAt, timeZone, customFormat: 'dd/MM/yyy - HH:mm' })}</Text>
                </Flex>
            )}
            style={{
                width: "400px"
            }}
        />,
        <Column
            header=''
            field=''
            key='preview'
            body={() => (
                <Flex
                    cursor="pointer"
                >
                    <Icon as={BsEye} boxSize="20px" />
                </Flex>
            )}
            style={{
                width: "100px"
            }}
        />
    ]
}