import { Box, Button, Flex, Icon, Text, useToast } from "@chakra-ui/react"
import { InformationInput } from "@imaginagroup/bit-components.ui.elements.information-input"
import { useState } from "react";
import { BiLock, BiPencil, BiSave } from "react-icons/bi";
import { InformationAsyncCard } from "../../Information/InformationAsyncCard";
import { CustomSwitch } from "../CustomSwitch";
import { toastNotify } from "../../../utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../utils/Types/StatusEnumTypes";
import { ChromePicker } from "react-color";
import ColorPicker from "../ColorPicker";
import tinycolor from "tinycolor2";

export enum InformationRowTypes {
    INPUT = "input",
    ASYNC_SELECT = "async_select",
    SWITCH = "switch",
    COLOR_PICKER = "color_picker",
    NONE = "none"
}

export interface InputRowProps {
    isRequired?: boolean;
    name: string;
    label: string;
    defaultValue: string;
    updateValue: (values: { [key: string]: string | boolean | number }) => void;
    isDisabled: boolean;
    placeholder?: string;
    hideIcon?: boolean;
    hideBorder?: {
        top: boolean;
        bottom: boolean;
    };
}

export interface AsyncSelectRowProps {
    name: string;
    label: string;
    defaultValue: string;
    isRequired?: boolean;
    laodOptions: (inputValue: string) => void;
    updateValue: (values: { [key: string]: string | boolean | number }) => void;
    isDisabled: boolean;
    placeholder?: string;
    hideIcon?: boolean;
    hideBorder?: {
        top: boolean;
        bottom: boolean;
    };
    typeCard: "empresa" | "supervisor" | "tutor";
    removeItem: (id?: string) => void;
    allowRemove?: boolean;
}

export interface SwitchSelectRowProps {
    label: string;
    optionLeft: string;
    optionRight: string;
    setValue?: (value: boolean) => void;
    defaultValue: boolean;
    onClick: (event: any) => Promise<void>;
    isDisabled: boolean;
    hideIcon?: boolean;
    hideBorder?: {
        top: boolean;
        bottom: boolean;
    };
}

export interface ColorPickerRowProps {
    label: string;
    defaultValue: {
        main?: string;
        secondary?: string;
        variant?: string;
    }
    updateValue: (values: { [key: string]: string | string[] | object }) => void;
    hideIcon?: boolean;
    hideBorder?: {
        top: boolean;
        bottom: boolean;
    };
    isDisabled?: boolean;
}

export type InformationRowItems = InputRowProps | AsyncSelectRowProps | SwitchSelectRowProps | ColorPickerRowProps;

interface Props {
    type: InformationRowTypes;
    item: InformationRowItems;
}

export const InformationRow = ({
    type,
    item,
}: Props) => {
    const toast = useToast();
    const [value, setValue] = useState<boolean>((item as SwitchSelectRowProps)?.defaultValue);
    const [colorMain, setColorMain] = useState<string>((item as ColorPickerRowProps)?.defaultValue?.main || "#0F1122")
    const [colorSecondary, setColorSecondary] = useState<string>((item as ColorPickerRowProps)?.defaultValue?.secondary || "#8694FF")
    const [colorVariant, setColorVariant] = useState<string>((item as ColorPickerRowProps)?.defaultValue?.variant || "#C7CDFF")

    const generateVariants = (baseColor: string) => {
        const colorObj = tinycolor(baseColor);
        const lighter = colorObj.clone().lighten(20).toHexString();
        const darker = colorObj.clone().darken(50).toHexString();

        return { baseColor, darker, lighter };
    };
    const handleColorChange = (color: string) => {
        const { baseColor, darker, lighter } = generateVariants(color);

        setColorMain(baseColor);
        setColorSecondary(darker);
        setColorVariant(lighter);
    };

    if (type === InformationRowTypes.NONE) return null;
    else return (
        <Flex
            borderTop={!item?.hideBorder?.top ? "1px solid" : "none"}
            borderBottom={!item?.hideBorder?.bottom ? "1px solid" : "none"}
            borderColor="light_grey"
            p="10px 20px"
            alignItems="center"
            justifyContent="space-between"
            gap="10px"
        >
            {type === InformationRowTypes.INPUT &&
                <InformationInput
                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                    isRequired={(item as InputRowProps)?.isRequired || false}
                    name={(item as InputRowProps)?.name}
                    label={(item as InputRowProps)?.label}
                    defaultValue={(item as InputRowProps)?.defaultValue}
                    updateValue={(item as InputRowProps)?.updateValue}
                    isDisabled={(item as InputRowProps)?.isDisabled}
                    placeholder={(item as InputRowProps)?.placeholder}
                />
            }

            {type === InformationRowTypes.ASYNC_SELECT &&
                <InformationAsyncCard
                    data={(item as AsyncSelectRowProps)?.defaultValue}
                    label={(item as AsyncSelectRowProps)?.label}
                    placeholder={(item as AsyncSelectRowProps)?.placeholder || "Selecciona una opción"}
                    isRequired={(item as AsyncSelectRowProps)?.isRequired || false}
                    loadOptions={(item as AsyncSelectRowProps)?.laodOptions}
                    isDisabled={(item as AsyncSelectRowProps)?.isDisabled}
                    name={(item as AsyncSelectRowProps)?.name}
                    removeItem={(item as AsyncSelectRowProps)?.removeItem || (() => { })}
                    updateValue={(item as AsyncSelectRowProps)?.updateValue}
                    type={(item as AsyncSelectRowProps)?.typeCard}
                    allowRemove={(item as AsyncSelectRowProps)?.allowRemove || false}
                    styleLabel={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "font",
                        textTransform: "uppercase"
                    }}
                    containerStyle={{
                        py: "20px",
                    }}
                />
            }

            {type === InformationRowTypes.SWITCH &&
                <CustomSwitch
                    padding="10px 0px"
                    labelStyles={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "font",
                        textTransform: "uppercase"
                    }}
                    label={(item as SwitchSelectRowProps)?.label}
                    optionLeft={(item as SwitchSelectRowProps)?.optionLeft || "Si"}
                    optionRight={(item as SwitchSelectRowProps)?.optionRight || "No"}
                    setValue={setValue}
                    value={value}
                    colorSwitch="light_grey"
                    onClick={(item as SwitchSelectRowProps)?.onClick}
                    isDisabled={(item as SwitchSelectRowProps)?.isDisabled || false}
                />
            }

            {type === InformationRowTypes.COLOR_PICKER &&
                <Flex
                    w="95%"
                    gap="10px"
                    alignItems="center"
                >
                    <Text
                        display="flex"
                        fontSize="12px"
                        fontWeight="600"
                        color="font"
                        textTransform="uppercase"
                        w="52%"
                    >
                        Colores
                    </Text>

                    <ColorPicker
                        main={colorMain}
                        secondary={colorSecondary}
                        variant={colorVariant}
                        initialColor={colorMain}
                        onChange={(color: string) => handleColorChange(color)}
                        isConfig
                    />

                    <Flex
                        justifyContent="center"
                        alignItems="center"
                        ml="50px"
                    >
                        <Button
                            onClick={() => {
                                (item as ColorPickerRowProps)?.updateValue({
                                    colores: { main: colorMain, secondary: colorSecondary, variant: colorVariant }
                                })
                            }}
                            bg="secondary"
                            color="purewhite"
                            borderRadius="5px"
                            fontSize="13px"
                            fontWeight="400"
                            h="fit-content"
                            p="5px 12px"
                            _hover={{ bg: "variant" }}
                            rightIcon={<Icon as={BiSave} boxSize="20px" />}
                        >
                            Guardar
                        </Button>
                    </Flex>
                </Flex >
            }

            <Flex
                boxSize="20px"
            >
                {(item?.isDisabled && !item?.hideIcon) && <Icon as={BiLock} boxSize="20px" />}
            </Flex>
        </Flex>
    )
}