import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    Box,
} from '@chakra-ui/react'
import { useClient } from '../../../shared/context/client.context';
import { useDataId } from '../../../shared/hooks/useDataId';
import { EndpointTypes } from '../../../shared/utils/Types/EndpointTypes';
import { TabResumen } from '../../Matriculas/views/Information/TabItems/TabResumen';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    matriculaId: string | null;
}

export const ResumenModal = ({
    isOpen,
    onClose,
    matriculaId
}: Props) => {
    const client = useClient()
    const { data } = useDataId({
        id: matriculaId as string | undefined,
        endpoint: EndpointTypes.MATRICULAS,
        client: client,
        ignoreRequest: !matriculaId
    })

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose()}
            closeOnOverlayClick={false}
            size="xs"
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                rounded="20px"
            >
                <ModalCloseButton onClick={onClose} />

                <Box
                    mx="15px"
                    my="20px"
                    overflowY="scroll"
                    overflowX="hidden"
                    __css={{
                        '&::-webkit-scrollbar': {
                            w: '7px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: '20px',
                            bg: `light_grey`,
                        },
                    }}
                >
                    <ModalBody>
                        <TabResumen
                            matricula={data}
                        />
                    </ModalBody>
                </Box>
            </ModalContent>
        </Modal>
    )
}