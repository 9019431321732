import { formatKeys } from "../functions/formatKeys";
import { AuditsTipoEnum } from "./AuditsTypes";
import { EntregableEstadoEnum } from "./EntregableEstadoEnum";
import { FilterSection } from "./FilterSectionEnum";

export enum FiltrosNames {
    NOMBRE_COMPLETO = 'nombreCompleto',
    NOMBRE = "nombre",
    EMAIL = "email",
    TELEFONO = "telefono",
    ESTADO_GRUPO = "estado",
    FUNDAE = "fundae",
    INF_PREGRESO = "infProgresoLecciones",
    SEMANAS = "semanas",
    CURSO = "curso",
    EMPRESA_ID = "empresaId",
    GRUPO_ID = "grupoId",
    EMPRESA = "empresa",
    GRUPO = "grupo",
    TUTOR = "tutor",
    ESTADO_LIQUIDACION = "liquidada",
    ASIGNACION_EMPRESA = "asignacionEmpresaFactura",
    LIQUIDACION_EMPRESA = "liquidacionEmpresaFactura",
    CONTRATACION = "tutorFreelance",
    FECHA_INICIO_SUP = "fechaInicioSup",
    FECHA_INICIO_INF = "fechaInicioInf",
    FECHA_FIN_SUP = "fechaFinSup",
    FECHA_FIN_INF = "fechaFinInf",
    FECHA_ENVIO_INF = "fechaEnvioInf",
    FECHA_ENVIO_SUP = "fechaEnvioSup",
    FECHA_PAGO_INICIO = "fechaPagoInicio",
    FECHA_PAGO_FIN = "fechaPagoFin",
    ESTADO_ENTREGABLE = "entregableEstado",
    ACCION_FORMATIVA = "accionFormativa",
    DESVIACION = "conDesviacion",
    POR_CONFIGURAR = "porConfigurar",
    ESTADO_USUARIO = "activo",
    ESTADO_MATRICULA = "matriculaActiva",
    SIN_ACCEDER = "sinAcceder",
    USERNAME = "username",
    NOMBRE_EMAIL = "nombreYemail",
    FECHA_ENTREGA = "fechaEntrega",
    TIPO = "tipo",
    MATRICULA_DE_BAJA = "deBaja",
    ID_GRUPO = "idFundae",
    CODIGO_GRUPO = "codigoGrupo",
    UPDATED_AT = "updatedAt",
}

export enum FiltrosTypes {
    INPUT = "input",
    SELECT = "select",
    DATE = "date",
    ASYNC_SELECT = "asyncSelect",
}

export const FiltrosLabel: {
    idFundae: string;
    codigoGrupo: string;
    nombreCompleto: string;
    nombre: string;
    email: string;
    telefono: string;
    estado: string;
    fundae: string;
    infProgresoLecciones: string;
    semanas: string;
    curso: string;
    empresaId: string;
    grupoId: string;
    empresa: string;
    grupo: string;
    tutor: string;
    liquidada: string;
    asignacionEmpresaFactura: string;
    liquidacionEmpresaFactura: string;
    tutorFreelance: string;
    fechaInicioSup: string;
    fechaInicioInf: string;
    fechaFinSup: string;
    fechaFinInf: string;
    fechaEnvioInf: string;
    fechaEnvioSup: string;
    fechaPagoInicio: string;
    fechaPagoFin: string;
    entregableEstado: string;
    accionFormativa: string;
    conDesviacion: string;
    porConfigurar: string;
    activo: string;
    matriculaActiva: string;
    sinAcceder: string;
    username: string;
    nombreYemail: string;
    fechaEntrega: string;
    tipo: string;
    deBaja: string;
} = {
    nombreCompleto: "Nombre",
    nombre: "Nombre",
    email: "Email",
    telefono: "Teléfono",
    estado: "Estado",
    fundae: "FUNDAE",
    infProgresoLecciones: "Progreso lecciones",
    semanas: "Semanas",
    curso: "Curso",
    empresaId: "Empresa",
    grupoId: "Grupo",
    empresa: "Empresa",
    grupo: "Grupo",
    tutor: "Tutor",
    liquidada: "Pagada",
    asignacionEmpresaFactura: "Empresa factura",
    liquidacionEmpresaFactura: "Empresa factura",
    tutorFreelance: "Contratación",
    fechaInicioSup: "Fecha inicio sup",
    fechaInicioInf: "Fecha inicio inf",
    fechaFinInf: "Fecha fin inf",
    fechaFinSup: "Fecha fin sup",
    fechaEnvioInf: "Fecha envío inf",
    fechaEnvioSup: "Fecha envío sup",
    fechaPagoInicio: "Fecha pago inicio",
    fechaPagoFin: "Fecha pago fin",
    entregableEstado: "Estado",
    accionFormativa: "Acción formativa",
    conDesviacion: "Con desviación",
    porConfigurar: "Por configurar",
    activo: "Estado usuario",
    matriculaActiva: "Estado matrícula",
    sinAcceder: "Sin acceder",
    username: "Username",
    nombreYemail: "Nombre o email",
    fechaEntrega: "Fecha entrega",
    tipo: "Tipo",
    deBaja: "Matrícula de baja",
    idFundae: "Grupo Id",
    codigoGrupo: "Código Grupo",
}

export const FiltrosSelect = {
    ESTADO_GRUPO: [
        { value: "0", label: "Activo" },
        { value: "1", label: "Próximo" },
        { value: "2", label: "Inactivo" },
    ],
    FUNDAE: [
        { value: true, label: "FUNDAE" },
        { value: false, label: "No FUNDAE" },
    ], 
    ESTADO_LIQUIDACION: [
        { value: true, label: "Si" },
        { value: false, label: "No" },
    ],
    CONTRATACION: [
        { value: true, label: "Colaborador" },
        { value: false, label: "Contratado" },
    ],
    ESTADO_ENTREGABLE: [
        { value: EntregableEstadoEnum.PENDIENTE_CORRECCION, label: "Pendiente" },
        { value: EntregableEstadoEnum.CORRECTO, label: "Correcto" },
        { value: EntregableEstadoEnum.ERROR, label: "Incorrecto" },
    ],
    ESTADO_USUARIO: [
        { value: true, label: "Activos" },
        { value: false, label: "Inactivos" },
    ],
    ESTADO_MATRICULA: [
        { value: true, label: "Activas" },
        { value: false, label: "Inactivas" },
    ],
    SEMANAS: [
        { value: "1", label: "1 Semana" },
        { value: "2", label: "2 Semanas" },
        { value: "3", label: "3 Semanas" },
        { value: "4", label: "4 Semanas" },
    ],
   [FilterSection.TIPO_FEEDBACK]: [
        { value: "curso", label: "Curso" },
        { value: "modulo", label: "Módulo" },
        { value: "leccion", label: "Lección" },
        { value: "tutor", label: "Tutor" },
    ],
    [FilterSection.TIPO_AUDITS]: 
        [...Object.entries(AuditsTipoEnum)?.map(([_key, value]) => ({ value, label: formatKeys(value) }))]
}