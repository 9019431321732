import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/accordion";
import { Flex, Text } from "@chakra-ui/layout";
import { Button, Icon, useDisclosure, useToast } from "@chakra-ui/react";
import { truncate } from "lodash";
import { AnimateLayoutChanges, SortableContext, arrayMove, defaultAnimateLayoutChanges, sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DndContext, KeyboardSensor, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { updateLeccion } from "../../../../shared/middlewares/lecciones.middleware";
import { LeccionInt, ModulosInt } from "../../../../interfaces/CursosInt";
import { useClient } from "../../../../shared/context/client.context";
import { AiOutlineHolder } from "react-icons/ai";
import { DndLecciones } from "./Lecciones";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { useData } from "../../../../shared/hooks/useData";
import { BiPlus } from "react-icons/bi";
import { TipoSelect } from "../../views/Information/TabItem/TabContenido";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { updateModulo } from "../../../../shared/middlewares/modulos.middleware";

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
    defaultAnimateLayoutChanges({ ...args, wasDragging: true });

interface Props {
    modulo: any;
    selected: { type: TipoSelect; data: ModulosInt | LeccionInt | null; } | undefined;
    setSelected: Dispatch<SetStateAction<{ type: TipoSelect; data: ModulosInt | LeccionInt | null; } | undefined>>;
    id: string | undefined;
    refreshData: { modulo: boolean, leccion: boolean };
    setRefreshData: Dispatch<SetStateAction<{ leccion: boolean; modulo: boolean; }>>;
}

export const DndModulos = ({
    modulo,
    selected,
    setSelected,
    id,
    refreshData,
    setRefreshData
}: Props) => {
    const toast = useToast();
    const client = useClient();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenLoading, onOpen: onOpenLoading, onClose: onCloseLoading } = useDisclosure();
    const [estado, setEstado] = useState<boolean>(modulo?.publicado)
    const [lecciones, setLecciones] = useState<any[]>([])

    const { data, Refresh } = useData({
        endpoint: EndpointTypes.LECCIONES,
        client: client,
        specificQuery: {
            modulo: id!,
            sortBy: "orden",
            order: "asc",
            limit: 100
        },
        ignoreRequest: !id
    })

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    useEffect(() => {
        if (data?.data && data?.data.length > 0) {
            setLecciones(data?.data)

            if (selected?.type === TipoSelect.LECCION) {
                const leccion = data?.data?.find((leccion: any) => leccion?.id === selected?.data?.id)

                if (leccion)
                    setSelected({
                        type: TipoSelect.LECCION,
                        data: leccion
                    })
            }
        }

    }, [data])

    useEffect(() => {
        if (refreshData?.leccion) {
            setLecciones((prev: any) => {
                const lecciones = prev?.filter((leccion: any) => !leccion?.isNew && leccion?.isNew !== true)

                return lecciones

            })
            Refresh()
            setRefreshData((prev) => ({ ...prev, leccion: false }))
        }
    }, [refreshData])

    const {
        attributes,
        isDragging,
        listeners,
        setNodeRef,
        transition,
        transform,
    } = useSortable({
        id: modulo?.id,
        data: {
            type: 'container',
            children: lecciones,
        },
        animateLayoutChanges,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }

    const handleDragEnd = (event: any) => {
        const { active, over } = event;

        const oldIndex = lecciones?.findIndex((leccion: any) => leccion?.id === active?.id)
        const newIndex = lecciones?.findIndex((leccion: any) => leccion?.id === over?.id)

        const newOrder = arrayMove(lecciones, oldIndex, newIndex)

        const newLeccionesData: LeccionInt[] = []

        newOrder?.map((l: LeccionInt, index: number) => {
            const leccion = {
                ...l,
                orden: (index + 1)
            }

            newLeccionesData.push(leccion)
            updateOrder(leccion)
        })

        setLecciones(newLeccionesData)
    };

    const updateOrder = (leccion: LeccionInt) => {
        if (!leccion?.id) return

        updateLeccion({
            id: leccion?.id,
            data: {
                orden: leccion.orden
            },
            client: client
        })
    }

    const handleDragOver = (event: any) => {
        const overId = event?.over?.id;

        if (overId == null) {
            return;
        }

        const overContainerIndex = lecciones.findIndex((leccion) => leccion.id === overId);
        const activeContainerIndex = lecciones.findIndex((leccion) => leccion.id === event?.active.id);

        if (overContainerIndex !== -1 && activeContainerIndex !== -1 && activeContainerIndex !== overContainerIndex) {
            setLecciones((currentLecciones) => {
                const newLecciones = [...currentLecciones];
                const [movingItem] = newLecciones.splice(activeContainerIndex, 1);
                newLecciones.splice(overContainerIndex, 0, movingItem);

                return newLecciones?.map((leccion, index) => ({
                    ...leccion,
                    orden: index + 1,
                }));
            });
        }
    }

    const changeEstadoModulo = async (event: React.MouseEvent) => {
        event.stopPropagation()
        const initState = estado
        setEstado(!initState)

        updateModulo({
            id: modulo?.id,
            data: {
                publicado: !initState
            },
            client: client
        })
            .then(() => setRefreshData((prev) => ({ ...prev, modulo: true })))
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
                setEstado(initState)
            })
    }


    // const onSubmit = (data: any) => {
    //     onOpenLoading()

    //     const lecciones: any[] = [];

    //     for (let i = 0; i < data?.validData?.length; i++) {
    //         const newData = {
    //             ...data?.validData[i],
    //             moduloId: modulo?.id,
    //         }

    //         lecciones.push(newData)
    //     }

    //     addLeccionesImport({
    //         data: lecciones,
    //         client: client
    //     })
    //         .then(() => {
    //             toastNotify(toast, StatusEnumTypes.SUCCESS, `Lecciones creadas correctamente`)
    //             setRefreshData({ leccion: true, modulo: true });
    //         })
    //         .catch((error: any) => {
    //             const errors = handleErrors(
    //                 error?.response?.data?.errors,
    //                 error?.response?.status
    //             )

    //             errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
    //         })
    //         .finally(() => onCloseLoading())
    // }

    return (
        <>
            <AccordionItem
                ref={setNodeRef}
                style={{
                    ...style,
                    transition,
                    transform: CSS.Translate.toString(transform),
                    opacity: isDragging ? 0.5 : undefined,
                }}
                {...attributes}
                display="flex"
                flexDirection="column"
                bg="purewhite"
                p="20px"
                w={"340px"}
                rounded="10px"
                shadow="0px 1px 4px 0px rgba(9, 51, 72, 0.15)"
                border={"1px solid"}
                borderColor={selected?.data?.id === modulo?.id ? "secondary" : "transparent"}
                onClick={(event: React.MouseEvent) => {
                    event.stopPropagation()

                    setSelected({
                        type: TipoSelect.MODULO,
                        data: modulo
                    })
                }}
            >
                {({ isExpanded }) => (
                    <>

                        <Flex
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            w="100%"
                            mb={isExpanded ? "15px" : "0"}
                        >
                            <Flex
                                alignItems="center"
                                gap="8px"
                            >
                                <Icon as={AiOutlineHolder} boxSize="18px" color="pureblack" cursor="grab" {...listeners} />

                                <Text
                                    color="pureblack"
                                    fontSize="16px"
                                    fontWeight="400"
                                    lineHeight="100%"
                                    letterSpacing="-0.16px"
                                >
                                    {truncate(modulo?.nombre, { length: 33 })}
                                </Text>

                                <Icon
                                    onClick={changeEstadoModulo}
                                    _active={{ transform: "scale(0.95)" }}
                                    as={estado ? HiOutlineEye : HiOutlineEyeOff}
                                    color={"pureblack"}
                                    mt="2px"
                                />
                            </Flex>

                            <AccordionButton
                                w="fit-content"
                                h="fit-content"
                                p="5px"
                                rounded="100%"
                                _hover={{ bg: "variant" }}
                                onClick={(event: React.MouseEvent) => { event.stopPropagation() }}
                            >
                                <AccordionIcon boxSize="19px" color="pureblack" />
                            </AccordionButton>
                        </Flex>


                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                            modifiers={[restrictToVerticalAxis]}
                            onDragOver={handleDragOver}
                        >
                            <AccordionPanel
                                display="flex"
                                flexDir="column"
                                p="0"
                                gap="5px"
                            >
                                <SortableContext
                                    items={lecciones}
                                    strategy={verticalListSortingStrategy}
                                >
                                    {lecciones?.map((leccion: LeccionInt) => (
                                        <DndLecciones
                                            key={leccion.id}
                                            leccion={leccion}
                                            selected={selected}
                                            setSelected={setSelected}
                                            setRefreshData={setRefreshData}
                                        />
                                    ))}
                                </SortableContext>
                            </AccordionPanel>
                        </DndContext>

                        <Flex
                            gap="20px"
                            mt="15px"
                            display={isExpanded ? "flex" : "none"}
                        >
                            <Button
                                h="fit-content"
                                w="fit-content"
                                py="10px"
                                px="10px"
                                rounded={"6px"}
                                border="0.6px solid"
                                borderColor="#AAA"
                                bg="white"
                                fontSize="14px"
                                color="pureblack"
                                fontWeight="400"
                                leftIcon={<Icon as={BiPlus} boxSize="18px" color="pureblack" />}
                                onClick={async (event: React.MouseEvent) => {
                                    event.stopPropagation()
                                    const lastOrden = modulo?.lecciones && modulo?.lecciones?.length > 0 ? modulo?.lecciones?.length + 1 : 1

                                    const newData = {
                                        isNew: true,
                                        moduloId: modulo?.id,
                                        nombre: `Lección ${lastOrden}`,
                                        publicado: false,
                                        orden: lastOrden,
                                        tipo: null,
                                        contenido: null,
                                        descripcion: `Esta es una lección de ejemplo para el módulo ${modulo?.nombre}, puedes borrarla o modificarla.`,
                                    }

                                    setSelected({
                                        type: TipoSelect.NUEVA_LECCION,
                                        data: newData
                                    })

                                    setLecciones((prev: any) => [...prev, newData])
                                }}
                            >
                                Añadir lección
                            </Button>

                            {/* <Button
                                h="fit-content"
                                w="fit-content"
                                py="10px"
                                px="10px"
                                rounded={"6px"}
                                border="0.6px solid"
                                borderColor="#AAA"
                                bg="white"
                                fontSize="14px"
                                color="pureblack"
                                fontWeight="400"
                                leftIcon={<Icon as={BiPlus} boxSize="18px" color="pureblack" />}
                                onClick={(event: React.MouseEvent) => {
                                    event.stopPropagation()

                                    onOpenLeccion()
                                }}
                            >
                                Carga masiva
                            </Button> */}
                        </Flex>
                    </>
                )}
            </AccordionItem>


            {/* <ReactSpreadsheetImport
                    isOpen={isOpen}
                    onClose={onClose}
                    onSubmit={onSubmit}
                    fields={getLeccionesFields()}
                    customTheme={SpreadSheetTheme}
                    translations={translations}
                /> */}

            {/* <ModaLoading
                    isOpen={isOpenLoading}
                /> */}
        </>
    );
}