import { Flex } from "@chakra-ui/react"
import { Route, Routes } from "react-router-dom"
import { ExamenesTable } from "./views/Table/ExamenesTable"
import { TabsExamenesInformation } from "./views/Information/TabsInformation"
import { Helmet } from "react-helmet"

export const Examenes = () => {
    return (
        <Flex
            direction="column"
            w="100%"
        >
            <Helmet>
                <title>Exámenes | Proacademy</title>
            </Helmet>
            <Flex direction="column">
                <Routes>
                    <Route
                        index
                        element={
                            <ExamenesTable />
                        }
                    />
                </Routes>

                <Routes>
                    <Route
                        path="/:id"
                        element={
                            <TabsExamenesInformation />
                        }
                    />
                </Routes>
            </Flex>
        </Flex>
    )
}