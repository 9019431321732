import { Button, Flex, Icon, Text, useToast } from "@chakra-ui/react"
import { InformationBox } from "../../../../shared/components/CustomElements/InformationBox/InformationBox"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { ModulosInt } from "../../../../interfaces/CursosInt"
import { updateModulo } from "../../../../shared/middlewares/modulos.middleware"
import { toastNotify } from "../../../../shared/utils/functions/toastNotify"
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes"
import { handleErrors } from "../../../../shared/utils/functions/handleErrors"
import { useClient } from "../../../../shared/context/client.context"
import { Form as FormikForm, Formik } from 'formik';
import "../../../../ui/scss/cargaMasiva.scss"
import { FormInput } from "@imaginagroup/bit-components.ui.form-input"
import { FormTextarea } from "@imaginagroup/bit-components.ui.form-textarea"
import { BiEdit } from "react-icons/bi"
import { useTenantInfo } from "../../../../shared/hooks/useTenantInfo"
import { FiEdit3 } from "react-icons/fi"

interface Props {
    modulo: ModulosInt | undefined;
    setRefreshData: Dispatch<SetStateAction<{ leccion: boolean; modulo: boolean; }>>;
}
export const EditModule = ({ modulo, setRefreshData }: Props) => {
    const client = useClient();
    const toast = useToast();
    const [editName, setEditName] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { handleGetInfo } = useTenantInfo();
    const colores = handleGetInfo({ key: "colores" });

    useEffect(() => {
        if (editName) {
            const inputElement: HTMLInputElement | null = document.querySelector('input[name="nombre"]');

            if (inputElement) {
                inputElement.focus();
            }
        }
    }, [editName])

    const initialValues = {
        nombre: modulo?.nombre,
        descripcion: modulo?.descripcion,
    }

    const handleSubmit = async (values: any) => {
        setLoading(true);
        let newValue = values

        if (values?.descripcion === "") {
            newValue = {
                ...values,
                descripcion: null
            }
        }

        return updateModulo({
            id: modulo?.id as string,
            data: newValue,
            client: client
        })
            .then(() => {
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Campo actualizado correctamente");
                setRefreshData((prev) => ({ ...prev, modulo: true }));
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    }

    const isExistsChanges = (values: {
        nombre: string | undefined;
        descripcion: string | undefined;
    }) => {
        if (
            values?.nombre !== modulo?.nombre ||
            values?.descripcion !== modulo?.descripcion
        ) return true

        return false
    }

    return (
        <Flex
            w="100%"
            direction="column"
            px="10px"
            pt="10px"
        >
            <Formik
                onSubmit={handleSubmit}
                enableReinitialize
                initialValues={initialValues}
            >
                {(formik) => {
                    const { handleSubmit, values } = formik;

                    return (
                        <FormikForm
                            onSubmit={handleSubmit}
                        >
                            <Flex
                                justifyContent="space-between"
                                mb="20px"
                            >
                                <Flex
                                    h="40px"
                                    alignItems="center"
                                >
                                    {!editName ?
                                        <Flex
                                            alignItems="center"
                                            gap="10px"
                                            mt="2px"
                                            onClick={() => setEditName(true)}
                                        >
                                            <Text
                                                fontSize="24px"
                                                fontWeight="400"
                                                color="pureblack"
                                                pl="2px"
                                            >
                                                {modulo?.nombre}
                                            </Text>

                                            <Icon
                                                as={FiEdit3}
                                                boxSize="18px"
                                                color="pureblack"
                                                cursor="pointer"
                                            />
                                        </Flex>
                                        :
                                        <FormInput
                                            name="nombre"
                                            isBlockError
                                            onBlur={() => setEditName(false)}
                                            ui={{
                                                styleInput: {
                                                    border: "none",
                                                    bg: "transparent",
                                                    width: "auto",
                                                    fontSize: "24px",
                                                    fontWeight: "400",
                                                    color: "pureblack",
                                                    pl: "2px",
                                                },
                                                focusInput: {
                                                    border: "none",
                                                    boxShadow: `0 0 0 1px ${colores.variant}`,
                                                },
                                            }}
                                        />
                                    }
                                </Flex>

                                <Flex
                                    alignItems="center"
                                    gap="10px"
                                >
                                    <Button
                                        h="fit-content"
                                        w="fit-content"
                                        py="10px"
                                        px="10px"
                                        rounded={"6px"}
                                        bg="secondary"
                                        fontSize="14px"
                                        color="purewhite"
                                        fontWeight="400"
                                        type="submit"
                                        isDisabled={!modulo?.id || !isExistsChanges(values)}
                                        isLoading={loading}
                                    >
                                        Guardar módulo
                                    </Button>

                                    <Button
                                        isDisabled={
                                            (!modulo?.id ||
                                                !isExistsChanges(values) ||
                                                loading) &&
                                            !editName
                                        }
                                        h="fit-content"
                                        w="fit-content"
                                        py="10px"
                                        px="10px"
                                        rounded={"6px"}
                                        border="1px solid"
                                        borderColor="#AAA"
                                        bg="white"
                                        fontSize="14px"
                                        color="pureblack"
                                        fontWeight="400"
                                        onClick={() => {
                                            formik.resetForm()
                                            setEditName(false)
                                        }}
                                    >
                                        Descartar cambios
                                    </Button>
                                </Flex>
                            </Flex>

                            <Flex>
                                <InformationBox
                                    noHeader
                                >
                                    <Flex
                                        direction="column"
                                        py="20px"
                                        px="20px"
                                        gap="30px"
                                    >
                                        <FormTextarea
                                            label="Descripción módulo"
                                            name="descripcion"
                                            ui={{
                                                minHeigh: "100px",
                                                styleLabel: {
                                                    color: "font",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                },
                                                styles: {
                                                    border: "1px solid",
                                                    borderColor: "#E2E8F0",
                                                    borderRadius: "5px",
                                                    color: "font",
                                                    padding: "5px",
                                                    maxHeight: "100px",
                                                }
                                            }}
                                        />
                                    </Flex>
                                </InformationBox>
                            </Flex>
                        </FormikForm>
                    );
                }}
            </Formik>
        </Flex>
    );
}