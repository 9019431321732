import { Button, Flex, useToast } from "@chakra-ui/react";
import { PreguntaInt } from "../../../../interfaces/ExamenInt";
import { InformationBox } from "../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { useState } from "react";
import { useClient } from "../../../../shared/context/client.context";
import { updatePregunta } from "../../../../shared/middlewares/examenes.middleware";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { Form as FormikForm, Formik } from 'formik';
import { FormTextarea } from "@imaginagroup/bit-components.ui.form-textarea";

interface Props {
    pregunta: PreguntaInt;
    Refresh: () => void;
}

export const EditPregunta = ({ pregunta, Refresh }: Props) => {
    const client = useClient();
    const toast = useToast();
    const [loading, setLoading] = useState<boolean>(false)

    const initialValues = {
        contenido: pregunta?.contenido,
    }

    const handleSubmit = async (values: any) => {
        setLoading(true);

        return updatePregunta({
            id: pregunta?.id as string,
            data: {
                contenido: values?.contenido,
            },
            client: client
        })
            .then(() => {
                Refresh()
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Campo actualizado correctamente")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setLoading(false))
    }

    return (
        <Flex
            w="100%"
            direction="column"
            px="5px"
            pt="10px"
        >
            <Formik
                onSubmit={handleSubmit}
                enableReinitialize
                initialValues={initialValues}
            >
                {(formik) => {
                    const { handleSubmit, values } = formik;

                    return (
                        <FormikForm
                            onSubmit={handleSubmit}
                        >
                            <Flex
                                alignItems="center"
                                gap="10px"
                                justifyContent="flex-end"
                                mb="20px"
                            >
                                <Button
                                    h="fit-content"
                                    w="fit-content"
                                    py="10px"
                                    px="10px"
                                    rounded={"6px"}
                                    bg="secondary"
                                    fontSize="14px"
                                    color="purewhite"
                                    fontWeight="400"
                                    type="submit"
                                    isDisabled={!pregunta?.id || values?.contenido === pregunta?.contenido}
                                    isLoading={loading}
                                >
                                    Guardar pregunta
                                </Button>

                                <Button
                                    isDisabled={loading}
                                    h="fit-content"
                                    w="fit-content"
                                    py="10px"
                                    px="10px"
                                    rounded={"6px"}
                                    border="1px solid"
                                    borderColor="#AAA"
                                    bg="white"
                                    fontSize="14px"
                                    color="pureblack"
                                    fontWeight="400"
                                    onClick={() => formik.resetForm()}
                                >
                                    Descartar cambios
                                </Button>
                            </Flex>

                            <Flex>
                                <InformationBox
                                    title="Pregunta"
                                >
                                    <Flex
                                        px="20px"
                                        pb="10px"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        gap={"5px"}
                                    >
                                        <FormTextarea
                                            noHead
                                            name="contenido"
                                            ui={{
                                                minHeigh: "150px",
                                                styleLabel: {
                                                    color: "font",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                },
                                                styles: {
                                                    border: "1px solid",
                                                    borderColor: "#E2E8F0",
                                                    borderRadius: "5px",
                                                    color: "font",
                                                    padding: "5px",
                                                    maxHeight: "100px",
                                                }
                                            }}
                                        />
                                    </Flex>
                                </InformationBox>
                            </Flex>
                        </FormikForm>
                    );
                }}
            </Formik>
        </Flex >
    )
}