import { Flex } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import { InformationRuta } from "./views/Information/Information";
import { RutasTable } from "./views/Table/Table";


export const Rutas = () => {
    return (
        <Flex
            direction="column"
            w="100%"
        >
            <Helmet>
                <title>Hojas de Ruta | Proacademy</title>
            </Helmet>
            <Flex direction="column">
                <Routes>
                    <Route
                        index
                        element={
                            <RutasTable />
                        }
                    />

                    <Route
                        path="/:id"
                        element={
                            <InformationRuta />
                        }
                    />
                </Routes>
            </Flex>
        </Flex>
    )
};