// PrimeReact
import { DataTable } from 'primereact/datatable';
import { Box, Flex, Skeleton, Spinner, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import "./GroupTable.scss"
import { Column } from 'primereact/column';

interface Props {
    data: any[];
    columns: any;
    groupRowsBy: string;
    emptyMessage?: string;
    style?: React.CSSProperties;
    loading: boolean;
    rowGroupHeaderTemplate?: any
    handleRowClick?: (e: any) => void;
    total?: any
    showHeaders?: any
    skeletonHeight?: any
    setQuery: (query: Record<string, string | number | string[]>) => void;
    query: Record<string, string | number | string[]>;
}

export const GroupTable = ({
    data,
    columns,
    groupRowsBy,
    loading,
    style,
    emptyMessage = "No hay datos para mostrar",
    rowGroupHeaderTemplate = (e: any) => { },
    handleRowClick = (e: any) => { },
    total,
    showHeaders,
    skeletonHeight = 40,
    setQuery,
    query
}: Props) => {
    const [expandedRows, setExpandedRows] = useState<any>([]);
    const [limit, setLimit] = useState<10 | 15 | 20 | 25>(10);
    const [sortBy, setSortBy] = useState<string | undefined>(groupRowsBy);
    const [sortOrder, setSortOrder] = useState<0 | 1 | -1 | null | undefined>();
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        let options = Object.fromEntries(Object.entries(query).filter(q => q[0] !== "page" && q[0] !== "limit" && q[0] !== "sortBy" && q[0] !== "order"))
        options = sortOrder === 1 ? { order: "asc", ...options } : sortOrder === -1 ? { order: "desc", ...options } : { ...options };
        options = sortBy !== undefined ? { sortBy: sortBy, ...options } : { ...options };

        setQuery({
            page: page,
            limit: limit,
            ...options
        });

    }, [page, sortBy, sortOrder, limit]);

    const bodyTemplate = () => {
        return <Skeleton flex="1" height={`${skeletonHeight}px`}></Skeleton>
    }

    const getSkeleton = () => {
        return [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}].map((c: any, index: number) => (
            <Column body={bodyTemplate} key={index} />
        ))
    }


    return (
        <Flex
            w="100%"
            className='GroupTable'
            border="1px solid"
            borderColor="light_grey"
            bg="purewhite"
            rounded="14px"
            overflow="hidden"
            justify="space-between"
            zIndex="600"
        >
            {loading ? (
                <DataTable
                    emptyMessage={emptyMessage}
                    lazy
                    rows={10}
                    showHeaders={showHeaders}
                    totalRecords={total}
                    value={[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                >
                    {getSkeleton()}
                </DataTable>
            ) : data && data?.length > 0 ? (
                <DataTable
                    value={data}
                    style={style}
                    groupRowsBy={groupRowsBy}
                    onRowClick={handleRowClick}
                    expandableRowGroups
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    rowGroupMode={"subheader"}
                    rowGroupHeaderTemplate={rowGroupHeaderTemplate}
                    sortField={sortBy}
                    sortOrder={sortOrder}
                >
                    {columns}
                </DataTable>
            )
                :
                <Text
                    p="20px 40px"
                    color="dark_grey"
                >
                    {emptyMessage}
                </Text>
            }
        </Flex>
    )
}

function setQuery(arg0: { page: any; limit: any; }) {
    throw new Error('Function not implemented.');
}
