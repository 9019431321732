import { Flex } from "@chakra-ui/react"
import { Route, Routes } from "react-router-dom";
import { InformationDashboard } from "./views/Information";


export const Dashboard = () => {
    return (
        <Flex
            direction="column"
            w="100%"
        >
            <Flex>
                <Routes>
                    <Route
                        index
                        element={
                            <InformationDashboard />
                        }
                    />
                </Routes>
            </Flex>
        </Flex>
    )
};