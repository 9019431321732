import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, ModalFooter, Button, useToast, Box, useDisclosure, ModalCloseButton } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { StatusEnumTypes } from "../../../shared/utils/Types/StatusEnumTypes";
import { toastNotify } from "../../../shared/utils/functions/toastNotify";
import { ModalCloseAlert } from "../../../shared/components/Modals/ModalCloseAlert";
import { useState } from "react";
import { handleErrors } from "../../../shared/utils/functions/handleErrors";
import { useClient } from "../../../shared/context/client.context";
import { useParams } from "react-router-dom";
import { FormAsyncSelect } from "@imaginagroup/bit-components.ui.form-async-select";
import { formSelectStyles } from "../../../ui/formSelectStyles";
import { loadData } from "../../../shared/utils/functions/loadData";
import { EndpointTypes } from "../../../shared/utils/Types/EndpointTypes";
import { QueryTypes } from "../../../shared/utils/Types/QueryTypes";
import { updateRuta } from "../../../shared/middlewares/rutas.middleware";
import { CursosInt } from "../../../interfaces/CursosInt";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    cursos: CursosInt[];
    refresh: () => void;
}
export const AddCursoModal = ({
    isOpen,
    onClose,
    cursos,
    refresh
}: Props) => {
    const { id } = useParams();
    const toast = useToast();
    const client = useClient();
    const { onOpen: onOpenAlert, onClose: onCloseAlert, isOpen: isOpenAlert } = useDisclosure();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const initialValues = {
        cursoId: null,
        duracion: null,
    };

    const validationSchema = Yup.object({
        cursoId: Yup.string()
            .required("El curso es requerido")
            .typeError("El curso es requerido"),
        duracion: Yup.number()
            .required("La duración es requerida")
            .typeError("La duración es requerida"),
    });

    const handleSubmit = (values: any) => {
        setIsLoading(true)

        const orden = cursos?.length + 1 || 0

        let addCursos = {
            [values.cursoId]: {
                duracion: values.duracion,
                orden: orden
            }
        }

        cursos?.map((curso: CursosInt) => {
            addCursos = {
                ...addCursos,
                [curso.id!]: {
                    duracion: curso.duracion,
                    orden: curso?.meta?.pivot_orden
                }
            }
        })

        updateRuta({
            id: id as string,
            data: {
                cursos: addCursos as any
            },
            client: client
        })
            .then(() => {
                refresh()
                onClose()
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setIsLoading(false))
    }

    const loadCursos = async (value: string) => await loadData({
        value: value,
        endpoint: EndpointTypes.CURSOS,
        client: client,
        query: QueryTypes.NOMBRE,
        specificQuery: QueryTypes.ACTIVO
    })

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => null}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                size="xl"
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    maxH="95vh"
                    rounded="20px"
                >
                    <Box
                        color="black"
                    >
                        <ModalHeader
                            textAlign="start"
                            fontSize="24px"
                            fontWeight="600"
                            px="30px"
                            pt="30px"
                            pb="0px"
                        >
                            Añadir Curso
                        </ModalHeader>

                        <ModalCloseButton onClick={() => onOpenAlert()} />
                    </Box>

                    <Box
                        overflowY="auto"
                        __css={{
                            '&::-webkit-scrollbar': {
                                w: '7px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '20px',
                                bg: `light_grey`,
                            },
                        }}
                    >
                        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                            {(formik) => {
                                const { handleSubmit, values } = formik;

                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                    >
                                        <ModalBody
                                            p="30px"
                                        >
                                            <Flex
                                                direction="column"
                                                gap="5px"
                                            >
                                                <FormAsyncSelect
                                                    name="cursoId"
                                                    label="Curso asociado"
                                                    loadOptions={loadCursos}
                                                    defaultOptions={true}
                                                    placeholder="Curso asociado"
                                                    isRequired={!values.cursoId ? true : false}
                                                    ui={{
                                                        formSelectStyles: formSelectStyles
                                                    }}
                                                />

                                                <FormInput
                                                    name="duracion"
                                                    label="Duración del curso"
                                                    placeholder="Duración del curso en días - EJ: 30"
                                                    type="number"
                                                    isRequired={!values.duracion ? true : false}
                                                    ui={{
                                                        styleInput: {},
                                                        styleLabel: {
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            color: "pureblack"
                                                        }
                                                    }}
                                                />
                                            </Flex>
                                        </ModalBody>

                                        <ModalFooter justifyContent="center">
                                            <Button
                                                isLoading={isLoading}
                                                type="submit"
                                                bg="secondary"
                                                p="10px 25px"
                                                h="fit-content"
                                                rounded="5"
                                                color="#FFF"
                                                fontSize="18px"
                                                fontWeight="500"
                                                _hover={{ color: "purewhite", backgroundColor: "variant" }}
                                            >
                                                Añadir
                                            </Button>
                                        </ModalFooter>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Box>
                </ModalContent>
            </Modal >

            <ModalCloseAlert
                isOpen={isOpenAlert}
                onClose={onCloseAlert}
                onCloseForm={onClose}
            />
        </>
    )
}