import {
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    Box,
    Image,
    Text,
    Flex
} from '@chakra-ui/react'
import { AnunciosInt } from '../../../../interfaces/AnunciosInt';
import { useTimeZone } from '../../../../shared/hooks/useTimeZone';
import { formatTimezone } from '../../../../shared/utils/functions/parseDate';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    anuncios: AnunciosInt | null;
}

export const AnunciosDrawer = ({ anuncios, isOpen, onClose }: Props) => {
    const timeZone = useTimeZone();

    return (
        <Drawer
            isOpen={isOpen}
            onClose={onClose}
            placement='right'
        >
            <DrawerOverlay />

            <DrawerContent
                maxW="40vw"
                minW="40vw"
                w="40vw"
            >
                <DrawerBody
                    className='scroll-bar'
                >
                    <Flex
                        direction="column"
                        gap="5px"
                        mb="20px"
                    >
                        <Text
                            fontSize="18px"
                            fontWeight="700"
                            textTransform="uppercase"
                        >
                            Titulo
                        </Text>

                        <Text
                            fontSize="16px"
                            fontWeight="400"
                        >
                            {anuncios?.titulo}
                        </Text>
                    </Flex>

                    {anuncios?.imagen &&
                        <Box
                            mb="20px"
                        >
                            <Image
                                src={anuncios?.imagen?.url}
                                alt={anuncios?.titulo}
                                width="100%"
                                height="100%"
                            />
                        </Box>
                    }

                    <Flex
                        direction="column"
                        gap="5px"
                        mb="20px"
                    >
                        <Text
                            fontSize="18px"
                            fontWeight="700"
                            textTransform="uppercase"
                        >
                            Contenido
                        </Text>

                        <Text
                            fontSize="16px"
                            fontWeight="400"
                        >
                            {anuncios?.contenido}
                        </Text>
                    </Flex>

                    <Text
                        fontSize="15px"
                        fontWeight="400"
                        mb="20px"
                    >
                        <Text as="span" fontSize="15px" fontWeight="600" mr="5px">Destino:</Text>
                        {anuncios?.general ? 'General' : anuncios?.grupo?.nombre}
                    </Text>

                    <Flex
                        direction="column"
                        fontSize="13px"
                        fontWeight="400"
                        gap="5px"
                    >
                        <Text>
                            <Text as="span" fontSize="13px" fontWeight="600" mr="5px">Creacion:</Text>
                            {anuncios?.createdAt && formatTimezone({ date: anuncios?.createdAt as string, timeZone })}
                        </Text>

                        <Text>
                            <Text as="span" fontSize="13px" fontWeight="600" mr="5px">Expiracion:</Text>
                            {anuncios?.fechaExpiracion ? formatTimezone({ date: anuncios?.fechaExpiracion as string, timeZone }) : 'Sin fecha de expiración'}
                        </Text>
                    </Flex>

                </DrawerBody>
            </DrawerContent>
        </Drawer >
    );
}