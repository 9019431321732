import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, ModalFooter, Button, useToast, Box, ModalCloseButton, Text } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { useState } from "react";
import { useClient } from "../../../../shared/context/client.context";
import { GruposInt } from "../../../../interfaces/GruposInt";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { EmpresasInt } from "../../../../interfaces/EmpresasInt";
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { getGuiaInicio } from "../../../../shared/middlewares/informes.middleware";


interface Props {
    isOpen: boolean;
    onClose: () => void;
    grupo: GruposInt | undefined;
    empresa: EmpresasInt | undefined;
}
export const GuiaInicioModal = ({
    isOpen,
    onClose,
    grupo,
    empresa
}: Props) => {
    const client = useClient();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const initialValues = {
        email: null,
    };

    const validationSchema = Yup.object({
        email: Yup.string()
            .email("El email no es válido")
            .notRequired()
            .nullable()
    });

    const handleSubmit = async (values: any) => {
        if (!grupo?.id || !empresa?.id) return toastNotify(toast, "No se ha podido enviar la guía de inicio", StatusEnumTypes.ERROR);
        setIsLoading(true);

        let data: {
            grupoId: string;
            empresaId: string;
            debug: boolean;
            email?: string | undefined;

        } = {
            grupoId: grupo?.id,
            empresaId: empresa?.id,
            debug: true,
        }

        if (values.email) data = { ...data, email: values.email }

        getGuiaInicio({
            data,
            client
        }).then(() => {
            toastNotify(toast, StatusEnumTypes.SUCCESS, "Se ha enviado la guía de inicio correctamente");
            onClose();
        })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => null}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size="xl"
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                maxH="95vh"
                rounded="20px"
            >
                <Box
                    color="purewhite"
                    bg="black"
                    roundedTop="20px"
                >
                    <ModalHeader
                        textAlign="center"
                        fontSize="28px"
                        fontWeight="600"
                        letterSpacing="-0.32px"
                    >
                        Inicio FUNDAE
                    </ModalHeader>

                    <ModalCloseButton onClick={() => onClose()} />
                </Box>

                <Box
                    m="10px"
                    overflowY="auto"
                    overflowX="hidden"
                    __css={{
                        '&::-webkit-scrollbar': {
                            w: '7px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: '20px',
                            bg: `light_grey`,
                        },
                    }}
                >
                    <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                        {(formik) => {
                            const { handleSubmit } = formik;

                            return (
                                <FormikForm
                                    onSubmit={handleSubmit}
                                >
                                    <ModalBody
                                        my="20px"
                                        display="flex"
                                        flexDirection="column"
                                        gap="30px"
                                    >
                                        <Flex
                                            direction="column"
                                            justifyContent={"center"}
                                            alignItems="center"
                                        >
                                            <Text
                                                fontSize={"16px"}
                                                fontWeight="500"
                                                color={"font"}
                                            >
                                                Los datos del grupo se enviarán a la siguiente dirección de correo:
                                            </Text>

                                            <Text
                                                fontSize={"20px"}
                                                fontWeight="700"
                                                color={"secondary"}
                                            >
                                                {empresa?.email}
                                            </Text>
                                        </Flex>

                                        <Text
                                            fontSize={"16px"}
                                            fontWeight="500"
                                            color={"font"}
                                            textAlign="center"
                                        >
                                            ¡En caso de que el email no sea correcto, por favor, indique el email de texto a continuación, caso contrario pulse enviar!
                                        </Text>

                                        <FormInput
                                            name="email"
                                            label="Email"
                                            placeholder="Email"
                                        />
                                    </ModalBody>

                                    <ModalFooter justifyContent="center">
                                        <Button
                                            isLoading={isLoading}
                                            type="submit"
                                            bg="black"
                                            p="10px 25px"
                                            h="fit-content"
                                            rounded="5"
                                            color="purewhite"
                                            fontSize="18px"
                                            fontWeight="600"
                                            _hover={{ color: "black", backgroundColor: "light_grey" }}
                                        >
                                            Enviar
                                        </Button>
                                    </ModalFooter>
                                </FormikForm>
                            );
                        }}
                    </Formik>
                </Box>
            </ModalContent>
        </Modal>
    )
}