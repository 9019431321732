import { Flex, Text } from "@chakra-ui/layout"
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleUnauthorized } from "../../shared/utils/functions/handleErrors";
import { useClient } from "../../shared/context/client.context";
import { useAuthContex } from "../../shared/context/auth.context";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { useData } from "../../shared/hooks/useData";
import { EndpointTypes } from "../../shared/utils/Types/EndpointTypes";
import { FiltrosNames, FiltrosTypes } from "../../shared/utils/Types/FiltrosEnum";
import { CorreccionDrawer } from "./Components/CorreccionDrawer";
import { Topbar } from "../../shared/components/Topbar/Topbar";
import { Helmet } from "react-helmet";
import { EmptyMessageType } from "../../shared/utils/Types/EmptyMessageType";
import { Table } from "../../shared/components/Table/Table";
import { CorreccionesColumns } from "./Columns/CorreccionesColumns";
import { Filters } from "../../shared/components/Filters/Filters";
import { EntityTypes, useQuerySession } from "../../shared/hooks/useQuerySession";

export const Correcciones = () => {
    const client = useClient();
    const { logout } = useAuthContex()
    const navigate = useNavigate()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast()
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.CORRECCIONES });
    const entregableRef = useRef(null)
    const { data: entregables, loading, error, status, errorsList } = useData({
        client: client,
        endpoint: EndpointTypes.ENTREGABLES,
        query: query
    });
    const [hasFilter, setHasFilters] = useState<boolean>();

    useEffect(() => {
        if (error)
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
    }, [error])

    const handleClick = (data: any) => {
        entregableRef.current = data
        onOpen()
    }

    return (
        <>
            <Helmet>
                <title>Correcciones | Proacademy</title>
            </Helmet>
            <Topbar
                title={
                    <Text
                        color="font"
                        fontSize="20px"
                        fontWeight="400"
                    >
                        Correcciones
                    </Text>
                }
            >
                <Flex direction="column" gap="20px" p="30px">
                    <Filters
                        setHasFilters={setHasFilters}
                        query={query}
                        setQuery={setQuery}
                        allowToggle={true}
                        filterElements={[
                            {
                                name: FiltrosNames.ESTADO_ENTREGABLE,
                                type: FiltrosTypes.SELECT,
                            },
                            {
                                name: FiltrosNames.GRUPO,
                                type: FiltrosTypes.ASYNC_SELECT,
                            },
                            {
                                name: FiltrosNames.TUTOR,
                                type: FiltrosTypes.ASYNC_SELECT,
                            },
                            {
                                name: FiltrosNames.FECHA_ENTREGA,
                                type: FiltrosTypes.DATE,
                            },
                        ]}
                    />

                    <Table
                        query={query}
                        setQuery={setQuery}
                        data={entregables?.data || []}
                        columns={CorreccionesColumns({ handleClick })}
                        isLoading={loading}
                        total={entregables?.meta?.total || 0}
                        emptyMessage={hasFilter ? EmptyMessageType.correccionesFiltros : EmptyMessageType.correcciones}
                    />

                    <CorreccionDrawer
                        isOpen={isOpen}
                        onClose={onClose}
                        entregable={entregableRef.current}
                    />
                </Flex>
            </Topbar>
        </>
    )
}