const formSelectStyles = {
    container: (styles: any, { isDisabled, isFocused }: any) => ({
        ...styles,
        cursor: isDisabled ? 'not-allowed' : 'default',
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "16px",
        letterSpacing: "-0.078p",
        zIndex: isFocused ? "999" : "0",
    }),
    singleValue: (styles: any) => ({
        ...styles,
        color: "#000",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "16px",
        letterSpacing: "-0.078p",
    }),
    indicatorSeparator: (styles: any) => ({ display: 'none' }),
    menuList: (styles: any) => ({ 
        ...styles,
        maxHeight: "300px",
    }),
    control: (styles: any) => ({
        ...styles,
        borderRadius: '5px',
        borderColor: 'light_grey',
        backgroundColor: "#FFFFFF",
        padding: "1.5px",
    }),
    option: (styles: any, { isFocused }: any) => ({
        ...styles,
        cursor: 'pointer',
        textAlign: 'left',
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "16px",
        letterSpacing: "-0.078p",
        color: "#000",
        backgroundColor: isFocused ? '#CCC' : "#FFFFFF",
    }),
    placeholder: (styles: any) => ({
        ...styles,
        color: "#8C909C",
        fontSize: "13px",
        fontWeight: "500",
        lineHeight: "16px",
        letterSpacing: "-0.078px",
    }),
};

export { formSelectStyles };