import sindatacursos from "../../../assets/img/sindatacursos.png";
import sindatagrupos from "../../../assets/img/sindatagrupos.png";
import sindatamatriculas from "../../../assets/img/sindatamatriculas.png";
import sindatausuarios from "../../../assets/img/sindatausuarios.png";
import sindataempresas from "../../../assets/img/sindataempresas.png";
import sindatacorrecciones from "../../../assets/img/sindatacorrecciones.png";


export const EmptyMessageType = {
    cursos: {
        title: "Aún no existen cursos",
        src: sindatacursos
    },
    grupos: {
        title: "Aún no existen grupos",
        src: sindatagrupos
    },
    matriculas: {
        title: "Aún no existen matrículas",
        src: sindatamatriculas
    },
    usuarios: {
        title: "Aún no existen usuarios",
        src: sindatausuarios
    },
    empresas: {
        title: "Aún no existen empresas",
        src: sindataempresas
    },
    correcciones: {
        title: "Aún no existen correcciones",
        src: sindatacorrecciones
    },
    foros: {
        title: "No existen dudas pendientes",
        src: sindatacorrecciones
    },
    cursosFiltros: {
        title: "No se han encontrado cursos con los filtros seleccionados",
        src: sindatacursos
    },
    gruposFiltros: {
        title: "No se han encontrado grupos con los filtros seleccionados",
        src: sindatagrupos
    },
    matriculasFiltros: {
        title: "No se han encontrado matrículas con los filtros seleccionados",
        src: sindatamatriculas
    },
    usuariosFiltros: {
        title: "No se han encontrado usuarios con los filtros seleccionados",
        src: sindatausuarios
    },
    empresasFiltros: {
        title: "No se han encontrado empresas con los filtros seleccionados",
        src: sindataempresas
    },
    correccionesFiltros: {
        title: "No se han encontrado correcciones con los filtros seleccionados",
        src: sindatacorrecciones
    },
    forosFiltros: {
        title: "No se han encontrado dudas con los filtros seleccionados",
        src: sindatacorrecciones
    }
}