import { Modal, ModalOverlay, ModalContent, ModalBody, Text, ModalFooter, Button, ModalCloseButton, Link, Flex } from "@chakra-ui/react";
import { useDataId } from "../../hooks/useDataId";
import { useClient } from "../../context/client.context";
import { EndpointTypes } from "../../utils/Types/EndpointTypes";
import { ConfigEnum } from "../../utils/Types/ConfigTypes";
import { Link as RouterLink } from "react-router-dom";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export const ModalFinalConfig = ({ isOpen, onClose }: Props) => {
    const client = useClient()
    const { data } = useDataId({
        client: client,
        endpoint: EndpointTypes.CAMPUS_CONFIG_KEY,
        id: ConfigEnum.USER_URL
    })

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose()}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size="2xl"
            isCentered
        >
            <ModalOverlay />

            <ModalContent
                borderRadius="10px"
            >
                <ModalCloseButton />

                <ModalBody
                    px="40px"
                    py="30px"
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Text
                        color="secondary"
                        fontSize="24px"
                        fontWeight="600"
                        lineHeight="28px"
                    >
                        Configuración finalizada
                    </Text>

                    <Text
                        mt="30px"
                        color="main"
                        fontSize="16px"
                        fontWeight="400"
                        lineHeight="24px"
                    >
                        ¡Listo! Has completado la configuración inicial. Ahora puedes comenzar a gestionar tu campus y explorar todas las funcionalidades disponibles.
                    </Text>

                    <Flex
                        mt="20px"
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        gap="2px"
                    >
                        <Text
                            color="main"
                            fontSize="15px"
                            fontWeight="600"
                            lineHeight="24px"
                        >
                            RECUERDA: Este es el enlace para acceder a tu campus:
                        </Text>

                        <Link
                            as={RouterLink}
                            isExternal
                            to={data}
                            color="main"
                            fontSize="15px"
                            fontWeight="400"
                            lineHeight="24px"
                            _hover={{ textDecoration: "underline", color: "secondary" }}
                        >
                            {data}
                        </Link>
                    </Flex>

                    <Text
                        mt="30px"
                        color="secondary"
                        fontSize="13px"
                        fontWeight="400"
                        lineHeight="24px"
                    >
                        Puedes modificar la configuración en cualquier momento desde el menú de configuración.
                    </Text>
                </ModalBody>

                <ModalFooter justifyContent="end" gap="15px">
                    <Button
                        type="submit"
                        bg="secondary"
                        p="8px 16px"
                        rounded="4"
                        color="purewhite"
                        fontSize="16px"
                        lineHeight="24px"
                        fontWeight="600"
                        onClick={onClose}
                    >
                        Acpetar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}