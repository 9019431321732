import { Box, Flex, Icon, Img, useToast } from "@chakra-ui/react"
import { ExamenInt } from "../../../../../interfaces/ExamenInt";
import { InformationBox } from "../../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { InformationInput } from "@imaginagroup/bit-components.ui.elements.information-input"
import { CustomSwitch } from "../../../../../shared/components/CustomElements/CustomSwitch";
import { InformationFilepond } from "../../../../../shared/components/Information/InformationFilepond";
import { useClient } from "../../../../../shared/context/client.context";
import { BiLock, BiPencil } from "react-icons/bi";
import { useEffect, useState } from "react";
import { InformationTextEditor } from "@imaginagroup/bit-components.ui.elements.information-text-editor"
import { InformationTimePicker } from "../../../../../shared/components/Information/InformationTimePicker";
import { SkeletonPerfilExamenes } from "../../../components/utils/ExamenesPerfilSkeleton";
import { toastNotify } from "../../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../../shared/utils/Types/StatusEnumTypes";

interface Props {
    examen: ExamenInt | undefined;
    updateValue: (value: any) => void;
    Refresh: () => void;
    loading: boolean;
}

export const TabPerfilExamen = ({
    examen,
    updateValue,
    Refresh,
    loading
}: Props) => {
    const toast = useToast();
    const client = useClient();
    const [editNombre, setEditNombre] = useState<boolean>(false);
    const [editDuracion, setEditDuracion] = useState<boolean>(false);
    const [editIntentos, setEditIntentos] = useState<boolean>(false);
    const [editEstado, setEditEstado] = useState<boolean>(false);
    const [estado, setEstado] = useState<boolean>();

    useEffect(() => {
        examen?.publicado === null || examen?.publicado === undefined
            ? setEstado(false)
            : setEstado(examen?.publicado)
    }, [examen])

    const updateEstado = () => {
        if (
            !examen?.id ||
            estado === null ||
            estado === undefined
        ) return

        updateValue({ publicado: !estado })
    }

    return (
        loading ?
            <SkeletonPerfilExamenes />
            :
            <Flex gap="20px">
                <Flex direction="column" flex="1">
                    <InformationBox title="Examen">
                        <Box>
                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditNombre(true)}
                                onMouseLeave={() => setEditNombre(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="nombre"
                                    label="Nombre"
                                    defaultValue={examen?.nombre}
                                    updateValue={updateValue}
                                />

                                <Flex boxSize="20px">
                                    {editNombre && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <Flex w="100%">
                                    <InformationInput
                                        onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                        name="curso"
                                        label="Curso"
                                        defaultValue={examen?.curso?.nombre || ""}
                                        updateValue={updateValue}
                                        isDisabled={true}
                                    />
                                </Flex>

                                <Icon as={BiLock} boxSize="20px" />
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                alignItems="center"
                                justifyContent="space-between"
                                pr="18px"
                                onMouseEnter={() => setEditEstado(true)}
                                onMouseLeave={() => setEditEstado(false)}
                            >
                                <CustomSwitch
                                    label="Estado"
                                    optionLeft="Publicado"
                                    optionRight="Oculto"
                                    setValue={setEstado}
                                    value={estado}
                                    colorSwitch="light_grey"
                                    onClick={updateEstado}
                                />

                                <Flex boxSize="22px">
                                    {editEstado && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                p="10px 20px"
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditDuracion(true)}
                                onMouseLeave={() => setEditDuracion(false)}
                                gap="10px"
                            >
                                <InformationTimePicker
                                    name="duracion"
                                    label="horas"
                                    defaultValue={examen?.duracion}
                                    updateValue={updateValue}
                                />

                                <Flex boxSize="20px">
                                    {editDuracion && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditIntentos(true)}
                                onMouseLeave={() => setEditIntentos(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="numIntentos"
                                    label="Cantidad de intentos"
                                    type="number"
                                    min={0}
                                    defaultValue={examen?.numIntentos}
                                    updateValue={updateValue}
                                />

                                <Flex boxSize="20px">
                                    {editIntentos && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>
                        </Box>
                    </InformationBox>

                    <InformationBox
                        title="Portada"
                    >
                        <>
                            {examen?.imagen?.url &&
                                <Img
                                    p="20px"
                                    m="auto"
                                    h="full"
                                    boxSize="100%"
                                    rounded="10px"
                                    src={examen?.imagen?.url}
                                />
                            }

                            <Flex
                                p="10px 20px"
                            >
                                <InformationFilepond
                                    noHead={true}
                                    name="imagen"
                                    label="Portada"
                                    putEP={`${client}/examenes/${examen?.id}`}
                                    isDisabled={!examen?.id}
                                    setRefresh={Refresh}
                                />
                            </Flex>
                        </>

                    </InformationBox>
                </Flex>

                <Flex
                    direction="column"
                    flex="1"
                >
                    <InformationBox title="Descripción">
                        <Box>
                            <InformationTextEditor
                                onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                name="descripcion"
                                placeholder="Introduce el texto"
                                defaultValue={examen?.descripcion}
                                updateValue={updateValue}
                                ui={{
                                    containerStyle: {
                                        padding: "10px",
                                    },
                                    editorStyle: {
                                        border: "none",
                                        borderRadius: "0 0 10px 10px"
                                    }
                                }}
                            />
                        </Box>
                    </InformationBox>
                </Flex>
            </Flex>
    )
}