import { Flex } from "@chakra-ui/react"
import { useClient } from "../../../../../shared/context/client.context";
import { useNavigate } from "react-router-dom";
import { useDataRelationship } from "../../../../../shared/hooks/useDataRelationship";
import { EndpointTypes, RelationshipTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { FiltrosNames, FiltrosTypes } from "../../../../../shared/utils/Types/FiltrosEnum";
import { useModuloFundae } from "../../../../../shared/hooks/ModuloFundae";
import { Table } from "../../../../../shared/components/Table/Table";
import { GruposCursoColumns } from "../../../components/TabsColumns/GruposCursoColumns";
import { Filters } from "../../../../../shared/components/Filters/Filters";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";
import { useState } from "react";
import { EmptyMessageType } from "../../../../../shared/utils/Types/EmptyMessageType";

interface Props {
    cursoId: string | undefined;
}

export const TabTableCurso = ({ cursoId }: Props) => {
    const client = useClient();
    const moduloFUNDAE = useModuloFundae();
    const navigate = useNavigate();
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.GRUPOS_CURSO });
    const { data: grupos, loading } = useDataRelationship({
        id: cursoId,
        client: client,
        endpoint: EndpointTypes.CURSOS,
        relationship: RelationshipTypes.GRUPOS,
        query: query
    })
    const [hasFilter, setHasFilters] = useState<boolean>();

    return (
        <Flex direction="column" gap="20px" w="100%" boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)" borderColor="light_grey">
            <Filters
            setHasFilters={setHasFilters}
                query={query}
                setQuery={setQuery}
                allowToggle={true}
                filterHeaders={[
                    {
                        name: FiltrosNames.NOMBRE,
                        type: FiltrosTypes.INPUT,
                    },
                ]}
                filterElements={[
                    {
                        name: FiltrosNames.ESTADO_GRUPO,
                        type: FiltrosTypes.SELECT,
                    },
                    {
                        name: FiltrosNames.FUNDAE,
                        type: FiltrosTypes.SELECT,
                    },
                    {
                        name: FiltrosNames.FECHA_INICIO_SUP,
                        type: FiltrosTypes.DATE,
                    },
                    {
                        name: FiltrosNames.FECHA_FIN_INF,
                        type: FiltrosTypes.DATE,
                    },
                    {
                        name: FiltrosNames.EMPRESA,
                        type: FiltrosTypes.ASYNC_SELECT,
                    },
                ]}
            />

            <Table
                query={query}
                setQuery={setQuery}
                data={grupos?.data || []}
                columns={GruposCursoColumns({ moduloFUNDAE })}
                isLoading={loading}
                total={grupos?.meta?.total || 0}
                emptyMessage={hasFilter ? EmptyMessageType.gruposFiltros : EmptyMessageType.grupos}
            />
        </Flex>
    )
}