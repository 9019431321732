import { Flex, Icon, Text, useToast } from "@chakra-ui/react";
import { BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { TipoSelect } from "../../views/Information/TabItem/TabContenido";
import { PreguntaInt, RespuestaInt } from "../../../../interfaces/ExamenInt";
import { useClient } from "../../../../shared/context/client.context";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { updateRespuesta } from "../../../../shared/middlewares/examenes.middleware";
import { Dispatch, SetStateAction } from "react";

interface Props {
    respuesta: RespuestaInt;
    setSelected: Dispatch<SetStateAction<{ type: TipoSelect; data: PreguntaInt | RespuestaInt | null; } | undefined>>;
    selected: { type: string, data: PreguntaInt | RespuestaInt | null } | undefined;
    refreshData: () => void;
}

export const RespuestasModule = ({
    respuesta,
    setSelected,
    selected,
    refreshData
}: Props) => {
    const client = useClient()
    const toast = useToast()

    const changeEstadoRespuesta = async (
        event: React.MouseEvent,
    ) => {
        event.stopPropagation()

        return updateRespuesta({
            id: respuesta?.id as string,
            data: {
                publicado: !respuesta?.publicado
            },
            client: client
        })
            .then(() => {
                const msg = respuesta?.publicado ? `Respuesta ocultado` : `Respuesta publicado`
                toastNotify(toast, StatusEnumTypes.SUCCESS, msg)

                refreshData()
            }
            )
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    }

    return (
        <Flex
            cursor={"pointer"}
            position="relative"
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            py="25px"
            px="15px"
            rounded="5px"
            border="1px solid"
            borderColor={selected?.data?.id === respuesta?.id ? "secondary" : "#DFE2E6"}
            onClick={(event: React.MouseEvent) => {
                event.stopPropagation();

                setSelected({
                    type: TipoSelect.RESPUESTA,
                    data: respuesta
                })
            }}
        >
            <Flex
                alignItems="center"
                gap="8px"
            >
                <Icon
                    as={respuesta?.correcta ? BsCheckCircleFill : BsXCircleFill}
                    boxSize={"12px"}
                    color={respuesta?.correcta ? "success" : "fail"}
                />

                <Text
                    color="pureblack"
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="100%"
                    letterSpacing="-0.16px"
                >
                    {respuesta?.contenido}
                </Text>
            </Flex>



            <Icon
                position="absolute"
                top="5px"
                right="5px"
                onClick={(event: React.MouseEvent) => changeEstadoRespuesta(event)}
                _active={{ transform: "scale(0.95)" }}
                as={respuesta?.publicado ? HiOutlineEye : HiOutlineEyeOff}
                color={"pureblack"}
            />
        </Flex>
    )
}